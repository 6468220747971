<template>
    <Form @submit="() => !info ? getInfo() : submit()" center contents>
        <h3 class="section-page-title tx-c">Привязка
            {{ linkType === 'driver' ? 'водителя' : 'диспетчера' }}</h3>
        <h3 class="tx-c clr-ltg-3">Введите код привязки сотрудника</h3>
        <FormItem v-if="!info">
            <FormInput
                required
                v-model="form.code"
            />
        </FormItem>
        <div v-else class="tx-c">
            <h3>{{ info | fullName }}</h3>
            <div class="block-inflect">
                {{ info.phone | mask('phone') }}
            </div>
            <p>
                {{ $service.account.roleName(info.linkType) }}
            </p>
        </div>
        <div class="buttons">
            <button class="btn btn-blue" @click="!info ? getInfo() : submit()">
                {{ !info ? 'Отправить' : 'Подтвердить привязку' }}
            </button>
            <button class="btn btn-white" @click="info ? this.info = null : $emit('toggle', false)">
                Отмена
            </button>
        </div>
    </Form>
</template>

<script>
export default {
    name: "FormStaffLinkConfirm",
    props: {
        linkType: String
    },
    data() {
        return {
            form: {
                code: "",
                linkType: _.toUpper(this.linkType)
            },
            info: null
        }
    },
    methods: {
        getInfo() {
            this.$repo('link_carrier').userInfo(this.form)
                .then(({object}) => this.info = object)
                .alert('Не удалось подтвердить код привязки.')
        },
        submit() {
            this.$repo('link_carrier').acceptCode(this.form)
                .then(() => {
                    this.$ok({
                        text: ['Код привязки подтвержден', 'Ожидается подтверждение от пользователя.'],
                        onConfirm: () => this.$emit('toggle', false)
                    })
                }).alert('Не удалось подтвердить код привязки.')
        }
    }
}
</script>
