<template>
    <div :id="id" class="ya-map"></div>
</template>

<script>

export default {
    name: 'YaMap',
    props: {
        getMap: {
            type: Function,
            default: () => ymaps
        },
        objects: Object,
        options: Object,
    },
    data() {
        return {
            id: this.$attrs.id || this.$uuid.v1(),
            controls: [
                'typeSelector',
                // 'zoomControl',
                // 'routePanelControl'
            ],
            map: null,
            mapObjects: {},
            mapPoints: {},
            iconOptions: {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#image',
                // Своё изображение иконки метки.
                iconImageHref: '',
                // Размеры метки.
                iconImageSize: [30, 30],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [0, 0]
            }
        }
    },
    computed: {
        maps() {
            return this.getMap()
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.maps.ready(() => {
                this.setup()
                Object.keys(this.getObjects()).forEach(id => {
                    this.addObject(id, this.objects[id])
                })
            })
        })
    },
    methods: {
        setup() {
            const options = {
                controls: this.controls,
                zoom: 7,
            };
            if (this.map !== null) {
                this.destroyMapObjects()
                this.map.destroy()
            }
            this.map = new this.maps.Map(this.id, Object.assign(options, this.options || {}))
            this.fitToViewport()
            this.$emit('setup', this)
            return this
        },
        fitToViewport(delay) {
            setTimeout(() => this.map.container.fitToViewport(), _.isNumber(delay) ? delay : 50)
        },
        reset() {
            const objects = {...this.mapObjects}
            this.setup()
            Object.keys(this.mapObjects).forEach(id => {
                this.addObject(id, objects[id])
                this.resolveObject(id)
            })
        },
        getObjects() {
            return _.isPlainObject(this.objects) ? this.objects : {}
        },
        getObject(id) {
            return this.mapObjects[id]
        },
        resolveObject(id, data) {
            let object = this.getObject(id)
            if (!id || !object) return false

            _.isPlainObject(data) && (object = _.merge(object, data))

            if (!this.getPoint(id) && object.lat !== 0 && object.lon !== 0) {
                this.addPoint(id, object)

            }
            // !this.isValidRoute(object.route) || object.routeObject !== null || this.addRoute(id);
            // object.polyLineRoute === undefined || object.polyLineRouteObject !== null || this.addPolyLineRoute(id);
            // if (_.isNumber(object.moveInterval) && object.move === null)
            //     object.move = setInterval(() => this.move(id), parseInt(object.moveInterval) || 5000);
            // !_.isFunction(cb) || cb(object)
        },
        destroyMapObjects() {
            Object.keys(this.mapObjects).forEach(id => this.destroyObject(id))
        },
        addObject(id, params) {
            this.mapObjects[id] = {
                lat: 0,
                lon: 0,
                ...params || {}
            }
        },
        destroyObject(id) {
            this.removePoint(id)
            this.$delete(this.mapObjects, id)
        },
        addPoint(id, params) {

            _.isPlainObject(params) || (params = {})

            const point = new this.maps.Placemark([
                params.lat,
                params.lon
            ], params.content || {}, _.merge({}, this.iconOptions, params.iconOptions || {}))

            this.mapPoints[id] = point

            params.click && point.events.add('click', e => params.click(e))
            params.open && point.events.add('balloonopen', e => params.open(e))

            this.map.geoObjects.add(point)

            this.mapPoints[id].pointLoaded = true
            return point
        },
        getPoint(id) {
            return this.mapPoints[id]
        },
        isPointInViewport(id) {
            if (!this.getPoint(id))
                return false
            let output
            this.maps.geoQuery([this.getPoint(id)]).searchInside(this.map).each(o => output = o)
            return !!output
        },
        toPoint(id, params) {
            const object = this.getObject(id)
            if (!object) return false
            const lat = object.lat
            const lon = object.lon
            lat && lon && this.map.panTo([lat, lon], {flying: true, ...(params || {})})
        },
        removePoint(id) {
            this.mapPoints[id] && this.map.geoObjects.remove(this.mapPoints[id])
            this.$delete(this.mapPoints, id)
        },
        getPointLocation(id) {
            const object = this.getObject(id)
            return [object.lat, object.lon]
        },
        getZoom() {
            return this.map.getZoom()
        },
        setPointZoom(id, zoom) {
            this.getObject(id) && this.map.setCenter(this.getPointLocation(id), zoom)
        },
        move(id, {coord: coord = {lat: 0, lon: 0}, toPoint: toPoint = false, params: params = {}}) {
            const object = this.getObject(id)
            const point = this.getPoint(id)
            if (!point || !object) return false

            const lat = _.isArray(coord) ? coord[0] : coord.lat
            const lon = _.isArray(coord) ? coord[1] : coord.lon

            if (!_.isNumber(lat) || !_.isNumber(lon) || (object.lastLat === lat && object.lastLon === lon))
                return false

            // this.pointBlink(id, true);

            setTimeout(() => {
                point.geometry.setCoordinates([lat, lon])
                object.lastLat = lat
                object.lastLon = lon
                setTimeout(() => {
                    // this.pointBlink(id)
                    (object.moveToPoint === false || toPoint === false) || this.map.panTo([lat, lon], _.merge({flying: true},
                        _.isPlainObject(object.moveParams) ? object.moveParams : {},
                        _.isPlainObject(params) ? params : {}))
                }, 250)
            }, 500)
        },
    },
}

</script>

<style scoped lang="scss">
.ya-map {
    width: 100%;
    height: 100%;
}
</style>
