<template>
    <Form
        buttons="Выполнить"
        @submit="changeStatus"
        inflect
    >
        <h2 class="tx-bold tx-c mt-5">Изменение статуса заказа</h2>
        <FormItem label="Статус">
            <FormSelect
                v-model="setStatus.name"
                :options="statuses[order.id]"
                label="name"
                labelName="title"
                :watch="onChangeStatus"
                required
            />
        </FormItem>
        <FormItem v-if="setStatusPoints && setStatusPoints.length" label="Населенный пункт">
            <FormFindSelect
                :key="setStatus.name"
                v-model="setStatus.cityId"
                objectsArray
                :options="setStatusPoints"
                :required="(v) => !v && setStatus.name.length > 0"
            />
        </FormItem>
        <FormItem
            v-if="order.route.enterLoadingDate === true  && (setStatus.name === 'shipping' || setStatus.name === 'shippingLate')"
            label="Фактическая дата загрузки"
        >
            <FormDate
                :key="setStatus.name"
                v-model="setStatus.actualLoadingDate"
                withTime
                clear
                format="YYYY-MM-DDTHH:mm:ss"
                required
            />
        </FormItem>
        <FormItem
            v-if="order.route.enterCargoPrice === true && (setStatus.name === 'shipping' || setStatus.name === 'shippingLate')"
            label="Цена груза, руб"
        >
            <FormInput
                :key="setStatus.name"
                v-model="setStatus.cargoPrice"
                type="number"
                mask="########"
                required
            />
        </FormItem>
        <FormItem label="Комментарий к изменению статуса">
            <FormText
                v-model="setStatus.comment"
            />
        </FormItem>
    </Form>
<!--    <Popup
        v-if="(orderId in statuses) && statuses[orderId].length"
       :key="setStatus.name"
       title-up="Изменение статуса заказа"
       v-model="setStatus"
       :tablet="false"
       submit="Выполнить"
       @submit="() => changeStatus()"
       zip
       :form="{
                data: setStatus,
                fields: {
                    name: {
                       type: 'select',
                       name: 'Статус',
                       options: statuses[orderId],
                       opKey: 'name',
                       opTitle: 'title',
                       on: {change: e => onChangeStatus(e)},
                       watch: (v, model, form) => {
                            form.toggleFieldCallback('cityId', () => form.updateField('cityId'));
                            form.updateField('actualLoadingDate');
                            form.updateField('cargoPrice')
                       }
                    },
                    cityId: {
                        check: () => setStatusPoints && setStatusPoints.length,
                        component: 'util-dropdown',
                        name: 'Населенный пункт',
                        required: (v, model, form) => !v && form.getValue('name').length > 0,
                        bind: () => ({
                            nullable: true,
                            src: [setStatusPoints, 'id', 'name'],
                            alert: 'Указанный город не найден.',
                        }),
                        timeout: 100,
                        set: v => !v ? null : v
                    },
                    actualLoadingDate: {
                       check: () => order.route.enterLoadingDate === true
                       && setStatus.name
                       && (setStatus.name === 'shipping' || setStatus.name === 'shippingLate'),
                       type: 'date-time',
                       name: 'Фактическая дата загрузки',
                       bind: {clear: true, nullable: true},
                       format: v => mxIsValidDate(v) ? mxDateFormat(v, 'YYYY-MM-DDTHH:mm:ss') : null
                    },
                    cargoPrice: {
                       check: () => order.route.enterCargoPrice === true
                       && setStatus.name
                       && (setStatus.name === 'shipping' || setStatus.name === 'shippingLate'),
                       type: 'number',
                       fill: false,
                       mask: '########',
                       name: 'Цена груза, руб'
                    },
                    comment: {
                       type: 'textarea',
                       required: false,
                       name: 'Комментарий к изменению статуса',
                    },
                },
            }"
    />-->
</template>

<script>
export default {
    name: "FormOrderStatusChange",
    props: {
        order: Object,
        statuses: Object,
        setStatus: Object,
        changeStatus: Function,
        onChangeStatus: Function,
        setStatusPoints: Array,
    }
}
</script>

<style scoped>

</style>
