
export default {
    model: {
        condition: null, // (FILLING|CONFIRMATION_PENDING|HAND_OVER_CONFIRMATION|TAKE_CONFIRMATION|CONFIRMED)//(Заполнение|Ожидаются подтверждения|Подтвержден сдающим|Подтвержден принимающим|Подтвержден)
        readOnly: null, // при false  запретить редактирование, скрыть кнопки сохранения.
        confirmationRequest: null, // при true отображать кнопку "Подтвердить"
        actType: null, // (FULL|SHORT) // тут описана full версия, в short часть полей будет скрыта (описание отдельно)
        // Статус
        city: {
            id: null
        },
        dateTime: null,
        mechanic: { // Механик
            id: 0, // для незарегистрированных = 0
            name: null
        },
        transmittingUser: { // Сдал
            id: 0, // для незарегистрированных = 0
            userRole: null, // "DRIVER"|"DISPATCHER"|"UNDEFINED",
            name: null
        },
        receivingUser: { // Принял
            id: 0, // для незарегистрированных = 0
            userRole: null, // "DRIVER"|"DISPATCHER"|"UNDEFINED",
            name: null
        },
        number: null, // Номер ТС
        odometerIndications: null, // Показания одометра
        truckDieselTankValue: null, // Объем бака(-ов) (ДТ, тягач, л.
        truckDieselBalance: null, // Остаток ГСМ (ДТ, тягач, л
        gasPressureIndications: null, // Показания давления газа, Mpa(bar)
        refrigeratorDieselTankValue: null, // Объем бака (ДТ, реф, л.
        refrigeratorDieselBalance: null, // Остаток ГСМ (ДТ, реф, л.
        mileageEngineRH: null, // Моточасы ДВС ХОУ (наработка)
        totalMileageEngineRH: null, // Моточасы ДВС ХОУ (общие)
        tachographPrintoutIsLoaded: null, // Распечатка тахографа получена и загружена в программу GR.cards
        waybillPassedOf: null, // Путевой лист сдан
        // Неисправности/дефекты
        truckBatteryNeedsService: null, // Визуальный осмотр АКБ Тягач. Состояние: Требуется обслужить
        batteryOfRHNeedsService: null, // Визуальный осмотр АКБ ХОУ. Состояние: Требуется обслужить
        truckOnBoardComputer: {
            hasErrors: null, // Ошибки бортового компьютера тягача (Да/Нет)
            note: null
        },
        rhErrors: {
            hasErrors: null, // Наличие ошибок ХОУ.
            note: null,
        },
        oilLevel: {
            truckEngine: null, // Уровень масла в ДВС Тягач
            engineRH: null, // Уровень масла в ДВС ХОУ
        },
        platonUnit: {
            set: null, //(UNAVAILABLE|COMPLETE|INCOMPLETE), комплектность Бортового Устройства ПЛАТОН (Отсутствует|Комплект|Некомплект)
            setDescription: null,
            setNote: null,
            serviceable: null, //(SERVICEABLE|UNSERVICEABLE), // Исправность комплектность Бортового Устройства ПЛАТОН (Исправно|Неисправно)
            errorNote: null
        },
        vehicle: {
            hasErrors: null, // Наличие проволочных соединений, и прочих вмешательств в устройство ТС
            note: null
        },
        fluidLeaks: {
            hasErrors: null, // Наличие течи топлива, антифриза, тех. Жидкостей,
            note: null,
        },
        refrigeratorTemperature: {
            correct: null, // Запуск водителем заданной механиком температуры и проверка режима работы рефа. Режим держит.
            set: null, // Заданная температура
            comment: null,
        },
        cab: {
            condition: null, // Состояние внутри кабины
            firstAidKit: null, // Аптечка
            firstAidKitExpireDate: null, // Срок годности
            numberOfFireExtinguishers: null, // Количество огнетушителей, шт
            seatsBerthCondition: null, // Состояние сидений, спального места, наличие пятен, потертостей
            toolsCondition: null, // Состояние (наличие/целостность) пластиковых обшивок, вставок, козырьков, креплений, ручек и пр. оборудования в кабине
            hasUnpleasantOdor: null, // Наличие неприятного запаха в кабине
            otherDefects: [] //Другие неисправности
        },
        // Наличие ТМЦ
        commodities: [{
            name: "",
            numbers: 0,
            quantityInFact: 0,
            quantityByNumber: 0,
            note: "",
        }],
        // Дополнительное оборудование
        addons: {
            belts: { //Ремни
                amountOfSatisfactory: null, //Состояние удовлетворительно, шт
                amountOfBad: null  //Состояние неудовлетворительно, шт
            },
            spacerPosts: { // Распорные стойки
                amountOfSatisfactory: null, //Состояние удовлетворительно, шт
                amountOfBad: null  //Состояние неудовлетворительно, шт
            },
            sanitaryPassport: null, // Санитарный паспорт
            sanitaryPassportValidity: null,
            serviceBookAndManuals: null, // Сервисная книжка и руководство по эксплуатации
            kmUntilNextMaintenance: null, // Километраж до следующего ТО
            tachographInstructionsCarriedOut: null, //Инструктаж по правилам использования тахографа проведен
            truckTirePressure: {
                firstAxle: [],
                secondAxle: []
            }, // Измерение давления в шинах
            semitrailerTirePressure: {
                firstAxle: [],
                secondAxle: [],
                thirdAxle: []
            }, //
            truckHasSpareWheels: null, // Запасные колеса (тягач)
            semitrailerHasSpareWheels: null, //
            observationNotesResolved: null, //Замечания с прошлого Акта осмотра устранены
            observationNotesReason: null, // Причина
            driverRecommendations: null, // Рекомендации и действия для водителя в рейсе
        },
        // Фотографии
        photosVehicle: [], //ТС с 4-х сторон, min 4 фото.
        photoOdometer: null, //fileId Одометр
        photoMileageEngineRH: null, //fileId Моточасы
        photosWheels: [], //Колеса, min 10 фото.
        photoBatteryTruck: null, //fileId АКБ тягача
        photoBatteryRH: null, //fileId АКБ ХОУ
        photosCab: [] //Состояние внутри кабины, min 4 фото.
    },
    groups: [
        {
            name: "Статус",
            fields: [
                'city.id', 'dateTime', 'mechanic.name', 'transmittingUser.name', 'receivingUser.name', 'number', 'odometerIndications',
                'truckDieselTankValue', 'truckDieselBalance', 'gasPressureIndications', 'refrigeratorDieselTankValue',
                'refrigeratorDieselBalance', 'mileageEngineRH', 'totalMileageEngineRH', 'tachographPrintoutIsLoaded', 'waybillPassedOf',
            ]
        },
        {
            name: "Неисправности/дефекты",
            fields: [
                'truckBatteryNeedsService', 'batteryOfRHNeedsService', 'rhErrors.hasErrors', 'rhErrors.note', 'oilLevel.truckEngine',
                'oilLevel.engineRH', 'platonUnit.set', 'platonUnit.setDescription', 'platonUnit.setNote',
                'platonUnit.serviceable', 'platonUnit.errorNote', 'vehicle.hasErrors',
                'vehicle.note', 'fluidLeaks.hasErrors', 'fluidLeaks.note', 'refrigeratorTemperature.correct',
                'refrigeratorTemperature.set', 'refrigeratorTemperature.comment',
                'truckOnBoardComputer.hasErrors', 'truckOnBoardComputer.note', 'cab.condition', 'cab.firstAidKit',
                'cab.firstAidKitExpireDate', 'cab.numberOfFireExtinguishers', 'cab.seatsBerthCondition', 'cab.toolsCondition',
                'cab.hasUnpleasantOdor', 'cab.otherDefects'
            ]
        },
        {
            name: "Наличие ТМЦ",
            fields: ['commodities']
        },
        {
            name: "Дополнительное оборудование",
            fields: [
                'addons.belts.amountOfSatisfactory', 'addons.belts.amountOfBad',
                'addons.spacerPosts.amountOfSatisfactory', 'addons.spacerPosts.amountOfBad',
                'addons.sanitaryPassport', 'addons.sanitaryPassportValidity', 'addons.serviceBookAndManuals',
                'addons.kmUntilNextMaintenance', 'addons.tachographInstructionsCarriedOut',
                'addons.truckTirePressure', 'addons.semitrailerTirePressure', 'addons.truckHasSpareWheels',
                'addons.semitrailerHasSpareWheels', 'addons.observationNotesResolved', 'addons.observationNotesReason',
                'addons.driverRecommendations'
            ]
        },
        {
            name: "Фотографии",
            fields: [
                'photosVehicle', 'photoOdometer', 'photoMileageEngineRH', 'photosWheels',
                'photoBatteryTruck', 'photoBatteryRH', 'photosCab'
            ]
        },
    ],
    assign: {
        'city.id': ({bind, vm}) => {
            vm.$repo('common').cities().then(({list}) => bind(list, 'options'))
        },
    },
    fields: {
        'city.id': {
            name: 'Город',
            type: 'select',
            disabled: true,
        },
        dateTime: {
            name: 'Дата и время составления',
            component: 'div',
            wrap: {bind: {noWrap: true}},
            value: ({value, vm}) => vm.$util.date.dateMsk(value, true)
        },
        'mechanic.name': {
            name: 'Механик',
            disabled: true,
        },
        'transmittingUser.name': {
            name: 'Сдал',
            disabled: true,
        },
        'receivingUser.name': {
            name: 'Принял',
            disabled: true,
        },
        number: {
            name: 'Номер ТС',
            disabled: true,
        },
        odometerIndications: {
            name: 'Показания одометра',
            type: 'number',
        },
        truckDieselTankValue: {
            name: 'Объем бака(-ов) (ДТ, тягач, л.)',
            type: 'number',
            disabled: true,
        },
        truckDieselBalance: {
            name: 'Остаток ГСМ (ДТ, тягач, л.)',
            type: 'number',
        },
        gasPressureIndications: {
            name: 'Показания давления газа, Mpa(bar)',
            type: 'number',
        },
        refrigeratorDieselTankValue: {
            name: 'Объем бака (ДТ, реф, л.)',
            type: 'number',
            disabled: true,
        },
        refrigeratorDieselBalance: {
            name: 'Остаток ГСМ (ДТ, реф, л.)',
            type: 'number',
        },
        mileageEngineRH: {
            name: 'Моточасы ДВС ХОУ (наработка)',
            type: 'number',
        },
        totalMileageEngineRH: {
            name: 'Моточасы ДВС ХОУ (общие)',
            type: 'number',
        },
        tachographPrintoutIsLoaded: {
            name: 'Распечатка тахографа получена и загружена в программу GR.cards',
            type: 'confirm',
            inline: true
        },
        waybillPassedOf: {
            name: 'Путевой лист сдан',
            type: 'confirm',
            inline: true
        },
        // Неисправности/дефекты
        truckBatteryNeedsService: {
            name: 'Визуальный осмотр АКБ Тягач. Состояние: Требуется обслужить',
            type: 'confirm',
            inline: true
        },
        batteryOfRHNeedsService: {
            name: 'Визуальный осмотр АКБ ХОУ. Состояние: Требуется обслужить',
            type: 'confirm',
            inline: true
        },
        'truckOnBoardComputer.hasErrors': {
            name: 'Наличие ошибок бортового компьютера тягача',
            type: 'confirm',
            inline: true,
        },
        'truckOnBoardComputer.note': {
            name: 'Наличие ошибок бортового компьютера тягача (Обозначение)',
            required: false,
            wrap: {if: ({model}) => model.truckOnBoardComputer.hasErrors === true}
        },
        'rhErrors.hasErrors': {
            name: 'Наличие ошибок ХОУ',
            type: 'confirm',
            inline: true,
        },
        'rhErrors.note': {
            required: false,
            name: 'Наличие ошибок ХОУ (Обозначение)',
            wrap:{if: ({model}) => model.rhErrors.hasErrors === true,}
        },
        'oilLevel.truckEngine': {
            name: 'Уровень масла в ДВС Тягач',
            type: 'radio',
            options: {NORM: 'Норма', MIN: 'Минимум', BELOW_MIN: 'Ниже минимума'},
            inline: true
        },
        'oilLevel.engineRH': {
            name: 'Уровень масла в ДВС ХОУ',
            type: 'radio',
            options: {NORM: 'Норма', MIN: 'Минимум', BELOW_MIN: 'Ниже минимума'},
            inline: true
        },
        'platonUnit.set': {
            name: 'Комплектность Бортового Устройства ПЛАТОН',
            type: 'radio',
            options: {UNAVAILABLE: 'Отсутствует', COMPLETE: 'Комплект', INCOMPLETE: 'Некомплект'},
            inline: true,
        },
        'platonUnit.setDescription': {
            name: 'Комплектность Бортового Устройства ПЛАТОН (Описание)',
            type: 'textarea',
            disabled: true
        },
        'platonUnit.setNote': {
            name: 'Комментарий по комплектности Бортового Устройства ПЛАТОН',
            type: 'textarea',
            wrap:{if: ({model}) => model.platonUnit.set === 'INCOMPLETE',}
        },
        'platonUnit.serviceable': {
            name: 'Исправность Бортового Устройства ПЛАТОН',
            type: 'confirm',
            options: ['Неисправно', 'Исправно'],
            inline: true,
        },
        'platonUnit.errorNote': {
            name: 'Описание неисправности Бортового Устройства ПЛАТОН',
            type: 'textarea',
            wrap:{if: ({model}) => model.platonUnit.serviceable === false,}
        },
        'vehicle.hasErrors': {
            name: 'Наличие проволочных соединений, и прочих вмешательств в устройство ТС',
            type: 'confirm',
            inline: true,
        },
        'vehicle.note': {
            name: 'Комментарий по наличию проволочных соединений, и прочих вмешательств в устройство ТС',
            type: 'textarea',
            required: false,
            wrap:{if: ({model}) => model.vehicle.hasErrors === true,}
        },
        'fluidLeaks.hasErrors': {
            name: 'Наличие течи топлива, антифриза, тех. Жидкостей',
            type: 'confirm',
            inline: true,
        },
        'fluidLeaks.note': {
            name: 'Комментарий по наличию течи топлива, антифриза, тех. Жидкостей',
            type: 'textarea',
            required: false,
            wrap:{if: ({model}) => model.fluidLeaks.hasErrors === true,}
        },
        'refrigeratorTemperature.correct': {
            name: 'Запуск водителем заданной механиком температуры и проверка режима работы рефа. Режим держит.',
            type: 'confirm',
            inline: true
        },
        'refrigeratorTemperature.set': {
            name: 'Заданная температура рефрижератора',
            type: 'number',
            // type: 'integer',
        },
        'refrigeratorTemperature.comment': {
            name: 'Комментарий по температуре рефрижератора',
            type: 'textarea',
            required: false,
        },
        'cab.numberOfFireExtinguishers': {
            name: 'Количество огнетушителей, шт',
            type: 'number',
            // type: 'integer',
        },
        'cab.firstAidKit': {
            name: 'Аптечка',
            type: 'confirm',
            inline: true,
        },
        'cab.firstAidKitExpireDate': {
            name: 'Срок годности аптечки',
            type: 'date',
            wrap:{if: ({model}) => model.cab.firstAidKit === true,}
        },
        'cab.condition': {
            name: 'Состояние внутри кабины',
            type: 'radio',
            options: {GOOD: 'Хорошее', SATISFACTORY: 'Удовлетворительное', BAD: 'Плохое'},
            inline: true
        },
        'cab.seatsBerthCondition': {
            name: 'Состояние сидений, спального места, наличие пятен, потертостей',
            type: 'radio',
            options: {
                DRY_CLEANING: 'Требуется химчистка обработка',
                WET_CLEANING: 'Требуется влажная уборка',
                NO_ACTION_REQUIRED: 'Действия не требуются'
            }
        },
        'cab.toolsCondition': {
            name: 'Состояние (наличие/целостность) пластиковых обшивок, вставок, козырьков, креплений, ручек и пр. оборудования в кабине',
            type: 'radio',
            options: {
                CLEANING: 'Требуется уборка',
                REPAIRS: 'Требуется ремонт',
                REPLACEMENT: 'Требуется замена',
                NO_ACTION_REQUIRED: 'Действия не требуются',
            }
        },
        'cab.hasUnpleasantOdor': {
            name: 'Наличие неприятного запаха в кабине',
            type: 'confirm',
            inline: true
        },
        'cab.otherDefects': {
            name: 'Другие неисправности',
            component: 'div',
            wrap: {bind: {noWrap: true}},
            render: ({vm, model, set}) => {

                let values = _.get(model, 'cab.otherDefects')
                const view = vm.$parent?.view
                const items = []

                _.isArray(values) && values.length || (values = [""])

                values.forEach((v, index) => {
                    items.push({
                        FormItem: {
                            FormInput: '',
                            bind: {
                                value: v,
                                disabled: view
                            },
                            on: {input: v => model.cab.otherDefects[index] = v}
                        },
                        bind: {
                            style: {width: '100%'},
                            controls: [{
                                remove: {
                                    show: !view,
                                    bind: {class: 'clr-red', style: {fontSize: '1.4em', fontWeight: 600}},
                                    on: {
                                        click: () => {
                                            model.cab.otherDefects.splice(index, 1)
                                            set(model.cab.otherDefects)
                                        }
                                    }
                                }
                            }]
                        }
                    })
                })

                if (!view) {
                    return {
                        div: [
                            {
                                div: '',
                                bind: {
                                    class: 'clr-blue c-pointer', title: 'Добавить', style: {
                                        fontSize: '1.9em',
                                        padding: '5px 15px',
                                        fontWeight: 600
                                    }
                                },
                                html: '&plus;',
                                on: {
                                    click: () => {
                                        model.cab.otherDefects.push("")
                                        set(model.cab.otherDefects)
                                    }
                                }
                            },
                            {div: items, bind: {class: 'flex flex-col-20', style: {width: '95%'}}}
                        ],
                        bind: {class: 'flex flex-row-15'}
                    }
                }

                return {'div.flex.flex-col-20': items}

            }
        },
        // Наличие ТМЦ
        commodities: {
            component: 'div',
            wrap: {bind: {noWrap: true}},
            render: ({vm, model}) => {

                const view = vm.$parent?.view

                return {
                    DataTable: '',
                    bind: {
                        noShadow: true,
                        cols: {
                            name: 'Наименование',
                            quantityInFact: 'Кол-во по факту',
                            quantityByNumber: 'Кол-во по учету',
                            note: 'Комментарий'
                        },
                        fetch: h => h(model.commodities?.filter(i => i?.name)),
                        cells: {
                            quantityInFact: (v, item, index) => view ? v : {
                                FormItem: {
                                    FormInput: '',
                                    bind: {
                                        type: 'number',
                                        rules: ['required'],
                                        value: model.commodities[index].quantityInFact,
                                    },
                                    on: {input: v => model.commodities[index].quantityInFact = v}
                                }
                            },
                            note: (v, item, index) => view ? vm.$filter.truncate(v, 50) : {
                                FormItem: {
                                    FormText: '',
                                    bind: {
                                        style: {fontSize: '0.9em', minWidth: '150px', maxWidth: '250px'},
                                        value: model.commodities[index].note
                                    },
                                    on: {input: v => model.commodities[index].note = v}
                                }
                            }
                        }
                    }
                }

            }
        },
        // Дополнительное оборудование
        'addons.belts.amountOfSatisfactory': {
            name: 'Ремни, Состояние удовлетворительно, шт.',
            type: 'number',
            // type: 'integer',
        },
        'addons.belts.amountOfBad': {
            name: 'Ремни, Состояние неудовлетворительно, шт.',
            type: 'number',
            // type: 'integer',
        },
        'addons.spacerPosts.amountOfSatisfactory': {
            name: 'Распорные стойки, Состояние удовлетворительно, шт.',
            type: 'number',
            // type: 'integer',
        },
        'addons.spacerPosts.amountOfBad': {
            name: 'Распорные стойки, Состояние неудовлетворительно, шт.',
            type: 'number',
            // type: 'integer',
        },
        'addons.sanitaryPassport': {
            name: 'Санитарный паспорт',
            type: 'confirm',
            options: ['Нет', 'Есть'],
            inline: true,
        },
        'addons.sanitaryPassportValidity': {
            name: 'Срок действия санитарного паспорта',
            type: 'date',
            wrap:{if: ({model}) => model.addons.sanitaryPassport === true,}
        },
        'addons.serviceBookAndManuals': {
            name: 'Сервисная книжка и руководство по эксплуатации',
            type: 'confirm',
            options: ['Нет', 'Есть'],
            inline: true
        },
        'addons.kmUntilNextMaintenance': {
            name: 'Километраж до следующего ТО',
            type: 'number',
            // type: 'integer',
        },
        'addons.tachographInstructionsCarriedOut': {
            name: 'Инструктаж по правилам использования тахографа проведен',
            type: 'confirm',
            inline: true
        },
        'addons.truckTirePressure': {
            name: 'Измерение давления в шинах (Тягач)',
            component: 'div',
            wrap: {bind: {noWrap: true}},
            render: ({vm, value, meta}) => {
                const view = vm.$parent?.view
                return meta.tirePressure(['firstAxle', 'secondAxle'], value, view)
            }
        },
        'addons.semitrailerTirePressure': {
            name: 'Измерение давления в шинах (Полуприцеп)',
            component: 'div',
            wrap: {bind: {noWrap: true}},
            render: ({vm, value, meta}) => {
                const view = vm.$parent?.view
                return meta.tirePressure(['firstAxle', 'secondAxle', 'thirdAxle'], value, view)
            }
        },
        'addons.truckHasSpareWheels': {
            name: 'Запасные колеса (Тягач)',
            type: 'confirm',
            options: ['Нет', 'Есть'],
            inline: true
        },
        'addons.semitrailerHasSpareWheels': {
            name: 'Запасные колеса (Полуприцеп)',
            type: 'confirm',
            options: ['Нет', 'Есть'],
            inline: true
        },
        'addons.observationNotesResolved': {
            name: 'Замечания с прошлого Акта осмЧтра устранены',
            type: 'confirm',
            inline: true,
        },
        'addons.observationNotesReason': {
            name: 'Причина не устранения замечаний с прошлого Акта осмотра',
            type: 'textarea',
            wrap:{if: ({model}) => model.addons.observationNotesResolved === false,}
        },
        'addons.driverRecommendations': {
            name: 'Рекомендации и действия для водителя в рейсе',
            type: 'textarea',
        },
        // Фотографии
        photosVehicle: {
            name: 'ТС с 4-х сторон, минимум 4 фото',
            type: 'img',
            min: 4
        },
        photoOdometer: {
            name: 'Одометр',
            type: 'img',
        },
        photoMileageEngineRH: {
            name: 'Моточасы ДВС ХОУ',
            type: 'img',
        },
        photosWheels: {
            name: 'Колеса, минимум 10 фото',
            type: 'img',
            min: 10
        },
        photoBatteryTruck: {
            name: 'АКБ тягача',
            type: 'img',
        },
        photoBatteryRH: {
            name: 'АКБ ХОУ',
            type: 'img',
        },
        photosCab: {
            name: 'Состояние внутри кабины, минимум 4 фото',
            type: 'img',
            min: 4
        }
    },
    tirePressure (axis, data, isView) {

        const items = []

        const fetch = axle => {
            const output = []
            const values = _.isEmpty(data[axle]) ? [] : data[axle]

            values.forEach((item, index) => {
                output.push({
                    FormItem: {
                        FormInput: '',
                        bind: {
                            type: 'number',
                            value: item,
                            disabled: isView
                        },
                        on: {
                            input: v => _.isNumber(parseInt(v)) && (data[axle][index] = parseInt(v) )
                        }
                    }
                })
            })
            return output
        }

        axis.forEach((item, i) => {
            items.push({
                div: [
                    {div: `Ось ${i+1}, (Мпа)`},
                    ...fetch(item)
                ],
                bind: {class: 'flex flex-col-10'}
            })
        })

        return {'div.flex.flex-row-20': items}
    }
}
