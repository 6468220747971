var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.form.model)?_c('DataForm',{key:_vm.view,attrs:{"model":_vm.form,"required":"","view":_vm.view,"form":{
            bind: Object.assign({}, _vm.$attrs,
                {buttons: [
                    _vm.view ? {text: 'Редактировать', on: {click: function () { return _vm.view = false; }}} : 'Сохранить',
                    _vm.semitrailerId ? {text: 'Удалить', bind: {class: 'btn btn-red'}, on: {click: _vm.remove}} : null
                ]}),
            on: {submit: _vm.submit},
        }},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}}):_vm._e(),(_vm.form.model)?_c('BackToggle',{on:{"toggle":function($event){return _vm.toggle()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }