export default {
    model: {
        color: "",
        id: 0,
        model: "",
        number: "",
        scans: [],
        status: "",
        vin: "",
        year: 0,
    },
    fields: {
        "model": {
            name: "Марка",
        },
        "number": {
            name: "Гос. номер",
            format: v => _.toUpper(v).replace(/\s/g, '')
        },
        "vin": {
            name: "VIN номер",
        },
        "color": {
            name: "Цвет",
        },
        "year": {
            name: "Год выпуска",
            mask: "####",
            rules: [['minLength', 4]]
        },
        "scans": {
            name: "Скан ПТС или СТС",
            type: "img",
        },
    },
}
