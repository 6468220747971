<template>
    <div v-if="form && formKey && cities" class="form-route-sheet">
        <div class="flex flex-col-10 flex-center">
            <h2 class="section-page-title pl-0">Маршрутный лист</h2>
            <h3 v-if="orderNumber" class="clr-ltg-3 mt-5 tx-bold">Заказ №{{orderNumber}}</h3>
            <div
                v-if="form.model.readOnlyComment"
                class="btn btn-small-2 btn-white btn-round btn-clr-gray flex flex-row-10 flex-align"
                @click="$ok({text: form.model.readOnlyComment})"
            >
                <FaIcon icon="info-circle" class="clr-blue" />
                <div>
                    Информация
                </div>
            </div>
        </div>

        <DataForm
            ref="form"
            :key="formKey"
            v-model="form.model"
            :model="form"
            :view="!editable"
            required
            class="form-route-sheet"
            @setup="i => formData = i"
            :form="{
                on: {submit},
                bind: {
                    center: true,
                    buttons: editable ? [ 'Сохранить' ] : null
                }
            }"
        >
            <h2 slot="startGroup_odometer" class="tx-up tx-c tx-bold mt-40 clr-ltg-3">
                Пробег
            </h2>
            <h2 slot="startGroup_fuelInitial" class="tx-up tx-c tx-bold mt-40 clr-ltg-3">
                ГСМ
            </h2>
            <h2 slot="startGroup_cash" class="tx-up tx-c tx-bold mt-40 clr-ltg-3">
                Прочее
            </h2>

            <div
                v-if="editable"
                slot="beforeField_mileageAutonomousHeater"
                class="--add --add-wrap"
                title="Добавить"
                @click="popupHeater()"
            >
                &plus;
            </div>
            <div slot="afterField_mileageAutonomousHeater" class="w-100 flex flex-center" style="margin-top: -10px; margin-bottom: 20px">
                <button class="btn btn-small btn-blue" @click="popupFieldData('heater')">
                    Список добавленных
                </button>
            </div>

            <div
                v-if="editable"
                slot="beforeField_mileageEngineWarming"
                class="--add --add-wrap"
                title="Добавить"
                @click="popupEngine()"
            >
                &plus;
            </div>
            <div slot="afterField_mileageEngineWarming" class="w-100 flex flex-center" style="margin-top: -10px; margin-bottom: 20px">
                <button class="btn btn-small btn-blue" @click="popupFieldData('engine')">
                    Список добавленных
                </button>
            </div>

            <div
                v-if="editable"
                slot="beforeField_refueledFuelTruck"
                style="position: relative"
            >
                <a style="position: absolute; right: 0; top: -30px" @click="popupFieldData('fuel')">Расшифровка</a>
            </div>
            <div
                v-if="editable"
                slot="afterField_refueledFuelRefrigerator"
                style="margin-bottom: 70px"
            >
                <a style="position: absolute; right: 0; bottom: -30px" @click="popupFuel">Отправить запрос на изменение данных</a>
            </div>

            <div
                v-if="editable"
                slot="beforeField_refueledGasTruck"
                class="--add"
                title="Добавить"
                @click="popupFuelGas()"
            >
                &plus;
            </div>

            <div
                v-if="editable"
                slot="beforeField_cashSpent"
                class="--add"
                title="Добавить"
                @click="popupCash()"
            >
                &plus;
            </div>
            <div slot="afterGroup_cash" class="w-100 flex flex-center"  style="margin-top: -20px; margin-bottom: 40px">
                <button class="btn btn-small btn-blue" @click="popupFieldData('cash')">
                    Список добавленных
                </button>
            </div>

        </DataForm>

        <BackToggle @toggle="$emit('toggle')" class="mt-10" />

        <Popup name="popup-heater">
            <h3 class="tx-c tx-bold">Время работы автономного отопителя</h3>
            <DataForm
                v-if="formHeater"
                :model="formHeater"
                v-model="formHeater.model"
                :form="{
                    on: {
                        submit: () => {
                            const data = $$.cloneDeep(formHeater.model)
                            data.city.id ? data.coordinates = null : data.city = null
                            validateHeater(data)
                        }
                    },
                    bind: {
                        buttons: [
                            'Готово',
                            formHeater.index >= 0 ? {
                                text: 'Удалить',
                                bind: {class: 'btn btn-red'},
                                on: {click: () => {
                                    form.model.mileage.autonomousHeater.splice(formHeater.index, 1)
                                    fieldDataReload()
                                    formData.updateGroup('heater')
                                    $popupClose('popup-heater')
                                }}
                            } : null
                        ],
                        buttonCancel: true,
                        onCancel: () => $popupClose('popup-heater')
                    }
                }"
                class="block-inflect"
            />
        </Popup>

        <Popup name="popup-engine">
            <h3 class="tx-c tx-bold">Время работы двигателя</h3>
            <DataForm
                v-if="formEngine"
                :model="formEngine"
                v-model="formEngine.model"
                :form="{
                    on: {
                        submit: () => {
                            const data = $$.cloneDeep(formEngine.model)
                            data.city.id ? data.coordinates = null : data.city = null

                            if (formEngine.index >= 0) {
                                $set(form.model.other.engineWarming, formEngine.index, formEngine.model)
                            } else {
                                form.model.other.engineWarming.push(formEngine.model)
                            }

                            formData.updateGroup('heater')
                            $popupClose('popup-engine')
                        }
                    },
                    bind: {
                        buttons: [
                            'Готово',
                            formEngine.index >= 0 ? {
                                text: 'Удалить',
                                bind: {class: 'btn btn-red'},
                                on: {click: () => {
                                    form.model.other.engineWarming.splice(formEngine.index, 1)
                                    fieldDataReload()
                                    formData.updateGroup('heater')
                                    $popupClose('popup-engine')
                                }}
                            } : null
                        ],
                        buttonCancel: true,
                        onCancel: () => $popupClose('popup-engine')
                    }
                }"
                class="block-inflect"
            />
        </Popup>

        <Popup name="popup-fuel">
            <h3 class="tx-c tx-bold">Заправлено в рейсе</h3>
            <DataForm
                v-if="formFuel"
                :model="formFuel"
                v-model="formFuel.model"
                :form="{
                    on: {submit: popupFuelSubmit},
                    bind: {
                        buttons: 'Отправить',
                        buttonCancel: true,
                        onCancel: () => $popupClose('popup-fuel')
                    }
                }"
                class="block-inflect"
            />
        </Popup>

        <Popup name="popup-fuel-gas">
            <h3 class="tx-c tx-bold">Заправлено в рейсе</h3>
            <DataForm
                v-if="formFuelGas"
                :model="formFuelGas"
                v-model="formFuelGas.model"
                :form="{
                    on: {submit: () => {
                        if (formFuelGas.index >= 0) {
                            $set(form.model.fuel.refueled, formFuelGas.index, formFuelGas.model)
                            fieldDataKey += 1
                        } else {
                            formFuelGas.model.id = null
                            form.model.fuel.refueled.push(formFuelGas.model)
                        }
                        formData.updateGroup('refueled')
                        $popupClose('popup-fuel-gas')
                    }},
                    bind: {
                        buttons: [
                            'Готово',
                            formFuelGas.index >= 0 ? {
                                text: 'Удалить',
                                bind: {class: 'btn btn-red'},
                                on: {click: () => {
                                    form.model.fuel.refueled.splice(formFuelGas.index, 1)
                                    fieldDataReload()
                                    formData.updateGroup('refueled')
                                    $popupClose('popup-fuel-gas')
                                }}
                            } : null
                        ],
                        buttonCancel: true,
                        onCancel: () => $popupClose('popup-fuel-gas')
                    }
                }"
                class="block-inflect"
            />
        </Popup>

        <Popup name="popup-cash">
            <h3 class="tx-c tx-bold">Наличные д/с</h3>
            <DataForm
                v-if="formCash"
                :model="formCash"
                v-model="formCash.model"
                :form="{
                    on: {submit: () => {
                        if (formCash.index >= 0) {
                            $set(form.model.other.used, formCash.index, formCash.model)
                        } else {
                            form.model.other.used.push(formCash.model)
                        }
                        formData.updateGroup('cash')
                        $popupClose('popup-cash')
                    }},
                    bind: {
                        buttons: [
                            'Готово',
                            formCash.index >= 0 ? {
                                text: 'Удалить',
                                bind: {class: 'btn btn-red'},
                                on: {click: () => {
                                    form.model.other.used.splice(formCash.index, 1)
                                    fieldDataReload()
                                    formData.updateGroup('cash')
                                    $popupClose('popup-cash')
                                }}
                            } : null
                        ],
                        buttonCancel: true,
                        onCancel: () => $popupClose('popup-cash')
                    }
                }"
                class="block-inflect"
            />
        </Popup>

        <Popup name="popup-field-data" v-if="fieldDataOpen">
            <h3 class="tx-c tx-bold">{{ fieldDataOpen.title }}</h3>
            <div class="block-inflect">
                <DataTable
                    :key="fieldDataKey"
                    :cols="fieldDataOpen.cols"
                    :cell-bind="{
                        price: {class: 'tx-inline clr-green'},
                        cashAmount: {class: 'tx-inline clr-green'},
                    }"
                    :cells="{
                        dateTime: v => $util.date.date(v, fieldDataOpen.type === 'fuel'),
                        vehicleType: v => $$.strtr(v, {
                            TRUCK: 'Тягач',
                            REFRIGERATOR: 'Рефрижератор'
                        }),
                        fuelType: v => $$.strtr(v, {
                            DIESEL: 'Дизельное',
                            GAS: 'Газ',
                        }),
                        byCash: v => v === true ? 'Да' : 'Нет',
                        price: (v, item) => item.byCash || (item.fuelType === 'GAS' && item.vehicleType === 'TRUCK') ? $$.price(v) : '-',
                        cashAmount: (v, item) => item.byCash || (item.fuelType === 'GAS' && item.vehicleType === 'TRUCK') ? $$.price(v) : '-',
                        comment: v => $filter.truncate(v, 150),
                        startDate: v => $util.date.date(v, true, true),
                        endDate: v => $util.date.date(v, true, true),
                        dateEarn: (v, item) => {
                            return $util.date.isValidDate(item.startDate) && $util.date.isValidDate(item.endDate)
                                ? $util.date.getFullDate($util.date.toUtc(item.endDate)-$util.date.toUtc(item.startDate), true)
                                : '0ч.'
                        },
                        city: v => $$.get(v, 'city.name')
                    }"
                    :click="editable && fieldDataOpen.onClick ? ((item, index) => fieldDataOpen.onClick(item, index)) : {}"
                    :fetch="h => h(fieldDataOpen.fetch())"
                />
            </div>
        </Popup>
    </div>
</template>

<script>

export default {
    name: "FormRouteSheet",
    props: {
        id: Number,
        orderNumber: [Number, String]
    },
    created() {
        this.getSheet()
    },
    data() {
        return {
            formKey: 0,
            cities: null,
            editable: true,
            form: null,
            formData: null,
            formHeater: null,
            formEngine: null,
            formFuel: null,
            formFuelGas: null,
            formCash: null,
            fieldDataOpen: null,
            fieldDataKey: 0,
            fieldData: {
                heater: {
                    type: 'heater',
                    title: 'Время работы автономного отопителя',
                    cols: {
                        'startDate': 'Время включения',
                        'endDate': 'Время выключения',
                        'dateEarn': 'Наработка, ч.',
                        'city.name': 'Город',
                        'coordinates.lat': 'Координаты: Широта',
                        'coordinates.lon': 'Координаты: Долгота',
                    },
                    fetch: () => [],
                    onClick: (data, index) => this.popupHeater(data, index)
                },
                engine: {
                    type: 'engine',
                    title: 'Время работы двигателя',
                    cols: {
                        'startDate': 'Время включения',
                        'endDate': 'Время выключения',
                        'dateEarn': 'Наработка, ч.',
                        'city.name': 'Город',
                        'coordinates.lat': 'Координаты: Широта',
                        'coordinates.lon': 'Координаты: Долгота',
                    },
                    fetch: () => [],
                    onClick: (data, index) => this.popupEngine(data, index)
                },
                fuel: {
                    type: 'fuel',
                    title: 'ГСМ',
                    cols: {
                        'dateTime': 'Дата',
                        'vehicleType': 'Куда заправлено',
                        'fuelType': 'Тип топлива',
                        'volume': 'Сколько заправлено',
                        'price': 'Цена',
                        'cashAmount': 'Сумма',
                        'byCash': 'Наличный расчет',
                        "gasStation": "АЗС",
                        "gasStationAddress": "Адрес АЗС",
                    },
                    fetch: () => [],
                    onClick: (data, index) => {
                        if (data.fuelType === 'GAS' && data.vehicleType === 'TRUCK') {
                            return this.popupFuelGas(data, index)
                        }
                        this.$notify().warn('Разрешено редактирование/удаление только заправок газом за наличный расчет.')
                    }
                },
                cash: {
                    type: 'cash',
                    title: 'Наличные д/с',
                    cols: {
                        'dateTime': 'Дата',
                        'amount': 'Сумма расхода',
                        'comment': 'Комментарий',
                    },
                    fetch: () => [],
                    onClick: (data, index) => this.popupCash(data, index)
                },
            }
        }
    },
    methods: {
        getSheet() {

            this.$repo('enterprise').getRouteSheet(this.id).then(({object}) => {

                this.$repo('common').cities().then(({list}) => {

                    this.cities = list

                    const data = _.cloneDeep(this.$apiModel.route_sheet)

                    Object.assign(data.model, object)

                    data.fields["startRoute.city.id"].options = this.cities

                    data.fields["endRoute.city.id"].options = this.cities

                    // this.$set(this, 'editable', false)
                    this.$set(this, 'editable', !object.readOnly)

                    this.$set(this, 'form', data)

                    this.formatForm()
                })

            }).alert('Не удалось загрузить маршрутный лист.')
        },
        formatForm() {

            const form = this.form

            form.fields['mileageAutonomousHeater'].wrap = {bind: {noShadow: true, rowWrap: true}}
            form.fields['mileageEngineWarming'].wrap = {bind: {noShadow: true, rowWrap: true}}
            form.fields['refueledGasTruck'].wrap = {bind: {noShadow: true, row: true}}
            form.fields['cashSpent'].wrap = {bind: {noShadow: true, row: true}}

            this.$set(this, 'formKey', this.formKey += 1)
        },
        popupHeater(data, index) {

            const heater = _.cloneDeep(this.$apiModel.route_sheet_heater)

            data && (heater.model = data)

            heater.periodsList = this.form.model.mileage?.autonomousHeater

            heater.fields['city.id'].options = this.cities

            heater.index = index !== undefined ? index : -1

            this.$set(this, 'formHeater', heater)

            this.$popup('popup-heater', {
                clickToClose: false
            })
        },
        popupEngine(data, index) {

            const engine = _.cloneDeep(this.$apiModel.route_sheet_heater)

            data && (engine.model = data)

            engine.periodsList = this.form.model.other?.engineWarming

            engine.fields['city.id'].options = this.cities

            engine.index = index !== undefined ? index : -1

            this.$set(this, 'formEngine', engine)

            this.$popup('popup-engine', {
                clickToClose: false
            })
        },
        popupFuel() {

            const fuel = _.cloneDeep(this.$apiModel.route_sheet_fuel)

            fuel.model.routeSheetId = this.form.model.id

            fuel.routeSheet = this.form.model

            if (_.isPlainObject(this.formFuel?.model)) {
                fuel.fields.newAmountTruckFuel.initial = this.formFuel.model.newAmountTruckFuel
                fuel.fields.newAmountRefrigeratorFuel.initial = this.formFuel.model.newAmountRefrigeratorFuel
            }

            this.$set(this, 'formFuel', fuel)

            this.$popup('popup-fuel', {
                clickToClose: false
            })
        },
        popupFuelGas(data, index) {

            const fuel = _.cloneDeep(this.$apiModel.route_sheet_fuel_gas)

            data && (fuel.model = data)

            fuel.routeSheet = this.form.model

            fuel.model.vehicleType = 'TRUCK'

            fuel.model.fuelType = 'GAS'

            fuel.index = index !== undefined ? index : -1

            this.$set(this, 'formFuelGas', fuel)

            this.$nextTick(() => {
                this.$popup('popup-fuel-gas', {
                    clickToClose: false
                })
            })
        },
        popupFuelSubmit() {
            this.$repo('enterprise').refueledChangeRequest(this.formFuel.model).then(({object}) => {
                this.formFuel.model = object.object
                this.$popupClose('popup-fuel')
            }).alert('Не удалось изменить данные по заправке.').done('Данные по заправке сохранены.')
        },
        popupCash(data, index) {

            const cash = _.cloneDeep(this.$apiModel.route_sheet_cash)

            data && (cash.model = data)

            cash.index = index !== undefined ? index : -1

            this.$set(this, 'formCash', cash)

            this.$popup('popup-cash', {
                clickToClose: false
            })
        },
        fieldDataReload() {
            this.$set(this, 'fieldDataKey', this.fieldDataKey += 1)
        },
        popupFieldData(type) {

            switch (type) {

                case 'heater': {

                    const data = _.cloneDeep(this.fieldData.heater)

                    data.fetch = () => this.form.model.mileage.autonomousHeater?.filter(v => {
                        return (this.$util.date.toUtc(v.endDate || this.$util.date.curDate())-this.$util.date.toUtc(v.startDate)) > 0
                    })

                    this.$set(this, 'fieldDataOpen', data)

                    break
                }

                case 'engine': {

                    const data = _.cloneDeep(this.fieldData.engine)

                    data.fetch = () => this.form.model.other.engineWarming?.filter(v => {
                        return (this.$util.date.toUtc(v.endDate || this.$util.date.curDate())-this.$util.date.toUtc(v.startDate)) > 0
                    })

                    this.$set(this, 'fieldDataOpen', data)

                    break
                }

                case 'fuel': {

                    const data = _.cloneDeep(this.fieldData.fuel)

                    data.fetch = () => this.form.model.fuel.refueled?.filter(v => {
                        return !_.isEmpty(v.vehicleType) && !_.isEmpty(v.fuelType)
                    })

                    this.$set(this, 'fieldDataOpen', data)

                    break
                }

                case 'cash': {

                    const data = _.cloneDeep(this.fieldData.cash)

                    data.fetch = () => this.form.model.other.used?.filter(v => {
                        return _.isNumber(parseFloat(v.amount)) && parseFloat(v.amount) > 0
                    })

                    this.$set(this, 'fieldDataOpen', data)

                    break
                }
            }

            this.$nextTick(() => {
                this.$popup('popup-field-data', {
                    clickToClose: false,
                    fullscreen: true
                })
            })
        },
        submit() {
            this.$repo('enterprise').saveRouteSheet(this.form.model.id, this.form.model).then(() => {
                this.$emit('saved', this.form.model)
            })
                .alert('Не удалось изменить Маршрутный Лист.')
                .done('Маршрутный Лист изменен.')
        },
        validateHeater(data) {

            this.$repo('enterprise').validateRouteSheetAH(this.form.model.id, data).then(() => {

                if (this.formHeater.index >= 0) {
                    this.$set(this.form.model.mileage.autonomousHeater, this.formHeater.index, this.formHeater.model)
                } else {
                    this.form.model.mileage.autonomousHeater.push(this.formHeater.model)
                }

                this.formData.updateGroup('heater')

                this.$popupClose('popup-heater')

            }).error(r => {

                if (r.data.result === 'ABNORMAL_PERIOD_BY_PARKING')

                    this.$notify.warn('Указанный период использования автономного обогревателя превышает время стоянки.')

                else if (r.data.result === 'ABNORMAL_USAGE_BY_WEATHER')

                    this.$notify.warn('Использование автономного обогревателя не соответствует погодным условиям в месте стоянки.')

                else this.$notify.alert('Не удалось проверить данные по Автономному отопителю.')

            }).catch(false)
        }
    }
}

</script>

<style scoped lang="scss">
.form-route-sheet {
    margin-top: 40px;
    .--add {
        font-weight: 600;
        font-size: 1.9em;
        color: $blue-link;
        cursor: pointer;
        padding-left: 15px;
        &:hover {
            color: $gold;
        }

        /deep/ &-wrap + .form-input{
            width: 80%;
        }
    }
}
</style>
