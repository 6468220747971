<template>
    <div
        class="form"
        :class="[
            row && 'block-row',
            center && 'block-center',
            wide && 'block-wide',
            thin && 'block-thin',
            inflect && 'block-inflect',
            contents && 'block-contents',
            border && 'block-bordered',
            radius && 'block-bordered block-bordered-radius',
        ]"
        @keyup.enter="$event.target.tagName === 'TEXTAREA' || onSubmit()"
    >
        <slot name="header"/>
        <h2 v-if="title" class="form-title" v-html="title"></h2>
        <slot/>
        <Buttons
            v-if="submitButtons"
            :buttons="submitButtons"
            :inline="buttonsInline"
            :align="buttonsAlign"
            :initial="buttonsInitial"
            :cancel="buttonCancel"
            :center="buttonsCenter"
            @cancel="buttonCancelClick"
            class="mt-40"
            :style="buttonsWidth ? {
                width: buttonsWidth.toString().includes('px') ? buttonsWidth : `${buttonsWidth}%`
            } : false"
        />
        <slot name="footer"/>
    </div>
</template>

<script>

export const FORM_INPUT_COMPONENT_TAG = 'FormInput'
export const FORM_SELECT_COMPONENT_TAG = 'FormSelect'
export const FORM_LONGTEXT_COMPONENT_TAG = 'FormText'
export const FORM_FIND_SELECT = 'FormFindSelect'
export const FORM_MULTI_SELECT = 'FormMultiSelect'
export const FORM_DATE_COMPONENT_TAG = 'FormDate'
export const FORM_FILE_COMPONENT_TAG = 'FormFile'

export const INPUT_COMPONENT_TAGS = [
    FORM_INPUT_COMPONENT_TAG, FORM_SELECT_COMPONENT_TAG,
    FORM_LONGTEXT_COMPONENT_TAG, FORM_FIND_SELECT, FORM_MULTI_SELECT,
    FORM_DATE_COMPONENT_TAG, FORM_FILE_COMPONENT_TAG
]

export const FORM_ITEM_COMPONENT_TAG = 'FormItem'
export const FORM_COMPONENT_TAG = 'Form'

export const FORM_ALIASES = {
    input: FORM_INPUT_COMPONENT_TAG,
    select: FORM_SELECT_COMPONENT_TAG,
    multi: FORM_MULTI_SELECT,
    find: FORM_FIND_SELECT,
    textarea: FORM_LONGTEXT_COMPONENT_TAG,
    date: FORM_DATE_COMPONENT_TAG,
    file: FORM_FILE_COMPONENT_TAG,
}

const DEFAULT_VALIDATION_MESSAGE = 'Не все поля заполнены корректно.'

export default {
    name: "Form",
    props: {
        title: String,
        buttons: [String, Array],
        buttonsCenter: Boolean,
        buttonsWidth: [Number, String],
        buttonsAlign: Boolean,
        buttonsInline: Boolean,
        buttonsInitial: Boolean,
        buttonCancel: [String, Boolean],
        onCancel: Function,
        row: Boolean,
        center: Boolean,
        wide: Boolean,
        thin: Boolean,
        inflect: Boolean,
        contents: Boolean,
        border: Boolean,
        radius: Boolean,
        validation: {
            type: [Function, Boolean],
            default: true
        },
        validationMessage: {
            type: [String, Boolean],
            default: true
        }
    },
    data() {
        return {
            formName: this.$attrs.name || this.$uuid.v1(),
            inputs: [],
            submitButtons: null
        }
    },
    created() {
        this.$registerComponent('forms', this.formName)
        this.setButtons()
    },
    mounted() {
        this.$emit('setup', this)
    },
    destroyed() {
        this.$resetComponent('forms', this.formName)
    },
    methods: {
        setButtons() {
            if (this.buttons && typeof this.buttons === 'string') {
                this.submitButtons = [{
                    text: this.buttons,
                    on: {click: this.onSubmit}
                }]
            } else {
                this.submitButtons = this.buttons
                this.submitButtons?.forEach((button, i) => {
                    if (!button) return
                    _.isPlainObject(button) || (this.submitButtons[i] = button = {text: button})
                    _.get(button, 'on.click') || _.set(button, 'on.click', this.onSubmit)
                })
            }
        },
        setError(inputIndex, {error}) {
            this.inputs[inputIndex] && Object.assign(this.inputs[inputIndex], {error})
        },
        getInputs(children) {
            if (!children || !children.length) return
            children.forEach(c => {
                if (INPUT_COMPONENT_TAGS.includes(c.$options._componentTag)) {
                    return this.inputs.push({el: c})
                }
                this.getInputs(c.$children)
            })
        },
        validate(showErrors = true, validate = true) {
            return new Promise((res, rej) => {
                if (!validate) return res()
                this.inputs = []
                this.getInputs(this.$children)
                let errors = 0
                this.inputs.forEach((input, index) => {
                    let error = _.isPlainObject(input.el.validator?.error) ? input.el.validator.error : null
                    this.setError(index, {error})
                    error && (errors += 1)
                })
                errors ? rej(this.inputs) : res()
                showErrors && this.showErrors()
            })
        },
        showErrors() {
            this.inputs.forEach(input => {
                input.el.$parent.$options._componentTag === FORM_ITEM_COMPONENT_TAG
                && input.el.$parent.toggleError(input.error)
            })
        },
        onSubmit() {
            if (this.validation) {
                this.validate().then(() => this.$emit('submit', this)).catch(() => {
                    _.isFunction(this.validation) && this.validation(this)
                    this.validationMessage && this.$notify().warn(
                        typeof this.validationMessage === 'string' ? this.validationMessage : DEFAULT_VALIDATION_MESSAGE
                    )
                })
                return
            }
            this.$emit('submit', this)
        },
        buttonCancelClick() {
            this.onCancel ? this.onCancel() : this.$emit('cancel', this)
        },
    }
}
</script>

<style lang="scss" scoped>

.form {
    &-title {
        text-align: center;
    }

    &-item {
        margin-bottom: 20px;
        position: relative;
    }
}

</style>
