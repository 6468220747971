var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Accordion',{staticClass:"form-accept-cert"},[_c('h2',{staticClass:"section-page-title tx-c pl-0"},[_vm._v(" Акт осмотра ТС "),_c('br'),_vm._v("«"+_vm._s(_vm.$$.trim(_vm.form.model.number))+"» ")]),(_vm.load)?_c('DataForm',{key:_vm.load,attrs:{"model":_vm.form,"view":_vm.cert.readOnly === true,"required":"","form":{
            on: {submit: _vm.submit},
            bind: {
                buttons: [
                    _vm.cert.readOnly ? null : 'Сохранить',
                    !_vm.cert.confirmationRequest ? null : {
                        text: 'Подтвердить',
                        on: {click: this.confirm}
                    },
                    _vm.cert.readOnly ? null : {
                        text: 'Удалить',
                        bind: {class: 'btn btn-red'}
                    }
                ]
            }
        }},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}},[_c('div',{staticClass:"px-15",attrs:{"slot":("afterGroup_" + (_vm.form.groups.length-1))},slot:("afterGroup_" + (_vm.form.groups.length-1))},[_c('small',{staticClass:"clr-ltg-4"},[_vm._v(" Передающий транспортное средство, несёт полную материальную ответственность за выявленные и указанные в данном акте недостатки. Транспортное средство принятое без указания на недостатки, считается переданным в исправном состоянии, в полном комплекте, без повреждений. Принимающий транспортное средство, несёт полную материальную ответственность с момента подписания акта ")])])]):_vm._e(),_c('BackToggle',{staticClass:"mt-10",on:{"toggle":_vm.toggle}}),_c('Confirm',{attrs:{"name":"confirm-accept-cert"}},[_c('p',{staticClass:"clr-dark"},[_vm._v("Ваш код подтверждения: "+_vm._s(_vm.confirmCode))]),_c('FormItem',[_c('FormInput',{attrs:{"placeholder":"Введите код подтверждения"},model:{value:(_vm.sendCode),callback:function ($$v) {_vm.sendCode=$$v},expression:"sendCode"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }