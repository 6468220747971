var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.proceed)?[_c('h2',{staticClass:"section-page-title tx-c pl-0"},[_vm._v("Анкета перевозчика")]),_c('DataForm',{attrs:{"form":{
                on: {submit: _vm.submit},
                bind: {
                    buttons: ['Сохранить'],
                    buttonCancel: true,
                    onCancel: function () { return _vm.$emit('toggle', null); }
                }
            },"model":_vm.data,"required":""},model:{value:(_vm.data.model),callback:function ($$v) {_vm.$set(_vm.data, "model", $$v)},expression:"data.model"}})]:_c('Buttons',{staticClass:"mt-40",attrs:{"initial":""}},[_c('button',{staticClass:"btn btn-green",on:{"click":function($event){return _vm.$emit('check-requisites')}}},[_vm._v(" Я раньше работал с РОСПРОМ ")]),_c('button',{staticClass:"btn btn-blue",on:{"click":function($event){_vm.proceed = true}}},[_vm._v(" Я буду работать с РОСПРОМ впервые ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }