var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-order-details"},[(_vm.list)?_c('ul',{staticClass:"form-order-details-list list"},[_c('Tree',{attrs:{"list":_vm.list,"bind":{
                 li: function (item, depth) { return (item.title && !item.valueString) ? {
                    class: depth >= 1 ? 'sub-title' : 'title'
                 } : null; },
              },"template":function (item) { return [
                    {'b': (item.title && !item.valueString) ? item.title : null},
                    {'div.label': (item.title && item.valueString) ? item.title : null},
                    {'div.value': item.valueString} ]; }}})],1):_vm._e(),(!_vm.$$.isEmpty(_vm.form.model))?_c('DataModel',{staticClass:"form-order-details-model",attrs:{"model":Object.assign({}, _vm.$apiModel.order_details,
            _vm.form,
            {render: _vm.render})}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }