/*
* ALL PERMISSIONS:
* CAN_CHANGE_DRIVER_PERMISSIONS,
* CAN_CHANGE_DISPATCHER_PERMISSIONS,
* CAN_ADD_DRIVER,
* CAN_DELETE_DRIVER,
* CAN_ADD_DISPATCHER,
* CAN_DELETE_DISPATCHER,
* CAN_ADD_TRANSPORT,
* CAN_DELETE_TRANSPORT,
* CAN_EDIT_TRANSPORT,
* CAN_ADD_COUNTERPART_TRANSPORT,
* CAN_DELETE_COUNTERPART_TRANSPORT,
* CAN_EDIT_COUNTERPART_TRANSPORT,
* CAN_CONTROL_SHIPPING_STATUS,
* CAN_ASSIGN_DRIVER,
* CAN_CONTROL_BIDS, // (2) Комплексное право "Участие в тендерах"
* CAN_CONFIRM_RFT,
* CAN_DECLINE_RFT,
* CAN_VIEW_TENDERS, // Просмотр тендеров + (2) Комплексное право "Участие в тендерах"
* CAN_VIEW_FUEL_REMINDER, // Возмжность просмотра свободного остатка д/с
* CAN_GRANT_VIEW_FUEL_REMINDER, // управление правом CAN_VIEW_FUEL_REMINDER для подчиненных пользователей
* CAN_TOPUP_FUEL_CARDS, // Возможность пополнения ТК любыми способами
* CAN_GRANT_TOPUP_FUEL_CARDS, // Управление правом CAN_TOPUP_FUEL_CARDS для подчиненных пользователей
*/
/*
* УПРАВЛЕНИЕ ТК:
* Водитель:
* Может смотреть остаток, если есть право
* Не может пополнять карту
*
* Диспетчер:
* Может смотреть остаток, если есть право
* Может пополнять карту, если есть право
* Может давать право смотреть, если есть право
*
* Перевозчик:
* Может смотреть остаток
* Может пополнять
* Может давать право смотреть остаток
*
* 1. Просмотр свободного остатка д/с (CAN_VIEW_FUEL_REMINDER)
* 2. Выдача права на просмотр свободного остатка д/с (CAN_GRANT_VIEW_FUEL_REMINDER)
* */

export default {
    groups: {
        permissions: {
            name: 'Управление правами',
            items: {
                CAN_CHANGE_DRIVER_PERMISSIONS: 'Управление водителями',
                CAN_CHANGE_DISPATCHER_PERMISSIONS: 'Управление диспетчерами',
            }
        },
        driver: {
            name: 'Управление водителями',
            items: {
                CAN_ADD_DRIVER: 'Добавление',
                CAN_DELETE_DRIVER: 'Удаление',
            }
        },
        dispatcher: {
            name: 'Управление диспетчерами',
            items: {
                CAN_ADD_DISPATCHER: 'Добавление',
                CAN_DELETE_DISPATCHER: 'Удаление',
            }
        },
        transport: {
            name: 'Управление транспортом',
            items: {
                CAN_ADD_TRANSPORT: 'Добавление',
                CAN_DELETE_TRANSPORT: 'Удаление',
                CAN_EDIT_TRANSPORT: 'Редактирование',
            }
        },
        counterpart_transport: {
            name: 'Транспорт контрагента',
            items: {
                CAN_ADD_COUNTERPART_TRANSPORT: 'Добавление',
                CAN_DELETE_COUNTERPART_TRANSPORT: 'Удаление',
                CAN_EDIT_COUNTERPART_TRANSPORT: 'Редактирование',
            }
        },
        fuel_card: {
            name: 'Топливные карты',
            items: {
                CAN_VIEW_FUEL_REMINDER: 'Просмотр свободного остатка д/с',
                CAN_GRANT_VIEW_FUEL_REMINDER: 'Выдача права на просмотр свободного остатка д/с',
                CAN_TOPUP_FUEL_CARDS: 'Пополнение ТК любыми способами',
                CAN_GRANT_TOPUP_FUEL_CARDS: 'Выдача права на пополнение ТК любыми способами',
            }
        },
        other: {
            name: 'Прочие права',
            items: {
                CAN_ASSIGN_DRIVER: 'Выбор исполнителя',
                CAN_CONTROL_BIDS: 'Подача ставки в тендерах',
                CAN_VIEW_TENDERS: 'Просмотр тендеров',
                CAN_CONTROL_SHIPPING_STATUS: 'Контроль статуса перевозки',
                CAN_CONFIRM_RFT: 'Подтверждение рейса',
                CAN_DECLINE_RFT: 'Отмена рейса',
            }
        }
    }
}