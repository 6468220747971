<template>
    <component
        :is="showOverlay ? 'transition' : 'div'"
        v-if="isOpen"
        v-show="showModal"
        :name="animate && showOverlay ? 'fade' : ''"
    >
        <div v-if="isOpen" class="modal-overlay" :class="showOverlay || 'modal-overlay-disabled'">
            <div
                class="modal"
                :class="[showOverlay || 'modal-fixed', isOpen ? 'modal-in' : 'modal-out']"
                ref="modal"
                :data-modal="name"
                role="dialog"
                :style="style"
                v-click-outside="() => clickToClose === false || close()"
            >
                <div v-if="header" ref="header" class="modal-header">
                    <slot name="header"/>
                    <div class="modal-header-close" @click="close">&times;</div>
                </div>
                <div ref="content" class="modal-content" @scroll="$emit('scroll', $refs.content)">
                    <slot/>
                    <Buttons
                        v-if="buttons"
                        :buttons="buttons"
                        :inline="buttonsInline"
                        :cancel="buttonCancel"
                        @cancel="$emit('cancel')"
                        class="mb-20"
                    />
                </div>
                <div v-if="footer" ref="footer" class="modal-footer">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </component>
</template>

<script>

const ANIMATION_DELAY = 50
const MODAL_TRANSFORM = 'translate(0, 10px)'
const MODAL_TRANSITION = '.2s ease-in-out'
const MODAL_TRANSITION_TIMEOUT = 220
const BODY_OVERFLOW = document.body.style.overflow

export default {
    name: 'Popup',
    props: {
        name: {
            type: String,
            required: true
        },
    },
    data: () => ({
        style: {},
        styles: {},
        buttons: null,
        buttonsInline: false,
        buttonCancel: false,
        isOpen: false,
        onOpen: null,
        onClose: null,
        fullscreen: null,
        header: true,
        footer: true,
        clickToClose: true,
        animate: true,
        showModal: false,
        showOverlay: true,
        pageScroll: true,
    }),
    created() {
        this.$registerComponent('modals', this.name)
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    beforeRouteUpdate(to, from, next) {
        this.close()
        next()
    },
    methods: {
        defaultBind() {
            const deviceIsMobile = this.$service.device.isMobile()
            return {
                height: deviceIsMobile ? '80%' : '70%',
                width: deviceIsMobile ? '90%' : '50%',
            }
        },
        onResize() {
            this.isOpen && this.normalize()
        },
        getModal() {
            return this.$refs.modal
        },
        show(
            {
                style,
                header: header = true,
                footer: footer = true,
                fullscreen: fullscreen = null,
                clickToClose: clickToClose = true,
                animate: animate = true,
                buttons,
                buttonsInline,
                buttonCancel,
                onOpen,
                onClose,
                showOverlay: showOverlay = true,
                pageScroll: pageScroll = true,
            }
        ) {
            if (buttons) {
                this.buttons = buttons
                this.buttonsInline = Boolean(buttonsInline)
                this.buttonCancel = Boolean(buttonCancel)
            }

            this.onOpen = onOpen
            this.onClose = onClose
            this.styles = style || {}
            this.fullscreen = fullscreen
            this.showOverlay = showOverlay
            this.pageScroll = pageScroll

            this.header = Boolean(header)
            this.footer = Boolean(footer)
            this.clickToClose = Boolean(clickToClose)
            this.animate = Boolean(animate)

            this.open()
        },
        getDimensions() {
            let height, width, top, left

            if (this.isFullScreen()) {
                height = '100%'
                width = '100%'
                left = '0px'
                top = '0px'
            } else {

                const styles = this.getStyles()

                const ww = window.innerWidth
                const wh = window.innerHeight
                height = styles.height !== undefined ? parseInt(styles.height) : 0
                width = styles.width !== undefined ? parseInt(styles.width) : 0
                left = styles.left !== undefined ? parseInt(styles.left) : 0
                top = styles.top !== undefined ? parseInt(styles.top) : 0

                if (styles.height === 'auto') {
                    height = 'auto'
                } else {
                    height = (!_.isNumber(height) ? 0 : styles.height.toString().includes('%') ? wh * height / 100 : height) + 'px'
                }
                if (styles.width === 'auto') {
                    width = 'auto'
                } else {
                    width = (!_.isNumber(width) ? 0 : styles.width.toString().includes('%') ? ww * width / 100 : width) + 'px'
                }

                left = (!_.isNumber(left) ? 0 : left) + 'px'
                top = (!_.isNumber(top) ? 0 : top) + 'px'
            }

            return {height, width, left, top}
        },
        setDimensions(assignStyles) {
            const modal = this.getModal()
            const content = this.$refs.content

            if (!content || !modal) return

            const header = this.$refs.header?.offsetHeight || 0
            const footer = this.$refs.footer?.offsetHeight || 0

            if (this.isFullScreen()) {

                modal.style.top = '0px'
                modal.style.height = '100%'
                modal.style.left = '0px'
                modal.style.width = '100%'
                modal.style.maxHeight = null
                modal.style.maxWidth = null
                content.style.height = modal.offsetHeight - (header + footer) + 'px'
                return
            }

            const styles = _.merge(this.getStyles(), _.isPlainObject(assignStyles) ? assignStyles : {})
            const dim = this.getDimensions()
            const ww = window.innerWidth
            const wh = window.innerHeight

            Object.assign(modal.style, styles)

            if (styles.height === 'auto' && parseInt(dim.top) === 0) {
                content.style.height = 'auto'
                modal.style.height = content.offsetHeight + header + footer + 'px'
                modal.style.top = (wh / 2) - (modal.offsetHeight / 2) + 'px'
            } else {
                content.style.height = modal.offsetHeight - (header + footer) + 'px'
            }

            const maxHeight = parseInt(styles.maxHeight)

            if (_.isNumber(maxHeight) && maxHeight && modal.offsetHeight > maxHeight) {
                content.style.height = maxHeight - (header + footer) + 'px'
            }

            if (styles.width === 'auto' && dim.left === 0) {
                content.style.width = 'auto'
                modal.style.width = content.offsetWidth + 'px'
                modal.style.left = (ww / 2) - (modal.offsetWidth / 2) + 'px'
            }
        },
        getPos() {
            const ww = window.innerWidth
            const wh = window.innerHeight
            let {height, width, left, top} = this.getDimensions()

            parseInt(left) !== 0 || width === '100%' || width === 'auto' || (left = (ww / 2) - (parseInt(width) / 2) + 'px')
            parseInt(top) !== 0 || height === '100%' || height === 'auto' || (top = (wh / 2) - (parseInt(height) / 2) + 'px')

            return {left, top}
        },
        transform() {
            const modal = this.$refs.modal
            this.showModal = true
            this.$nextTick(() => {
                this.resetTransform()
                this.setDimensions()
                this.animate && !this.isFullScreen() ? setTimeout(() => {
                    this.setTransform(modal, {
                        transform: MODAL_TRANSFORM,
                        transition: MODAL_TRANSITION
                    })
                    setTimeout(() => this.isFullScreen() || this.fitToWindow({}), MODAL_TRANSITION_TIMEOUT)
                }, ANIMATION_DELAY) : (this.isFullScreen() || this.fitToWindow({}))
            })
        },
        fitToWindow({el, width, height, resize: resize = true}) {
            const modal = el || this.$refs.modal
            if (!modal) return

            if (!width) {
                const minWidth = _.isNumber(parseInt(modal.style.minWidth)) ? parseInt(modal.style.minWidth) : 0
                const maxWidth = _.isNumber(parseInt(modal.style.maxWidth)) ? parseInt(modal.style.maxWidth) : 0
                width = _.isNumber(parseInt(modal.style.width)) ? parseInt(modal.style.width) : 0

                if (!width && !minWidth && !maxWidth) {
                    width = modal.offsetWidth
                } else if (!width && (minWidth || maxWidth)) {
                    minWidth ? width = minWidth : width = maxWidth
                } else width > maxWidth ? width = maxWidth : width = minWidth
            }
            if (!height) {
                const minHeight = _.isNumber(parseInt(modal.style.minHeight)) ? parseInt(modal.style.minHeight) : 0
                const maxHeight = _.isNumber(parseInt(modal.style.maxHeight)) ? parseInt(modal.style.maxHeight) : 0
                height = _.isNumber(parseInt(modal.style.height)) ? parseInt(modal.style.height) : 0

                if (!height && !minHeight && !maxHeight) {
                    height = modal.offsetHeight
                } else if (!height && (minHeight || maxHeight)) {
                    minHeight ? height = minHeight : height = maxHeight
                } else height > maxHeight ? height = maxHeight : height = minHeight
            }

            this.$util.page.fitToWindow({
                el: modal,
                width: width,
                height: height,
                resize
            })
        },
        setTransform(target, {transform, transition}) {
            const props = {
                transform: [
                    '-webkit-transform', /* Safari & Chrome */
                    '-moz-transform', /* Firefox */
                    '-ms-transform', /* Internet Explorer */
                    '-o-transform',
                    'transform', /* W3C */
                ],
                transition: [
                    '-webkit-transition',
                    '-moz-transition',
                    '-o-transition',
                    '-ms-transition',
                    'transition'
                ]
            }
            const setProperty = (option, value) => {
                value === undefined || props[option].forEach(opt => target.style.setProperty(opt, value))
            }

            setProperty('transform', transform)
            setProperty('transition', transition)
        },
        resetTransform() {
            const modal = this.$refs.modal
            modal && this.setTransform(modal, {transform: null, transition: null})
        },
        getStyles() {
            const styles = this.styles instanceof Function ? this.styles(this) : this.styles
            return Object.assign({}, this.defaultBind(), _.isPlainObject(styles) ? styles : {})
        },
        setStyles(styles) {
            const modal = this.$refs.modal
            if (!modal) return
            this.animate || this.resetTransform()
            this.setDimensions(styles)
            this.$nextTick(() => {
                this.isFullScreen() || this.fitToWindow({})
            })
        },
        normalize() {
            this.resetTransform()
            this.setDimensions(this.getPos())
            this.$nextTick(() => {
                this.isFullScreen() || this.fitToWindow({})
            })
        },
        setLayer() {

            const indexes = []

            if (!this.getModal()) return

            $(document).find('.modal[data-modal]').each((i, el) => {
                el = el.closest('.modal-overlay') || el
                const index = window.getComputedStyle(el).getPropertyValue('z-index')
                _.isNumber(parseInt(index)) && indexes.push(parseInt(index))
            })

            if (!indexes.length) return

            let max = Math.max(...indexes)

            this.getModal().closest('.modal-overlay')?.style.setProperty('z-index', max += 1)
            this.getModal().style.setProperty('z-index', max + 1)
        },
        open() {
            Object.assign(this.style, this.getDimensions())
            Object.assign(this.style, this.getPos())
            this.showModal = false
            this.isOpen = true
            this.$nextTick(() => {
                if (!this.getModal()) return
                this.transform()
                this.$emit('opened')
                this.onOpen && this.onOpen(this)
                this.setLayer()
            })
            if (this.pageScroll === false || this.isFullScreen()) {
                document.body.style.overflow = 'hidden'
            }
        },
        close() {
            this.showModal = false
            setTimeout(() => {
                this.isOpen = false
                this.$emit('closed')
                this.onClose && this.onClose(this)
            }, this.animate ? ANIMATION_DELAY : 0)
            document.body.style.overflow = BODY_OVERFLOW
        },
        isFullScreen() {
            return _.isBoolean(this.fullscreen) ? this.fullscreen : this.$service.device.isPhone()
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    z-index: 999;
    color: $dark-grey;

    &.modal-fixed {
        position: fixed;
    }

    &-header {
        display: block;
        border-bottom: #dadada 1px solid;
        position: relative;
        padding: 15px 40px;
        min-height: 20px;

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 30%;
            right: 15px;
            font-size: 20pt;
            cursor: pointer;
            text-align: center;
        }

        /deep/ > h1 {
            padding: 0;
            margin: 0;
        }

        /deep/ > h2 {
            padding: 0;
            margin: 0;
        }

        /deep/ > h3 {
            padding: 0;
            margin: 0;
        }
    }

    &-content {
        overflow: hidden;
        overflow-y: auto;
        max-height: 100%;
        padding: 0;
    }

    &-footer {
        border-top: #dadada 1px solid;
        padding: 20px;
    }
}

.modal-overlay {
    content: '';
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    background: rgba(44, 62, 80, 0.46);
    width: 100%;
    height: 100%;

    &.modal-overlay-disabled {
        display: inline-block !important;
        position: absolute !important;
        width: 0 !important;
        height: 0 !important;
        background: none !important;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    transition: opacity .1s;
}

</style>
