import Repo from '@/api/service/repo'

export default class extends Repo {

    //имеется ли новая версия пользовательского соглашения
    checkAgreement() {
        return this.client.get('/needUpdateUserAgreement')
    }

    getAgreement(docVersion) {
        return this.client.html().get('/userAgreement', {docVersion})
    }

    acceptAgreement(docVersion) {
        return this.client.post('/acceptUserAgreement', {docVersion})
    }

    privacy() {
        return this.client.html().get('/privacy')
    }

}
