<template>
    <div class="block-contents">
        <h2 class="section-page-title pl-0">{{regulation.name || name}}</h2>
        <Document v-if="details" :html="details" class="mb-40" />
        <buttons v-if="regulation && regulation.confirmation === false">
            <button class="btn btn-blue" @click="confirm">Ознакомлен(-а)</button>
        </buttons>
        <BackToggle @toggle="$emit('toggle')" class="mt-15" />
    </div>
</template>

<script>
import Document from "@plugins/Document"
export default {
    name: "FormRegulation",
    components: {Document},
    props: {
        id: Number,
        name: String,
    },
    data() {
        return {
            regulation: {},
            details: null,
        }
    },
    created() {
        this.get()
    },
    methods: {
        get() {
            const alert = `Не удалось загрузить данные по нормативному документу ${this.name ? `"${this.name}"` : ``}.`
            this.$repo('enterprise').getRegulation(this.id).then(({object}) => {
                this.regulation = object
                this.$repo('enterprise').regulationDetails(object.id, object.docVersion).then((r) => {
                    this.details = r.data
                }).alert(alert)
            }).alert(alert)
        },
        confirm() {
            this.$repo('enterprise').regulationConfirm(this.id, this.regulation.docVersion).then(() => {
                this.$emit('confirmed')
            }).done('Нормативный документ подтвержден Вами.').alert('Не удалось подтвердить Нормативный документ.')
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
