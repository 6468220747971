import Repo from '@/api/service/repo'

export default class extends Repo {

    _stubs = {
        excludeUrl: ['/notifications/markAsRead', '/notifications/markAllAsRead']
    }

    getMessages({ page, size }) {
        return this.client.get('/notifications/history', { page, size })
    }

    getUnreadQuantity() {
        return this.client.get('/notifications/getUnreadQuantity')
    }

    markAsRead(idList) {
        return this.client.post('/notifications/markAsRead', idList)
    }

    markAllAsRead(fromNotifId) {
        return this.client.encode().post('/notifications/markAllAsRead', {fromNotifId})
    }

    //Регистрация push токена для текущего пользователя
    setPushToken({pushToken, fcmToken, platform: platform = "WEB"}) {
        return this.client.encode().post('/notifications/setPushToken', {pushToken, fcmToken, platform})
    }

    //Получение списка настроек пуш уведомлений
    getPushSettings() {
        return this.client.get('/pushSettings')
    }

    //Изменение списка настроек пуш уведомлений
    setPushSettings(pushSettings) {
        return this.client.post('/pushSettings', pushSettings)
    }

    //Отправка тестового уведомления самому себе
    test() {
        return this.client.get('/notifications/test')
    }

    // ДОЛЖНОСТНЫЕ ИНСТРУКЦИИ ВОДИТЕЛЯ:

    //Получить описатель ДИ (должн. инструкция водителя)
    getConfirmMessage(id) {
        return this.client.get(`/official/${id}`)
    }

    //Получить Код подтверждения для ДИ
    getMessageConfirmCode(id) {
        return this.client.get(`/official/${id}/getConfirmationCode`)
    }

    //Подтвердить Код подтверждения для ДИ
    confirmMessageCode(id, confirmationCode) {
        return this.client.encode().post(`/official/${id}/confirm`, {confirmationCode})
    }

}
