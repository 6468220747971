export default {
    model: {
        "accountDetails": {
            "bankAddress": "",
            "bankName": "",
            "bic": "",
            "correspondingAccount": "",
            "currentAccount": ""
        },
        "companyDetails": {
            "city": "",
            "email": "",
            "formOfPayment": "",
            "iec": "",
            "itn": "",
            "legalAddress": "",
            "name": "",
            "phone": "+7",
            "postAddress": "",
            "region": "",
            "typeOfBusiness": ""
        },
        "directorAssignmentScans": [],
        "directorPassport": {
            "birthDate": "",
            "birthLocation": "",
            "citizenship": "",
            "departmentCode": "",
            "firstPageScan": "",
            "issuingAuthority": "",
            "passportDate": "",
            "passportNumber": "",
            "passportSeries": "",
            "registration": "",
            "registrationPageScan": ""
        },
        "id": 0,
        "itnScans": [],
        "name": "",
        "otherScans": [],
        "patronymic": "",
        "phone": "+7",
        "seat": "",
        "status": "",
        "surname": ""
    },
    groups: [
        {
            name: "Контактная информация", fields: [
                'surname', 'name', 'patronymic', 'phone', 'position', 'status'
            ]
        },
        {
            name: "Реквизиты предприятия", fields: [
                'companyDetails.typeOfBusiness', 'companyDetails.name',
                'companyDetails.formOfPayment', 'companyDetails.postAddress', 'companyDetails.legalAddress',
                'companyDetails.city', 'companyDetails.region', 'companyDetails.phone', 'companyDetails.email', 'companyDetails.itn',
                'companyDetails.iec', 'itnScans'
            ]
        },
        {
            name: "Банковские реквизиты", fields: [
                'accountDetails.currentAccount', 'accountDetails.bankName',
                'accountDetails.bankAddress', 'accountDetails.bic', 'accountDetails.correspondingAccount',
            ]
        },
        {
            name: "Сканы решения учредителя", label: 'directorAssignmentScans', fields: [
                'directorAssignmentScans'
            ]
        },
        {
            name: "Данные паспорта директора", fields: [
                'directorPassport.birthDate', 'directorPassport.birthLocation',
                'directorPassport.passportSeries', 'directorPassport.passportNumber',
                'directorPassport.passportDate', 'directorPassport.departmentCode',
                'directorPassport.issuingAuthority', 'directorPassport.registration', 'directorPassport.citizenship',
                'directorPassport.firstPageScan', 'directorPassport.registrationPageScan',
            ]
        },
    ],
    assign: {
        'companyDetails.city': ({bind, vm}) => {
            vm.$repo('common').cities().then(({list}) => bind(list, 'options'))
        },
        'companyDetails.region': ({bind, vm}) => {
            vm.$repo('common').regions().then(({list}) => bind(list, 'options'))
        },
    },
    fields: {
        "surname": {
            name: "Фамилия",
        },
        "name": {
            name: "Имя",
        },
        "patronymic": {
            name: "Отчество",
        },
        "phone": {
            name: "Основной моб. телефон",
            mask: "phone",
            format: v => _.toNum(v)
        },
        "position": {
            name: "Должность",
            disabled: true,
            model: ({vm}) => vm.$service.account.roleName('carrier')
        },
        "status": {
            name: "Статус перевозчика",
            disabled: true,
            required: false,
        },
        "companyDetails.typeOfBusiness": {
            name: "Организационно правовая форма",
            type: 'select',
            options: [
                'ООО',
                'ИП',
            ],
            watch: ({value, vm}) => {
                vm.updateField('companyDetails.name')
                vm.updateField('companyDetails.itn', {
                    mask: value === 'ООО' ? "##########" : "############",
                    required: true,
                    rules: [['minLength', value === 'ООО' ? 10 : 12]]
                })
            }
        },
        "companyDetails.name": {
            name: "Название компании",
            bind: ({model}) => {
                return {
                    placeholder: model.companyDetails.typeOfBusiness === 'ИП'
                        ? 'Официальное название ИП, например: "Иванов Иван Иванович"'
                        : false,
                    rules: [
                        (v) => {
                            if (model.companyDetails.typeOfBusiness !== 'ИП') return
                            const match = v?.replace(/^(.*?)\s(ип)$/i, "$1")?.match(new RegExp(`^\\s*(.){2,}\\s(.){2,}\\s(.){2,}\\s*$`, 'ui'))
                            return match
                                ? false
                                : 'Название компании должно включать официальное название ИП (ФИО полностью)'
                        }
                    ]
                }
            },
            watch: ({value, model, vm}) => {
                return model.companyDetails.typeOfBusiness !== 'ИП'
                    ? value
                    : vm.$filter.cutLineBreaks(value ?.replace(/[^a-zа-я\d\s-'"]+/gi, ''))
                        .replace(/(\sип\s|\s.\s)/gi, " ")
                        .replace(/^(ип)\s(.*?)$/i, "$2")
            }
        },
        "companyDetails.formOfPayment": {
            name: "Форма оплаты",
            type: 'select',
            options: [
                'с НДС',
                'без НДС',
            ]
        },
        "companyDetails.postAddress": {
            name: "Почтовый адрес",
            type: 'textarea',
        },
        "companyDetails.legalAddress": {
            name: "Юридический адрес",
            type: 'textarea',
        },
        "companyDetails.city": {
            name: "Город",
            type: 'find',
            options: [],
            label: "name",
            labelName: "name",
        },
        "companyDetails.region": {
            name: "Регион",
            type: 'select',
            options: [],
            label: "name",
            labelName: "name",
        },
        "companyDetails.phone": {
            name: "Основной телефон компании",
            mask: "phone",
            format: v => _.toNum(v)
        },
        "companyDetails.email": {
            name: "Основная эл. почта",
        },
        "companyDetails.itn": {
            name: "ИНН",
            mask: "############",
        },
        "companyDetails.iec": {
            name: "КПП",
            mask: "#########",
            rules: [['minLength', 9]],
            wrap: {
                if : ({model}) => model.companyDetails.typeOfBusiness === 'ООО',
            }
        },
        "itnScans": {
            name: "Фото ИНН",
            type: 'img',
        },
        "accountDetails.currentAccount": {
            name: "Номер расчётного счета",
            mask: "####################",
            rules: [['minLength', 20]]
        },
        "accountDetails.bankName": {
            name: "Полное название банка",
        },
        "accountDetails.bankAddress": {
            name: "Адрес банка",
        },
        "accountDetails.bic": {
            name: "БИК",
            rules: [['minLength', 9]],
            mask: "#########",
        },
        "accountDetails.correspondingAccount": {
            name: "Корреспондентский счет",
            rules: [['minLength', 20]],
            mask: "####################",
        },
        "directorAssignmentScans": {
            name: "Фото",
            type: 'img',
            wrap: {
                if : ({model}) => model.companyDetails.typeOfBusiness === 'ООО',
            }
        },
        "directorPassport.birthDate": {
            name: "Дата рождения",
            type: 'date'
        },
        "directorPassport.birthLocation": {
            name: "Место рождения",
        },
        "directorPassport.passportSeries": {
            name: "Серия",
            rules: [['minLength', 4]],
            mask: '####',
            format: v => _.toNum(v)
        },
        "directorPassport.passportNumber": {
            name: "Номер",
            rules: [['minLength', 6]],
            mask: '######',
        },
        "directorPassport.passportDate": {
            name: "Дата выдачи",
            type: 'date'
        },
        "directorPassport.departmentCode": {
            name: "Код подразделения",
            rules: [['minLength', 7]],
            mask: '###-###',
        },
        "directorPassport.issuingAuthority": {
            name: "Кем выдан",
        },
        "directorPassport.registration": {
            name: "Адрес регистрации",
        },
        "directorPassport.citizenship": {
            name: "Гражданство",
        },
        "directorPassport.firstPageScan": {
            name: "Фото 1-й страницы",
            type: 'img',
        },
        "directorPassport.registrationPageScan": {
            name: "Фото регистрации",
            type: 'img',
        },
    }
}
