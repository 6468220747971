var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"favorite-routes"},[_c('h3',{staticClass:"tx-c clr-ltg-3"},[_vm._v(" Здесь Вы можете указать интересующие Вас направления, когда в системе появится подходящая заявка, Вы получите уведомление. ")]),(_vm.cities && _vm.regions && _vm.transportTypes && _vm.loadingMethods)?[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"btn btn-blue btn-small",on:{"click":function($event){_vm.setForm(); _vm.openForm()}}},[_vm._v(" Добавить ")])]),_c('DataTable',{key:_vm.load,staticClass:"mt-20",attrs:{"cols":{
                    enabled: 'Активно',
                    loadingDateFrom: 'Дата погрузки (От)',
                    loadingDateTo: 'Дата погрузки (До)',
                    'loadingCity.name': 'Город загрузки',
                    'loadingRegion.name': 'Регион загрузки',
                    'unloadingCity.name': 'Город выгрузки',
                    'unloadingRegion.name': 'Регион выгрузки',
                    'transportType': 'Тип транспорта',
                    'loadingMethods': 'Способ погрузки',
                },"cells":{
                    loadingDateFrom: function (v) { return _vm.$util.date.date(v); },
                    loadingDateTo: function (v) { return _vm.$util.date.date(v); },
                    transportType: function (v) { return [].concat( v ).map(function (i) { return (i && i.name) || ''; }).join(', '); },
                    loadingMethods: function (v) { return [].concat( v ).map(function (i) { return (i && i.name) || ''; }).join(', '); },
                    enabled: function (v, item) { return [
                        {'Toggle': '', on: {change: function (ref) {
                                        var srcEvent = ref.srcEvent;

                                        return _vm.toggle(srcEvent, item.id);
                                    }}, bind: {value: v}}
                    ]; }
                },"fetch":_vm.fetchData,"click":{exclude: ['enabled'], action: function (route) {_vm.setForm(route.id); _vm.openForm()}}},on:{"setup":function (i) { return this$1.list = i; }}}),_c('Popup',{attrs:{"name":"popup-form-fav-routes"}},[_c('FormFavoriteRoutes',_vm._b({attrs:{"form":_vm.form},on:{"success":function () { return this$1.load += 1; }}},'FormFavoriteRoutes',{
                        form: _vm.form,
                        cities: _vm.cities,
                        regions: _vm.regions,
                        transportTypes: _vm.transportTypes,
                        loadingMethods: _vm.loadingMethods,
                    },false))],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }