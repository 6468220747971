<template>
    <component
        v-if="component.is === 'html'"
        :is="'div'"
        v-bind="component.bind"
        v-on="component.on"
        v-html="component.html"
    />
    <component
        v-else-if="component.html !== null || component.value !== null"
        :is="component.is"
        v-bind="component.bind"
        v-on="component.on"
    >
        <div v-if="component.html" v-html="component.html"></div>
        <template v-else-if="!$$.isObject(component.value)">
            {{ component.value }}
        </template>
        <template v-else-if="$$.isArray(component.value)">
            <template v-for="(item, key) in component.value">
                <Render :key="key" :data="item"/>
            </template>
        </template>
        <Render v-else :data="component.value"/>
        <slot/>
    </component>
</template>

<script>

export default {
    name: "Render",
    props: {
        data: null,
    },
    data() {
        return {
            component: {
                is: 'div',
                bind: {},
                on: {},
                html: null,
                value: null
            }
        }
    },
    created() {
        if (_.isArray(this.data)) {
            let data, index = -1
            this.data.some((item, i) => {
                if (_.isPlainObject(item) && _.isPlainObject(item.component)) {
                    index = i
                    return data = this.fetchComponent(item.component)
                }
            });
            if (index >= 0) {
                this.component = _.merge(this.component, data)
                delete this.data[index]
            }
        }
        _.isPlainObject(this.data)
            ? this.component = this.fetchComponent(this.data)
            : this.component.value =
                (_.isObject(this.data) && _.isEmpty(this.data))
                || this.data === undefined || _.isBoolean(this.data) ? null : this.data
    },
    methods: {
        fetchComponent(data) {
            const tag = Object.keys(data)[0]
            let value = data[tag]
            _.isBoolean(value) && (value = value ? '' : null)
            if (_.isNil(value))
                return {value: null}
            const bind = data.bind || {}
            let component = tag, id, cls
            if (component.match(/#/g)
                && !('id' in bind)
                && (id = component.replace(/^([^#]+)#([^.#]+)(.*?)$/g, '$2'))) {
                component = component.replace(`#${id}`, '')
                bind.id = id
            }
            if (component.match(/\./g) && (cls = component.replace(/^([^.]+)(.*?)$/g, '$2'))) {
                component = component.replace(cls, '')
                if (typeof bind.class === 'string')
                    bind.class += cls.split('.').join(' ')
                else if (_.isPlainObject(bind.class) || _.isArray(bind.class)) {
                    const bindClass = {}
                    cls.split('.').forEach(i => bindClass[i] = true)
                    bind.class = _.isArray(bind.class)
                        ? bind.class.concat(Object.keys(bindClass))
                        : _.merge(bindClass, bind.class)
                } else bind.class = cls.split('.').join(' ')
            }
            return {
                is: component,
                bind: bind,
                on: data.on || {},
                html: data.html || null,
                value: value,
            }
        }
    }
}

</script>
