<template>
    <div
        class="buttons"
        :style="{
            maxWidth: width ? `${width}px` : false,
            marginLeft: center ? 'auto' : false,
            marginRight: center ? 'auto' : false,
        }"
        :class="[
            inline && 'buttons-inline',
            align && 'buttons-align',
            initial && 'buttons-initial',
        ]"
    >
        <slot/>
        <template v-for="(btn, k) in items">
            <button v-if="$$.isPlainObject(btn)" :key="k" v-bind="btn.bind" v-on="btn.on">
                {{ btn.text }}
            </button>
        </template>
    </div>
</template>

<script>
export default {
    name: "Buttons",
    props: {
        buttons: [Array, String],
        inline: Boolean,
        align: Boolean,
        initial: Boolean,
        center: Boolean,
        cancel: [Boolean, String],
        width: Number
    },
    data: () => ({
        items: []
    }),
    computed: {
        stubButton() {
            return {
                text: '',
                bind: {class: 'btn btn-blue'},
            }
        }
    },
    mounted() {
        this.buttons && this.setButtons(this.buttons)
    },
    methods: {
        setButtons(data) {
            this.items = []
            data instanceof Array || (data = [data])
            data.forEach((btn, index) => {
                btn instanceof Function && (btn = btn(this))
                if (!btn) {
                    return
                }
                typeof btn === 'string' && (btn = {text: btn})
                _.isPlainObject(btn.on) || (btn.on = {})
                btn.on.click || (btn.on.click = () => this.$emit('btn-click', index))
                this.items.push(Object.assign({...this.stubButton}, {...btn}))
            })
            if (this.cancel) {
                let cancel = {
                    text: typeof this.cancel === 'string' ? this.cancel : 'Отмена',
                    bind: {class: 'btn btn-white'}
                }
                cancel.on = {click: () => this.$emit('cancel')}
                this.items.push(Object.assign({...this.stubButton}, {...cancel}))
            }
        }
    }
}
</script>

<style scoped lang="scss">
.buttons {
    @media (max-width: 425px) {
        width: 100% !important;
    }
}
</style>
