export default {
    model: {
        "dateTime": null,
        "volume": 0,
        "fuelType": "",
        "byCash": null,
        "id": undefined,
        "vehicleType": "",
        "fileId": null,
        "price": 0,
        "cashAmount": 0,
    },
    routeSheet: {},
    fields: {
        'vehicleType': {
            name: 'Тип транспортного средства',
            type: 'select',
            required: true,
            disabled: true,
            options: {
                'TRUCK': 'Тягач',
                'REFRIGERATOR': 'Рефрижератор',
            }
        },
        'fuelType': {
            name: 'Тип топлива',
            type: 'select',
            disabled: true,
            required: true,
            options: {
                'DIESEL': 'Дизельное',
                'GAS': 'Газ',
            }
        },
        'byCash': {
            name: 'Оплата наличными',
            type: 'checkbox',
            initial: ({value}) => value === null || value === true,
            bind:  ({value}) => ({
                checked: value === null || value === true,
                disabled: true
            })
        },
        'dateTime': {
            name: 'Дата',
            type: 'datetime',
            current: true,
            required: false,
            watch: ({field, vm, value}) => {
                return value === vm.$util.date.dateFormat(field.initial, 'YYYY-MM-DD HH:mm:ss')
                    ? field.initial
                    : vm.$util.date.dateFormat(value, 'YYYY-MM-DDTHH:mm:00')
            }
        },
        'volume': {
            name: 'Заправлено л./м.куб.',
            type: 'number',
            bind: ({model, meta}) => {

                const sheet = meta.routeSheet
                let max

                switch (model.vehicleType) {
                    case 'TRUCK':
                        max = model.fuelType === 'DIESEL' ? sheet.fuel.truckDieselTankVolume : sheet.fuel.truckGasTankVolume
                        break
                    case 'REFRIGERATOR':
                        max = sheet.fuel.refrigeratorDieselTankVolume
                }

                return {
                    rules: [ ['min', 0.001] ],
                    max
                }
            },
            initial: ({value}) => _.num(value, 2),
            step: 0.01,
            watch: ({vm}) => {
                vm.updateField('cashAmount')
                vm.updateField('price')
            }
        },
        'price': {
            name: 'Цена',
            type: 'number',
            bind: ({model}) => ({
                required: (v) => (!parseFloat(v) || isNaN(parseFloat(v))) && parseFloat(model.volume) > 0
            }),
            initial: ({value}) => _.num(value, 2),
            step: 0.01,
            watch: ({vm}) => {
                vm.updateField('cashAmount')
            }
        },
        'cashAmount': {
            name: 'Сумма',
            type: 'number',
            disabled: true,
            model: ({model}) => {
                const price = isNaN(parseFloat(model.price)) ? 0 : parseFloat(model.price)
                const volume = isNaN(parseFloat(model.volume)) ? 0 : parseFloat(model.volume)
                return Math.round(price*volume)
            }
        },
        'fileId': {
            name: 'Прикрепить изображение',
            type: 'img',
            required: false,
        }
    },
}
