export const DEFAULT_ERROR_MESSAGES = {
    required: (name) => name ? `Поле "${name}" обязательно для заполнения.` : 'Обязательно для заполнения.',
}

export const RULES = {
    required: (v) => {
        return _.isObject(v) ? _.isEmpty(v) : _.isNil(v) || v.toString().trim() === ''
    },
    min: (v, min) => {
        if (!_.isNumber(min) || !_.isNumber(parseFloat(v))) return
        if (parseFloat(v) >= min) return false
        return `Значение должно быть не менее "${min}".`
    },
    max: (v, max) => {
        if (!_.isNumber(max) || !_.isNumber(parseFloat(v))) return
        if (parseFloat(v) <= max) return false
        return `Значение должно быть не более "${max}".`
    },
    minLength: (v, min) => {
        if (!_.isNumber(min) || !_.isString(v?.toString())) return
        if (v.toString().length >= min) return false
        return `Длина не менее "${min}" символов.`
    },
    maxLength: (v, max) => {
        if (!_.isNumber(max) || !_.isString(v?.toString())) return
        if (v.toString().length <= max) return false
        return `Длина не более "${max}" символов.`
    },
    regex: (v, {regex, flags}) => {
        if (!regex || (!_.isString(regex) && !_.isObject(regex)) || !_.isString(v)) return
        flags = flags && _.isString(flags) ? flags : 'g'
        return v.match(new RegExp(regex, flags)) ? false : 'Значение имеет недопустимый формат.'
    }
}

export default class {

    constructor({rules, messages, name}) {
        this.rules = rules
        this.error = null
        this.messages = _.isPlainObject(messages) ? messages : {}
        this.name = name && typeof name === 'string' ? name : ''
    }

    ruleNames() {
        return Object.keys(RULES)
    }

    fetchRule(source, value, message) {

        let result, rule, params

        if (source instanceof Promise) {

            (async () => {
                let result = await source
                _.isString(result) ? this.setError({ value, message: result }) : this.resetError()
            })()

        } else if (source instanceof Function) {

            result = source(value)

            if (result === true) {

                message || (message = DEFAULT_ERROR_MESSAGES['required']())

            } else {

                return this.fetchRule(result, value, message)
            }

        } else if (typeof source === 'string') {

            result = this.ruleNames().includes(source) ? RULES[source](value) : source
            rule = source

        } else if (_.isArray(source)) {

            rule = source[0]
            params = source.slice(1)
            result = this.ruleNames().includes(rule) && RULES[rule](value, this.fetchParams(rule, params))

        } else if (_.isPlainObject(source)) {

            return this.fetchRule(source.rule, value, source.message)
        }

        !message && result && _.isString(result) && (message = result)

        return {result, message, rule, params}
    }

    fetchParams(ruleName, params) {
        _.isArray(params) || (params = [])
        switch (ruleName) {
            case 'min':
            case 'minLength':
            case 'max':
            case 'maxLength':
                return params[0]
            case 'regex':
                return {
                    regex: params[0],
                    flags: params[1],
                }
            default:
                return {}
        }
    }

    validate(value, rules, name) {

        name = name || this.name
        rules = rules || this.rules

        const data = {
            result: undefined,
            message: undefined,
            rule: undefined,
            params: undefined,
        }

        _.toArray(rules).some(rule => {
            rule && Object.assign(data, this.fetchRule(rule, value))
            return data.result
        })

        if (data.rule && !data.message) {
            data.message = this.messages[data.rule] || DEFAULT_ERROR_MESSAGES[data.rule]
            _.isFunction(data.message) && (data.message = data.message(name, this.fetchParams(data.rule, data.params)))
        }

        let error

        if (data.result) {
            this.setError(error = {...data, value, name})
        } else {
            error = false
            this.resetError()
        }

        return error
    }

    setError(error) {
        this.error = error
    }

    resetError() {
        this.error = null
    }

}
