<template>
    <Page class="block-contents">
        <DataTable
            :key="key"
            :cols="{
                vehicleName: 'Транспортное средство',
                date: 'Данные на дату',
                fuelLevel: 'Данные',
            }"
            :cells="{
                date: (v, item) => $util.date.date(item.date, true)
            }"
            :fetch="fetch"
        />
    </Page>
</template>

<script>

export default {
    name: "SensorsData",
    methods: {
        fetch(h) {
            this.$repo('enterprise').sensorsData().then(({object}) => {
                h([object])
            })
        }
    }
}

</script>
