<template>
    <Page>
        <h1 class="tx-c clr-extra-ltg" style="font-size: 50pt">
            404
        </h1>
        <h3 class="tx-c clr-ltg">Запрашиваемая Вами страница не найдена</h3>
    </Page>
</template>

<script>
    export default {
        name: 'PageNotFound'
    }
</script>
