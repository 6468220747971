import {RequestHandler} from "@argab/request-service"
import Cache from "@/api/service/cache"
import helper from "@/helpers";

export default class extends RequestHandler {

    _data
    _cache
    _format

    constructor(data) {
        super()
        this._data = data
        this._cache = this._data.cache instanceof Cache ? this._data.cache : null
        this._format = _.isFunction(this._data.format) ? this._data.format : ((data) => data)
    }

    /*
   * method executes before request sent
   * @params: request data
   * @return: void
   * */
    before() {
    }

    /*
    * request checking method
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return Boolean on Success
    * */
    isSuccess(resp) {

        const type = resp.headers?.['content-type']

        if (type && false === type.includes('json'))  return this._data.isSuccess = true

        return this._data.isSuccess = resp.data.result === 'SUCCESS'
    }

    /*
    * request checking method
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return Boolean on Error
    * */
    isError() {
        return this._data.isError = !this._data.isSuccess
    }

    /*
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return: void
    * */
    onSuccess() {
    }

    /*
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return: void
    * */
    onError() {
    }

    /*
    * method executes within a Promise.prototype.catch()
    * @params: error
    * @return: void
    * */
    onCatch() {
    }

    /*
    * method executes within a Promise.prototype.finally()
    * @params: request data
    * @return: void
    * */
    onFinally() {
    }

    /*
    * method executes at the start of a Promise.prototype.then()
    * @params: response
    * @return: void
    * */
    after(response) {

        if (response.data.result === 'SESSION_EXPIRED' || helper.$service.account.isAuthExpired()) {
            helper.$service.account.set('authExpired', true)
        }

        this._data.response = response

        if (!this.isSuccess(response)) return

        const object = response.data.object || response.data.obj

        object && (this._data.object = this._data.response.object = this._format(object))

        const list = _.isArray(response.data.list)

            ? response.data.list

            : _.isArray(response.data.object?.list) ? response.data.object.list : null

        list && (this._data.list = this._data.response.list = this._format(list))

        if (!list && !object) response.data = this._format(response.data)
    }

    /*
    * method executes at the start of a Promise.prototype.catch()
    * @params: error
    * @return: void
    * */
    afterCatch() {
    }

    /*
    * method executes at the start of a Promise.prototype.finally()
    * @params: request data
    * @return: void
    * */
    afterFinally() {
    }

}
