var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('div',{staticClass:"block-center"},[_c('h3',{staticClass:"mt-40 tx-c"},[_vm._v(_vm._s(_vm.registered ? 'Подтверждение регистрации' : 'Регистрация'))]),(_vm.registered === false)?_c('div',[_c('DataForm',{attrs:{"required":"","form":{
                    on: {
                        submit: _vm.reg,
                        cancel: function () { return _vm.$redirect('/login'); }
                    },
                    bind: {
                        buttons: 'Регистрация',
                        buttonCancel: true
                    }
                },"model":{
                    model: Object.assign({}, _vm.formData),
                    fields: {
                        surname: {name: 'Фамилия'},
                        name: {name: 'Имя'},
                        patronymic: {name: 'Отчество'},
                        phone: {name: 'Номер телефона', type: 'text', mask: 'phone', format: function (v) { return _vm.$$.toNum(v); }, required: function (v) { return !v; }},
                        agree: {type: 'checkbox', wrap: {bind: {row: true}}, required: function (v) { return !v; }},
                    }
                }},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('div',{staticClass:"clr-ltg-4",attrs:{"slot":"afterField_agree"},slot:"afterField_agree"},[_c('small',[_vm._v("Я прочитал условия")]),_c('div',{staticClass:"link",on:{"click":_vm.getUserAgreement}},[_c('small',[_vm._v("ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ")])]),_c('small',[_vm._v("и согласен на обработку своих персональных данных")])])])],1):(_vm.token)?_c('div',[_c('h4',{staticClass:"tx-c mb-40"},[_vm._v("На Ваш номер телефона отправлено SMS с кодом подтверждения телефона")]),_c('FormCodeConfirm',{attrs:{"type":"phone","dest":_vm.formData.phone,"endpoint":_vm.$config.api().codeConfirm.confirmRegister,"request-code-endpoint":_vm.$config.api().codeConfirm.resendCode,"token":_vm.token,"counter":_vm.counter},on:{"confirmed":_vm.confirmed,"cancel":function($event){_vm.registered = false}}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }