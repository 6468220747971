import { RequestRepository } from "@argab/request-service"
import helpers from '@/helpers'

export default class extends RequestRepository {

    client

    constructor() {
        super()
        Object.assign(this, helpers)
    }

    get isLogged() {
        return helpers.$service.account.isLogged()
    }

}
