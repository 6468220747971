<template>
    <div class="controls">
        <div
            v-for="(c, k) in getControls()"
            v-show="$$.isFunction(c.show) ? c.show(index) : c.show"
            :key="k"
            class="controls-item"
        >
            <component
                :is="c.tag"
                v-on="getControlHandlers(c.on)"
                v-bind="c.bind"
                v-html="c.html"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "Controls",
    props: {
        index: Number,
        controls: {
            type: [Array, Function],
            default: () => [{
                tag: null,
                on: {},
                bind: {},
                html: '',
                show: true
            }]
        }
    },
    computed: {
        stubControls() {
            return {
                remove: {
                    tag: 'div',
                    on: {click: () => this.$emit('remove', this.index)},
                    html: '&times;',
                    bind: {class: '--remove-icon', title: 'Очистить'},
                    show: false
                }
            }
        }
    },
    methods: {
        getControls() {
            let output = [], embed = []
            let fetch = typeof this.controls === 'function' ? this.controls(this.index) : this.controls
            fetch instanceof Array || (fetch = [])
            fetch.forEach(c => _.isPlainObject(c) && c.tag && output.push({
                tag: c.tag,
                on: c.on || {},
                bind: c.bind || {},
                html: c.html || '',
                show: c.show !== undefined ? c.show : true,
            }))
            Object.keys(this.stubControls).forEach(name => {
                let control = this.fetchControl(name, fetch)
                control && embed.push(control)
            })
            output = [...output, ...embed]
            output.length > 1 && this.$nextTick(() => this.$emit('width', this.$el.offsetWidth))
            return output
        },
        fetchControl(name, controls) {
            let control = controls.find(c => name in c)
            if (control) {
                if (control[name] === false) return null
                if (control[name] === true) return this.stubControls[name]
                control = _.merge(this.stubControls[name], control[name])
            } else {
                control = this.stubControls[name]
            }
            return control
        },
        getControlHandlers(handlers) {
            const h = {...handlers}, hc = {...handlers}
            Object.keys(hc).forEach(k => hc[k] = (e) => h[k](e, this.index))
            return hc
        }
    }
}
</script>

<style lang="scss" scoped>
.controls {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 97%;
    gap: 10px;

    &-item {
        color: #a1a1ab;
        cursor: pointer;

        .--remove-icon {
            font-size: 14pt;
            height: 15px;
        }
    }
}
</style>
