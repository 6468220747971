import Repo from '@/api/service/repo'

export default class extends Repo {

    cities() {
        return this.client.db({
            source: 'cities',
            onGet: ({response, cache, schema}) => {
                if (_.isEmpty(cache) || response.data.object.timeStamp) {
                    schema.timestamp = response.data.object.timeStamp
                }
                return response.data.object?.list?.length ? response.data.object.list : cache
            },
            onSend: ({data, cache, schema}) => {
                data.params = _.assign(data.params, { timeStamp: _.isEmpty(cache) ? 0 : (schema.timestamp || 0) })
                return false
            }
        }).format(data => _.isArray(data) ? _.orderBy(data, 'name') : data).get('/cities')
    }

    regions() {
        return this.client.cache({source: 'regions'}).get(this.isLogged ? '/regions' : '/regions/unAuthUser')
    }

    fileUpload(formData) {
        return this.client.post('/files', formData)
    }

    getFile(id) {
        return this.client.get(`/files/${id}`)
    }

    getImage(params) {
        return this.client.get(`/files/images/${params.id}`, params)
    }

}
