<template>
    <div class="block-center">
        <h1 class="section-page-title tx-c pl-0">Профиль водителя</h1>

        <h3 class="link tx-c" @click="$popup('popup-driver-location', {fullscreen: true})">
            Показать на карте
        </h3>

        <div v-if="driver.linkToCarrierRequired && canAddDriver">
            <div class="flex flex-center">
                <div class="user-link-icon" style="width: 70px; height: 70px"></div>
            </div>
            <div class="flex flex-center flex-row-20">
                <div>
                    <h3 class="tx-up">
                        <a href="#" @click="$emit('add-user')" class="link">привязать</a>
                    </h3>
                </div>
                <div>
                    <h3 class="tx-up">
                        <a href="#" @click="$emit('unlink-user')" class="link">отмена</a>
                    </h3>
                </div>
            </div>
        </div>

        <DataForm :model="{...$apiModel.driver, model: driver}" view/>

        <buttons :width="250" initial center>
            <template v-if="driver.linkToCarrierRequired && canAddDriver">
                <button class="btn btn-blue" @click="$emit('add-user')">
                    привязать водителя
                </button>
                <button class="btn btn-red" @click="$emit('unlink-user')">
                    Отменить привязку
                </button>
            </template>
            <template v-else>
                <button v-if="canViewPermissions" class="btn btn-blue" @click="$emit('rules')">
                    Управление разрешениями
                </button>
                <button v-if="canDelete" class="btn btn-red" @click="$emit('remove')">
                    Удалить водителя
                </button>
            </template>
            <BackToggle :flag="true" @toggle="$emit('toggle', false)" initial/>
        </buttons>
        <Popup name="popup-driver-location">
            <DriverLocation :driver-id="driver.id"/>
        </Popup>
    </div>
</template>

<script>

import DriverLocation from '@pages/order/DriverLocation.vue'

export default {
    name: "FormStaffDriver",
    components: {DriverLocation},
    props: {
        driver: {
            type: Object,
            default: null,
        },
        canViewPermissions: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            canAddDriver: false,
            canDelete: false,
        }
    },
    created() {
        this.driver.linkToCarrierRequired && this.$service.account.hasRule('CAN_ADD_DRIVER', can => this.canAddDriver = can)
        this.$service.account.hasRule('CAN_DELETE_DRIVER', can => this.canDelete = can)
    }
}
</script>
