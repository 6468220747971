<template>
    <Page>
        <div class="pt-40">
            <h3 class="section-page-title clr-blue tx-c">
                &laquo;{{$service.account.get('carrier').companyName}}&raquo;
            </h3>
            <h4 class="tx-c clr-ltg-4 mb-20">Перевозчик</h4>
        </div>
        <buttons>
            <button class="btn btn-blue" @click="unlinkCarrier()">Отвязаться</button>
            <button class="btn btn-white" @click="$router.go(-1)">Отмена</button>
        </buttons>
    </Page>
</template>

<script>
    export default {
        name: "ProfileCarrierInfo",
        methods: {
            unlinkCarrier() {
                const carrier = this.$service.account.get('carrier').companyName

                this.$confirm({
                    text: `Вы действительно хотите отвязаться от перевозчика "${carrier}"?`,
                    onConfirm: () => {
                        this.$repo('link_carrier').delete()
                            .then(() => this.$util.page.reload({}))
                            .done(`Ваш профиль удален из списка перевозчика "${carrier}"`)
                            .alert(`Не удалось отвязаться от перевозчика "${carrier}"`)
                    }
                })
            }
        }
    }
</script>
