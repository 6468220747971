<template>
    <footer>
        <section class="section-container">
            <section class="section-footer">
                <div>
                    <img class="--logo" src="~@/assets/img/logo.png" alt="">
                    <p class="--copy">
                        2010-{{ $util.date.curDate('YYYY') }}. Все права защищены
                    </p>
                </div>
                <div>
                    <ul class="list --apps">
                        <li style="width: 100%">Установи приложение и присоединяйся</li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.sebbia.rosp" target="_blank">
                                <img src="~@/assets/img/gp.png" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="https://apps.apple.com/ru/app/%D1%82%D1%82-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1436694187" target="_blank">
                                <img src="~@/assets/img/as.png" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <div>
                        <h2>8 (800) 555-56-70</h2>
                    </div>
                    <div>
                        <h3>help@ttonline.ru</h3>
                        <a class="--lnk" href="mailto:help@ttonline.ru">Написать нам</a>
                    </div>
                </div>
            </section>
        </section>
    </footer>
</template>

<script>
export default {
    name: "BaseLayoutFooter"
}
</script>

<style scoped lang="scss">
footer {
    background-color: #F1F5F8;

    .--apps {
        li img {
            max-width: 150px;
        }
    }

    .section-footer {
        min-height: 200px;
        display: flex;
        align-items: stretch;
        justify-content: left;
        white-space: nowrap;

        > div {
            &:nth-child(1) {
                width: 32%;
                padding-top: 37px;
                padding-left: 5px;

                > img {
                    width: 60%;
                }

                .--copy {
                    color: $black-73;
                    margin-top: 9px;
                    font-size: 10pt;
                    letter-spacing: 1px;
                }
            }

            &:nth-child(2) {
                width: 46%;
                padding-top: 37px;

                > ul {
                    display: flex;
                    flex-wrap: wrap;
                    color: $black-73;
                    gap: 15px;
                }
            }

            &:nth-child(3) {
                padding-top: 33px;
                text-align: right;
                width: 20.5%;

                h2 {
                    font-family: $font-proxima-bold;
                    color: $black-73;
                    margin: 0;
                    padding: 0;
                    letter-spacing: 1px;
                    font-size: 1.3em;
                }

                .--lnk {
                    font-size: 1.0em;
                    text-decoration: none;
                    color: #40AEE3 !important;
                    border-bottom: #40AEE3 2px dotted;
                    line-height: 18px;
                    margin-right: 2px;
                    transition: $transition-01;

                    &:hover {
                        color: #46c3ff !important;
                    }
                }

                h3 {
                    font-family: $font-proxima-bold;
                    color: $black-73;
                    font-size: 1.2em;
                    margin: 21px 0 -2px;
                    letter-spacing: 1px;
                }
            }
        }

        @media (max-width: 768px) {
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: left;
            white-space: normal;
            > div {
                &:nth-child(1) {
                    > img {
                        width: 70%;
                        margin-left: 20px;
                    }

                    .--copy {
                        margin-left: 20px;
                    }

                    width: 40%;
                    padding-left: 0 !important;
                    padding-top: 20px;
                }

                &:nth-child(2) {
                    width: 60%;
                    text-align: left;
                    padding: 30px 0 0 !important;
                }

                &:nth-child(3) {
                    width: 100%;
                    padding: 0 !important;
                    text-align: center;
                }

                &:last-child {
                    padding-bottom: 30px !important;

                    > div {
                        display: inline-block;
                        padding: 0 20px;
                    }
                }
            }
        }
        @media (max-width: 475px) {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            > div {
                &:nth-child(1) {
                    > img {
                        width: 30%;
                        margin-left: 0;
                    }

                    .--copy {
                        margin-left: 0;
                    }

                    text-align: center;
                    width: 100%;
                    padding-left: 0 !important;
                    padding-top: 20px;
                }

                &:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    padding: 15px 0 0 !important;

                    ul {
                        justify-content: center;
                    }
                }

                &:nth-child(3) {
                    padding-top: 20px !important;
                }
            }
        }
    }
}
</style>
