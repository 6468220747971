<template>
    <div>
        <template v-if="proceed">
            <h2 class="section-page-title tx-c pl-0">Анкета перевозчика</h2>
            <DataForm
                v-model="data.model"
                :form="{
                    on: {submit},
                    bind: {
                        buttons: ['Сохранить'],
                        buttonCancel: true,
                        onCancel: () => $emit('toggle', null)
                    }
                }"
                :model="data"
                required
            />
        </template>

        <Buttons v-else initial class="mt-40">
            <button class="btn btn-green" @click="$emit('check-requisites')">
                Я раньше работал с РОСПРОМ
            </button>
            <button class="btn btn-blue" @click="proceed = true">
                Я буду работать с РОСПРОМ впервые
            </button>
        </Buttons>
    </div>
</template>

<script>

export default {
    name: "form-carrier",
    props: {
        form: Object,
    },
    data() {
        const data = this.$apiModel.carrier
        const profile = this.$service.account.get('user')

        return {
            data: _.merge(data, {model: {...(this.form || {})}}, {
                fields: {
                    name: {model: profile.name, disabled: true},
                    surname: {model: profile.surname, disabled: true},
                    patronymic: {model: profile.patronymic, disabled: true},
                    phone: {model: profile.phone, disabled: true},
                }
            }),
            proceed: false
        }
    },
    methods: {
        submit() {
            this.$repo('carrier').save(this.data.model)
                .then(() => this.$emit('registered'))
                .alert('Не удалось завершить регистрацию.')
                .done('Перевозчик зарегистрирован.')
        }
    }
}

</script>
