<template>
    <div>
        <h2 class="section-page-title tx-c pl-0">Анкета водителя</h2>
        <DataForm
            v-model="data.model"
            :model="data"
            :form="{
                on: {submit},
                bind: {
                    buttons: [
                        'Сохранить',
                        {text: 'Отклонить приглашение от перевозчика', bind: {class: 'btn btn-red'}, on: {click: () => $emit('decline')}},
                    ],
                    buttonCancel: 'Выйти из аккаунта',
                    buttonsInitial: true,
                    buttonsCenter: true,
                    buttonsWidth: 60,
                    onCancel: () => $service.account.logout(),
                },
            }"
        />
    </div>
</template>

<script>

export default {
    name: "form-driver",
    props: {
        form: Object
    },
    data() {
        const data = this.$apiModel.driver
        const profile = this.$service.account.get('user')

        return {
            data: _.merge(data, {model: {...(this.form || {})}}, {
                fields: {
                    name: {model: profile.name, disabled: true},
                    surname: {model: profile.surname, disabled: true},
                    patronymic: {model: profile.patronymic, disabled: true},
                    phone: {model: profile.phone, disabled: true},
                }
            }),
        }
    },
    methods: {
        submit() {
            this.$repo('driver').save(this.data.model)
                .then(() => this.$emit('registered'))
                .alert('Не удалось завершить регистрацию.')
                .done('Водитель зарегистрирован.')
        }
    }
}

</script>
