var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canAssign !== null)?_c('Page',[_c('FormTransportSearch',{on:{"search":_vm.reload},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('DataTable',{key:_vm.load,staticClass:"mt-20",attrs:{"cols":{
            id: 'ID',
            driver: 'Водитель',
            truck: 'Тягач',
            semitrailer: 'Полуприцеп'
        },"cells":{
            driver: function (v) { return ((_vm.$filter.fullName(v)) + " (" + (v.status) + ")"); },
            truck: function (v) { return ((v.model) + " - " + (v.number)); },
            semitrailer: function (v) { return ((v.model) + " - " + (v.number)); },
        },"fetch":_vm.fetchData,"paginate":"","click":_vm.toggle}}),(_vm.canAssign)?_c('Popup',{attrs:{"name":"popup-add-assign"}},[_c('FormAssign',{attrs:{"assign":_vm.assign,"toggle":_vm.toggle,"inflect":""}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }