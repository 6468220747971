import Repo from '@/api/service/repo'

export default class extends Repo {

    //Отправка отзыва
    sendFeedback(data) {
        return this.client.encode().post('/other/sendFeedback', data)
    }

}
