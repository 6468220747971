var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[(_vm.sheet)?_c('FormRouteSheet',{attrs:{"id":_vm.sheet,"orderNumber":_vm.orderNumber},on:{"saved":_vm.load,"toggle":function($event){_vm.sheet = _vm.orderNumber = null; _vm.$redirect({name: 'RouteSheets'})}}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sheet),expression:"!sheet"}]},[_c('h1',{staticClass:"section-page-title"},[_vm._v(" Поиск ")]),_c('FormRouteSheetSearch',{model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),(_vm.key)?_c('DataTable',{key:_vm.key,attrs:{"cols":_vm.cols,"pageStart":_vm.pageStart,"cells":{
                orderNumber: function (v) { return ({a: v}); },
                _danger: function (v, item) { return ({
                    'i.fa.fa-exclamation-triangle.clr-red': item.expired === true,
                    bind: {style: {fontSize: '1.2em'}}
                }); },
                truckSemitrailerNumber: function (v) {
                    var split = v.toString().split(',');
                    return [{'div.tx-inline': split[0]}, {'div.tx-inline': split[1] || null}]
                },
                startRoute: function (v) { return [
                    {'p.tx-inline.tx-bold': _vm.$$.find(_vm.cities, 'id', v.city.id, 'name')},
                    {
                        div: [
                            {'small.clr-ltg-3': 'Начало рейса: '},
                            {small: _vm.$util.date.date(v.dateTime, true) || '(не определено)'} ]
                    } ]; },
                endRoute: function (v) { return [
                    {'p.tx-inline.tx-bold': _vm.$$.find(_vm.cities, 'id', v.city.id, 'name')},
                    {
                        div: [
                            {'small.clr-ltg-3': 'Конец рейса: '},
                            {small: _vm.$util.date.date(v.dateTime, true) || '(не определено)'} ]
                    } ]; },
            },"click":function (item) { return _vm.setSheet(item.id, item.orderNumber); },"fetch":_vm.fetch,"paginate":true}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }