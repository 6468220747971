import Axios from 'axios'
import { RequestClient } from '@argab/request-service'
import helper from '@/helpers'

const _axios = (config) => Axios.create(config)

export default class extends RequestClient {

    config = {}

    constructor(data) {

        super()

        Axios.defaults.baseURL = helper.$app().endpoint

        const config = _.merge(helper.$config.api().clientConfig, { headers: data.headers })

        _.isPlainObject(data.axios) && Object.assign(config, _.merge(config, data.axios))

        config.headers.Session = helper.$service.account.getSession()

        this.params = data.params

        if (config.headers['Content-Type'] === 'application/octet-stream') {
            config.timeout = data.timeout || 80000
        }

        if (config.headers['Accept'] === 'text/html') {
            delete config.headers['Content-Type']
        }

        if (['get', 'delete'].includes(data.method) || config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            this.params = _.uriEncode(null, this.params)
        }

        Object.assign(this.config, config)

    }

    get({uri}) {
        return _axios(this.config).get(uri + '?' + this.params)
    }

    post({uri}) {
        return _axios(this.config).post(uri, this.params)
    }

    patch({uri}) {
        return _axios(this.config).patch(uri, this.params)
    }

    put({uri}) {
        return _axios(this.config).put(uri, this.params)
    }

    delete({uri}) {
        return _axios(this.config).delete(uri + '?' + this.params)
    }

}
