<template>
    <div v-if="order && canConfirmOrder !== null && canTopUpFuelCards !== null" class="card-order">
        <div class="card-order-container mt-20">
            <h1 class="section-page-title">Заказ №{{order.number}}</h1>
            <OrderConfirm
                v-if="carrierOrderKey
                    && $service.account.access.isAdmin()
                    && canConfirmOrder
                    && (order.orderConfirmation === 'UNCONFIRMED' || order.orderConfirmation === 'CONFIRMATION_PROHIBITED')"
                :key="carrierOrderKey"
                :id="order.id"
                :carrier-order-key="carrierOrderKey"
                :confirmation-prohibited="order.orderConfirmation === 'CONFIRMATION_PROHIBITED'"
                :confirmation-comment="order.orderConfirmationComment"
                @confirmed="() => $emit('confirmed')"
                class="block-wide"
            />
            <div class="mt-5 mb-10">
                <p class="my-5">{{ $$.get(order, 'assign.driver') | fullName }}</p>
                <small class="clr-ltg-2">Водитель</small>
                <ul class="list flex flex-center mt-5">
                    <li>
                        <p class="my-5 clr-ltg-3 tx-left">Тягач</p>
                        <h3 class="tx-inline m-0">{{$$.get(order, 'assign.truck.number')}}</h3>
                    </li>
                    <li class="pl-15">
                        <p class="my-5 clr-ltg-3 tx-left">Полуприцеп</p>
                        <h3 class="tx-inline m-0">{{$$.get(order, 'assign.semitrailer.number')}}</h3>
                    </li>
                </ul>
                <template v-if="$$.get(order, 'lastStatus.title')">
                    <h3 class="clr-green">{{ $$.get(order, 'lastStatus.title') }}</h3>
                </template>
                <div v-if="$$.get(order, 'lastStatus.comment')">
                    <p class="clr-ltg-4">
                        <FaIcon
                            icon="question-circle"
                        />
                        Комментарий к изменению статуса:
                    </p>
                    <p class="clr-blue">{{ order.lastStatus.comment }}</p>
                </div>
            </div>
            <div class="card-order-details">
                <div>
                    <p v-if="$service.account.access.isDriver()">
                        <a class="link link-row" @click="() => order.unfinishedOrder ? $ok([
                            'Изменение статуса заказа',
                            'Статус заказа может быть изменен, если завершены прошлые заказы.',
                            `У Вас есть незавершенный заказ №${order.unfinishedOrder}.`,
                            'Измените его статус на «Выгружен» и повторите попытку.',
                        ]) : openStatuses()">
                            Изменить статус заказа
                            <FaIcon icon="angle-right" class="pl-5" />
                        </a>
                    </p>
                    <template v-if="$service.account.access.isDriver()">
                        <p>
                            <a class="link link-row" @click="getDocumentReceptionPoints">
                                Необходимые документы и пункты приема
                                <FaIcon icon="angle-right" class="pl-5" />
                            </a>
                        </p>
                        <p>
                            <a class="link link-row" @click="getInstructions">
                                Инструкции на загр./выгр. <FaIcon icon="angle-right" class="pl-5" />
                            </a>
                        </p>
                    </template>
                    <p v-if="$service.account.access.isAdmin() && canConfirmOrder">
                        <a class="link link-row" @click="$service.file.getAndDownload({
                            repo: 'order',
                            repoMethod: 'carriageTerms',
                            params: order.id,
                            name: `Условия перевозки заказа №${order.number}`,
                            errMsg: 'Не удалось загрузить условия перевозки'
                        })">
                            Условия перевозки
                            <FaIcon icon="angle-right" class="pl-5" />
                        </a>
                    </p>
                    <p>
                        <a class="link link-row" @click="$popup('popup-driver-location', {
                            fullscreen: true
                        })">
                            Показать на карте <FaIcon icon="angle-right" class="pl-5" />
                        </a>
                    </p>
                    <p v-if="canTopUpFuelCards && $$.num(order.affordableBidAmount) > 0">
                        <a class="link link-row" @click="$popup('popup-fuel-card', {clickToClose: false})">
                            Пополнить топливную карту <FaIcon icon="angle-right" class="pl-5" />
                        </a>
                    </p>
                    <p v-if="$service.account.access.isDriver() || $service.account.access.isDispatcher()">
                        <a class="link link-row" @click="chat">
                            Связаться с личным менеджером <FaIcon icon="angle-right" class="pl-5" />
                        </a>
                    </p>
                    <p>
                        <a class="link link-row" @click="getManager">
                            Позвонить менеджеру <FaIcon icon="angle-right" class="pl-5" />
                        </a>
                    </p>
                    <CarriageRoute :route="order.route" class="mt-20" />
                </div>
                <div>
                    <FormOrderDetails :data="order.characteristics" />
                    <template v-if="$service.account.access.isAdmin() && payment">
                        <FormOrderDetails :data="payment" style="padding-top: 0" />
                    </template>
                </div>
            </div>
        </div>

        <BackToggle @toggle="$emit('toggle')" />

        <Popup name="popup-order-statuses">
            <FormOrderStatusChange
                v-if="(order.id in statuses) && statuses[order.id].length"
                v-bind="{
                    order,
                    statuses,
                    setStatus,
                    changeStatus,
                    onChangeStatus,
                    setStatusPoints,
                }"
            />
            <h2 class="tx-c clr-ltg-2" v-else-if="!statuses[order.id] || !statuses[order.id].length">
                Нет подходящих статусов
            </h2>
        </Popup>
        <Popup
            v-if="documentReceptionPoints"
            name="popup-document-reception-points"
            @closed="documentReceptionPoints = null"
        >
            <h2 class="tx-c">Документы</h2>
            <div class="px-15">
                <div v-for="(item, k) in documentReceptionPoints.documents" :key="k">
                    <h3 v-if="documentReceptionPoints.documents.length > 1">
                        <strong>Погрузка №{{item.loadingPointNumber}}</strong>
                    </h3>
                    <DataTable
                        :cols="{
                            name: 'Наименование',
                            amount: 'Взять на загрузке, шт.',
                            provideAmount: 'Предоставить в Роспром, шт.',
                        }"
                        noShadow
                        :fetch="h => h(item.docList)"
                    />
                </div>
                <h2 class="tx-c">Пункты приема документов</h2>
                <ol>
                    <li v-for="(item, k) in documentReceptionPoints.receiptPoints" :key="k">
                        {{item}}
                    </li>
                </ol>
            </div>
        </Popup>
        <Popup name="popup-driver-location">
            <DriverLocation
                :driver-id="$$.get(order, 'assign.driver.id')"
                :order-id="order.id"
                :route="order.route"
            />
        </Popup>
        <Popup
            v-if="instructions"
            name="popup-order-instructions"
            @closed="instructions = null"
        >
            <h3 slot="header" class="tx-c">Инструкции на загрузку/выгрузку</h3>
            <div class="px-15">
                <Document :html="instructions" nl2p/>
            </div>
        </Popup>
        <Popup name="popup-order-contacts">
            <div v-if="contacts && contacts.length" class="block-inflect">
                <h3 class="tx-bold tx-c">Контакты персонала, сопровождающего заказ</h3>
                <div :key="k" v-for="(i, k) in contacts">
                    <h3 v-if="i.label" class="clr-red tx-c">{{i.label}}:</h3>
                    <ol class="list block-inner">
                        <template v-for="(ii, kk) in i.contacts">
                            <li :key="kk" v-if="ii !== null">
                                <p class="tx-bold">{{ii.label}}:</p>
                                <div class="flex flex-row-10">
                                    <div>
                                        {{ii.fullName}}
                                    </div>
                                    <div class="tx-bold">
                                        {{ii.phone | mask($app().mask.phone)}}
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ol>
                </div>
            </div>
            <h3 v-else class="tx-c clr-ltg-2">Нет контактов</h3>
        </Popup>
        <Popup name="popup-fuel-card">
            <FuelCardOrder
                :order="order"
                @payed="orderPayed"
                class="block-inflect"
            />
        </Popup>
        <Popup name="manager_chat">
            <h3 slot="header" class="tx-c">
                <FaIcon icon="comments" class="clr-ltg-2"/>
                Чат
            </h3>
            <ManagerChat/>
        </Popup>
    </div>
</template>

<script>

import OrderConfirm from '@pages/order/OrderConfirm.vue'
import DriverLocation from '@pages/order/DriverLocation.vue'
import FormOrderDetails from './FormOrderDetails.vue'
import CarriageRoute from '@pages/order/CarriageRoute.vue'
import FormOrderStatusChange from '@pages/order/form/FormOrderStatusChange'
import Document from '@plugins/Document'
import FuelCardOrder from '@pages/order/FuelCardOrder'
import ManagerChat from "@pages/chats/ManagerChat";

export default {
    name: "FormOrder",
    props: {
        id: Number,
    },
    components: {
        ManagerChat,
        FuelCardOrder,
        Document,
        FormOrderStatusChange,
        OrderConfirm,
        DriverLocation,
        FormOrderDetails,
        CarriageRoute
    },
    data() {
        return {
            order: null,
            canConfirmOrder: null,
            canTopUpFuelCards: null,
            statuses: {},
            setStatus: {
                actualLoadingDate: '',
                cargoPrice: 0,
                cityId: 0,
                name: "",
                comment: "",
            },
            setStatusPoints: [],
            cities: null,
            report: null,
            carrierOrderKey: 0,
            contacts: null,
            payment: null,
            documentReceptionPoints: null,
            instructions: null,
        }
    },
    created() {
        this.getOrder()
        this.$service.account.access.isDriver() && this.$repo('common').cities().then(({list}) => this.cities = list)
        this.$service.account.access.isAdmin()
            ? this.$service.account.hasRule('CAN_CONFIRM_RFT').then(can => this.canConfirmOrder = can)
            : this.canConfirmOrder = false
        this.$service.account.hasRule('CAN_TOPUP_FUEL_CARDS').then(can => this.canTopUpFuelCards = can)
    },
    methods: {
        getOrder() {
            this.$repo('order').orderDetails(this.id).then(({object}) => {
                this.$set(this, 'order', object)
                this.$set(this, 'carrierOrderKey', object.carrierOrderKey)
                this.$service.account.access.isAdmin() && this.getPayment()
            }).alert('Не удалось загрузить данные по заказу.')
        },
        getPayment() {
            this.$rs.bg()
                .repo('order').payment(this.order.id)
                .then(r => this.payment = r.data)
                .alert('Не удалось загрузить информацию об оплате заказа.')
        },
        openStatuses() {
            this.setStatusPoints = []
            this.setStatus = {
                cityId: 0,
                name: "",
                comment: "",
            }
            this.$popup('popup-order-statuses', {
                clickToClose: false,
                onOpen: this.setStatuses
            })
        },
        onChangeStatus(status) {

            if (!status) return false

            const cities = _.cloneDeep(this.cities),
                loading = this.order.route.loading.map(i => i.city.id),
                unloading = this.order.route.unloading.map(i => i.city.id),
                points = _.uniq([...loading, ...unloading])

            if (['readyToLoading', 'loading', 'readyToUnloading', 'unloading', 'unloaded'].includes(status)) {

                let getPoints = []

                if (status === 'unloaded' && unloading.length)
                    getPoints = [unloading[unloading.length - 1]]
                else if (points.length === 1)
                    getPoints = loading.length ? loading : unloading
                else if (points.length > 1)
                    getPoints = ['readyToLoading', 'loading'].includes(status) ? loading : unloading

                this.setStatusPoints = cities.filter(o => getPoints.includes(o.id))

            } else {
                this.setStatusPoints = cities
                this.setStatus.cityId = 0
            }
        },
        setStatuses() {
            if (this.statuses[this.order.id]?.length) return

            this.$repo('rft').rftStatuses(this.order.id).then(({list}) => {
                this.$set(this.statuses, this.order.id, list || [])
            }).alert('Нет подходящих статусов.')
        },
        changeStatus() {
            this.$repo('rft').setRftStatus(this.order.id, this.setStatus).then(() => {
                this.$delete(this.statuses, this.order.id)
                this.$popupClose('popup-order-statuses')
                this.getOrder()
            }).alert('Не удалось изменить статус заказа.').done('Статус заказа изменен')
        },
        orderPayed() {
            this.getOrder()
            this.$popupClose()
        },
        getManager() {
            this.$repo('order').orderContacts(this.order.id, {type: 'DISPATCHER'}).then(({list}) => {
                this.contacts = list
                this.$popup('popup-order-contacts', {})
            })
        },
        getDocumentReceptionPoints() {
            this.$repo('order').documentReceptionPoints(this.order.id).then(({object}) => {
                this.$set(this, 'documentReceptionPoints', object)
                this.$nextTick(() => this.$popup('popup-document-reception-points', {}))
            }).alert('Не удалось загрузить документы и пункты приема.')
        },
        getInstructions() {
            this.$repo('order').orderInstructions(this.order.id).then(({object}) => {
                this.instructions = object.instructions
                if (_.isEmpty(this.instructions))
                    return this.$notify().warn('Для данного заказа нет инструкций.')
                this.$nextTick(() => this.$popup('popup-order-instructions', {}))
            }).alert('Не удалось загрузить инструкции.')
        },
        chat() {
            this.$popup('manager_chat', {
                clickToClose: false,
                footer: false
            })
        }
    },
}

</script>

<style lang="scss" scoped>
.card-order {
    width: 100%;
    margin: 0 auto;
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .section-page-title {
            padding-left: 0;
        }
    }

    &-details {
        display: flex;
        flex-direction: row;
        flex: 1;
        text-align: left;
        gap: 40px;

        /deep/ .form-order-details {
            padding-top: 18px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
            padding-left: 0;
            gap: 0;
            row-gap: 20px;
            /deep/ .form-order-details {
                padding-top: 0;
            }
            > div {
                border-bottom: #dadada 1px solid;
                width: 90%;
                margin: 0 auto;
                &:last-child {
                    border-bottom: none;
                }
            }

            /deep/ .form-order-details {
                min-width: auto;
                &-model {
                    .wrapper {
                        flex-direction: column;
                    }
                }
            }
        }

        /deep/ .carriage-route {
            > h4 {
                margin-top: 0;
                font-size: 1.5em;
                color: $black-50;
            }
        }
    }

    &-bid-form {
        text-align: left;
    }
}
</style>
