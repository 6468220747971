<template>
    <div v-if="confirm || options" class="form-input-options" :class="inline && 'flex flex-align flex-row-20'">
        <template v-for="(option, k) in inputOptions">
            <div class="flex flex-row-10 flex-align" :key="k">
                <div>
                    <input
                        :type="isRadio ? 'radio' : 'checkbox'"
                        :name="$attrs.name"
                        class="form-input"
                        :checked="option.value === val"
                        @change="check($event, option)"
                    />
                </div>
                <div>{{option.text}}</div>
            </div>
        </template>
    </div>
    <input
        v-else
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
        class="form-input"
        v-model="val"
        v-mask="mask ? ($app().mask[mask] || mask) : undefined"
    />
</template>

<script>
import {FORM_ITEM_COMPONENT_TAG} from "@ui/form/Form"

export const DEFAULT_CONFIRM_OPTIONS = [{
    text: 'Да',
    value: true
}, {
    text: 'Нет',
    value: false
}]

export const FETCH_INPUT_OPTIONS = (isConfirm, opt) => {

    if (isConfirm) {
        if (_.isEmpty(opt)) return DEFAULT_CONFIRM_OPTIONS
        if (_.isArray(opt)) return [{
            text: opt[1],
            value: true,
        }, {
            text: opt[0],
            value: false,
        }]
        return []
    }

    const options = []

    _.isPlainObject(opt) && Object.keys(opt).forEach(value => options.push({
        text: opt[value],
        value
    }))

    _.isArray(opt) && opt.forEach((option) => {
        if (_.isPlainObject(option)) return options.push(option)
        if (_.isString(option)) return options.push({text: option, value: option})
        if (_.isBoolean(option)) return options.push({text: option ? 'Да' : 'Нет', value: option})
    })

    return options
}

export default {
    name: "FormInput",
    props: {
        index: Number,
        mask: String,
        value: [Number, String, Boolean],
        confirm: Boolean,
        options: [Array, Object],
        inline: Boolean,
        rules: Array,
        watch: Function,
        format: Function,
        focusEmptyZero: {
            type: Boolean,
            default: true
        },
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    data() {
        return {
            val: this.value,
            validator: null,
            initial: this.value,
        }
    },
    computed: {
        isNumber() {
            return Boolean(this.$attrs.type === 'number')
        },
        isZero() {
            return parseFloat(this.val) === 0
        },
        isOptional() {
            return Boolean(this.isRadio || this.isCheckbox)
        },
        isRadio() {
            return Boolean(this.confirm || this.$attrs.type === 'radio')
        },
        isCheckbox() {
            return Boolean(this.$attrs.type === 'checkbox')
        },
        inputOptions() {
            return FETCH_INPUT_OPTIONS(this.confirm, this.options)
        },
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    created() {
        let rules = this.rules || []
        this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')
        this.required === false && (rules = rules.filter(r => r !== 'required'))
        rules.length && (this.validator = new this.$service.validation({rules, name: this.$attrs.name}))
    },
    mounted() {
        this.$nextTick(() => {

            const getWrap = () => this.$getParent({tag: FORM_ITEM_COMPONENT_TAG})

            if (this.isOptional) {
                const wrap = getWrap()
                wrap && this.$set(wrap, 'wrap', false)
                wrap && !wrap.controls && this.$set(wrap, 'canClear', false)

            } else if (!this.$attrs.disabled) {
                const wrap = getWrap()
                wrap && !wrap.controls && wrap.setControls()
            }
        })

        this.isDisabled || (this.validator && this.validator.validate(this.val))
    },
    methods: {
        input() {

            if (this.format) {
                this.val = this.format(this.val)
            }

            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(this.val))
            }

            if (this.watch) {
                const value = this.watch(this.val, this)
                value !== undefined && (this.val = value)
            }

            this.$emit('input', this.val)
        },
        getAttributes() {
            return this.$attrs || {}
        },
        reset() {
            this.val = ''
            this.input()
        },
        getValue() {
            return this.val
        },
        onFocus() {
            this.focusEmptyZero && this.isZero && (this.val = null)
            this.input()
        },
        onBlur() {
            this.focusEmptyZero && _.isEmpty(this.val) && this.isNumber && (this.val = 0)
            this.input()
        },
        onChange() {

            if (!this.isOptional) {
                this.input()
                return
            }

            const checked = Boolean(this.$el.checked)

            if (this.isRadio) {
                this.val = checked ? this.initial : null
            } else {
                this.val = _.isBoolean(this.initial) || _.isNil(this.initial) ? checked : (checked ? this.initial : null)
            }

            this.input()
        },
        check(e, option) {
            this.val = e.target.checked ? option.value : null
            this.input()
        }
    }
}
</script>
