<template>
    <div class="form-row form-filter">
        <div v-if="$slots.header" class="form-row-header">
            <slot name="header"/>
        </div>
        <div class="form-filter-wrap">
            <Form @submit="$emit('search')" class="form-row-inner">
                <div class="form-row-inner-row">
                    <FormItem
                        type="search"
                        clear
                        @search="$emit('search')"
                        @remove="$emit('search')"
                        style="width: 100%"
                    >
                        <FormInput
                            v-model="search.search"
                            placeholder="Наименование документа"
                        />
                    </FormItem>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormRegulationsSearch",
    props: {
        value: Object
    },
    data() {
        return {
            search: this.value,
        }
    }
}
</script>
