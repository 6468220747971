<template>
    <Page>
        <div class="block-center">
            <Form v-if="!logged" title="Вход" @submit="login" buttons="Войти">
                <FormItem label="Номер телефона">
                    <FormInput
                        required
                        type="text"
                        v-model="form.phone"
                        name="Номер телефона"
                        placeholder="Номер телефона"
                        :mask="$app().mask.phone"
                    />
                </FormItem>
                <FormItem label="Пароль" type="show-password">
                    <FormInput
                        required
                        type="password"
                        v-model="form.password"
                        name="Пароль"
                        placeholder="Пароль"
                    />
                </FormItem>
                <div slot="footer" class="mt-40 flex flex-center flex-col-20">
                    <div class="flex flex-row-10">
                        <span class="clr-ltg">Нет учетной записи? </span>
                        <router-link :to="{name:'Reg'}">Зарегистрироваться</router-link>
                    </div>
                    <router-link :to="{name:'PasswordRecovery', params: { phone: $$.toNum(form.phone) }}">
                        Восстановить пароль
                    </router-link>
                </div>
            </Form>
            <template v-else>
                <h4 class="tx-c mb-40">На Ваш номер телефона отправлено SMS с кодом подтверждения телефона</h4>
                <FormCodeConfirm
                    class="mt-25"
                    type="phone"
                    :dest="form.phone"
                    :endpoint="confirmLoginUrl"
                    :request-code-endpoint="resendCodeUrl"
                    :token="token"
                    :counter="counter"
                    @confirmed="confirmed"
                    @cancel="logged = false"
                />
            </template>
        </div>
    </Page>
</template>

<script>

import FormCodeConfirm from './form/FormCodeConfirm.vue'
import {CONFIRM_LOGIN_URL, RESEND_CODE_URL} from '@repo/auth'

export default {
    name: "Login",
    props: {
        passwordRecoveryToken: String,
    },
    data: () => ({
        form: {
            phone: null,
            password: null,
        },
        logged: false,
        token: "",
        counter: 0,
        confirmLoginUrl: CONFIRM_LOGIN_URL,
        resendCodeUrl: RESEND_CODE_URL,
    }),
    components: {FormCodeConfirm},
    methods: {
        login(form) {
            form.validate().then(() => {
                this.$repo('auth').login(this.form).then(r => {
                    if (this.passwordRecoveryToken) {
                        this.$repo('auth').loginConfirm(r.data.token, this.passwordRecoveryToken, 'TOKEN')
                            .then(r => this.confirmed(true, null, r))
                            .alert('Ошибка авторизации.')
                    } else {
                        this.logged = true
                        this.token = r.data.token
                        this.counter = r.data.resendCodeAfter
                    }
                }).alert('Ошибка авторизации.')
            }).catch(() => null)
        },
        confirmed(result, params, resp) {
            if (result) {
                this.$service.account.setSession(resp.data.session)
                this.$service.account.setRefreshTokenId(resp.data.refreshTokenUID)
                this.$util.page.reload({})
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
