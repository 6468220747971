import helper from '@/helpers'

export default {
    set(name, value, setExpire) {
        localStorage.setItem(name, _.isObject(value) ? _.jsonStringify(value) : value)
        name in helper.$app().expire && setExpire !== false && this.setExpire(name)
    },
    get(name, def) {
        const storage = localStorage.getItem(name)
        let data
        try {
            data = JSON.parse(storage)
        } catch (e) {
            data = storage
        }
        return data === null && def !== undefined ? def : data
    },
    rm(name) {
        localStorage.removeItem(name)
        localStorage.removeItem(`expire_${name}`)
    },
    clear() {
        localStorage.clear()
        sessionStorage.clear()
    },
    setExpire(name, milisec) {
        const expire = milisec !== undefined ? milisec : helper.$app().expire[name]
        localStorage.setItem(`expire_${name}`, (new Date()).getTime() + parseInt(expire))
    },
    getExpire(name) {
        const expire = parseInt(localStorage.getItem(`expire_${name}`))
        return isNaN(expire) ? 0 : expire
    },
    isExpired(name) {
        return this.getExpire(name) <= (new Date()).getTime()
    },
    flushRead(name) {
        return this.flush(name, undefined, false)
    },
    flush(name, value, clear) {
        const flush = sessionStorage.getItem('__flush')
        try {
            const data = !flush ? {} : _.cloneDeep(JSON.parse(flush))
            if (value !== undefined) {
                data[name] = value
                sessionStorage.setItem('__flush', _.jsonStringify(data))
            } else {
                const resolve = _.cloneDeep(data[name])
                if (clear !== false) {
                    delete data[name]
                    sessionStorage.setItem('__flush', _.jsonStringify(data))
                }
                return resolve
            }
        } catch (e) {
            return undefined
        }
    }
}
