<template>
    <div class="form-item" :class="{'--error': error}">
        <label v-if="label" v-html="label" />
        <slot name="label"/>
        <div
            ref="wrap"
            class="form-item-wrap"
            :class="[
                row && '--row',
                rowWrap && '--row-wrap',
                noShadow && '--no-shadow',
                wrap && 'form-input-wrap'
            ]"
            @keyup="checkToggleError()"
            @click="checkToggleError()"
            @blur="checkToggleError()"
        >
            <slot/>
            <Controls
                v-if="bindControls"
                :index="index"
                :controls="bindControls"
                @remove="$emit('remove')"
            />
        </div>
        <small v-if="error && typeof error === 'string'" class="--error-msg">{{ error }}</small>
    </div>
</template>

<script>

import {INPUT_COMPONENT_TAGS} from './Form'
import Controls from '@ui/Controls.vue'

export default {
    name: "FormItem",
    components: {Controls},
    props: {
        index: Number,
        label: String,
        row: Boolean,
        rowWrap: Boolean,
        breakRow: Boolean,
        noShadow: Boolean,
        noWrap: Boolean,
        controls: [Array, Function],
        type: String,
        clear: {
            type: Boolean,
            default: true
        },
        showError: {
            type: [Boolean, Function],
            default: () => (input, error) => {
                return error ? !_.isEmpty(error.value) : true
            }
        }
    },
    data() {
        return {
            error: null,
            bindControls: null,
            wrap: !this.noWrap,
            canClear: this.clear
        }
    },
    mounted() {
        if (this.controls || this.type) this.setControls()

        this.$nextTick(() => {
            this.breakRow && $('<div style="width: 100%"></div>').insertBefore(this.$el)
        })
    },
    methods: {
        toggleError(error) {
            this.error = error ? (error.message || true) : null
        },
        getInput(children, componentTags) {

            if (!children || !children.length) return

            let input = null

            children.some(c => {
                if (componentTags.includes(c.$options._componentTag)) {
                    return input = c
                }
                this.getInput(c.$children, componentTags)
            })

            return input
        },
        checkToggleError(componentTags) {

            if (!this.showError) return

            const input = this.getInput(this.$children, componentTags || INPUT_COMPONENT_TAGS)

            if (!input?.validator) return

            const error = input.validator.error

            if (_.isFunction(this.showError) && false === this.showError(input, error)) {
                return this.toggleError(null)
            }

            this.toggleError(error)
        },
        setControls() {

            this.getControls()

            this.onClear()
        },
        getControls() {

            this.bindControls = null

            switch (this.type) {
                case 'search':
                    this.bindControls = [{
                        tag: 'div',
                        on: {click: () => this.$emit('search', this.index)},
                        html: '<i class="fa fa-search fa-flip-horizontal link link-gray" title="Поиск"></i>',
                        bind: {class: '--search-icon'}
                    }]
                    break
                case 'show-password':
                    this.bindControls = [{
                        tag: 'div',
                        on: {
                            click: () => {
                                const wrap = $(this.$refs.wrap)
                                const icon = wrap.find('.--show-password-icon')
                                const input = wrap.find('input')

                                if (!input.length) return

                                if (input.attr('type') === 'password') {
                                    input.attr('type', 'text')
                                    icon.html('<i class="fa fa-eye-slash link link-gray" title="Скрыть пароль"></i>')
                                } else {
                                    input.attr('type', 'password')
                                    icon.html('<i class="fa fa-eye link link-gray" title="Показать пароль"></i>')
                                }
                            }
                        },
                        html: '<i class="fa fa-eye fa-flip-horizontal link link-gray" title="Показать пароль"></i>',
                        bind: {class: '--show-password-icon'}
                    }]
                    break
            }

            if (_.isFunction(this.controls) || !this.bindControls) {

                this.bindControls = this.controls

            } else if (this.bindControls && _.isArray(this.controls)) {

                this.bindControls = [...this.bindControls, ...this.controls]
            }
        },
        onClear() {

            if (!this.canClear || (this.bindControls && !_.isArray(this.bindControls))) return

            const input = () => this.getInput(this.$children, INPUT_COMPONENT_TAGS)
            const controls = this.bindControls || (this.bindControls = [])
            const remove = controls.find(c => _.isPlainObject(c.remove) ? c.remove : null)
            const control = {
                show: remove?.show || (() => {
                    const inp = input()
                    if (!inp || inp.isDisabled) return false
                    return !_.isEmpty((inp.getValue && inp.getValue()) || (inp.getSearchString && inp.getSearchString()))
                }),
                on: {
                    click: remove?.on?.click || (() => {
                        const inp = input()
                        if (inp) {
                            inp.reset()
                            this.$nextTick(() => this.$emit('remove', inp.index))
                        }
                    })
                }
            }

            remove ? Object.assign(remove, control) : controls.push({remove: control})
        }
    }
}
</script>

<style lang="scss" scoped>
.form-item {
    &-wrap {
        position: relative;
    }

    > label {
        font-size: 10pt;
        display: block;
        color: $black-79;
        font-family: $font-roboto;
        margin-bottom: 12px;
    }

    &.--error {
        > .form-item-label, .--error-msg {
            color: $red-light;
        }

        .--error-msg {
            display: block;
            font-size: 9pt;
            margin-top: 7px;
        }
    }

    .--row, .--row-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .--row-wrap {
        flex-wrap: wrap;
    }

    .--break {
        width: 100%;
        height: 0;
    }

    input[type="number"] + .controls {
        right: 25px
    }
}
</style>
