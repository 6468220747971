<template>
    <div ref="document" v-html="html"></div>
</template>

<script>
    export default {
        name: "Document",
        props: {
            html: {
                type: String,
                required: true,
            },
            nl2br: {
                type: Boolean,
                required: false,
                default: false,
            },
            nl2p: {
                type: Boolean,
                required: false,
                default: false,
            },
            removeStyle: {
                type: Boolean,
                required: false,
                default: true,
            },
            disableStyles: Object,
        },
        data() {
            return {
                disableCss: _.merge({ lineHeight: true }, this.disableStyles),
            }
        },
        created() {
            this.$nextTick(() => this.format(this.$refs.document))
        },
        methods: {
            format(document, disable) {

                document = $(document)

                if (!document.length) return false

                disable = _.merge(this.disableCss, _.isPlainObject(disable) ? disable : {})
                this.removeStyle && document.find('style').remove()
                document.find('meta').remove()

                document.find('*').each((i, el) => {
                    el = $(el)

                    const css = {}
                    disable.lineHeight || (css.lineHeight = el.css('line-height'))
                    disable.fontWeight || (css.fontWeight = el.css('font-weight'))
                    disable.fontStyle || (css.fontStyle = el.css('font-style'))
                    disable.textDecoration || (css.textDecoration = el.css('text-decoration'))
                    disable.color || (css.color = el.css('color'))

                    if (el[0].tagName === 'H1') {
                        css.fontSize = '1.8rem'
                        css.letterSpacing = 'normal'
                    }
                    if (el[0].tagName === 'H2') {
                        css.fontSize = '1.4rem'
                        css.letterSpacing = 'normal'
                    }
                    if (el[0].tagName === 'H3') {
                        css.fontSize = '1.0rem'
                        css.letterSpacing = 'normal'
                    }
                    el.removeAttr('style')
                    el.css(css)
                })

                if (this.nl2br && document.html()) document.html(document.html().replace(/[\r\n]+/g, '</br>'))

                else if (this.nl2p && document.html()) document.html(document.html().replace(/[\r\n]+/g, '<p> </p>'))

                this.$emit('format', document[0])
            }
        }
    }
</script>
