import Vue from 'vue'
import {FontAwesomeIcon} from './vendors'
import {ToggleButton} from 'vue-js-toggle-button'

// ---- UI
Vue.component('Page', () => import('@ui/Page.vue'))
Vue.component('DataForm', () => import('@ui/form/DataForm.vue'))
Vue.component('Form', () => import('@ui/form/Form.vue'))
Vue.component('FormItem', () => import('@ui/form/FormItem.vue'))
Vue.component('FormInput', () => import('@ui/form/FormInput.vue'))
Vue.component('FormSelect', () => import('@ui/form/FormSelect.vue'))
Vue.component('FormText', () => import('@ui/form/FormText.vue'))
Vue.component('FormDate', () => import('@ui/form/FormDate.vue'))
Vue.component('FormFile', () => import('@ui/form/FormFile.vue'))
Vue.component('FormFindSelect', () => import('@ui/form/FormFindSelect.vue'))
Vue.component('FormMultiSelect', () => import('@ui/form/FormMultiSelect.vue'))
Vue.component('Buttons', () => import('@ui/Buttons.vue'))
Vue.component('Popup', () => import('@ui/modals/Popup.vue'))
Vue.component('Confirm', () => import('@ui/modals/Confirm.vue'))
Vue.component('Dropdown', () => import('@ui/modals/Dropdown.vue'))
Vue.component('DataTable', () => import('@ui/lists/DataTable.vue'))
Vue.component('Multiselect', () => import('@ui/lists/Multiselect.vue'))
Vue.component('BackToggle', () => import('@ui/BackToggle.vue'))

// ---- PLUGINS
Vue.component('HScroll', () => import('@plugins/HScroll.vue'))
Vue.component('Render', () => import('@plugins/Render.vue'))
Vue.component('DataModel', () => import('@plugins/DataModel.vue'))
Vue.component('Countdown', () => import('@plugins/Countdown.vue'))
Vue.component('Accordion', () => import('@plugins/accordion/Accordion.vue'))
Vue.component('AccordionItem', () => import('@plugins/accordion/AccordionItem.vue'))
Vue.component('Tabs', () => import('@plugins/Tabs.vue'))

// ---- VENDORS
Vue.component('FaIcon', FontAwesomeIcon)
Vue.component('Toggle', ToggleButton)

