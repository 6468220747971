var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataForm',{attrs:{"model":_vm.form,"required":"","form":{
        bind: Object.assign({}, _vm.$attrs,
            {buttons: [
                'Сохранить',
                {text: 'Удалить', bind: {class: 'btn btn-red'}, on: {click: _vm.remove}}
            ]}),
        on: {submit: _vm.submit},
    }},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}})}
var staticRenderFns = []

export { render, staticRenderFns }