import Repo from '@/api/service/repo'

export default class extends Repo {

    managerChats(params) {
        _.isPlainObject(params) || (params = {})
        Object.assign(params, {
            type: 'PERSONAL_MANAGER'
        })
        return this.client.get('/chats', params)
    }

    getMessages(chatId, params) {
        _.isPlainObject(params) || (params = {})
        return this.client.get(`/chats/${chatId}/messages`, params)
    }

    sendMessage(chatId, {linkedObjectId, linkedObjectType, message}) {
        return this.client.post(`/chats/${chatId}/messages`, {linkedObjectId, linkedObjectType, message})
    }

}
