<template>
    <ul class="pagination">
        <li v-if="useForward && getLastInRange() - pageCount > 1"
            class="pagination-back"
            v-bind="getBackBind(getLastInRange() - pageCount)">
            <a @click="back" href="#">&laquo;</a>
        </li>
        <template v-for="(page, key) in pageRange">
            <li v-if="(getPageTotal && page <= getPageTotal) || !getPageTotal"
                :key="key"
                :class="{active: page === current}"
                v-bind="getPageBind(page)">
                <a @click="page === current || setPage(page)" href="#">{{page}}</a>
            </li>
        </template>
        <li v-if="useForward && ((getPageTotal && getLastInRange() + 1 <= getPageTotal) || !getPageTotal)"
            class="pagination-forward"
            v-bind="getForwardBind(getLastInRange() + 1)">
            <a @click="forward" href="#">&raquo;</a>
        </li>
    </ul>
</template>

<script>

    export default {
        name: "Pagination",
        props: {
            pageCount: {
                type: Number,
                required: true,
                default: 0
            },
            pageTotal: {
                type: [Number, Function],
                required: false,
                default: 0
            },
            pageStart: {
                type: Number,
                required: false,
                default: 1
            },
            pageBind: {
                type: [Object, Function],
                required: false,
                default: null
            },
            useForward: {
                type: Boolean,
                required: false,
                default: true
            },
            forwardBind: {
                type: [Object, Function],
                required: false,
                default: null
            },
            backBind: {
                type: [Object, Function],
                required: false,
                default: null
            }
        },
        computed: {
            pageRange() {
                const offset = this.offset
                return _.range(offset-this.pageCount+1, offset+1)
            },
            offset() {
                const offset = this.page > this.pageCount ? Math.ceil(this.page/this.pageCount) : 1
                return offset === 1 ? this.pageCount : this.pageCount*offset
            },
            getPageTotal() {
                const total = this.total instanceof Function ? this.total() : this.total
                return total && _.isNumber(total) ? Math.ceil(total) : 0
            }
        },
        data() {
            const total = this.getPageTotal
            return {
                key: null,
                page: total && this.pageStart > total ? total : this.pageStart || 1,
                total: this.pageTotal,
                current: 0,
            }
        },
        mounted() {
            this.$emit('setup', this)
        },
        created() {
            this.current = this.page
        },
        methods: {
            forward() {
                this.page = this.offset + 1
                this.setPage(this.page)
            },
            back() {
                this.page = this.offset-this.pageCount
                this.setPage(this.page)
            },
            setPage(page) {
                this.current = page;
                this.$emit('page-load', page)
            },
            loadPage(page) {
                this.page = this.current = page
            },
            setPageTotal(total) {
                this.total = total
            },
            getLastInRange() {
                const range = this.pageRange
                return range.length ? range[range.length-1] : 0
            },
            getPageBind(page) {
                const bind = this.pageBind instanceof Function ? this.pageBind(page) : this.pageBind
                return _.isPlainObject(bind) ? bind : {}
            },
            getForwardBind(page) {
                const bind = this.forwardBind instanceof Function ? this.forwardBind(page) : this.forwardBind
                return _.isPlainObject(bind) ? bind : {}
            },
            getBackBind(page) {
                const bind = this.backBind instanceof Function ? this.backBind(page) : this.backBind
                return _.isPlainObject(bind) ? bind : {}
            },
        }
    }

</script>

<style lang="scss">

    .pagination {
        list-style-type: none;
        margin: 0 !important;
        padding: 0 !important;
        > li {
            display: inline-block;
            float: left;
            > a {
                padding: 7px 9px;
                margin-right: 10px;
                font-size: 1.2em;
                border-radius: 2px;
                line-height: 45px;
            }
            &.active {
                > a {
                    background-color: #eaeaea;
                    color: #797979;
                    cursor: auto;
                }
            }
            &:last-child {
                > a {
                    margin-right: 0;
                }
            }
        }
    }

</style>
