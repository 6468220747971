<template>
    <Form
        buttons="Отправить"
        @submit="submit"
    >
        <p class="tx-c w-70 m-0-a my-20">
            Вы можете сообщить о проблеме, возникшей в процессе перевозки груза <br>(на
            загрузке, в пути следования, на выгрузке).
        </p>
        <FormItem label="Текст сообщения" noWrap>
            <FormText
                v-model="form.description"
                required
                class="w-100"
            />
        </FormItem>
    </Form>
</template>

<script>
export default {
    name: "FormReport",
    props: {
        orderId: {
            type: [Number, String],
            required: true,
        }
    },
    data() {
        return {
            form: {
                description: ""
            }
        }
    },
    mounted() {
        this.$emit('setup', this)
    },
    methods: {
        submit() {
            this.$repo('order')
                .report(this.orderId, this.form)
                .then(() => this.$emit('sent'))
                .alert('Не удалось отправить сообщение.')
                .done('Сообщение отправлено.')
        }
    },
}
</script>
