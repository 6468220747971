export default (access) => {

    return [
        {
            title: 'Грузы',
            route: '/preorders',
            access: !(access.isDriver() && access.isEnterprise()),
            sub: [
                {
                    title: 'Все грузы',
                    route: '/preorders/all',
                    access: access.hasAnyRole()
                },
                {
                    title: 'Мои ставки',
                    route: '/preorders/self',
                    access: access.hasAnyRole()
                },
                {
                    title: 'Избранное',
                    route: '/preorders-favorites',
                    access: access.isAdmin(),
                },
            ]
        },
        {
            title: 'Заказы',
            route: access.isAdmin() ? '/orders' : '/rft',
            access: access.hasAnyRole(),
            sub: [
                {
                    title: 'Активные',
                    route: access.isAdmin() ? '/orders/active' : '/rft/active',
                },
                {
                    title: 'Архивные',
                    route: access.isAdmin() ? '/orders/archive' : '/rft/archive',
                },
            ]
        },
        {
            title: 'Топливные карты',
            route: '/fuel-cards',
            access: access.isAdmin(),
            sub: [
                {
                    title: 'Пополнение',
                    route: '/fuel-cards/topup',
                },
                {
                    title: 'История',
                    route: '/fuel-cards/history',
                },
            ]
        },
        {
            title: 'Собственный транспорт',
            route: '/enterprise',
            access: access.isEnterprise(),
            sub: [
                {
                    title: 'Маршрутные листы',
                    route: '/enterprise/route-sheets',
                    access: access.isAdmin() || access.isDriver(),
                },
                {
                    title: 'Акты осмотра ТС',
                    route: '/enterprise/accept-certs',
                    access: access.isDispatcher() || access.isDriver(),
                },
                {
                    title: 'Заявки на ремонт',
                    route: '/enterprise/repair-orders',
                    access: access.isDriver(),
                },
                {
                    title: 'Нормативные документы',
                    route: '/enterprise/regulations',
                    access: access.isDriver(),
                },
                {
                    title: 'Справки-расчет З/П',
                    route: '/enterprise/wage',
                    access: access.isDriver(),
                },
                {
                    title: 'Данные с датчиков уровня топлива',
                    route: '/enterprise/sensors-data',
                    access: access.isDriver(),
                },
                {
                    title: 'Контактные телефоны',
                    route: '/enterprise/contact-phones',
                    access: access.isDriver(),
                },
            ]
        },
        {
            title: 'Управление транспортом',
            route: '/transport',
            access: access.isAdmin(),
            sub: [
                {
                    title: 'Тягачи',
                    route: '/transport/trucks',
                },
                {
                    title: 'Полуприцепы',
                    route: '/transport/semitrailers',
                },
                {
                    title: 'Транспорт контрагента',
                    route: '/transport/assigns',
                },
            ]
        },
        {
            title: 'Сотрудники',
            route: '/staff',
            access: access.isAdmin(),
            sub: [
                {
                    title: 'Водители',
                    route: '/staff/drivers',
                },
                {
                    title: 'Диспетчеры',
                    route: '/staff/dispatchers',
                },
            ]
        }
    ]
}
