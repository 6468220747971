<template>
    <div ref="panel" class="accordion-panel">
        <template v-if="uid">
            <div class="accordion-panel-heading" role="tab" :id="'heading-' + uid">
                <div
                    class="accordion-panel-heading"
                    :class="isOpen && 'in'"
                    role="button"
                    data-toggle="collapse"
                    :data-parent="'#'+uid"
                    :aria-expanded="isOpen.toString()"
                    :aria-controls="'collapse-'+uid"
                    @click.stop="clickToClose ? (isOpen ? close($event) : open($event)) : (isOpen || open($event))"
                    v-bind="panelHeadBind"
                >
                    <div class="--toggle">
                        <FaIcon
                            icon="chevron-right"
                            class="--toggle-icon"
                            @click.stop="isOpen ? close($event) : open($event)"
                        />
                    </div>
                    <h3 v-if="title" class="accordion-panel-heading-title">{{title}}</h3>
                    <slot name="title" />
                </div>
            </div>
            <div
                :id="'#collapse-'+uid"
                class="accordion-panel-collapse"
                :class="isOpen && 'in'"
                role="tabpanel"
                :aria-labelledby="'heading-' + uid"
                v-bind="panelContentBind"
            >
                <div v-if="expandLoad && isOpen">
                    <slot />
                </div>
                <template v-else>
                    <slot />
                </template>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "AccordionItem",
    props: {
        name: String,
        title: String,
        expand: Boolean,
        expandLoad: Boolean,
        clickToClose: {
            type: Boolean,
            default: true
        },
        panelHeadBind: Object,
        panelContentBind: Object,
    },
    data() {
        return {
            uid: null,
            isOpen: Boolean(this.expand)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.uid = this.$refs.panel.closest('.accordion').getAttribute('id')
        })
    },
    methods: {
        open(e) {
            this.$set(this, 'isOpen', true)
            this.$emit('opened', e)
            this.$emit('expand', this.name)
        },
        close(e) {
            this.$set(this, 'isOpen', false)
            this.$emit('closed', e)
        }
    }
}
</script>

<style scoped lang="scss">
.accordion-panel {
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 4px rgba(65, 103, 160, 0.32);
    box-shadow: 0 1px 4px rgba(65, 103, 160, 0.32);

    &-heading {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        min-height: 72px;
        position: relative;
        background-color: #F1F5F8;
        border-color: #ddd;

        .--toggle {
            cursor: pointer;
            position: absolute;
            width: 25px;
            height: 25px;
            right: 10px;
            top: 40%;
            &-icon {
                color: #aaa;
                font-size: 13px;
            }
        }

        &.in {
            .--toggle-icon {
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        &-title {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 15px;
        }
    }

    &-collapse {
        background-color: #fff;
        height: 0;
        min-height: 0;
        overflow: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;

        &.in {
            padding: 8px;
            height: auto;
            min-height: 200px;
        }
    }
}
</style>
