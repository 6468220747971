<template>
    <Page class="block-contents">
        <DataTable
            :key="key"
            :cols="{
                date: 'Дата'
            }"
            :cells="{
                date: (v, item) => $$.capitalize($util.date.dateFormat(item, 'MMMM YYYY'))
            }"
            :click="getWage"
            :fetch="fetch"
        />
    </Page>
</template>

<script>

export default {
    name: "Wage",
    props: {
        id: String
    },
    data() {
        return {
            key: 0,
            dataItem: null,
            form: null,
        }
    },
    created() {
        this.id && this.$util.date.isValidDate(this.id) && this.getWage(this.id)
    },
    methods: {
        getWage(date) {
            this.$service.file.getAndDownload({
                repo: 'enterprise',
                repoMethod: 'getWage',
                params: date,
                name: 'Справка по расчету начислений з/п',
                errMsg: 'Не удалось загрузить справку по расчету начислений з/п'
            }).catch(() => null)
        },
        fetch(h) {
            this.$repo('enterprise').wage().then(({list}) => h(list))
        }
    }
}

</script>
