<template>
    <div class="tabs">
        <div class="tabs-buttons">
            <template v-for="(tab, k) in tabs">
                <div
                    v-if="$$.isPlainObject(tab)"
                    :key="k + '-' + activeId"
                    :class="[
                        tab.active && '--active',
                        k === 0 && '--start',
                        k === tabs.length-1 && '--end'
                    ]"
                    class="tabs-button"
                    @click="open(tab.id, k)"
                >
                    {{tab.name}}
                    <slot :name="'title-' + (tab.id || k)" />
                </div>
            </template>
        </div>
        <div class="tabs-contents">
            <slot/>
            <template v-for="(tab, k) in tabs">
                <div
                    v-if="$$.isPlainObject(tab)"
                    :key="k"
                    class="tabs-contents-item"
                    v-show="tab.active"
                >
                    {{tab.content}}
                    <slot :name="(tab.id || k)" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        tabs: {
            type: Array,
            required: true
        },
        value: [Number, String]
    },
    data() {
        return {
            activeTab: null,
            activeId: null
        }
    },
    created() {

        const active = this.tabs.findIndex((tab, i) => this.value ? ((tab.id || i) === this.value) : tab?.active)

        if (active >= 0) {
            this.activeTab = this.tabs[active]
            this.activeId = this.tabs[active]?.id || active
            this.activeTab.active = true
            this.$emit('input', this.activeTab.id || active)
        }
    },
    methods: {
        open(id, index) {
            this.tabs.forEach((tab, i) => {
                if (!_.isPlainObject(tab)) return
                if ((tab.id && tab.id === id) || (index !== undefined && index === i)) {
                    tab.active = true
                    this.activeTab = tab
                    this.activeId = tab.id || i
                    tab.onOpen && tab.onOpen(tab)
                    this.$emit('input', this.activeId)
                    this.$nextTick(() => this.$emit('opened', tab))
                } else {
                    tab.active = false
                    tab.onClose && tab.onClose(tab)
                    this.$nextTick(() => this.$emit('closed', tab))
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;

    &-buttons {
        display: flex;
        flex-direction: row;
        box-shadow: 0 1px 4px rgba(65, 103, 160, 0.32);
        border-radius: 5px;
    }

    &-button {
        padding: 10px;
        cursor: pointer;
        min-width: 150px;
        text-align: center;

        border-right: #ddd 1px solid;

        &.--active {
            background-color: $blue;
            color: #fff;
        }

        &.--start {
            border-radius: 5px 0 0 5px;
        }

        &.--end {
            border-radius: 0 5px 5px 0;
        }
    }

    &-contents {
        min-width: 100%;

        &-item {
            min-width: 100%;
        }
    }
}
</style>
