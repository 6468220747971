import helper from '@/helpers'

const PROVIDERS = ['STORAGE', 'DATABASE']

const resolveProvider = {
    DATABASE: {
        /*
        * @param data - данные запроса, (можно модифицировать data.params перед отправкой запроса)
        * @param cache - данные из кэша
        * @param onSend - фунция переданная через посредника запроса
        *
        * Если результат === FALSE ничего не делаем, выполняем запрос
        * Если результат === undefined вставляем в запрос заглушку из кэша
        * Если результат !== [undefined|FALSE] вставляем в запрос заглушку из результата
        *
        * @returns: {false|void|any}
        * */
        onSend({data, cache, onSend}) {
            return onSend({data, schema: cache?.schema, cache: cache?.data})
        },
        /*
        * @param response - ответ из запроса
        * @param cache - данные из кэша
        * @param onGet - фунция переданная через посредника запроса
        *
        * Если результат === FALSE ничего не сохраняем
        * Если результат === undefined сохраняем данные ответа сервера
        * Если результат !== [undefined|FALSE] сохраняем данные из результата и вставляем в ответ
        *
        * @returns: {false|void|any}
        * */
        onGet({response, cache, onGet}) {
            return onGet({response, schema: cache?.schema, cache: cache?.data})
        },
        save(source, data, cache) {
            helper.$db.save({table: source, data, schemaData: cache?.schema})
        }
    },
    STORAGE: {
        onSend({data, cache, onSend}) {
            return onSend({data, cache: cache?.data})
        },
        onGet({response, cache, onGet}) {
            return onGet({response, cache: cache?.data})
        },
        save(source, data) {
            helper.$service.store.set(source, data)
        }
    }
}

export default class {

    provider = {
        name: '',
        props: {
            source: '',
            onGet: () => undefined,
            onSend: () => undefined,
        }
    }

    cache

    constructor({provider, props}) {
        PROVIDERS.includes(provider) && (this.provider = {
            name: provider,
            props: {
                source: props?.source,
                onGet: props?.onGet || this.provider.props.onGet,
                onSend: props?.onSend || this.provider.props.onSend,
            }
        })
    }

    async getCache() {

        if (this.cache !== undefined) {
            return this.cache
        }

        this.cache = {
            data: undefined,
            schema: undefined,
        }

        switch (this.provider.name) {
            case 'DATABASE':
                Object.assign(this.cache, (await helper.$db.query(this.provider.props.source)))
                break
            case 'STORAGE':
                this.cache.data = helper.$service.store.get(this.provider.props.source)
                if (helper.$service.store.isExpired(this.provider.props.source)) {
                    this.cache.data = null
                    helper.$service.store.rm(this.provider.props.source)
                }
                break
        }

        return this.cache
    }

    async onSend(data) {
        const cache = await this.getCache()
        return resolveProvider[this.provider.name]?.onSend({
            data,
            cache,
            onSend: this.provider.props.onSend
        })
    }

    async onGet(response) {
        const cache = await this.getCache()
        return resolveProvider[this.provider.name]?.onGet({
            response,
            cache,
            onGet: this.provider.props.onGet
        })
    }

    async save(data) {
        const cache = await this.getCache()
        resolveProvider[this.provider.name]?.save(this.provider.props.source, data, cache)
    }

}
