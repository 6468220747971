<template>
    <div :id="uid" class="accordion" role="tablist" aria-multiselectable="true">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Accordion",
    data() {
        return {
            uid: this.$attrs.id || this.$uuid.v4()
        }
    }
}
</script>

<style scoped lang="scss">
.accordion {
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
</style>
