<template>
    <div class="form-row form-filter preorder-filter">
        <div class="form-row-header mb-20">
            <slot name="header"/>
            <button class="btn btn-white btn-small mr-15 ml-15" @click="$emit('reset')">Сбросить</button>
            <button class="btn btn-dark-blue btn-small" @click="$emit('apply')">Применить</button>
        </div>
        <div class="form-filter-wrap">
            <Form class="form-row-inner">
                <div class="form-row-inner-row">
                    <FormItem label="Откуда" style="width: 28%">
                        <FormFindSelect
                            v-model="filter.loadingRegion"
                            objectsArray
                            :options="regions"
                            label="id"
                            labelName="name"
                        />
                    </FormItem>
                    <FormItem label="Куда" style="width: 28%">
                        <FormFindSelect
                            v-model="filter.unloadingRegion"
                            objectsArray
                            :options="regions"
                            label="id"
                            labelName="name"
                        />
                    </FormItem>
                    <FormItem label="Статус" style="min-width: 12%">
                        <FormSelect
                            v-model="filter.tenderStatus"
                            :options="{
                                ALL: 'Все тендеры',
                                ACTIVE: 'Действующий',
                                COMPLETED: 'Завершенный',
                            }"
                        />
                    </FormItem>
                    <FormItem label="Тип транспорта" style="min-width: 41%">
                        <FormMultiSelect
                            v-if="transportTypes"
                            v-model="filter.transportTypeId"
                            :options="transportTypes"
                        />
                    </FormItem>
                </div>

                <div class="form-row-inner-row">
                    <FormItem label="Дата загрузки" row style="min-width: 20%;">
                        <FormDate v-model="filter.startLoadDate" :type="false" :bind="{placeholder: 'с'}"/>
                        <FormDate v-model="filter.endLoadDate" :type="false" :bind="{placeholder: 'по'}"/>
                    </FormItem>
                    <!--                    <FormItem label="Тип заявки" style="min-width: 22%;">
                                            <FormSelect
                                                v-model="filter.preorderType"
                                                :options="{
                                                    ALL: 'Любой',
                                                    AUCTION: 'Тендер',
                                                    FIX_BID: 'Фиксированная ставка',
                                                }"
                                            />
                                        </FormItem>-->

                    <FormItem label="Тоннаж" noShadow style="min-width: 21%">
                        <div>
                            <span>От {{ filter.cargoWeightMin }} тонн</span>
                            <template v-if="filter.cargoWeightMax < filterCargoWeightMax">
                                <span> До {{ filter.cargoWeightMax }} </span>
                                <span>тонн</span>
                            </template>
                            <span v-else-if="filter.cargoWeightMax === filterCargoWeightMax"> До неограничено</span>
                        </div>
                        <div>
                            <RangeDualSlider
                                :min="filterCargoWeightMin"
                                :max="filterCargoWeightMax"
                                :min-threshold="filterCargoWeightMin"
                                @change="changeFilterWeightRange"
                            />
                        </div>
                    </FormItem>

                    <FormItem label="Тариф" style="width: 12%">
                        <FormSelect
                            @input="v=>$emit('setRate', v)"
                            :value="setFilterRate"
                            :options="filterRate"
                        />
                    </FormItem>

                    <FormItem label="Способ загрузки" style="min-width: 41%">
                        <FormMultiSelect
                            v-if="loadingMethods"
                            v-model="filter.loadingMethodsId"
                            :options="loadingMethods"
                        />
                    </FormItem>
                </div>

                <!--                <div v-if="['FIX_BID', 'AUCTION'].includes(filter.preorderType)" class="form-row-inner-row">
                                    <FormItem v-if="filter.preorderType === 'FIX_BID'" label="Сортировка по фиксированной ставке" style="width: 30%">
                                        <FormSelect
                                            v-model="filter.sortFixedBidAmount"
                                            :options="{
                                                ASC: 'По возрастанию',
                                                DESC: 'По убыванию',
                                            }"
                                        />
                                    </FormItem>
                                    <FormItem v-if="filter.preorderType === 'AUCTION'" label="Статус" style="min-width: 20%">
                                        <FormSelect
                                            v-model="filter.tenderStatus"
                                            :options="{
                                                ALL: 'Все тендеры',
                                                ACTIVE: 'Действующий',
                                                COMPLETED: 'Завершенный',
                                            }"
                                        />
                                    </FormItem>
                                </div>-->

            </Form>
        </div>
    </div>
</template>

<script>
import RangeDualSlider from '@plugins/RangeDualSlider.vue'

export default {
    name: "FormPreOrderFilter",
    props: {
        filter: Object,
        filterRate: Object,
        setFilterRate: String,
        regions: Array,
        transportTypes: Array,
        loadingMethods: Array,
        filterCargoWeightMax: Number,
        filterCargoWeightMin: Number,
        changeFilterWeightRange: Function,
    },
    components: {RangeDualSlider}
}
</script>

<style lang="scss" scoped>
.preorder-filter {
    &-count {
        margin: 4px 14px;
        padding: 0;
        font-size: 0.9em;
        color: $blue;
    }
}
</style>
