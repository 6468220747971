<template>
    <Page>
        <template v-if="!dispatcher && !assign && !showPermissions">
            <FormStaffSearch v-model="search" @search="reload"/>
            <Buttons>
                <button class="btn btn-blue btn-small mb-20" @click="assign = true">
                    Добавить
                </button>
            </Buttons>
        </template>
        <FormStaffPermissions
            v-if="showPermissions && dispatcher"
            :key="dispatcher.id"
            :data="dispatcher"
            role="dispatcher"
            @toggle="toggle"
        />
        <FormStaffDispatcher
            v-else-if="dispatcher"
            :key="dispatcher.id"
            :data="dispatcher"
            @add-user="add"
            @unlink-user="unlink"
            @remove="remove"
            @permissions="() => this.showPermissions = true"
            @toggle="toggle"
        />
        <FormStaffLinkConfirm v-else-if="assign" linkType="dispatcher" @toggle="toggle"/>
        <DataTable
            :key="load"
            v-show="!dispatcher && !assign && !showPermissions"
            :cols="{
                id: 'ID',
                linkIcon: '',
                name: 'ФИО',
                phone: 'Номер телефона',
            }"
            :cells="{
                linkIcon: (v, item) => !item.linkToCarrierRequired ? null : [
                    {'div.user-link-icon': '', bind: {style: {width: '30px', height: '30px'}}}
                ],
                name: (v, item) => $filter.fullName(item),
                phone: v => $filter.mask(v, 'phone')
            }"
            :fetch="fetchData"
            :click="dispatcher => dispatcher.id === currentDispatcherId || setForm(dispatcher.id)"
            @setup="i => this.list = i"
        />
    </Page>
</template>

<script>

import FormStaffLinkConfirm from './form/FormStaffLinkConfirm.vue'
import FormStaffDispatcher from './form/FormStaffDispatcher.vue'
import FormStaffPermissions from './form/FormStaffPermissions.vue'
import FormStaffSearch from './form/FormStaffSearch.vue'

export default {
    name: "Dispatchers",
    props: {
        id: Number
    },
    components: {
        FormStaffLinkConfirm,
        FormStaffDispatcher,
        FormStaffPermissions,
        FormStaffSearch
    },
    data() {
        return {
            load: 0,
            assign: false,
            showPermissions: false,
            list: null,
            search: {data: ''},
            currentDispatcherId: null,
            dispatcher: null,
            linkedObjectId: null
        }
    },
    created() {
        this.currentDispatcherId = this.$service.account.access.isDispatcher() ? this.$service.account.get('userId') : null
        _.isNumber(this.id) && this.id !== this.currentDriverId && (this.linkedObjectId = this.id)
    },
    methods: {
        reload() {
            this.load += 1
        },
        toggle() {
            this.$set(this, 'assign', false)
            this.$set(this, 'showPermissions', false)
            this.$set(this, 'dispatcher', null)
            this.$redirect({name: 'Dispatchers'}, true)
        },
        fetchData(h) {
            this.$repo('staff').dispatchers({search: this.search.data}).then(({list}) => {
                h(list)
                this.linkedObjectId && this.$nextTick(() => {
                    this.setForm(this.linkedObjectId)
                    this.linkedObjectId = null
                })
            })
        },
        add() {
            this.$repo('link_carrier').add('DISPATCHER', this.getId()).then(() => {
                this.toggle()
                this.reload()
            })
                .done(`Диспетчер ${this.$filter.fullName(this.dispatcher)} добавлен в список Вашего персонала.`)
                .alert('Не удалось добавить диспетчера.')
        },
        unlink() {
            this.$confirm({
                text: 'Вы действительно хотите отклонить заявку на добавление диспетчера?.',
                onConfirm: () => {
                    this.$repo('link_carrier').cancel('DISPATCHER', this.getId()).then(() => {
                        this.toggle()
                        this.reload()
                    }).done('Заявка диспетчера отклонена.').alert('Не удалось отклонить заявку диспетчера.')
                }
            })
        },
        remove() {
            const name = this.$filter.fullName(this.dispatcher)
            this.$confirm({
                text: `Вы действительно хотите удалить диспетчера "${name}"?`,
                onConfirm: () => {
                    this.$repo('staff').deleteDispatcher(this.getId()).then(() => {
                        this.reload()
                        this.toggle()
                    }).done(`Диспетчер "${name}" удален из списка.`).alert(`Не удалось удалить диспетчера "${name}".`)
                }
            })
        },
        getId() {
            return this.dispatcher.linkToCarrierRequired ? this.dispatcher.backendId : this.dispatcher.id
        },
        setForm(id, backendId) {
            this.$set(this, 'dispatcher', this.list.getItem((i) => {
                return (id && i.id === id)
                    || (backendId && i.backendId === backendId)
                    || (id && i.backendId === id)
            }))
            this.dispatcher || this.$notify().warn('Диспетчер не найден.')
        }
    }
}

</script>
