<template>
    <div>
        <div v-if="requestCarrierCode">
            <div class="block-center">
                <div v-if="!carrier">
                    <a class="link"
                       @click="preRequestCode($service.account.access.isDriver() ? 'driver' : 'dispatcher')">
                        Привязаться к перевозчику
                    </a>
                </div>
                <div v-else-if="accept || (carrier && carrier.linkState === 'IN_PROGRESS')">
                    <FormAcceptCarrier
                        :carrier="carrier"
                        @accept="acceptCarrier"
                        @decline="declineCarrier"
                    />
                </div>
            </div>
        </div>
        <Page v-else class="block-wide block-center pt-40">
            <div v-if="carrier === null && formType !== 'carrier'">
                <h3 class="section-page-title tx-c pl-0">{{ $service.account.fullName() }}</h3>
                <p class="tx-c">
                    {{ $service.account.get('phone') | mask('phone') }}
                </p>
                <h3 class="clr-ltg-4 tx-c mb-40">Выберите роль в системе</h3>
                <Buttons>
                    <button class="btn btn-blue" @click="linkExisting('driver')">
                        Водитель
                    </button>
                    <button class="btn btn-blue" @click="preRequestCode('dispatcher')">
                        Диспетчер
                    </button>
                    <button class="btn btn-blue" @click="linkExisting('carrier')">
                        Перевозчик
                    </button>
                </Buttons>
                <div class="mt-40 tx-c">
                    <a class="link link-blue-light" @click="chat">Связаться с личным менеджером</a>
                </div>
            </div>
            <div v-else-if="accept && carrier">
                <FormAcceptCarrier
                    :carrier="carrier"
                    @accept="acceptCarrier"
                    @decline="declineCarrier"
                />
            </div>
            <div v-else-if="checkRequisites === true">
                <FormRequisites
                    @confirmed="requisites => setLinkedCarriers(requisites)"
                    @cancel="checkRequisites = false"
                />
            </div>
            <div
                v-else-if="formType === 'carrier' || (carrier && (carrier.linkState === 'CONFIRMED' || carrierAccepted))">
                <FormCarrier
                    v-if="formType === 'carrier'"
                    :key="ukey"
                    :form="formCarrier"
                    @check-requisites="() => this.checkRequisites = true"
                    @toggle="t => this.formType = t"
                    @registered="registered"
                    @link="linkExisting"
                />
                <FormDriver
                    v-else-if="formType === 'driver'"
                    :key="ukey"
                    :form="formDriver"
                    @decline="declineCarrier"
                    @registered="registered"
                />
            </div>
        </Page>

        <Popup name="popup-proposed-carriers" v-if="proposedCarriers && proposedCarriers.length">
            <div class="flex flex-center flex-col-20">
                <h3 v-if="showProposedCarriers" class="section-page-title">
                    Выберите перевозчика из списка
                </h3>
                <div v-if="showProposedCarriers">
                    <FormItem label="Перевозчик">
                        <FormSelect
                            v-model="selectedCarrierId"
                            required
                            label="carrier.id"
                            labelName="carrier.name"
                            :options="proposedCarriers"
                        />
                    </FormItem>
                </div>
                <div v-else>
                    <h3 class="clr-ltg-4">Ваш перевозчик</h3>
                    <h3 class="tx-up clr-blue">&laquo;{{ getSelectedCarrier().carrier.name }}&raquo;</h3>
                </div>
                <Buttons initial>
                    <button class="btn btn-blue" @click="requestCarrier(selectedRole)">
                        Отправить запрос перевозчику
                    </button>
                    <button
                        v-if="!showProposedCarriers"
                        class="btn btn-blue"
                        @click="proposedCarriers.length > 1 ? showProposedCarriers = true : requestCode(selectedRole)"
                    >
                        Другой перевозчик
                    </button>
                    <button
                        v-else
                        class="btn btn-blue"
                        @click="requestCode(selectedRole)"
                    >
                        Моего перевозчика нет в списке
                    </button>
                </Buttons>
            </div>
        </Popup>
        <Popup name="popup-linked-carriers" v-if="linkedCarriers && linkedCarriers.length" small>
            <div class="flex flex-center flex-col-20">
                <h3 class="clr-ltg-4">Ваш перевозчик</h3>
                <ul class="linked-carriers-item list">
                    <li v-for="(item, k) in linkedCarriers"
                        :key="k"
                        :class="{selected: linkedCarrierId === item.id}"
                        @click="setLinkedCarrierId(item.id)"
                    >
                        {{ item.companyDetails.name }}
                    </li>
                </ul>
                <button class="btn btn-blue" @click="linkExisting('carrier')">
                    Подтвердить
                </button>
                <button class="btn btn-blue"
                        @click="() => $ok({
                            text: [
                                '',
                                'С указанными контактными данными владельца/руководителя другие контрагенты в базе не найдены.',
                                'Зарегистрируйте нового контрагента. Для этого заполните и сохраните профиль.',
                                `Если при сохранении Вы получите уведомление о том, что контрагент с таким ИНН уже есть в базе,
                                то необходимо актуализировать его контактные данные.`,
                                'Для этого свяжитесь с менеджером. После чего, нужно повторить поиск перевозчика в разделе «Я раньше работал с Роспром».',
                           ],
                           onConfirm: () => {
                                $popupClose()
                                linkExisting('carrier', () => this.checkRequisites = true)
                           }
                       })">
                    Моего перевозчика нет в списке
                </button>
            </div>
        </Popup>
        <Confirm name="confirm-carrier-request-code" @closed="carrierRequestCode = null">
            <h2 class="clr-blue tx-c">{{ carrierRequestCode }}</h2>
            <h4 class="clr-ltg-3 tx-c">сообщите его перевозчику по телефону или почте</h4>
        </Confirm>
        <Confirm name="confirm-search-linked-carriers-error">
            {{ searchLinkedCarriersErrorMessage }}
            <p>
                <a class="link link-blue-light" @click="chat">Написать менеджеру</a>
            </p>
        </Confirm>
        <Popup name="manager_chat">
            <h3 slot="header" class="tx-c">
                <FaIcon icon="comments" class="clr-ltg-2"/>
                Чат
            </h3>
            <ManagerChat
                :promptMessage="searchLinkedCarriersErrorMessagePrompt"
                :fetchFirstChat="!!searchLinkedCarriersErrorMessage"
            />
        </Popup>
    </div>
</template>

<script>

import FormCarrier from './form/FormCarrier.vue'
import FormDriver from './form/FormDriver.vue'
import FormRequisites from './form/FormRequisites.vue'
import FormAcceptCarrier from './form/FormAcceptCarrier.vue'
import ManagerChat from "@pages/chats/ManagerChat";

export default {
    name: "LinkCarrier",
    props: {
        requestCarrierCode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            carrier: null,
            checkRequisites: false,
            formType: null,
            accept: false,
            formCarrier: {},
            formDriver: {},
            ukey: _.randKey(),
            carrierAccepted: false,
            proposedCarriers: null,
            linkedCarriers: null,
            showProposedCarriers: false,
            selectedCarrierId: null,
            linkedCarrierId: null,
            selectedRole: null,
            carrierRequestCode: null,
            searchLinkedCarriersErrorMessage: null,
            searchLinkedCarriersErrorMessagePrompt: null,
        }
    },
    components: {
        ManagerChat,
        FormCarrier,
        FormDriver,
        FormRequisites,
        FormAcceptCarrier
    },
    created() {
        this.carrier = this.$service.account.get('carrier')
        this.requestCarrierCode || this.checkCarrierLinkState()
    },
    methods: {
        preRequestCode(role) {
            this.selectedRole = role
            this.$service.account.fetchAccount().finally(({object, isSuccess}) => {
                const carrier = object?.linkedCarrier
                if (isSuccess && carrier && carrier.linkType === _.toUpper(role) && carrier.linkState === 'IN_PROGRESS') {
                    this.$confirm({
                        title: `Код привязки уже подтвержден перевозчиком "${carrier.companyName}"`,
                        text: 'Желаете отправить код привязки перевозчику повторно?',
                        onConfirm: () => this.searchCarriers(role),
                        buttons: [
                            'Да',
                            {
                                text: 'Нет, продолжить',
                                bind: {class: 'btn btn-white'},
                                on: {click: () => this.$util.page.reload({})}
                            }
                        ]
                    })
                } else this.searchCarriers(role)
            })
        },
        searchCarriers(role) {
            return this.$repo('link_carrier').searchCarriers(_.toUpper(role)).then(({list}) => {
                if (!list?.length) return this.requestCode(role)
                this.$set(this, 'proposedCarriers', list)
                this.$set(this, 'selectedCarrierId', this.proposedCarriers[0].carrier.id)
                this.$nextTick(() => this.$popup('popup-proposed-carriers', {}))
            }).error(() => this.requestCode(role)).alert('Не удалось загрузить список доступных перевозчиков.')
        },
        getSelectedCarrier() {
            return this.proposedCarriers.filter(item => item.carrier.id === this.selectedCarrierId)?.[0]
        },
        requestCode(role) {
            this.$repo('link_carrier').requestCode(_.toUpper(role)).then((r) => {
                this.carrierRequestCode = r.data.code
                this.$confirm('confirm-carrier-request-code', {
                    title: 'Ваш код привязки к перевозчику',
                    buttons: 'ok',
                    onConfirm: () => this.$util.page.reload({})
                })
            }).error((r) => {
                if (r.data.result === 'OBJECT_ALREADY_EXISTS') {
                    this.$notify.info('Ваш профиль уже привязан к перевозчику...')
                    this.$util.page.reload({}, 3500)
                    return
                }
                this.$notify.alert('Не удалось получить код привязки.')
            })
        },
        requestCarrier(role) {
            return this.$repo('link_carrier').requestCarrier({
                carrierId: this.selectedCarrierId,
                userId: this.getSelectedCarrier()[role].id,
                linkType: _.toUpper(role),
            }).then(({object}) => {
                if (object.automaticallyLinked) {
                    return this.$service.account.fetchAccount().finally(({object}) => {
                        if (!object) {
                            return this.$notify.alert('Не удалось загрузить Ваш профиль в ответ на автоматическую привязку к перевозчику.')
                        }
                        this.$util.page.reload({})
                    }).error(() => this.$util.page.reload({}, 3000))
                }
                this.$ok({
                    text: [
                        `Ваш запрос на привязку отправлен перевозчику "${this.getSelectedCarrier().carrier.name}"`,
                        'Ожидается подтверждение Вашего запроса перевозчиком.'
                    ],
                    onConfirm: () => this.$popupClose('popup-proposed-carriers')
                })
            }).error(r => {
                r.data.result === 'ALREADY_HAS_LINKAGE'
                    ? this.$util.page.reload({})
                    : this.$notify.alert('Не удалось привязаться к перевозчику.')
            })
        },
        linkExisting(role, cb) {

            const data = role === 'carrier' && this.linkedCarrierId ? this.linkedCarrierId : null

            this.formType = role

            this.checkState(null, () => {

                this.$repo(role).linkExisting(data).alert(false).finally(({response, object, isSuccess}) => {

                    if (role === 'carrier' && response.data.result === 'OBJECT_ALREADY_EXISTS') {

                        const num = response.data.message.slice(-2)

                        this.$confirm({
                            text: [
                                'Уже существует пользователь связанный с выбранным перевозчиком',
                                `Телефон пользователя +7 (***) ***-**-${num}.`,
                                `Войдите под этим телефоном. Если к нему нет доступа - отправьте запрос на удаление аккаунта +7 (***) ***-**-${num}.`
                            ],
                            onConfirm: () => {
                                this.$repo('link_carrier').userRemovalRequest(response.data.message)
                                    .then(() => {
                                        this.$popupClose('popup-linked-carriers')
                                        this.$ok(['Запрос отправлен', 'С Вами свяжется наш менеджер.'])
                                    }).alert('Не удалось удалить аккаунт.')
                            },
                            buttons: ['Отправить запрос'],
                            buttonCancel: true
                        })

                    } else if (isSuccess) {

                        role === 'carrier' ? this.formCarrier = object : this.formDriver = object
                        this.ukey = _.randKey()
                        this.checkState(() => role === 'carrier' || this.preRequestCode(role))

                    } else role === 'carrier' || this.preRequestCode(role)

                    _.isFunction(cb) && cb(isSuccess, response)
                })
            })
        },
        searchLinkedCarriers(requisites, cb) {

            _.isFunction(cb) || (cb = () => {
            })

            const get = (phone, email, notify) => {
                return this.$repo('carrier').search({
                    phone: _.toNum(phone),
                    email: email
                })
                    .then(({list}) => cb(!_.isEmpty(list) ? list : null))
                    .error(r => {

                        if (notify !== false && r.data.result.match(/CARRIER_/)) {

                            this.searchLinkedCarriersErrorMessage = r.data.message
                            this.searchLinkedCarriersErrorMessagePrompt = 'Здравствуйте. ' +
                                'При попытке получить роль Перевозчик не удалось найти контрагента. ' +
                                `На экране появилось уведомление: «${r.data.message}». ` +
                                `Для поиска был указан телефон - ${phone} и почта - ${email}. ` +
                                `Наименование моего контрагента «». ` +
                                'Как завершить выбор роли?'

                            return this.$confirm('confirm-search-linked-carriers-error', {
                                onConfirm: () => cb(null),
                                buttons: 'ok',
                                onClose: () => {
                                    this.searchLinkedCarriersErrorMessage = null
                                    this.searchLinkedCarriersErrorMessagePrompt = null
                                }
                            })
                        }
                        cb(null)
                    })
            }

            if (_.isPlainObject(requisites)) {
                get(requisites.phone, requisites.email)
            } else {
                this.$repo('link_carrier').requisitesStatus().finally(({isSuccess, response}) => {
                    return !isSuccess ? cb(null) : get(response.data.phone.value, response.data.email.value, false)
                }).catch(() => cb(null))
            }
        },
        setLinkedCarriers(requisites) {
            this.$set(this, 'checkRequisites', false)
            this.searchLinkedCarriers(requisites, carriers => {
                this.$set(this, 'linkedCarriers', !carriers ? null : _.uniqBy(carriers, 'id'))
                this.linkedCarriers && this.$set(this, 'linkedCarrierId', this.linkedCarriers[0].id)
                this.linkedCarriers && this.$nextTick(() => {
                    this.$popup('popup-linked-carriers', {
                        onClose: () => this.$set(this, 'linkedCarrierId', null)
                    })
                })
            })
        },
        setLinkedCarrierId(id) {
            this.$set(this, 'linkedCarrierId', id)
        },
        acceptCarrier() {

            return this.$repo('link_carrier').acceptCarrier().then(() => this.checkState()).error(resp => {

                let msg = 'Не удалось подтвердить приглашение от перевозчика. '

                switch (resp.data.result) {
                    case 'INVALID_OPERATION':
                        msg += 'Перевозчик все еще не подтвердил Ваш код, либо Вы уже привязаны к другому перевозчику.'
                        break
                    case 'OBJECT_ALREADY_EXISTS':
                        msg += 'Вы уже зарегистрированы водителем.'
                        break
                    case 'OBJECT_NOT_FOUND':
                        this.carrierAccepted = parseInt(_.get(resp.data, 'params.carrierBackendId')) > 0
                        if (this.carrierAccepted) return this.checkState()
                        break
                }

                this.$notify.alert(msg)

            }).alert(false)
        },
        declineCarrier() {
            const carrier = this.carrier ? this.carrier.companyName : ''

            this.$confirm({
                text: `Вы действительно хотите отвязаться от перевозчика "${carrier}"?`,
                onConfirm: () => {
                    this.$repo('link_carrier').delete()
                        .then(() => this.$util.page.reload({}, 3000))
                        .done(`Ваш профиль удален из списка перевозчика "${carrier}"`)
                        .alert(`Не удалось отвязаться от перевозчика "${carrier}"`)
                }
            })
        },
        checkState(cb, fb) {

            this.$service.account.fetchAccount().finally(({object, response}) => {

                if (object) {

                    this.carrier = object.linkedCarrier

                    if (object.roles.length > 0 && false === this.requestCarrierCode) {
                        this.$service.account.set('profile', object)
                        return this.linkedNotify(true)
                    }
                    if (_.isFunction(cb)) {
                        cb(response.data)
                    } else {
                        this.checkCarrierLinkState()
                        if (_.isFunction(fb) && !this.carrier) fb(response.data)
                    }

                } else {
                    this.$notify.alert('Не удалось загрузить профиль.')
                    this.$util.page.reload({}, 3000)
                }
            })
        },
        checkCarrierLinkState() {
            if (this.carrier === null) {
                this.accept = false
                this.formType === 'carrier' || (this.formType = null)
            } else if (this.carrierAccepted || this.carrier.linkState === 'CONFIRMED') {
                this.linkedNotify(this.requestCarrierCode)
                this.formType = this.carrier.linkType?.toLowerCase()
                this.accept = false
            } else if (this.carrier.linkState === 'IN_PROGRESS') {
                this.accept = true
                this.formType = null
            }
        },
        linkedNotify(linkConfirmed) {
            if (!_.isPlainObject(this.carrier)) return this.$util.page.reload({})

            if (linkConfirmed) {
                this.formType = null
                return this.$ok({
                    text: [
                        `Теперь Вы привязаны к "${this.carrier.companyName}"`,
                        'Ваша анкета заполнена автоматически, рекомендуем проверить актуальность данных указанных в профиле.'
                    ],
                    onConfirm: () => this.$util.page.reload({})
                })
            }
            this.$ok([
                `Теперь Вы привязаны к "${this.carrier.companyName}"`,
                `Заполните анкету водителя, дождитесь проверки данных нашей службой безопасности
                    и смело приступайте к выполнению заказов в нашей системе.`
            ])
        },
        registered() {
            this.$util.page.reload({}, 3000)
        },
        chat() {
            this.$popup('manager_chat', {
                clickToClose: false,
                footer: false
            })
        }
    }
}

</script>

<style scoped>

.linked-carriers-item li {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.linked-carriers-item li.selected {
    background-color: #e4e4e4;
}

</style>
