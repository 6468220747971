<template>
    <Page class="home-page-intro">
        <div class="--overlay">
            <template v-if="account.isAuthorized()">
                <div class="--overlay-sup">
                    <img alt="" src="~@/assets/icons/logo-icon-96x96.png" class="--logo">
                    <template v-if="account.access.hasAnyRole()">
                        <ProfileInfo/>
                    </template>
                    <template v-else>
                        <h3>Вы не выбрали роль</h3>
                        <h2
                            class="link link-blue-extra-light flex flex-center flex-row-10"
                            @click="$redirect({name: 'LinkCarrier'})"
                            style="margin-top: 0"
                        >
                            Перейти к выбору роли в системе
                            <FaIcon icon="chevron-right" style="font-size: 0.6em; margin-bottom: -2px"/>
                        </h2>
                    </template>
                </div>
                <template v-if="account.access.hasAnyRole()">
                    <h3 v-if="account.access.isCarrier() && !account.access.isDriver()" class="my-20">
                        <a @click="getLinkedDriver" class="link-blue-extra-light">
                            Регистрация водителем
                        </a>
                    </h3>
                </template>
                <template v-if="!account.access.hasAnyRole() || account.access.isDriver() || account.access.isDispatcher()">
                    <h3>
                        <a class="link-blue-extra-light" @click="chat">Личный менеджер</a>
                    </h3>
                    <Popup name="manager_chat">
                        <h3 slot="header">
                            <FaIcon icon="comments" class="clr-ltg-2"/>
                            Чат
                        </h3>
                        <ManagerChat />
                    </Popup>
                </template>
            </template>
            <template v-else>
                <div class="--overlay-sup">
                    <img alt="" src="~@/assets/icons/logo-icon-96x96.png" class="--logo">
                    <h2>Добро пожаловать</h2>
                    <h3>в нашу систему грузоперевозок</h3>
                </div>
                <h3>
                    <router-link class="link link-blue-extra-light" to="/login">Вход</router-link>
                </h3>
                <h3>
                    <router-link class="link link-blue-extra-light" to="/reg">Регистрация</router-link>
                </h3>
            </template>
        </div>
    </Page>
</template>

<script>

import ProfileInfo from "@pages/profile/ProfileInfo"
import ManagerChat from "@pages/chats/ManagerChat"

export default {
    name: "HomePage",
    components: {ProfileInfo, ManagerChat},
    data() {
        return {
            account: this.$service.account
        }
    },
    methods: {
        getLinkedDriver() {
            this.$repo('carrier').tryLinkToSelfOwn().then(() => {
                this.$ok({
                    title: 'Вы зарегистрировались как водитель',
                    text: 'Анкета заполнена автоматически, рекомендуем проверить актуальность данных указанных в профиле.',
                    onConfirm: () => this.$util.page.reload({})
                })
            }).error((resp) => {
                resp.data.result === 'INVALID_OPERATION'
                    ? this.$redirect({name: 'ProfileDriverRegister'})
                    : this.$notify().warn('Не удалось зарегистрироваться водителем.')
            }).alert(false)
        },
        chat() {
            this.$popup('manager_chat', {
                clickToClose: false,
                footer: false
            })
        }
    }
}

</script>

<style scoped lang="scss">
.home-page-intro {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 650px;
    background: url(~@/assets/img/home-page-bg-optimized.jpg) center center no-repeat;
    background-size: cover;
    margin-bottom: 0;
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
    @media (max-width: 425px) {
        background-position: center right;
    }

    .--overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(84, 134, 175, 0.28%);
        text-align: center;
        z-index: 1;

        .--logo {
            margin: 0 0 20px;
            @media (max-width: 425px) {
                width: 15%;
            }
        }

        &-sup {
            margin-top: 40px;
            background: rgba(255, 255, 255, 0.21);
            padding: 30px 20px 15px;
            text-shadow: 0px 1px 3px rgba(48, 48, 48, 0.74%);

            h1, h2, h3 {
                padding: 0 0 15px;
                margin: 0;
                font-family: $font-proxima-bold;
                color: #fff;
            }
        }
    }
}
</style>
