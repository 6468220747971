<template>
    <div v-if="data" class="form-staff-permissions block-center block-contents">
        <div>
            <h2 class="section-page-title tx-c pl-0">{{ this.data | fullName }}</h2>
            <h4 class="tx-c">
                {{ data.phone | mask('phone') }}
            </h4>
        </div>
        <div v-if="notRegistered" class="my-20 tx-c">
            {{ $service.account.roleName(this.role) }} не зарегистрирован в системе "ТТ-Онлайн",
            поэтому Вы не можете управлять его правами. Чтобы это исправить - сотрудник должен зарегистрироваться в
            мобильном приложении.
        </div>
        <div v-else-if="rolePermissions && permissionChecked">
            <div v-for="value in permissions" :key="value.name" class="flex flex-col-20 mb-40">
                <h3 class="tx-bold clr-dark">{{ value.name }}</h3>
                <div class="flex flex-align w-100" v-for="(item,key) in value.items" :key="key">
                    <div class="form-staff-permissions-label" style="width: 85%">{{ item }}</div>
                    <div>
                        <Toggle @change="({srcEvent}) => setPermission(srcEvent, key)"
                                :value="getPermissionValue(key)"/>
                        {{ item.value }}
                    </div>
                </div>
            </div>
        </div>
        <BackToggle @toggle="$emit('toggle', false)" :flag="true"/>
    </div>
</template>

<script>

import Permission from '@/config/permission'

export default {
    name: "FormStaffPermissions",
    props: {
        role: {
            type: String,
            default: "",
            required: true,
        },
        data: {
            type: Object,
            default: null,
            required: true,
        }
    },
    data() {
        return {
            permissions: {},
            permissionChecked: false,
            rolePermissions: null,
            permissionValues: null,
            notRegistered: false
        }
    },
    created() {

        const method = (this.role === 'dispatcher' ? 'dispatcher' : 'driver') + 'Permissions'

        this.$repo('staff')[method](this.data.id)
            .then(({list}) => this.rolePermissions = list)
            .error(() => this.notRegistered = true)

        this.permissions = _.cloneDeep(Permission.groups)

        if (this.role === 'driver') {
            this.$delete(this.permissions, 'permissions')
            this.$delete(this.permissions, 'driver')
            this.$delete(this.permissions, 'dispatcher')
            this.$delete(this.permissions, 'transport')
            this.$delete(this.permissions, 'counterpart_transport')
            this.$delete(this.permissions.fuel_card.items, 'CAN_GRANT_TOPUP_FUEL_CARDS')
            this.$delete(this.permissions.fuel_card.items, 'CAN_GRANT_VIEW_FUEL_REMINDER')
            this.$delete(this.permissions.fuel_card.items, 'CAN_TOPUP_FUEL_CARDS')
            this.$delete(this.permissions.other.items, 'CAN_ASSIGN_DRIVER')
            this.$delete(this.permissions.other.items, 'CAN_CONFIRM_RFT')
            this.$delete(this.permissions.other.items, 'CAN_DECLINE_RFT')
        }

        if (this.$service.account.access.isDispatcher()) {

            this.$delete(this.permissions.fuel_card.items, 'CAN_GRANT_VIEW_FUEL_REMINDER')
            this.$delete(this.permissions.fuel_card.items, 'CAN_GRANT_TOPUP_FUEL_CARDS')
            this.$service.account.hasRule('CAN_GRANT_VIEW_FUEL_REMINDER', can => {

                if (can === false) this.$delete(this.permissions.fuel_card.items, 'CAN_VIEW_FUEL_REMINDER')

                this.$service.account.hasRule('CAN_GRANT_TOPUP_FUEL_CARDS', can => {
                    if (can === false) this.$delete(this.permissions.fuel_card.items, 'CAN_TOPUP_FUEL_CARDS')
                    if (_.isEmpty(this.permissions.fuel_card.items)) this.$delete(this.permissions, 'fuel_card')
                    this.$set(this, 'permissionChecked', true)
                })
            })

        }
    },
    mounted() {
        this.$service.account.access.isCarrier() && this.$set(this, 'permissionChecked', true)
    },
    methods: {
        getPermissionValue(permission) {
            if (!this.permissionValues) {
                this.permissionValues = {}
                this.rolePermissions.forEach(item => this.permissionValues[item.name] = item.value)
            }
            return permission in this.permissionValues ? this.permissionValues[permission] : false
        },
        setPermission(e, permission) {
            const method = this.role === 'dispatcher' ? 'saveDispatcherPermissions' : 'saveDriverPermissions'
            const checked = e.target.checked

            this.$repo('staff')[method](this.data.id, [{
                name: permission,
                value: checked
            }])
                .alert('Не удалось изменить разрешения.')
                .error(() => e.target.checked = !checked)
                .catch(() => e.target.checked = !checked)
        }
    }
}

</script>

<style scoped lang="scss">
.form-staff-permissions {
    &-label {
        padding-right: 15px;

        @media (max-width: 768px) {
            padding-bottom: 10px;
        }
    }
}
</style>
