var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticStyle:{"padding-left":"5%"}},[_c('h2',{staticClass:"section-page-title pl-0"},[_vm._v("Настройки")]),_c('div',{staticClass:"flex-col mt-40"},[_c('p',[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.changePasswordForm(true)}}},[_vm._v("Изменить пароль")])]),_c('p',[_c('a',{staticClass:"link",on:{"click":_vm.listPushSettings}},[_vm._v("PUSH-уведомления")])]),_c('p',[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.$service.file.getAndDownload({
                    repo: 'docs',
                    repoMethod: 'getAgreement',
                    params: null,
                    name: 'Пользовательское соглашение',
                    errMsg: 'Не удалось загрузить Пользовательское соглашение'
                })}}},[_vm._v(" Пользовательское соглашение ")])]),_c('p',[_c('a',{staticClass:"link",on:{"click":_vm.about}},[_vm._v("О веб-клиенте")])]),_c('p',[_c('a',{staticClass:"link",on:{"click":_vm.aboutDev}},[_vm._v("О разработчике")])]),_c('p',[_c('a',{staticClass:"link",on:{"click":_vm.feedback}},[_vm._v("Оставить отзыв")])])]),_c('Popup',{attrs:{"name":"popup-change-password"},on:{"closed":function () { return this$1.passwordToken = null; }}},[(_vm.passwordToken)?_c('FormCodeConfirm',{staticClass:"block-wide block-center",attrs:{"requestCodeTitle":"На Ваш номер телефона отправлен SMS с кодом подтверждения пароля","type":"phone","dest":_vm.$service.account.get('phone'),"endpoint":_vm.$config.api().codeConfirm.passwordConfirm,"request-code-endpoint":_vm.$config.api().codeConfirm.resendCode,"token":_vm.passwordToken,"counter":_vm.passwordResendCodeAfterCount,"useApiKey":false,"confirmCancelBtn":false,"requestCancelBtn":false},on:{"confirmed":function () {
                _vm.changePasswordForm()
                _vm.$notify().info('Пароль успешно изменен.')
            }}}):[_c('h2',{staticClass:"section-page-title tx-c mb-20"},[_vm._v("Изменение пароля")]),_c('DataForm',{attrs:{"required":"","form":{
                    bind: {buttons: 'Сохранить', center: true, wide: true},
                    on: {submit: _vm.changePassword}
                },"model":{
                    model: _vm.data.password,
                    fields: {
                        oldPassword: {
                            name: 'Старый пароль',
                            type: 'password',
                            autocomplete: 'new-password',
                            wrap: {bind: {type: 'show-password'}}
                        },
                        newPassword: {
                            name: 'Новый пароль',
                            type: 'password',
                            autocomplete: 'new-password',
                            wrap: {bind: {type: 'show-password'}},
                            rules: [{
                                rule: ['regex', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{4,}$/],
                                message: "Пароль должен состоять минимум из 4-х символов и содержать цифры и буквы латинского алфавита в верхнем и нижнем регистре."
                            }]
                        },
                    }
                }},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]],2),_c('Popup',{attrs:{"name":"popup-profile-settings"}},[(_vm.type === 'listPushSettings')?_c('div',{staticClass:"block-center block-wide"},[_c('h3',{staticClass:"tx-bold tx-c my-40"},[_vm._v("Настройки PUSH-уведомлений")]),(_vm.notifyPushSettings)?_c('div',{staticClass:"flex flex-col-20"},[_vm._l((_vm.notifyPushSettings),function(item,i){return [(_vm.notifyPushSettings)?_c('div',{key:i,staticClass:"flex w-100"},[_c('div',{staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(item.name in _vm.data.push_settings ? _vm.data.push_settings[item.name] : item.name)+" ")]),_c('div',[_c('Toggle',{attrs:{"value":item.value},on:{"change":function (ref) {
                            var srcEvent = ref.srcEvent;

                            return _vm.setPushSettings(srcEvent, i);
                }}})],1)]):_vm._e()]})],2):_vm._e()]):(_vm.type === 'about')?_c('div',{staticClass:"block-contents block-inflect tx-c"},[_c('h2',{staticClass:"section-page-title tx-c"},[_vm._v("ТТ Онлайн")]),_c('p',[_vm._v(" Веб-клиент ("),_c('b',[_vm._v("версия "+_vm._s(_vm.$app().version))]),_vm._v(") предназначен для предоставления пользователям возможности получения доступа к информации Заказчика при помощи веб-браузера, отправки и получения информации, используя особенности веб-браузера. ")]),_c('p',[_vm._v(" СВЯЖИТЕСЬ С НАМИ: ")]),_c('p',[_c('a',{staticClass:"link",attrs:{"href":"mailto:devmapp@tkrosprom.com"}},[_vm._v("DEVMAPP@TKROSPROM.COM")])])]):(_vm.type === 'aboutDev')?_c('div',{staticClass:"block-contents block-inflect tx-c"},[_c('h2',{staticClass:"section-page-title tx-c"},[_vm._v("ТТ Онлайн")]),_c('p',[_vm._v(" Все права на разработку веб-клиента принадлежат Компании ООО «Транспортные технологии». ")])]):(_vm.type === 'feedback')?_c('div',{staticClass:"block-center block-wide pb-20"},[_c('h2',{staticClass:"section-page-title tx-c"},[_vm._v("Ваш отзыв")]),_c('DataForm',{attrs:{"form":{
                    bind: {buttons: 'Отправить'},
                    on: {submit: _vm.submitFeedback}
                },"model":{
                    model: _vm.data.feedback,
                    fields: {
                        name: {name: 'Имя', disabled: true},
                        surname: {name: 'Фамилия', disabled: true},
                        patronymic: {name: 'Отчество', disabled: true},
                        phone: {name: 'Телефон', mask: 'phone', disabled: true},
                        email: {name: 'Эл. почта'},
                        text: {name: 'Текст сообщения', type: 'textarea', required: true},
                    }
                }},model:{value:(_vm.data.feedback),callback:function ($$v) {_vm.$set(_vm.data, "feedback", $$v)},expression:"data.feedback"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }