import Vue from 'vue'

import jquery from 'jquery'
export {jquery}

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
    faBars,
    faEdit,
    faClock,
    faTrash,
    faTrophy,
    faArrowDown,
    faInfoCircle,
    faChevronDown,
    faChevronRight,
    faQuestionCircle,
    faExclamationTriangle,
    faAngleRight,
    faSearch,
    faEye,
    faEyeSlash,
    faCheck,
    faComments,
    faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
    faBars,
    faEdit,
    faClock,
    faTrash,
    faTrophy,
    faArrowDown,
    faInfoCircle,
    faChevronDown,
    faChevronRight,
    faQuestionCircle,
    faExclamationTriangle,
    faAngleRight,
    faSearch,
    faEye,
    faEyeSlash,
    faCheck,
    faComments,
    faAngleDoubleRight
)
dom.watch()
export {FontAwesomeIcon}

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import UUID from 'vue-uuid'
Vue.use(UUID)

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading, {
    color: '#207DD3',
    container: null
})

import Toast from 'vue-easy-toast'
require('vue-easy-toast/dist/vue-easy-toast.css')
Vue.use(Toast)

