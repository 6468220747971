import Vue from 'vue'
import {RequestLoader} from '@argab/request-service'

let loader

const load = () => {
    loader ||= Vue.prototype.$loading.show()
}
const hide = () => {
    loader?.hide()
    loader = null
}

export default class extends RequestLoader {

    start() {
        load()
    }

    end() {
        this.pending === 0 && hide()
    }

}
