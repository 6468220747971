var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form",class:[
        _vm.row && 'block-row',
        _vm.center && 'block-center',
        _vm.wide && 'block-wide',
        _vm.thin && 'block-thin',
        _vm.inflect && 'block-inflect',
        _vm.contents && 'block-contents',
        _vm.border && 'block-bordered',
        _vm.radius && 'block-bordered block-bordered-radius' ],on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.target.tagName === 'TEXTAREA' || _vm.onSubmit()}}},[_vm._t("header"),(_vm.title)?_c('h2',{staticClass:"form-title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._t("default"),(_vm.submitButtons)?_c('Buttons',{staticClass:"mt-40",style:(_vm.buttonsWidth ? {
            width: _vm.buttonsWidth.toString().includes('px') ? _vm.buttonsWidth : (_vm.buttonsWidth + "%")
        } : false),attrs:{"buttons":_vm.submitButtons,"inline":_vm.buttonsInline,"align":_vm.buttonsAlign,"initial":_vm.buttonsInitial,"cancel":_vm.buttonCancel,"center":_vm.buttonsCenter},on:{"cancel":_vm.buttonCancelClick}}):_vm._e(),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }