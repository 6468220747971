<template>
    <div class="profile-info">
        <h2>{{ account.fullName() }}</h2>
        <h3>{{ account.get('phone') | mask('phone') }}</h3>
        <div v-for="(role, i) in account.get('roles')" :key="i">
            <h3>
                {{ account.roleName(role) }}
                <FaIcon
                    v-if="role.toLowerCase() !== 'dispatcher'"
                    icon="edit"
                    title="Редактировать профиль"
                    class="link link-white pl-15"
                    @click="showProfile(role)"
                />
            </h3>
        </div>
        <div v-if="false === account.access.isCarrier() && account.get('carrier')" class="mt-40">
            <h3> Ваш перевозчик: </h3>
            <h2>
                <router-link class="link link-blue-light" to="/profile/carrier-info">
                    &laquo;{{ account.get('carrier').companyName }}&raquo;
                </router-link>
            </h2>
        </div>
        <LinkCarrier
            v-if="false === account.access.isCarrier()
            && (!account.get('carrier') || !account.get('carrier').linkState === 'IN_PROGRESS')"
            :request-carrier-code="true"
        />
    </div>
</template>

<script>

import LinkCarrier from '@pages/auth/LinkCarrier.vue'

export default {
    name: "ProfileInfo",
    components: {
        LinkCarrier
    },
    data() {
        return {
            account: this.$service.account
        }
    },
    methods: {
        showProfile(role) {
            this.$redirect({name: 'ProfileEdit', params: {role: role.toLowerCase()}})
        }
    }
}

</script>

<style scoped lang="scss">
.profile-info {
    h1, h2, h3 {
        padding: 0 0 15px;
        margin: 0;
        font-family: $font-proxima-bold;
        color: #fff;
    }
}
</style>
