import Repo from '@/api/service/repo'

export default class extends Repo {

    // Привязка пользователя к существующему водителю по подтвержденным реквизитам
    linkExisting() {
        return this.client.post('/driver/linkExisting')
    }

    save(data) {
        return this.client.post('/driver', data)
    }

    edit(data) {
        return this.client.patch('/driver', data)
    }

    // Список ТС зарегистрированных на Водителя в 1С
    transports() {
        return this.client.get('/driver/transports')
    }

}
