export default {

    get(obj, path, def) {
        return path.split('.').reduce((acc, part) => (this.isPlainObject(acc) && acc[part] !== undefined)
                ? acc[part]
                : (def !== undefined ? def : null)
            , obj)
    },

    has(obj, path) {
        let has = path.split('.').reduce((acc, part) => (this.isPlainObject(acc) && acc[part] !== undefined)
                ? acc[part]
                : undefined
            , obj)
        return has !== undefined
    },

    set(obj, path, value) {
        if (!this.isPlainObject(obj)) return
        const arr = path.split('.')
        for (let i = 0; i < arr.length - 1; i++) {
            obj = obj[arr[i]] = obj[arr[i]] !== undefined ? obj[arr[i]] : {}
        }
        obj[arr[arr.length - 1]] = value
    },

    find(arr, path, value, label) {
        const target = arr?.find(v => this.isPlainObject(v) ? this.get(v, path) === value : v === value)
        if (label && this.isPlainObject(target) && typeof label === 'string')
            return this.get(target, label)
        return target
    },

    diff(obj1, obj2) {
        return require('deep-object-diff').diff(obj1, obj2)
    },

    cloneDeep(obj) {
        return this.merge({}, obj)
    },

    jsonStringify(obj) {
        return JSON.stringify(obj, (key, val) => typeof val === 'function' ? val + '' : val)
    },

    assign(source, value) {
        _.isPlainObject(source) || (source = {})
        Object.assign(source, value)
        return source
    },

    merge(target, ...sources) {
        this.isPlainObject(target) || (target = {})
        const merge = require('deepmerge')

        for (let source of sources) {
            source && (target = merge(target, source))
        }

        return target
    },

    uniqBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    },

    uniq(arr) {
        return [...new Set(arr)]
    },

    range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    },

    isBoolean(value) {
        return typeof value === 'boolean'
    },

    isNil(value) {
        return value === null || value === undefined
    },

    isFunction(value) {
        return !this.isNil(value) && value instanceof Function
    },

    isPlainObject(value) {
        return !this.isNil(value) && !Array.isArray(value) && typeof value !== 'function' && value instanceof Object
    },

    isObject(value) {
        const type = typeof value
        return value !== null && (type === 'object' || type === 'function')
    },

    isArray(value) {
        return Array.isArray(value)
    },

    isArrayOfObjects(value) {
        if (!this.isArray(value) || this.isEmpty(value)) return false
        const first = value[0]
        const last = value[value.length - 1]
        return Boolean(this.isPlainObject(first) && this.isPlainObject(last))
    },

    isString(value) {
        return !this.isNil(value) && typeof value === 'string'
    },

    isNumber(value) {
        return !this.isNil(value) && typeof value === 'number' && !isNaN(value)
    },

    isEmpty(value) {
        if (this.isNil(value)) return true
        const type = Array.isArray(value) ? 'array' : this.isPlainObject(value) ? 'object' : typeof value

        switch (type) {
            case 'array': {
                return value.length === 0
            }
            case 'object': {
                return Object.keys(value).length === 0
            }
            case 'string': {
                return value.trim() === ""
            }
            case 'number': {
                return value <= 0
            }
            case 'boolean': {
                return value !== true
            }
        }
        return !value
    },

    md5(value) {
        return require('md5')(value)
    },

    orderBy(arr, key, dir = 'asc') {
        const sort = (arr, k) => arr.sort((a, b) => dir === 'asc' ? (a[k] > b[k] ? 1 : -1) : (a[k] < b[k] ? 1 : -1))
        Array.isArray(key) || (key = [key])
        let output = []
        key.forEach(k => output = sort(arr, k))
        return output
    },

    toArray(value) {
        return Array.isArray(value) ? value : (this.isEmpty(value) && !this.isNumber(value) ? [] : [value])
    },

    includes(text, arr) {
        return arr?.some(v => text.includes(v))
    },

    sortString(arr, string, sortKey) {
        string = string?.toString().toLowerCase() || ''
        sortKey === undefined && (sortKey = null)
        return arr.sort((a, b) => {
            a = (sortKey !== null ? a[sortKey] : a)?.toLowerCase()
            b = (sortKey !== null ? b[sortKey] : b)?.toLowerCase()
            const an = a.indexOf(string)
            const bn = b.indexOf(string)
            if (an === bn) {
                return sortKey !== null
                    ? (a[sortKey] > b[sortKey] ? 1 : (b[sortKey] > a[sortKey] ? -1 : 0))
                    : (a > b ? 1 : b > a ? -1 : 0)
            } else {
                return an > bn ? 1 : -1
            }
        })
    },

    capitalize(text) {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
    },

    toUpper(text) {
        return text?.toUpperCase() || ''
    },

    toLower(text) {
        return text?.toLowerCase() || ''
    },

    trim(str, characters, flags) {

        flags = flags || "g"

        if (!str || !characters || typeof str !== 'string' || typeof characters !== 'string' || typeof flags !== 'string') {
            return str?.toString().trim()
        }

        const escapeRegex = (string) => {
            return string.replace(/[[\](){}?*+^$\\.|-]/g, "\\$&")
        }

        if (!/^[gi]*$/.test(flags)) {
            throw new TypeError("Invalid flags supplied '" + flags.match(new RegExp("[^gi]*")) + "'")
        }

        characters = escapeRegex(characters)

        return str.replace(new RegExp("^[" + characters + "]+|[" + characters + "]+$", flags), '')
    },

    randKey(cut, cutAfterDot) {
        const num = (Math.random() * 100).toString().replace(/\./, '')
        if (cut || cutAfterDot) {
            cut = cut && Number.isInteger(cut) ? cut : 0
            const afterDot = cutAfterDot && Number.isInteger(cutAfterDot) ? '.' + num.substr(cut ? cut : 0, cutAfterDot) : ''
            return (cut ? parseInt(num.substr(0, cut)) : 0) + afterDot
        }
        return num
    },

    toNum(value) {
        return parseInt(value?.toString().replace(/[^\d]+/g, "") || 0)
    },

    formatNumber(x) {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0
    },

    price(amount, def, cur = '₽') {
        return this.num(amount) > 0 ? `${this.formatNumber(this.num(amount, 2))} ${cur}` : def || 0
    },

    parseNumber(x, toNumber) {
        if (x?.toString().match(/^0[.,]0*[\d]+?$/))
            return x.toString() === '0.0' || x.toString() === '0,0' ? (toNumber ? 0.01 : '0.01') : x
        const num = !x?.toString()
            ? 0 : x.toString().replace(/[^\-\d.,]+/g, "").replace(/,,+/g, ',').replace(/\.\.+/g, '.')
        return toNumber ? (!this.isNumber(parseFloat(num)) ? 0 : Number.parseFloat(num)) : num
    },

    num(x, toFixed) {
        if (this.isNumber(toFixed)) {
            const num = this.parseNumber(x, true)
            const split = num.toString().split('.')
            return parseFloat(split[0] + (split[1] ? `.${split[1].substr(0, toFixed)}` : '.00'))
        }
        return this.parseNumber(x, true)
    },

    sum(array, iteratee) {
        this.isFunction(iteratee) || (iteratee = (v) => v)
        const sum = () => {
            let result,
                index = -1

            while (++index < array.length) {
                let current = iteratee(array[index])
                if (current !== undefined) {
                    result = result === undefined ? current : (result + current)
                }
            }
            return result
        }
        return array.length ? sum() : 0
    },

    // Эдакий прокачанный Promise
    // работает так:
    // await promise(() => document.querySelector('#someSelector')).then(elementDomObject => elementDomObject))
    // Проверяет истинность результата "cond(номер попытки)"
    //      interval - интервал проверки (мс)
    //      delay - задержка перед первой проверкой (мс)
    //      maxCount - макс число попыток
    promise(cond, {maxCount: maxCount = 30, interval: interval = 1000, delay: delay = 0}) {
        if (typeof cond !== 'function')
            return false
        let count = 0, i
        return new Promise((resolve, reject) => {
            const handle = () => {
                count += 1
                const check = cond(count)
                if (check || count >= maxCount) {
                    check ? resolve(check) : reject()
                    clearInterval(i)
                }
            }
            setTimeout(() => {
                handle()
                i = setInterval(() => handle(), interval)
            }, delay)
        })
    },

    uriEncode(uri, params, options) {
        _.isPlainObject(params) || (params = {})
        _.isPlainObject(options) || (options = {arrayFormat: 'comma'})
        const queryString = require('query-string').stringify(params, options)
        return uri ? uri + '?' + queryString : queryString
    },

    strtr(str, replacements, useBraces) {
        for (let r in replacements) {
            if (Object.prototype.hasOwnProperty.call(replacements, r)) {
                let rp = useBraces === true ? `{${r}}` : r
                str = str.replace(new RegExp(rp, 'g'), replacements[r])
            }
        }
        return str
    },

    log(data) {
        console.log(data)
    }
}
