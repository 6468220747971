var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[(_vm.semitrailer)?_c('FormSemitrailer',{attrs:{"id":_vm.semitrailer,"truckName":_vm.semitrailerName,"toggle":_vm.toggle,"center":"","contents":""}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.semitrailer),expression:"!semitrailer"}]},[_c('FormTransportSearch',{on:{"search":_vm.reload},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"btn btn-blue btn-small",on:{"click":_vm.openForm}},[_vm._v(" Добавить ")])]),_c('DataTable',{key:_vm.load,staticClass:"mt-20",attrs:{"cols":{
                id: 'ID',
                model: 'Модель',
                number: 'Гос.номер',
                status: 'Статус',
                vin: 'VIN',
                year: 'Год выпуска',
            },"fetch":_vm.fetchData,"paginate":"","click":_vm.toggle}}),_c('Popup',{attrs:{"name":"popup-add-semitrailer"}},[_c('FormSemitrailer',{attrs:{"toggle":_vm.toggle,"inflect":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }