var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[(!_vm.dispatcher && !_vm.assign && !_vm.showPermissions)?[_c('FormStaffSearch',{on:{"search":_vm.reload},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('Buttons',[_c('button',{staticClass:"btn btn-blue btn-small mb-20",on:{"click":function($event){_vm.assign = true}}},[_vm._v(" Добавить ")])])]:_vm._e(),(_vm.showPermissions && _vm.dispatcher)?_c('FormStaffPermissions',{key:_vm.dispatcher.id,attrs:{"data":_vm.dispatcher,"role":"dispatcher"},on:{"toggle":_vm.toggle}}):(_vm.dispatcher)?_c('FormStaffDispatcher',{key:_vm.dispatcher.id,attrs:{"data":_vm.dispatcher},on:{"add-user":_vm.add,"unlink-user":_vm.unlink,"remove":_vm.remove,"permissions":function () { return this$1.showPermissions = true; },"toggle":_vm.toggle}}):(_vm.assign)?_c('FormStaffLinkConfirm',{attrs:{"linkType":"dispatcher"},on:{"toggle":_vm.toggle}}):_vm._e(),_c('DataTable',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dispatcher && !_vm.assign && !_vm.showPermissions),expression:"!dispatcher && !assign && !showPermissions"}],key:_vm.load,attrs:{"cols":{
            id: 'ID',
            linkIcon: '',
            name: 'ФИО',
            phone: 'Номер телефона',
        },"cells":{
            linkIcon: function (v, item) { return !item.linkToCarrierRequired ? null : [
                {'div.user-link-icon': '', bind: {style: {width: '30px', height: '30px'}}}
            ]; },
            name: function (v, item) { return _vm.$filter.fullName(item); },
            phone: function (v) { return _vm.$filter.mask(v, 'phone'); }
        },"fetch":_vm.fetchData,"click":function (dispatcher) { return dispatcher.id === _vm.currentDispatcherId || _vm.setForm(dispatcher.id); }},on:{"setup":function (i) { return this$1.list = i; }}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }