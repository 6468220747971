export default {
    model: {
        'id': null,
        'dateTime': null,
        'amount': 0,
        'comment': '',
        'fileId': null,
    },
    fields: {
        'dateTime': {
            name: 'Дата',
            type: 'date',
            current: true,
            watch: ({value, field, vm}) => {
                return value === vm.$util.date.dateFormat(field.initial, 'YYYY-MM-DD')
                    ? field.initial
                    : vm.$util.date.dateFormat(value, 'YYYY-MM-DD')
            }
        },
        'amount': {
            name: 'Сумма расхода, руб.',
            type: 'number',
            rules: ['required', ['min', 0.01]],
            format: v => _.isNumber(parseFloat(v)) ? parseFloat(v) : v
        },
        'comment': {
            name: 'Комментарий',
            type: 'textarea',
            required: false,
        },
        'fileId': {
            name: 'Прикрепить изображение',
            type: 'img',
            required: false,
        }
    }
}
