<template>
    <div class="form-input-date" :class="box && '--box-shadow'">
        <input
            :type="getType()"
            class="form-input"
            v-model="formattedDate"
            v-bind="getAttributes()"
            @click="show"
            @keyup="handle(formattedDate)"
            @change="handle(formattedDate)"
            :disabled="isDisabled"
        />
        <Dropdown v-if="!isDisabled" :name="dropdownName">
            <div class="form-input-date-select">
                <div v-if="!time" class="--date">
                    <div class="form-input-wrap">
                        <select
                            title="Число месяца"
                            v-if="numberRange"
                            :key="numKey"
                            class="--day"
                            v-model="number" @change="handle()"
                        >
                            <option>Число</option>
                            <option v-for="n in numberRange" :key="n" :value="n">{{ n }}</option>
                        </select>
                    </div>
                    <div class="form-input-wrap">
                        <select title="Месяц" class="--month" v-model="month" @change="handle()">
                            <option>Месяц</option>
                            <option value="01">Январь</option>
                            <option value="02">Февраль</option>
                            <option value="03">Март</option>
                            <option value="04">Апрель</option>
                            <option value="05">Май</option>
                            <option value="06">Июнь</option>
                            <option value="07">Июль</option>
                            <option value="08">Август</option>
                            <option value="09">Сентябрь</option>
                            <option value="10">Октябрь</option>
                            <option value="11">Ноябрь</option>
                            <option value="12">Декабрь</option>
                        </select>
                    </div>
                    <div class="form-input-wrap">
                        <input
                            type="number"
                            title="Год"
                            ref="year"
                            class="--year"
                            v-model="year"
                            v-mask="'####'"
                            @keyup="setYear"
                            @change="setYear"
                            @click="setYear"
                        >
                    </div>
                </div>
                <div v-if="withTime || time" class="--time">
                    <div v-if="timeIcon" class="--clock">
                        <FaIcon icon="clock" title="Время"/>
                    </div>
                    <div class="form-input-wrap">
                        <input
                            type="number"
                            title="Часы"
                            min="0"
                            max="24"
                            v-model="hours"
                            ref="hours"
                            @keyup="checkTime"
                            @change="handle()"
                        >
                    </div>
                    <div class="form-input-wrap">
                        <input
                            type="number"
                            title="Минуты"
                            min="0"
                            max="60"
                            v-model="minutes"
                            ref="minutes"
                            @keyup="checkTime"
                            @change="handle()"
                        >
                    </div>
                    <div v-if="sec" class="form-input-wrap">
                        <input
                            type="number"
                            title="Секунды"
                            min="0"
                            max="60"
                            v-model="seconds"
                            ref="seconds"
                            @keyup="checkTime"
                            @change="handle()"
                        >
                    </div>
                </div>
                <div v-if="clear" class="--reset">
                    <button @click="dateClear" class="btn btn-blue btn-small">Сбросить</button>
                </div>
            </div>
        </Dropdown>
    </div>
</template>

<script>

export default {
    name: "FormDate",
    props: {
        value: [String, Date],
        index: Number,
        rules: Array,
        bind: Object,
        format: String,
        clearOnLoad: Boolean,
        sec: Boolean,
        secToDate: Boolean,
        time: Boolean,
        withTime: Boolean,
        fromCurrent: Boolean,
        beforeCurrent: Boolean,
        current: Boolean,
        type: {
            type: [Boolean, String],
            default: true,
        },
        clear: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            default: "",
        },
        timeIcon: {
            type: Boolean,
            default: true,
        },
        currentYearOnClick: {
            type: Boolean,
            default: true,
        },
        watch: Function,
        box: Boolean,
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    data() {
        return {
            numKey: 0,
            model: this.value,
            formattedDate: null,
            validator: null,
            dropdownName: 'date-select-' + this.$uuid.v1(),
            numberRange: this.time ? null : _.range(1, 31).map(v => v < 10 ? '0' + v : v),
            number: null,
            month: null,
            year: null,
            hours: null,
            minutes: null,
            seconds: null,
            date: this.$util.date
        }
    },
    computed: {
        getHours() {
            return !_.isNumber(parseInt(this.hours)) ? `00` : (parseInt(this.hours) < 10 ? `0${parseInt(this.hours)}` : this.hours)
        },
        getMin() {
            return !_.isNumber(parseInt(this.minutes)) ? `00` : (parseInt(this.minutes) < 10 ? `0${parseInt(this.minutes)}` : this.minutes)
        },
        getSec() {
            return !_.isNumber(parseInt(this.seconds)) ? `00` : (parseInt(this.seconds) < 10 ? `0${parseInt(this.seconds)}` : this.seconds)
        },
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    created() {

        if (this.current && (!this.model || !this.date.isValidDate(this.model)))

            this.model = this.date.curDate('YYYY-MM-DDTHH:mm:ss')

        this.formattedDate = this.getFormattedDate(this.model)

        this.setRules()

        this.parseDate(this.model)

        this.input()
    },
    mounted() {
        this.handle()
        this.clearOnLoad && this.dateClear()
        this.isDisabled || (this.validator && this.validator.validate(this.model))
    },
    methods: {
        input() {

            let val = this.model

            this.formattedDate = this.getFormattedDate(val)

            this.format && this.date.isValidDate(val) && (val = this.date.dateFormat(val, this.format))

            if (this.isDisabled) {
                this.$emit('input', val)
                return
            }
            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(val))
            }
            if (this.watch) {
                const value = this.watch(val, this)
                value !== undefined && (val = value)
            }

            this.$emit('input', val)
        },
        getAttributes() {
            return _.isPlainObject(this.bind) ? Object.assign(this.bind, this.$attrs || {}) : this.$attrs || {}
        },
        show() {
            if (this.isDisabled) return
            this.$dropdown(this.dropdownName, {
                el: this.$el,
                fullscreen: false,
                style: () => {
                    let h = this.time || this.withTime ? 100 : 50
                    this.clear && (h += 50)
                    return {
                        maxHeight: h + 'px',
                        minHeight: h + 'px',
                    }
                }
            })
        },
        getType() {

            if (!this.type) return ''

            let type = typeof this.type === 'string' ? this.type : 'date'

            if (this.time || type === 'time') return 'time'
            if (this.withTime || type.includes('datetime')) return 'datetime-local'

            return 'date'
        },
        getFormattedDate(date) {

            let type = this.getType()
            let format = this.time ? '' : ['datetime-local', 'date'].includes(type) ? 'YYYY-MM-DD' : 'DD.MM.YYYY'
            let formatTime = this.time ? 'HH:mm' : this.withTime ? ' HH:mm' : ''

            type === 'datetime-local' && (formatTime = 'THH:mm')

            this.sec && (formatTime += ':ss')

            return this.time ? date : this.date.dateFormat(date, format + formatTime)
        },
        parseDate(model) {

            const date = this.secToDate ? this.date.dateDiff(model * 1000) : model

            if (!this.time) {
                this.number = model ? (this.secToDate ? date.days : this.date.dateFormat(date, 'DD')) : 0
                this.month = model ? (this.secToDate ? date.month : this.date.dateFormat(date, 'MM')) : 0
                this.year = model ? (this.secToDate ? date.years : this.date.dateFormat(this.date.curDate(), 'YYYY')) : ""
            }

            if (this.secToDate || this.withTime || this.time) {
                this.hours = model ? (this.secToDate ? date.hours : this.date.dateFormat(date, 'HH')) : 0
                this.minutes = model ? (this.secToDate ? date.minutes : this.date.dateFormat(date, 'mm')) : 0
                this.seconds = model ? (this.secToDate ? date.seconds : this.date.dateFormat(date, 'ss')) : 0
            }
        },
        handle(formattedDate) {

            formattedDate && this.parseDate(formattedDate)

            parseInt(this.hours) <= 23 || (this.hours = 0)
            parseInt(this.minutes) <= 59 || (this.minutes = 0)
            parseInt(this.seconds) <= 59 || (this.seconds = 0)

            if (this.time) {

                if (formattedDate !== undefined) {
                    let parse = formattedDate.split(':')
                    this.hours = parse[0]
                    this.minutes = parse[1]
                    this.seconds = parse[2] || 0
                }

                this.model = `${this.getHours}:${this.getMin}`
                this.sec && (this.model += `:${this.getSec}`)

                this.input()

            } else {

                const date = this.isValidYear(this.year) && this.month && this.number

                    ? `${this.year}-${this.month}-${this.number}` : null

                if (date && this.date.isValidDate(date) === false) {
                    this.number -= 1
                    this.numKey += 1
                    return this.handle()
                }

                this.model = date

                if (this.withTime) this.model += ` ${this.getHours}:${this.getMin}:${this.getSec}`

                this.model = _.trim(this.model)
                this.model && this.date.isValidDate(this.model) || (this.model = "")

                this.input()
            }
        },
        checkTime() {

            const hours = this.$refs.hours
            const minutes = this.$refs.minutes
            const sec = this.$refs.seconds
            const format = v => v.toString().replace(/[^\d]+/g, '').replace(/^(0)?(.*?)$/, '$2').substr(0, 2)

            if (hours && minutes) {
                this.getHours.toString() && (this.hours = parseInt(format(this.getHours)))
                this.getMin.toString() && (this.minutes = parseInt(format(this.getMin)))
            }

            sec && this.getSec.toString() && (this.seconds = parseInt(format(this.getSec)))

            this.handle()

            this.$nextTick(() => {
                if (hours && minutes) {
                    hours.value = this.hours
                    minutes.value = this.minutes
                }
                sec && (sec.value = this.seconds)
            })
        },
        setYear() {

            let value = this.$refs.year.value

            value || this.currentYearOnClick && (value = this.date.curDate('YYYY'))

            if (!this.isValidYear(value)) return false

            this.year = parseInt(value.toString().replace(/[^\d]+/g, '').substr(0, 4))

            this.handle()
        },
        isValidYear(year) {
            return !(!year || year.toString().length < 4)
        },
        dateClear() {
            this.number = this.month = this.hours = this.minutes = this.seconds = 0
            this.year = this.model = ""
            this.input()
        },
        setRules() {

            let rules = this.rules || []

            this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')

            this.required === false && (rules = rules.filter(r => r !== 'required'))

            if (this.fromCurrent || this.beforeCurrent) {

                rules.push(value => {
                    const date = this.time ? '' : `[\\d]{4}-[\\d]{2}-[\\d]{2}`
                    const time = this.withTime || this.time ? '(T|\\s)?[\\d]{2}:[\\d]{2}(:[\\d]{2}(\\.[\\d]+)?)?' : ''

                    if (value && value.toString().match(new RegExp(`^${date}${time}$`, 'g'))) {
                        const current = this.date.toUtc(this.date.curDate())
                        if (this.fromCurrent && this.date.toUtc(value) <= current) {
                            return `Укажите дату больше текущей даты.`
                        } else if (this.beforeCurrent && this.date.toUtc(value) >= current) {
                            return `Укажите дату меньше текущей даты.`
                        }
                    }
                })
            }

            rules.length && (this.validator = new this.$service.validation({rules, name: this.name}))

        }
    }
}

</script>

<style lang="scss" scoped>
.form-input-date {
    position: relative;

    &.--box-shadow {
        box-shadow: $box-shadow-thin;
        border-radius: 4px;
    }

    &-select {
        > div {
            display: flex;
            justify-content: center;
            flex-direction: row;
            padding: 5px;

            .form-input-wrap {
                margin: 5px !important;

                select, input {
                    padding: 5px;
                }

                .--day {
                    min-width: 50px;
                }

                .--month {
                    min-width: 100px;
                }

                .--year {
                    min-width: 60px;
                }
            }

            .--clock {
                padding: 12px 5px 0 0;
                color: $gray-e7;
            }
        }
    }
}
</style>
