<template>
    <Page v-if="canAssign !== null">
        <FormTransportSearch v-model="search" @search="reload"/>
        <DataTable
            :key="load"
            :cols="{
                id: 'ID',
                driver: 'Водитель',
                truck: 'Тягач',
                semitrailer: 'Полуприцеп'
            }"
            :cells="{
                driver: (v) => `${$filter.fullName(v)} (${v.status})`,
                truck: v => `${v.model} - ${v.number}`,
                semitrailer: v => `${v.model} - ${v.number}`,
            }"
            :fetch="fetchData"
            paginate
            :click="toggle"
            class="mt-20"
        />
        <Popup v-if="canAssign" name="popup-add-assign">
            <FormAssign :assign="assign" :toggle="toggle" inflect/>
        </Popup>
    </Page>
</template>

<script>

import FormAssign from './form/FormAssign.vue'
import FormTransportSearch from './form/FormTransportSearch.vue'

export default {
    name: "Assigns",
    components: {
        FormAssign,
        FormTransportSearch
    },
    data() {
        return {
            load: 0,
            assign: null,
            search: {data: ''},
            assignName: '',
            canAssign: null
        }
    },
    created() {
        this.$service.account.hasRule('CAN_ASSIGN_DRIVER').then(can => this.canAssign = can)
    },
    methods: {
        reload() {
            this.load += 1
        },
        fetchData(h, a) {
            this.$repo('transport').assigns({search: this.search.data, ...a}).then(({list}) => h(list))
        },
        open() {
            if (!this.canAssign) {
                this.$notify().warn('У Вас нет прав доступа на редактирование транспорта контрагента.')
                return
            }
            this.$popup('popup-add-assign', {
                clickToClose: false,
            })
        },
        toggle(item) {
            this.assign = item || null
            if (this.assign) {
                this.open()
            } else {
                this.$popupClose()
                this.reload()
            }
        }
    }
}

</script>
