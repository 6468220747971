import ApiHandler from "../service/handler"
import helper from '@/helpers'
import {getTitle, getMessage} from '../service/request'

export default class extends ApiHandler {

    /*
    * method executes within a Promise.prototype.catch()
    * @params: error
    * @return: void
    * */
    onCatch(error) {
        helper.$notify().alert(`<b>Ошибка приложения или сервера</b><p>${error.message}</p>`)
    }

    /*
    * method executes at the start of a Promise.prototype.then()
    * @params: response
    * @return: void
    * */
    after(response) {
        if (response.data.result === 'UNDER_CONSTRUCTIONS') {
            this._data.alert = false
            helper.$ok({
                title: 'Сервер не доступен',
                text: getMessage(response)
            })
        }
    }

    /*
    * method executes at the start of a Promise.prototype.catch()
    * @params: error
    * @return: void
    * */
    afterCatch(error) {
        console.error(error)
    }

    /*
    * method executes at the start of a Promise.prototype.finally()
    * @params: request data
    * @return: void
    * */
    afterFinally() {

        if (false === _.isPlainObject(this._data.response?.data)) return

        const done = this._data.done
        const alert = this._data.alert
        const code = this._data.response.status

        if (this._data.isSuccess && done && typeof done === 'string') {

            helper.$notify().info(done)

        } else if (this._data.isError && alert !== false) {

            const showMsg = helper.$app().isLocalhost() || (code && code < 500)
            const title = getTitle(this._data.response) || 'Ошибка'
            const notify = typeof alert === 'string' ? alert : ''

            let msg = showMsg ? getMessage(this._data.response) : ''
            msg = !notify ? msg : `${notify} ${msg ? `(${msg})` : ''}`

            helper.$notify().alert(`<b>${title}</b><p>${msg}</p>`)
        }
    }

}
