<template>
    <Page style="padding-left: 5%">
        <h2 class="section-page-title pl-0">Настройки</h2>
        <div class="flex-col mt-40">
            <p>
                <a class="link" @click="changePasswordForm(true)">Изменить пароль</a>
            </p>
            <p>
                <a class="link" @click="listPushSettings">PUSH-уведомления</a>
            </p>
            <p>
                <a @click="$service.file.getAndDownload({
                        repo: 'docs',
                        repoMethod: 'getAgreement',
                        params: null,
                        name: 'Пользовательское соглашение',
                        errMsg: 'Не удалось загрузить Пользовательское соглашение'
                    })" class="link">
                    Пользовательское соглашение
                </a>
            </p>
            <p>
                <a class="link" @click="about">О веб-клиенте</a>
            </p>
            <p>
                <a class="link" @click="aboutDev">О разработчике</a>
            </p>
            <p>
                <a class="link" @click="feedback">Оставить отзыв</a>
            </p>
        </div>
        <Popup name="popup-change-password" @closed="() => this.passwordToken = null">
            <FormCodeConfirm
                v-if="passwordToken"
                requestCodeTitle="На Ваш номер телефона отправлен SMS с кодом подтверждения пароля"
                type="phone"
                :dest="$service.account.get('phone')"
                :endpoint="$config.api().codeConfirm.passwordConfirm"
                :request-code-endpoint="$config.api().codeConfirm.resendCode"
                :token="passwordToken"
                :counter="passwordResendCodeAfterCount"
                @confirmed="() => {
                    changePasswordForm()
                    $notify().info('Пароль успешно изменен.')
                }"
                :useApiKey="false"
                :confirmCancelBtn="false"
                :requestCancelBtn="false"
                class="block-wide block-center"
            />
            <template v-else>
                <h2 class="section-page-title tx-c mb-20">Изменение пароля</h2>
                <DataForm
                    v-model="data.password"
                    required
                    :form="{
                        bind: {buttons: 'Сохранить', center: true, wide: true},
                        on: {submit: changePassword}
                    }"
                    :model="{
                        model: data.password,
                        fields: {
                            oldPassword: {
                                name: 'Старый пароль',
                                type: 'password',
                                autocomplete: 'new-password',
                                wrap: {bind: {type: 'show-password'}}
                            },
                            newPassword: {
                                name: 'Новый пароль',
                                type: 'password',
                                autocomplete: 'new-password',
                                wrap: {bind: {type: 'show-password'}},
                                rules: [{
                                    rule: ['regex', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{4,}$/],
                                    message: `Пароль должен состоять минимум из 4-х символов и содержать цифры и буквы латинского алфавита в верхнем и нижнем регистре.`
                                }]
                            },
                        }
                    }"
                />
            </template>
        </Popup>

        <Popup name="popup-profile-settings">
            <div v-if="type === 'listPushSettings'" class="block-center block-wide">
                <h3 class="tx-bold tx-c my-40">Настройки PUSH-уведомлений</h3>
                <div v-if="notifyPushSettings" class="flex flex-col-20">
                    <template v-for="(item, i) in notifyPushSettings">
                        <div class="flex w-100" v-if="notifyPushSettings" :key="i">
                            <div style="width: 80%">
                                {{ item.name in data.push_settings ? data.push_settings[item.name] : item.name }}
                            </div>
                            <div>
                                <Toggle :value="item.value" @change="({srcEvent}) => setPushSettings(srcEvent, i)"/>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else-if="type === 'about'" class="block-contents block-inflect tx-c">
                <h2 class="section-page-title tx-c">ТТ Онлайн</h2>
                <p>
                    Веб-клиент (<b>версия {{ $app().version }}</b>) предназначен для предоставления
                    пользователям возможности
                    получения доступа к информации Заказчика при помощи веб-браузера,
                    отправки и получения информации, используя особенности веб-браузера.
                </p>
                <p>
                    СВЯЖИТЕСЬ С НАМИ:
                </p>
                <p><a class="link" href="mailto:devmapp@tkrosprom.com">DEVMAPP@TKROSPROM.COM</a></p>
            </div>
            <div v-else-if="type === 'aboutDev'" class="block-contents block-inflect tx-c">
                <h2 class="section-page-title tx-c">ТТ Онлайн</h2>
                <p>
                    Все права на разработку веб-клиента принадлежат Компании ООО &laquo;Транспортные технологии&raquo;.
                </p>
            </div>
            <div v-else-if="type === 'feedback'" class="block-center block-wide pb-20">
                <h2 class="section-page-title tx-c">Ваш отзыв</h2>
                <DataForm
                    v-model="data.feedback"
                    :form="{
                        bind: {buttons: 'Отправить'},
                        on: {submit: submitFeedback}
                    }"
                    :model="{
                        model: data.feedback,
                        fields: {
                            name: {name: 'Имя', disabled: true},
                            surname: {name: 'Фамилия', disabled: true},
                            patronymic: {name: 'Отчество', disabled: true},
                            phone: {name: 'Телефон', mask: 'phone', disabled: true},
                            email: {name: 'Эл. почта'},
                            text: {name: 'Текст сообщения', type: 'textarea', required: true},
                        }
                    }"
                />
            </div>
        </Popup>
    </Page>
</template>

<script>

import PushSettings from '@/config/pushes'
import FormCodeConfirm from '@pages/auth/form/FormCodeConfirm.vue'

export default {
    name: 'ProfileSettings',
    components: {FormCodeConfirm},
    data() {
        const profile = this.$service.account.get('profile')
        return {
            data: {
                push_settings: _.cloneDeep(PushSettings).names,
                password: {
                    oldPassword: null,
                    newPassword: null,
                },
                feedback: {
                    name: profile.name,
                    surname: profile.surname,
                    patronymic: profile.patronymic,
                    phone: profile.phone,
                    email: profile.email,
                    text: null,
                },
            },
            passwordToken: null,
            passwordResendCodeAfterCount: 30,
            type: null,
            notifyPushSettings: null
        }
    },
    methods: {
        settingsOpen() {
            this.$popup('popup-profile-settings', {})
        },
        listPushSettings() {
            this.type = 'listPushSettings'
            let exclude = PushSettings.exclude

            this.$repo('notify').getPushSettings().then(({list}) => {
                this.$set(this, 'notifyPushSettings', list.filter(v => !exclude.includes(v.name)))
                this.$service.account.access.isDispatcher() && (exclude = Array.prototype.concat(exclude, PushSettings.dispatcherExclude))
                this.settingsOpen()
            })
        },
        setPushSettings(e, key) {
            const checked = e.target.checked
            this.notifyPushSettings[key].value = checked

            this.$repo('notify').setPushSettings(this.notifyPushSettings)
                .error(() => e.target.checked = !checked)
                .catch(() => e.target.checked = !checked)
                .alert('Не удалось изменить настройки.')
        },
        about() {
            this.type = 'about'
            this.settingsOpen()
        },
        aboutDev() {
            this.type = 'aboutDev'
            this.settingsOpen()
        },
        feedback() {
            this.type = 'feedback'
            this.settingsOpen()
        },
        submitFeedback() {

            const profile = this.$service.account.get('profile')

            this.data.feedback = _.merge(this.data.feedback, {
                surname: profile.surname,
                name: profile.name,
                patronymic: profile.patronymic,
                phone: _.toNum(profile.phone),
            })

            this.$repo('settings').sendFeedback(this.data.feedback).then(() => {
                this.$set(this.data.feedback, 'text', null)
                this.$popupClose()
            }).done('Сообщение отправлено.').alert('Не удалось отправить сообщение.')
        },
        changePasswordForm(open) {
            this.$set(this, 'passwordToken', null)
            open ? this.$popup('popup-change-password', {}) : this.$popupClose()
        },
        changePassword() {
            this.$repo('auth').passwordChange(this.data.password).then((r) => {
                this.$set(this, 'passwordToken', r.data.token)
                this.$set(this, 'passwordResendCodeAfterCount', r.data.resendCodeAfter || 30)
            }).alert('Не удалось проверить пароль.')
        }
    }
}

</script>
