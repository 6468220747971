<template>
    <Page>
        <FormOrder
            v-if="order"
            :id="order"
            @toggle="order = null; $redirect({name: $route.name}, true)"
        />
        <div v-if="getMode()" v-show="!order">
            <h1 class="section-page-title">
                Поиск
            </h1>
            <FormOrderSearch v-model="searchData" @search="reload()"/>
            <DataTable
                :key="load"
                :cols="{
                    number: 'Номер',
                    lastStatusName: 'Статус заявки',
                    driverSNP: 'Водитель',
                    truckSemitrailerNumber: 'Номер ТС',
                    // affordableBidAmount: 'Д/c для пополнения Топливных карт',
                    'route.loading': 'Загрузка',
                    'route.unloading': 'Разгрузка',
                }"
                :cell-bind="{
                    lastStatusName: {class: 'clr-green'},
                }"
                :cells="{
                    number: (v, item) => [
                        {a: `№${v}`},
                        {'div.clr-gold.tx-inline': unConfirmed(item) ? `(Не подтвержден)` : null},
                        {'div.clr-red.tx-inline': isCancelled(item) ? `(Отменен)` : null},
                    ],
                    // affordableBidAmount: v => ({'div.clr-green.tx-inline':$$.price(v, '-')}),
                    truckSemitrailerNumber: v => {
                        const split = v.toString().split(',');
                        return [{'div.tx-inline.clr-ltg-3': split[0]}, {'div.tx-inline.clr-ltg-3': split[1] || null}]
                    },
                    'route.loading': v => v && [
                        {'div': `${v.region}, ${v.city.name}`},
                        {'small.clr-ltg-2.tx-lh-3': v.point},
                        {'div.clr-ltg-3': `Дата погрузки: ${$util.date.arrivingDate(v)}`},
                    ],
                    'route.unloading': v => v && [
                        {'div': `${v.region}, ${v.city.name}`},
                        {'small.clr-ltg-2.tx-lh-3': v.point},
                        {'div.clr-ltg-3': `Дата разгрузки: ${$util.date.arrivingDate(v)}`},
                    ]
                }"
                :adaptive="{cols: 7}"
                :fetch="fetchData"
                :paginate="true"
                :assign="getAssign"
                :click="order => setOrder(order.id)"
                @setup="table => this.dataTable = table"
            />
        </div>
    </Page>
</template>

<script>

import FormOrderSearch from './form/FormOrderSearch.vue'
import FormOrder from './form/FormOrder.vue'

export default {
    name: "Rft",
    props: {
        id: Number,
        mode: String
    },
    data() {
        return {
            order: null,
            load: 0,
            dataTable: null,
            transportTypes: null,
            searchData: {
                search: null,
                orderStatuses: null
            },
        }
    },
    components: {
        FormOrderSearch,
        FormOrder
    },
    created() {
        _.isNumber(this.id) && this.setOrder(this.id)

        if (this.getMode()) {
            this.$repo('transport').transportTypes().then(({list}) => {
                this.transportTypes = list ? [...[{
                    id: 0,
                    name: 'Все типы транспорта'
                }], ...list] : []
            })
        }
    },
    methods: {
        setOrder(id) {
            this.$set(this, 'order', id)
            this.$redirect({name: this.$route.name, query: {id}})
        },
        getMode() {
            return (['active', 'archive']).includes(this.mode) ? this.mode : null
        },
        confirmed(id) {
            this.dataTable && this.dataTable.setItem({id}, { orderConfirmation: 'CONFIRMED' })
            this.order = null
        },
        reload() {
            this.load += 1
        },
        fetchData(h, a) {
            this.$repo('rft').driverRft(a).then(({list}) => h(list))
        },
        getAssign() {
            return Object.assign({mode: this.getMode() === 'archive' ? 'ARCHIVED' : 'ACTIVE'}, this.searchData || {})
        },
        unConfirmed(item) {
            return item.orderConfirmation === 'UNCONFIRMED'
        },
        isCancelled(item) {
            return item.orderConfirmation === 'CANCELLED'
        }
    }
}

</script>
