<template>
    <Page>
        <FormRouteSheet
            v-if="sheet"
            :id="sheet"
            @saved="load"
            @toggle="sheet = orderNumber = null; $redirect({name: 'RouteSheets'})"
            :orderNumber="orderNumber"
        />
        <div v-show="!sheet">
            <h1 class="section-page-title">
                Поиск
            </h1>
            <FormRouteSheetSearch v-model="searchData"/>
            <DataTable
                v-if="key"
                :key="key"
                :cols="cols"
                :pageStart="pageStart"
                :cells="{
                    orderNumber: v => ({a: v}),
                    _danger: (v, item) => ({
                        'i.fa.fa-exclamation-triangle.clr-red': item.expired === true,
                        bind: {style: {fontSize: '1.2em'}}
                    }),
                    truckSemitrailerNumber: v => {
                        const split = v.toString().split(',');
                        return [{'div.tx-inline': split[0]}, {'div.tx-inline': split[1] || null}]
                    },
                    startRoute: v => [
                        {'p.tx-inline.tx-bold': $$.find(cities, 'id', v.city.id, 'name')},
                        {
                            div: [
                                {'small.clr-ltg-3': 'Начало рейса: '},
                                {small: $util.date.date(v.dateTime, true) || '(не определено)'},
                            ]
                        },
                    ],
                    endRoute: v => [
                        {'p.tx-inline.tx-bold': $$.find(cities, 'id', v.city.id, 'name')},
                        {
                            div: [
                                {'small.clr-ltg-3': 'Конец рейса: '},
                                {small: $util.date.date(v.dateTime, true) || '(не определено)'},
                            ]
                        },
                    ],
                }"
                :click="item => setSheet(item.id, item.orderNumber)"
                :fetch="fetch"
                :paginate="true"
            />
        </div>
    </Page>
</template>

<script>

import FormRouteSheet from "./form/FormRouteSheet.vue"
import FormRouteSheetSearch from "./form/FormRouteSheetSearch.vue"

export default {
    name: "RouteSheets",
    props: {
        id: Number,
    },
    components: {
        FormRouteSheetSearch,
        FormRouteSheet
    },
    data() {
        return {
            key: 0,
            sheet: null,
            orderNumber: null,
            cities: [],
            cols: {
                orderNumber: 'Номер заказа',
                _danger: '',
                startRoute: 'Начало маршрута',
                endRoute: 'Конец маршрута',
                truckSemitrailerNumber: 'Номер транспортного средства',
                driverSNP: this.$service.account.access.isAdmin() ? 'Водитель' : false,
            },
            searchData: {
                search: "",
                empty: true
            },
            pageStart: 1,
            pageStartLoaded: false,
        }
    },
    watch: {
        searchData: {
            handler() {
                this.load()
            },
            deep: true
        }
    },
    created() {
        _.isNumber(+this.$route.params.page) && (this.pageStart = +this.$route.params.page)
        if (!_.isNumber(this.pageStart) || this.pageStart <= 0) this.pageStart = 1
        if (_.isNumber(this.id) && !this.$route.query.id) {
            this.$redirect({name: 'RouteSheets', query: {id: this.id}, params: {page: this.pageStart}})
        } else {
            _.isNumber(this.id) && this.setSheet(this.id)
            this.getCities()
        }
    },
    methods: {
        load() {
            this.key += 1
        },
        setSheet(id, orderNumber) {
            this.sheet = parseInt(id)
            orderNumber && (this.orderNumber = orderNumber)
            parseInt(this.$route.query.id) === this.sheet || this.$redirect({name: 'RouteSheets', query: {id}})
        },
        fetch(h, a) {
            this.$repo('enterprise').routeSheets({...this.searchData, ...a}).then(({list}) => {
                if (!this.pageStartLoaded && list.length === 0 && this.pageStart > 1) {
                    this.pageStart = 1
                    this.load()
                    return
                }
                h(list)
                this.pageStartLoaded = true
            })
        },
        getCities() {
            this.$repo('common').cities().finally(({list}) => {
                this.cities = list || []
                this.load()
            })
        }
    }
}
</script>
