<template>
    <Page>
        <h2 class="section-page-title tx-c pl-0 mb-40">Редактирование профиля водителя</h2>
        <DataForm
            :key="load"
            v-if="form"
            :model="form"
            v-model="form.model"
            required
            :form="{
                bind: {
                    buttons: ['Сохранить изменения'],
                    buttonCancel: true,
                    onCancel: () => $redirect('/'),
                    wide: true,
                    center: true
                },
                on: {submit}
            }"
        />
    </Page>
</template>

<script>

export default {
    name: "FormProfileDriver",
    data() {
        return {
            form: null,
            load: 0
        }
    },
    created() {
        this.getForm()
    },
    methods: {
        getForm() {
            this.$service.account.fetchProfile('driver').then(({object}) => {
                this.form = _.merge(this.$apiModel.driver, { model: object })
                this.form.form = _.merge(this.form.form, {
                    name: {model: object.name, disabled: true},
                    surname: {model: object.surname, disabled: true},
                    patronymic: {model: object.patronymic, disabled: true},
                    phone: {model: object.phone, disabled: true},
                })
                this.load += 1
            })
        },
        submit() {
            this.$repo('driver').edit(this.form.model).then(this.getForm)
                .alert('Не удалось обновить профиль.')
                .done('Профиль изменен.')
        }
    },
}

</script>
