<template>
    <div class="dropdown">
        <slot name="header"/>
        <Popup :name="name">
            <div v-html="text"></div>
            <slot/>
        </Popup>
    </div>
</template>

<script>

const PARAMS = {
    el: null,
    text: '',
    onClose: null,
    onOpen: null,
    clickToClose: true,
    animate: true,
    fs: null,
    showOverlay: true,
    style: {
        height: 'auto',
        width: 'auto',
        maxWidth: '250px',
        minWidth: '200px',
    }
}

export default {
    name: 'Dropdown',
    props: {
        name: {
            type: String,
            required: true,
        }
    },
    data: () => ({
        ...PARAMS
    }),
    created() {
        this.$registerComponent('dropdowns', this.name)
    },
    mounted() {
        window.addEventListener('scroll', this.listener)
        window.addEventListener('resize', this.listener)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.listener)
        window.removeEventListener('resize', this.listener)
    },
    methods: {
        listener() {
            const popup = this.$popup(this.name)
            if (popup?.isOpen) {
                this.setStyle()
                popup.normalize()
            }
        },
        show(data) {
            this.resetData()
            this.setData(data)
            this.$nextTick(() => {
                this.$popup(this.name, {
                    style: this.style,
                    onOpen: (modal) => {
                        this.setStyle()
                        this.$popup(this.name).setStyles(this.style)
                        this.$popup(this.name).normalize()
                        $(this.$el).find(`[data-modal="${this.name}"]`).appendTo('body')
                        this.onOpen && this.onOpen(modal)
                    },
                    onClose: (modal) => {
                        this.onClose && this.onClose(modal)
                        $(document).find(`[data-modal="${this.name}"]`).remove()
                    },
                    clickToClose: this.clickToClose,
                    fullscreen: this.fullscreen,
                    showOverlay: this.showOverlay,
                    animate: this.animate,
                    header: false,
                    footer: false,
                })
            })
        },
        setData(
            {
                el,
                style,
                clickToClose: clickToClose = true,
                fullscreen,
                animate: animate = true,
                text,
                onClose,
                onOpen,
                showOverlay: showOverlay = true,
            }
        ) {
            this.onClose = onClose
            this.onOpen = onOpen
            this.clickToClose = clickToClose
            this.showOverlay = showOverlay
            this.fullscreen = fullscreen
            this.animate = animate
            this.el = el
            text && this.setText(text)
            this.setStyle(style)
        },
        resetData() {
            Object.assign(this, {...PARAMS})
        },
        setText(text) {
            this.text = text
        },
        setStyle(style) {
            const el = this.el || this.$el

            let {left, top} =  el.getBoundingClientRect()
            _.isFunction(style) && (style = style(this))

            Object.assign(this.style, _.isPlainObject(style) ? style : {})

            let scroll = $(document).scrollTop()

            left = parseInt(left)
            top = parseInt(top)

            let offset = top + el.offsetHeight
            let scrollOffset = offset + scroll

            const popup = this.$popup(this.name)
            if (!popup) return

            top = popup.showOverlay ? scrollOffset : offset

            Object.assign(this.style, {left: left + 'px', top: top + 'px'})
        },
        close() {
            this.$popup(this.name).close()
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown {
    &-text {
        text-align: center;
        font-size: 14px;
        margin: 0;

        > h1, h2, h3 {
            margin: 0 0 20px;
            padding: 0;
        }
    }
}
</style>
