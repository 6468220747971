import helper from '@/helpers'

export const DEFAULT_THUMBS_SELECTOR = '#image-thumbnails'

export const DEFAULT_THUMB_PARAMS = {
    height: 50,
    width: 50,
    scaleType: 'FILL'
}

export const DEFAULT_DB_IMAGES_TABLE = 'images'

export default class {

    thumbsSelector
    uploadRepo
    uploadRepoMethod
    downloadRepo
    downloadRepoMethod
    dbImagesTable

    constructor ({
        thumbsSelector,
        uploadRepo,
        uploadRepoMethod,
        downloadRepo,
        downloadRepoMethod,
        dbImagesTable,
    }) {
        const conf = helper.$config.api()

        this.thumbsSelector = thumbsSelector || DEFAULT_THUMBS_SELECTOR
        this.uploadRepo = uploadRepo || conf.files.DEFAULT_UPLOAD_REPO
        this.uploadRepoMethod = uploadRepoMethod || conf.files.DEFAULT_UPLOAD_REPO_METHOD
        this.downloadRepo = downloadRepo || conf.images.DEFAULT_DOWNLOAD_REPO
        this.downloadRepoMethod = downloadRepoMethod || conf.images.DEFAULT_DOWNLOAD_REPO_METHOD
        this.dbImagesTable = dbImagesTable || DEFAULT_DB_IMAGES_TABLE
    }

    upload ({repo, repoMethod, files}) {

        repo = repo || this.uploadRepo
        repoMethod = repoMethod || this.uploadRepoMethod

        return new Promise((resolve, reject) => {
            helper.$service.file.upload({repo, repoMethod, files, imagesOnly: true}).then(r => {
                resolve(r.data.fileIds)
            }).done('Файлы успешно загружены.').alert('Не удалось загрузить файлы.').catch(() => reject())
        })
    }

    getImage ({
        imageId,
        cb,
        thumb: thumb = false,
        params,
        repo,
        repoMethod,
        useLoader: useLoader = true,
    }) {

        repo = repo || this.downloadRepo
        repoMethod = repoMethod || this.downloadRepoMethod

        return helper.$service.file.get({
            cb,
            repo,
            repoMethod,
            params: _.assign({id: imageId}, thumb ? DEFAULT_THUMB_PARAMS : {}, _.isPlainObject(params) ? params : {}),
            useLoader,
        })
    }

    fetchThumbs (ids, params = {useCache: true}) {

        _.isPlainObject(params) || (params = {})

        return new Promise(resolve => {

            const output = {
                thumbs: [],
                fails: []
            }

            let id = null
            let cacheId = null

            const images = _.cloneDeep(_.toArray(ids))

            const cb = ({url, buffer}) => {

                if (url === null || !buffer) {

                    output.fails.push(id)

                } else {

                    const {img} = this.setThumb(buffer)
                    this.addThumb(id, img)

                    output.thumbs.push({img, id})
                    params.useCache && this.storeImage(cacheId, {url, buffer})
                }

                (async () => await fetchImage())()
            }

            const getImage = () => this.getImage({
                ...params,
                imageId: id,
                thumb: true,
                cb
            })

            const fetchImage = async () => {
                if (!images.length) {
                    resolve(output)
                    return
                }

                id = images[0]
                cacheId = `${id}_thumb`

                images.shift()

                if (params.useCache) {
                    this.getStoredImage(cacheId).then((data) => cb(data)).catch(() => getImage(id))
                } else {
                    getImage(id)
                }
            }

            (async () => await fetchImage())()

        })
    }

    setThumb (arrayBuffer) {

        if (!arrayBuffer) return

        const img = document.createElement('img')
        const openId = _.randKey()

        img.setAttribute('src', `data:image/png;base64,${this.bufferToString(arrayBuffer)}`)
        img.setAttribute('data-open-id', openId)

        return {img, openId}
    }

    addThumb (imageId, img) {

        const thumbs = $(this.thumbsSelector)

        thumbs.length || $(`<div id="${_.trim(this.thumbsSelector, '#')}" style="display: none"></div>`).appendTo('body')

        const thumb = thumbs.find(`[data-image-open="${imageId}"]`)

        thumb.length && thumb.remove()

        $(img).attr('data-image-open', imageId)

        $(this.thumbsSelector).append(img)
    }

    getThumb (imageId) {
        return $(this.thumbsSelector)?.find(`[data-image-open="${imageId}"]`)
    }

    onOpen (imageId) {

        const open = data => {
            if (data.url) return helper.$service.file.download(data.url)

            const w = window.open()
            const src = `data:image/png;base64,${data.buffer}`
            w.document.body.setAttribute('style', 'text-align:center')
            w.document.body.innerHTML =
                `<a href="${src}" download="${_.randKey()}.png"><img src="${src}" style="max-width: 100%" /></a>`
        }

        this.getStoredImage(imageId).then(data => open(data)).catch(() => {

            this.getImage({
                imageId, thumb: false, cb: ({url, buffer}) => {

                    if (url === null) return helper.$notify().error('Не удалось загрузить файл.')

                    const resolve = {
                        url: url,
                        buffer: this.bufferToString(buffer),
                        createdDate: helper.$util.date.curDate()
                    }

                    open(resolve)

                    buffer && this.storeImage(imageId, resolve)
                }
            })
        })
    }

    bufferToString (arrayBuffer) {
        return arrayBuffer.toString('base64')
    }

    images (ids, asThumbHtml = true) {
        const items = _.toArray(ids)
        const images = []
        let html = ''

        items.forEach(id => {

            const thumb = this.getThumb(id)

            if (!thumb?.length) return

            asThumbHtml !== false ? html += thumb[0].outerHTML : images.push({
                id: id,
                image: thumb[0]
            })
        })

        return asThumbHtml !== false ? html : images
    }

    storeImage (id, data) {
        data.buffer && (data.buffer = this.bufferToString(data.buffer))
        helper.$db.save({
            table: this.dbImagesTable,
            data: _.merge({}, data, {id})
        })
    }

    getStoredImage (id) {
        return new Promise((resolve, reject) => {
            helper.$db.query(this.dbImagesTable, 'get', id)
                .then(({data}) => {
                    _.isEmpty(data) ? reject() : resolve(data)
                })
                .catch(() => reject())
        })
    }
}
