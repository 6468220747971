<template>
    <Page>
        <FormPreOrder
            v-if="preorder"
            :preorder="preorder"
            @toggle="preorder = null; $redirect({name: $route.name}, true)"
            @amount-changed="onAmountChanged"
        />
        <div v-if="getMode()" v-show="!preorder">
            <h1 class="section-page-title">
                Найти груз
            </h1>
            <PreOrderFilter
                :key="'filters-' + load"
                v-if="filter && regions && transportTypes && loadingMethods"
                @reset="resetFilter"
                @apply="applyFilter"
                @setRate="setRate"
                v-bind="{
                    filter,
                    filterRate,
                    setFilterRate: rateName || null,
                    regions,
                    transportTypes,
                    loadingMethods,
                    filterCargoWeightMax,
                    filterCargoWeightMin,
                    changeFilterWeightRange
                }"
            >
                <p v-if="!$$.isEmpty(filterSaved) && pageCount > 0" slot="header" class="preorder-filter-count">
                    Найдено {{ pageCount >= pageSize ? `более ${pageSize}` : pageCount }} грузов
                </p>
            </PreOrderFilter>
            <DataTable
                ref="table"
                :key="load"
                :cols="{
                    number: 'Номер',
                    endDateTime: 'Время до окончания',
                    route: 'Маршрут',
                    transportTypeId: 'Прицеп',
                    bidAmount: 'Ставка',
                    reserveBidAmount: 'Резерв',
                    rate: () => rateName ? filterRate[rateName] : 'Тариф',
                    totalSum: 'Итого',
                    // type: '',
                }"
                :cell-bind="{
                    number: {class: 'tx-inline'},
                    route: {style: {maxWidth: '400px'}},
                    rate: {class: 'tx-inline', title: 'Тариф'},
                    totalSum: {class: 'tx-inline', title: 'Сумма тарифа (деньгами + ГСМ)'},
                    bidAmount: {class: 'tx-inline', title: 'Ставка'},
                    reserveBidAmount: {class: 'tx-inline', title: 'Резервная ставка'}
                }"
                :col-data="{
                    rate: {bind: {class: 'tx-inline'}}
                }"
                :cells="{
                     number: v => ({'div.tx-bold': v, bind: {style: {color: '#545454'}}}),
                     transportTypeId: v => $$.find(transportTypes, 'id', v, 'name'),
                     // type: (v, item) => ({
                     //    'div.preorder-type': item.byFixedBid ? 'Фикс' : 'Тендер',
                     //    bind: {class: item.byFixedBid && '--fixed'}
                     // }),
                     endDateTime: (v, item) => item.byFixedBid === true || ({
                        Countdown: '',
                        bind: {
                            date: $util.date.dateFromMsk(v),
                            showSeconds: false,
                            shorten: true,
                            roundDays: true,
                            expiredText: 'Завершен'
                        }
                     }),
                     route: (v, item) => [
                        {html: `<small class='clr-ltg-2'>${$util.date.arrivingDate(item.loading)}</small>`
                        + `<p class='mt-5'>${item.loading.city.name}</p>`},
                        {html: `<small class='clr-ltg-2'>${$util.date.arrivingDate(item.unloading)}</small>`
                        + `<p class='mt-5 mb-0'>${item.unloading.city.name}</p>`},
                     ],
                     bidAmount: (v, item) => $$.price(item.byFixedBid ? item.fixedBidAmount : v, '-'),
                     reserveBidAmount: (v) => $$.price(v, '-'),
                     rate: (v, item) => getRate(item),
                     totalSum: (v, item) => getRate(item, true)
                }"
                :fetch="fetchData"
                :paginate="true"
                :pageSize="pageSize"
                :assign="getAssign"
                :click="preorder => getPreorder(preorder.id)"
            />
        </div>
    </Page>
</template>

<script>

import PreOrderFilter from './form/FormPreOrderFilter.vue'
import FormPreOrder from './form/FormPreOrder.vue'

const FILTER = Object.freeze({
    loadingRegion: "",
    unloadingRegion: "",
    transportTypeId: [],
    startLoadDate: "",
    endLoadDate: "",
    preorderType: "",
    cargoWeightMin: 5,
    cargoWeightMax: 23,
    loadingMethodsId: [],
    sort: "NONE",
    search: "",
    tenderStatus: "",
    sortFixedBidAmount: "",
})

export default {
    name: "PreOrders",
    components: {
        PreOrderFilter,
        FormPreOrder
    },
    props: {
        id: Number,
        mode: String
    },
    data () {
        return {
            preorder: null,
            pageSize: 50,
            pageCount: 0,
            load: 0,
            transportTypes: null,
            regions: null,
            loadingMethods: null,
            filter: null,
            filterSaved: null,
            filterCargoWeightMin: 0,
            filterCargoWeightMax: 0,
            filterRate: {
                withoutTax: 'без НДС',
                withTax: 'с НДС',
                fuelHalf: '50% ГСМ',
                fuelFull: 'Макс. ГСМ',
            },
            setFilterRate: null
        }
    },
    computed: {
        rateName () {
            return this.setFilterRate && this.filterRate[this.setFilterRate] ? this.setFilterRate : null
        }
    },
    created () {

        _.isNumber(this.id) && this.getPreorder(this.id)

        if (this.getMode()) {

            this.$repo('transport').transportTypes().then(({list}) => {
                this.transportTypes = list ? [...[{
                    id: 0,
                    name: 'Все типы транспорта'
                }], ...list] : []
            })

            this.$repo('transport').loadingMethods().then(({list}) => {
                this.loadingMethods = list ? [...[{
                    id: 0,
                    name: 'Все способы погрузки'
                }], ...list] : []
            })

            this.$repo('common').regions().then(({list}) => this.regions = list)

            this.resetFilter()
        }
    },
    methods: {
        getMode () {
            return (['all', 'self']).includes(this.mode) ? this.mode : null
        },
        getPreorder (id) {
            this.$repo('preorder').get(id).then(({object}) => {
                this.$set(this, 'preorder', object)
                this.$redirect({name: this.$route.name, query: {id}})
            })
                .error(r => r.data.result === 'TENDER_COMPLETED' && this.$ok('Данный тендер завершен.'))
                .alert('Не удалось загрузить заявку по тендеру.')
        },
        reload () {
            this.load += 1
        },
        fetchData (h, a) {
            this.$repo('preorder').list(a).then(({list}) => {
                this.pageCount = list.length
                h(list)
            })
        },
        getAssign () {
            return _.merge({
                mode: this.getMode() === 'all' ? 'ALL' : 'WITH_CARRIER_BIDS',
                sort: 'NONE'
            }, this.filterSaved)
        },
        changeFilterWeightRange ({min, max}) {
            this.filter.cargoWeightMin = min
            this.filter.cargoWeightMax = max
        },
        resetFilter () {
            this.filter = {...FILTER}
            const withTax = this.$service.account.withTax
            this.filterSaved = {}
            this.filterCargoWeightMin = this.filter.cargoWeightMin
            this.filterCargoWeightMax = this.filter.cargoWeightMax
            this.setFilterRate = withTax === null ? null : withTax
            this.reload()
        },
        applyFilter () {
            this.filterSaved = this.getFilter()
            this.reload()
        },
        getFilter () {
            const filters = {}
            const filterState = {...this.filter}
            if (filterState.preorderType)
                filterState.preorderType === 'AUCTION' ? filterState.sortFixedBidAmount = '' : filterState.tenderStatus = ''
            Object.keys(filterState).forEach(key => {
                if (_.isEmpty(filterState[key]) || filterState[key] === 'Invalid date')
                    return
                if (key === 'cargoWeightMax' && filterState[key] === this.filterCargoWeightMax)
                    return
                if (key === 'loadingRegion' || key === 'unloadingRegion') {
                    filters[key] = this.regions.find(r => r.id === filterState[key]).name
                    return
                }
                filters[key] = filterState[key]
            })
            return filters
        },
        onAmountChanged ({id, amount, reserveAmount}) {
            this.$refs.table.setItem(i => i.id === id, {
                bidAmount: amount,
                reserveBidAmount: reserveAmount
            })
        },
        setRate (v) {
            this.$set(this, 'setFilterRate', v)
            this.$refs.table.updateCell('rate')
            this.$refs.table.updateCell('totalSum')
        },
        getRate (item, getSum = false) {
            if (!this.rateName || !item?.rate) return ''

            const rate = item.rate[this.rateName] ?? {}
            const out = []

            if (getSum) {
                const sum = (+rate.amount ?? 0) + (+rate.amountOfFuel ?? 0)
                sum && out.push(_.price(sum))
            } else {
                rate.amount && out.push(_.price(rate.amount))
                rate.amountOfFuel && out.push(_.price(rate.amountOfFuel) + ' ГСМ')
            }

            return out
        }
    }
}

</script>

<style scoped lang="scss">
/deep/ .preorder-type {
    padding: 5px 20px;
    border-radius: 4px;
    background-color: #E3FFF1;
    text-align: center;

    &.--fixed {
        background-color: #29841B;
        color: #E3FFF1;
    }
}
</style>
