import Repo from '@/api/service/repo'

export default class extends Repo {

    //Список активных/архивных заказов перевозчика/диспетчера
    carrierOrders(params) {
        return this.client.get('/carrier/orders', params)
    }

    //Получение детальной информации по заказу
    orderDetails(id) {
        return this.client.get(`/orders/${id}/details`)
    }

    //Информация об оплате Заказа
    payment(id) {
        return this.client.get(`/carrier/orders/${id}/payment`)
    }

    //Получение текста условий перевозки
    carriageTerms(id) {
        return this.client.get(`/carrier/orders/${id}/carriageTerms`)
    }

    //Подтверждение заказа
    carrierOrderConfirm(id, { confirmationCode, carrierOrderKey }) {
        return this.client.encode().post(`/carrier/orders/${id}/confirm`, { confirmationCode, carrierOrderKey })
    }

    //Отказ от заказа
    carrierOrderDecline(id, carrierOrderKey) {
        return this.client.encode().post(`/carrier/orders/${id}/decline`, {carrierOrderKey})
    }

    //Получение кода для подтверждения заказа
    getCarrierOrderConfirmationCode(id, carrierOrderKey) {
        return this.client.get(`/carrier/orders/${id}/getConfirmationCode`, {carrierOrderKey})
    }

    // Список статусов заявки
    statusTypes() {
        return this.client.cache({source: 'order_status_types'}).get(`/statusTypes`)
    }

    // Сообщить о проблеме с заказом
    report(orderId, data) {
        return this.client.encode().post(`/issues/order/${orderId}`, data)
    }

    //Данные менеджера заказа
    orderContacts(orderId, params) {
        return this.client.get(`/orders/${orderId}/contacts`, params)
    }

    //Инструкции на загр./выгр.
    orderInstructions(orderId) {
        return this.client.get(`/orders/${orderId}/instructions`)
    }

    //Маршрут рейса Заказа
    orderRoute(orderId) {
        return this.client.get(`/orders/${orderId}/route`)
    }

    //Необходимые документы и пункты приема
    documentReceptionPoints(orderId) {
        return this.client.get(`/orders/${orderId}/documentReceptionPoints`)
    }
}
