<template>
    <div class="form-row form-filter">
        <div v-if="header || $slots.header" class="form-row-header">
            <h2 v-if="header" class="section-page-title">{{ header }}</h2>
            <slot name="header"/>
        </div>
        <div class="form-filter-wrap">
            <Form @submit="$emit('search')" class="form-row-inner">
                <div class="form-row-inner-row">
                    <FormItem
                        type="search"
                        clear
                        @search="$emit('search')"
                        @remove="$emit('search')"
                        class="w-100"
                    >
                        <FormInput
                            title="Фамилия сотрудника (часть или целиком)"
                            placeholder="по фамилии сотрудника"
                            v-model="value.data"
                        />
                    </FormItem>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>

export default {
    name: "FormStaffSearch",
    props: {
        value: Object,
        header: {
            type: String,
            default: 'Поиск'
        }
    }
}

</script>
