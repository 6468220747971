<template>
    <Page>
        <Document
            v-if="doc"
            :html="doc"
            class="block-center block-wide"
            style="text-align: justify; line-height: 1.2em; max-width: 85%; padding-left: 0; padding-right: 0"
        />
    </Page>
</template>

<script>

import Document from "@plugins/Document";

export default {
    name: "Privacy",
    components: {Document},
    data () {
        return {
            doc: null
        }
    },
    created () {
        this.$repo('docs').privacy().then((r) => this.doc = r.data)
    },
}

</script>
