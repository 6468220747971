<template>
    <Form v-if="requisitesChecked" center wide @setup="i => this.form = i" class="form-requisites">
        <h3 class="tx-c">Подтверждение телефона и почты</h3>
        <h4 class="tx-c clr-ltg-4 mb-20">
            Укажите номер телефона и почту директора организации или Индивидуального Предпринимателя, которые были
            зарегистрированы в системе ранее.
        </h4>
        <div class="mb-40">
            <FormItem label="Номер телефона">
                <FormInput
                    required
                    mask="phone"
                    v-model="dataPhone"
                    @blur="!phoneCodeEntity || phoneCodeEntity.getDest() === dataPhone || (requestPhoneCode = 0)"
                />
                <div v-if="phoneConfirmed" class="--confirm-check">
                    <FaIcon icon="check" class="clr-green"/>
                    Подтвержден
                </div>
            </FormItem>
        </div>

        <FormCodeConfirm
            v-if="requestPhoneCode && !phoneConfirmed"
            :key="'request-phone-code-' + requestPhoneCode"
            type="PHONE"
            :dest="dataPhone"
            :token="phoneToken"
            :get-code="true"
            :endpoint="endpoint"
            :request-code-endpoint="requestCodeEndpoint"
            :resend-request-code-endpoint="resendRequestCodeEndpoint"
            :use-api-key="false"
            :request-code-title="true"
            :confirm-cancel-btn="false"
            :compact="true"
            :hide-send-code-button="true"
            @confirmed="confirmed"
            @send-error="sendCodeError"
            @cancel="$emit('cancel')"
            @code-requested="phoneCodeRequested = true"
            @load="i => this.phoneCodeEntity = i"
        />

        <div class="mb-40">
            <FormItem label="Адрес электронной почты">
                <FormInput
                    v-model="dataEmail"
                    required
                    @blur="!emailCodeEntity || emailCodeEntity.getDest() === dataEmail || (requestEmailCode = 0)"
                />
                <div v-if="emailConfirmed" class="--confirm-check">
                    <FaIcon icon="check" class="clr-green"/>
                    Подтвержден
                </div>
            </FormItem>
        </div>

        <FormCodeConfirm
            v-if="requestEmailCode && !emailConfirmed"
            :key="'request-email-code-' + requestEmailCode"
            type="EMAIL"
            :dest="dataEmail"
            :token="emailToken"
            :get-code="true"
            :endpoint="endpoint"
            :request-code-endpoint="requestCodeEndpoint"
            :resend-request-code-endpoint="resendRequestCodeEndpoint"
            :use-api-key="false"
            :request-code-title="true"
            :confirm-cancel-btn="false"
            :compact="true"
            :hide-send-code-button="true"
            @confirmed="confirmed"
            @send-error="sendCodeError"
            @cancel="$emit('cancel')"
            @code-requested="emailCodeRequested = true"
            @load="i => this.emailCodeEntity = i"
        />
        <Buttons initial>
            <button class="btn btn-blue" @click="proceed">Далее</button>
            <button class="btn btn-white" @click="$emit('cancel')">
                Вернуться к регистрации
            </button>
        </Buttons>
    </Form>
</template>

<script>

import FormCodeConfirm from './FormCodeConfirm.vue'

export default {
    name: 'FormRequisites',
    components: {FormCodeConfirm},
    data() {
        return {
            endpoint: this.$config.api().codeConfirm.confirmRequisites,
            requestCodeEndpoint: this.$config.api().codeConfirm.requisitesRequestConfirmation,
            resendRequestCodeEndpoint: this.$config.api().codeConfirm.requisitesResendCode,
            dataPhone: "",
            dataEmail: "",
            phone: "",
            phoneConfirmed: false,
            phoneToken: "",
            email: "",
            emailConfirmed: false,
            emailToken: "",
            requisitesChecked: false,
            requestEmailCode: 0,
            requestPhoneCode: 0,
            phoneCodeRequested: false,
            emailCodeRequested: false,
            phoneCodeEntity: null,
            emailCodeEntity: null,
            form: null
        }
    },
    created() {
        this.$repo('link_carrier').requisitesStatus().then((r) => {
            this.phone = r.data.phone.value
            this.email = r.data.email.value
            this.phoneToken = this.requestPhoneCode === 0 ? "" : r.data.phone.token
            this.emailToken = this.requestEmailCode === 0 ? "" : r.data.email.token
            this.phoneConfirmed = r.data.phone.status === 'CONFIRMED'
            this.emailConfirmed = r.data.email.status === 'CONFIRMED'
            this.dataPhone = this.phone
            this.dataEmail = this.email
        }).finally(() => this.requisitesChecked = true)
    },
    methods: {
        proceed() {

            this.phone = this.phone || ""
            this.email = this.email || ""

            const phoneEqual = _.toNum(this.dataPhone) === _.toNum(this.phone),
                emailEqual = _.toLower(this.dataEmail) === _.toLower(this.email),
                phoneConfirmed = phoneEqual && this.phoneConfirmed,
                emailConfirmed = emailEqual && this.emailConfirmed

            if (phoneConfirmed && emailConfirmed) {
                this.$emit('confirmed', {
                    phone: this.dataPhone,
                    email: this.dataEmail,
                })
            } else {
                this.phoneCodeRequested && phoneEqual
                    ? (phoneConfirmed || this.phoneCodeEntity.submit(_.trim(this.phoneCodeEntity.code === "")))
                    : this.getCode(!phoneConfirmed)
                this.emailCodeRequested && emailEqual
                    ? (emailConfirmed || this.emailCodeEntity.submit(_.trim(this.emailCodeEntity.code === "")))
                    : this.getCode(false, !emailConfirmed)
            }
        },
        getCode(confirmPhone, confirmEmail) {
            this.form.validate().then(() => {
                if (confirmPhone) {
                    this.phone = this.dataPhone
                    this.requestPhoneCode += 1
                    this.phoneConfirmed = false
                }
                if (confirmEmail) {
                    this.email = this.dataEmail
                    this.requestEmailCode += 1
                    this.emailConfirmed = false
                }
            }).catch(false)
        },
        sendCodeError(result, params, resp) {
            result
                ? (resp.data.token === null && resp.data.resendCodeAfter === null && this.confirmed(true, params))
                : params.type === 'PHONE' ? this.requestPhoneCode = 0 : this.requestEmailCode = 0
        },
        confirmed(result, params) {
            if (!result) return

            if (params.type === 'PHONE') {
                this.phoneConfirmed = true
                this.phone = params.value
            } else {
                this.emailConfirmed = true
                this.email = params.value
            }
            this.phoneConfirmed && this.emailConfirmed && this.proceed()
        }
    }
}

</script>

<style scoped lang="scss">
.form-requisites {
    .--confirm-check {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: -21px;
        font-size: 0.9em;
        color: $gray-65;
    }
}
</style>
