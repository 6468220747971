var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"section-page-title tx-c pl-0"},[_vm._v("Анкета водителя")]),_c('DataForm',{attrs:{"model":_vm.data,"form":{
            on: {submit: _vm.submit},
            bind: {
                buttons: [
                    'Сохранить',
                    {text: 'Отклонить приглашение от перевозчика', bind: {class: 'btn btn-red'}, on: {click: function () { return _vm.$emit('decline'); }}} ],
                buttonCancel: 'Выйти из аккаунта',
                buttonsInitial: true,
                buttonsCenter: true,
                buttonsWidth: 60,
                onCancel: function () { return _vm.$service.account.logout(); },
            },
        }},model:{value:(_vm.data.model),callback:function ($$v) {_vm.$set(_vm.data, "model", $$v)},expression:"data.model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }