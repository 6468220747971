<template>
    <Accordion class="form-accept-cert">
        <h2 class="section-page-title tx-c pl-0">
            Акт осмотра ТС <br>&laquo;{{$$.trim(form.model.number)}}&raquo;
        </h2>
        <DataForm
            v-if="load"
            :key="load"
            :model="form"
            v-model="form.model"
            :view="cert.readOnly === true"
            required
            :form="{
                on: {submit},
                bind: {
                    buttons: [
                        cert.readOnly ? null : 'Сохранить',
                        !cert.confirmationRequest ? null : {
                            text: 'Подтвердить',
                            on: {click: this.confirm}
                        },
                        cert.readOnly ? null : {
                            text: 'Удалить',
                            bind: {class: 'btn btn-red'}
                        }
                    ]
                }
            }"
        >
            <div :slot="`afterGroup_${form.groups.length-1}`" class="px-15">
                <small class="clr-ltg-4">
                    Передающий транспортное средство, несёт полную материальную ответственность
                    за выявленные и указанные в данном акте недостатки.
                    Транспортное средство принятое без указания на недостатки, считается переданным в исправном
                    состоянии,
                    в полном комплекте, без повреждений. Принимающий транспортное средство,
                    несёт полную материальную ответственность с момента подписания акта
                </small>
            </div>
        </DataForm>

        <BackToggle @toggle="toggle" class="mt-10" />

        <Confirm name="confirm-accept-cert">
            <p class="clr-dark">Ваш код подтверждения: {{ confirmCode }}</p>
            <FormItem>
                <FormInput v-model="sendCode" placeholder="Введите код подтверждения"/>
            </FormItem>
        </Confirm>
    </Accordion>
</template>

<script>

export default {
    name: "FormAcceptCert",
    props: {
        cert: Object
    },
    data() {
        return {
            form: null,
            formDiff: {},
            load: 0,
            confirmCode: null,
            sendCode: null,
        }
    },
    created() {

        this.form = _.cloneDeep(this.$apiModel.accept_cert)

        Object.assign(this.form.model, _.cloneDeep(this.cert))
        Object.assign(this.formDiff, _.cloneDeep(this.cert))

        this.form.groups.forEach(group => {
            group.component = 'AccordionItem'
            group.title = false
            group.bind = { title: group.name }
        })

        this.load += 1
    },
    methods: {
        submit() {
            return this.$repo('enterprise').saveAcceptanceCert(this.cert.id, this.form.model).then(({object}) => {
                this.$set(this.form, 'model', object)
                this.$set(this, 'formDiff', _.cloneDeep(object))
                this.load += 1
            }).done('Акт осмотра ТС изменен.').alert('Не удалось изменить Акт осмотра ТС.')
        },
        confirm() {
            this.$repo('enterprise').acceptanceCertCode(this.cert.id, this.cert.certVersion).then((r) => {

                this.confirmCode = r.data.code

                this.$confirm('confirm-accept-cert', {
                    title: 'Подтверждение',
                    buttons: 'Подтвердить',
                    onConfirm: () => {
                        if (this.confirmCode !== this.sendCode) {
                            this.$notify().warn('Код подтверждения не совпадает.')
                            return
                        }

                        this.$repo('enterprise').acceptanceCertConfirm(this.cert.id, this.cert.certVersion, this.sendCode).then(() => {
                            this.$popupClose()
                        })
                            .done('Акт осмотра ТС подтвержден.')
                            .alert('Не удалось подтвердить Акт осмотра ТС.')
                    }
                })
            }).alert('Не удалось получить код подтверждения.')
        },
        isCertChanged(callback) {

            if (this.form.model.readOnly) {
                callback && callback()
                return false
            }

            const diff = _.diff(this.form.model, this.formDiff)

            if (_.isEmpty(diff)) {
                callback && callback()
                return false
            }

            this.$confirm({
                title: 'Акт осмотра ТС изменен',
                text: 'Сохранить изменения?',
                onConfirm: () => this.submit().then(() => callback && callback()),
                onDecline: () => callback && callback()
            })

            return true
        },
        toggle() {
            this.isCertChanged(() => this.$emit('toggle'))
        }
    }
}

</script>

<style scoped lang="scss">
.form-accept-cert /deep/  {
    .accordion-panel-collapse > div {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin-bottom: 20px;
    }
}
</style>
