export default {
    model: {
        driverID: null,
        truckID: null,
        semitrailerID: null,
    },
    fields: {
        driverID: {
            name: 'Водитель',
            type: 'array',
            labelName: (v) => {
                return `${v?.fullName} (${v?.status})`
            },
        },
        truckID: {
            name: 'Тягач',
            type: 'array',
            labelName: (v) => {
                return `${v?.model} - ${v?.number}`
            },
        },
        semitrailerID: {
            name: 'Полуприцеп',
            type: 'array',
            labelName: (v) => {
                return `${v?.model} - ${v?.number}`
            },
        },
    },
    assign: {
        driverID: ({bind, vm}) => {
            vm.$repo('staff').drivers().then(({list}) => bind(list, 'options'))
        },
        truckID: ({bind, vm}) => {
            vm.$repo('transport').trucks().then(({list}) => bind(list, 'options'))
        },
        semitrailerID: ({bind, vm}) => {
            vm.$repo('transport').semitrailers().then(({list}) => bind(list, 'options'))
        }
    }
}
