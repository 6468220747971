<template>
    <div class="form-order-details">
        <ul v-if="list" class="form-order-details-list list">
            <Tree
                :list="list"
                :bind="{
                     li: (item, depth) => (item.title && !item.valueString) ? {
                        class: depth >= 1 ? 'sub-title' : 'title'
                     } : null,
                  }"
                :template="item => [
                        {'b': (item.title && !item.valueString) ? item.title : null},
                        {'div.label': (item.title && item.valueString) ? item.title : null},
                        {'div.value': item.valueString},
                  ]"
            />
        </ul>

        <DataModel
            v-if="!$$.isEmpty(form.model)"
            class="form-order-details-model"
            :model='{
                ...$apiModel.order_details,
                ...form,
                render
            }'
        />

    </div>
</template>

<script>

import Tree from '@plugins/Tree.vue'

export default {
    name: "form-order-details",
    props: {
        data: Object
    },
    components: {Tree},
    data() {
        return {
            list: null,
            form: null
        }
    },
    created() {
        this.form = _.cloneDeep(this.data)
        if (_.isArray(this.form.list) && this.form.list.length) {
            this.list = {list: this.form.list}
            this.$delete(this.form, 'list')
        }
    },
    methods: {
        render({field, value}) {
            return {
                html: `<div class="label">${field.bind.name}</div><div class="name">${field.prompt || value}</div>`,
                bind: {class: "wrapper"}
            }
        }
    }
}

</script>

<style lang="scss" scoped>

.form-order-details-model {
    /deep/ .wrapper {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        justify-content: stretch;
        .label {
            min-width: 200px;
            color: $gray-99;
        }
        .name {
            min-width: 200px;
        }
    }
}

/deep/ .form-order-details-list {
    ul {
        padding-left: 0;
        font-size: 0.99em;
        margin: 0;
        @media (max-width: 768px) {
            max-width: 95%;
            .value {
                display: block;
                padding-left: 0;
            }
        }
    }
    li.title > div > b {
        font-family: $font-proxima-bold;
    }
    li.title > ul, li.sub-title > ul {
        margin: 0.5em 0;
    }
    li.sub-title {
        margin-top: 10px;
        > ul {
            margin: 0;
        }
    }
    li.title {
        margin-bottom: 30px
    }
    li.sub-title {
        ul > li:first-child {
            padding-top: 2px !important;
        }
    }
    .label, .value {
        display: table-cell;
        line-height: 1.3em;
    }
    .label {
        color: $gray-a3
    }
    .value {
        padding-left: 15px;
        vertical-align: top;
    }
}

</style>
