import axios from 'axios'

export const STUBS = []

export default class {

    constructor({repoPath, uri, method, params, headers}) {
        this.data = {repoPath, uri, method, params, headers}
    }

    getStubPath() {
        const dir = this.data.repoPath && typeof this.data.repoPath === 'string' ? this.data.repoPath.replace(/\//g, '_') : ''
        const url = new URL(this.data.uri, 'http://example.com')
        const hash = _.isPlainObject(this.data.params) && Object.keys(this.data.params).length
            ? _.md5(_.jsonStringify(this.data.params))
            : ''

        return {
            dir,
            name: this.data.method + url.pathname.replace(/\//g, '_') + (hash ? '-' + hash : '')
        }
    }

    send(url, data) {
        axios.create({
            baseURL: `http://localhost:${process.env.DEV_SERVER_BACKEND_PORT}`,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
                'Access-Control-Allow-Headers': 'X-Requested-With, content-type',
            }
        }).post(url, data)
    }

    push({data, headers}) {
        const get = this.get()
        const stubData = {
            repo: this.getStubPath(),
            request: this.data,
            response: {data, headers}
        }
        get ? Object.assign(get, stubData) : STUBS.push(stubData)
        this.send('/stubs', STUBS)
    }

    get() {
        try {
            return this.getRepo()
        } catch(e) {
            const path = this.getStubPath()
            return STUBS.find(s => s.dir === path.dir && s.path === path.name)
        }
    }

    getRepo() {
        const stub = this.getStubPath()

        if (stub.dir) return require(`@/api/stubs/${stub.dir}/${stub.name}`).default

        return require(`@/api/stubs/${stub.name}`).default
    }

    refresh({refresh}) {
        refresh && this.send('/stubs-refresh', refresh)
    }

}
