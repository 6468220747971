<template>
    <Page class="block-contents">
        <div :key="k" v-for="(i, k) in contacts" class="block-center block-wide mb-40">
            <h3 class="tx-bold tx-c clr-dark">{{ i.label }}:</h3>
            <ol>
                <template v-for="(v, kk) in i.contacts">
                    <li :key="kk" v-if="v !== null" class="mb-20">
                        <div class="tx-bold clr-dark">{{ v.label }}:</div>
                        <p>
                            {{ v.fullName }},
                            {{ v.phone | mask('phone') }}
                        </p>
                    </li>
                </template>
            </ol>
        </div>
    </Page>
</template>

<script>
export default {
    name: "ContactPhones",
    data() {
        return {
            contacts: null,
        }
    },
    created() {
        this.$repo('enterprise').contacts().then(({list}) => {
            this.contacts = list
        })
    }
}
</script>
