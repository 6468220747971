<template>
    <div class="form-row form-filter">
        <div v-if="$slots.header" class="form-row-header">
            <slot name="header"/>
        </div>
        <div class="form-filter-wrap">
            <Form @submit="$emit('search')" class="form-row-inner">
                <div class="form-row-inner-row-align">
                    <FormItem
                        type="search"
                        clear
                        @search="$emit('search')"
                        @remove="$emit('search')"
                        style="width: 50%"
                    >
                        <FormInput
                            v-model="model.search"
                            placeholder="Номер заказа, номер ТС, ФИО водителя"
                            title="Название целиком или частично"
                        />
                    </FormItem>
                    <FormItem style="width: 50%" row noShadow>
                        <Toggle
                            v-model="model.empty"
                            :value="model.empty"
                        />
                        <div>только незаполненные</div>
                    </FormItem>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormRouteSheetSearch",
    props: {
        value: Object
    },
    data() {
        return {
            model: this.value
        }
    }
}
</script>
