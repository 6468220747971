import {set} from 'vuex-intern'

export const ACCOUNT_STATE_NAME = 'account'

export const ACCOUNT_STATE = {
    namespaced: true,
    state: {
        user: {},
        profile: {},
        authExpired: false,
    },
    mutations: {
        user: set('user'),
        profile: set('profile'),
        authExpired: set('authExpired'),
    },
    getters: {
        authExpired: state => Boolean(state.authExpired),
        userEmpty: state => Boolean(!state.user || Object.keys(state.user).length === 0),
        user: state => state.user,
        profile: state => state.profile,
        phone: state => _.toNum(state.user.phone),
        roles: state => state.user.roles?.length ? state.user.roles : [],
        carrier: state => state.user.linkedCarrier,
        userId: state => state.user.id,
    },
    actions: {
        destroy({commit}) {
            commit('user', {})
            commit('profile', {})
        }
    }
}

export const ACCESS = {

    hasRole(pattern) {
        return Boolean(ACCOUNT_STATE.state.user.roles?.find(v => v.match(new RegExp(pattern))))
    },

    getRole() {
        return this.isCarrier() ? 'CARRIER' : ACCOUNT_STATE.state.user.roles?.[0]
    },

    hasAnyRole() {
        return Boolean(ACCOUNT_STATE.state.user.roles?.length)
    },

    isEnterprise() {
        return Boolean(ACCOUNT_STATE.state.user.enterprise)
    },

    isDriver() {
        return this.hasRole('DRIVER')
    },

    isCarrier() {
        return this.hasRole('CARRIER')
    },

    isDispatcher() {
        return this.hasRole('DISPATCHER')
    },

    isAdmin() {
        return this.isCarrier() || this.isDispatcher()
    },

}
