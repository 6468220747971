<template>
    <Page>
        <div class="block-center">
            <h3 class="mt-40 tx-c">{{ registered ? 'Подтверждение регистрации' : 'Регистрация' }}</h3>
            <div v-if="registered === false">
                <DataForm
                    v-model="formData"
                    required
                    :form="{
                        on: {
                            submit: reg,
                            cancel: () => $redirect('/login')
                        },
                        bind: {
                            buttons: 'Регистрация',
                            buttonCancel: true
                        }
                    }"
                    :model="{
                        model: {...formData},
                        fields: {
                            surname: {name: 'Фамилия'},
                            name: {name: 'Имя'},
                            patronymic: {name: 'Отчество'},
                            phone: {name: 'Номер телефона', type: 'text', mask: 'phone', format: v => $$.toNum(v), required: v => !v},
                            agree: {type: 'checkbox', wrap: {bind: {row: true}}, required: v => !v},
                        }
                    }"
                >
                    <div slot="afterField_agree" class="clr-ltg-4">
                        <small>Я прочитал условия</small>
                        <div class="link" @click="getUserAgreement"><small>ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</small></div>
                        <small>и согласен на обработку своих персональных данных</small>
                    </div>
                </DataForm>
            </div>
            <div v-else-if="token">
                <h4 class="tx-c mb-40">На Ваш номер телефона отправлено SMS с кодом подтверждения телефона</h4>
                <FormCodeConfirm
                    type="phone"
                    :dest="formData.phone"
                    :endpoint="$config.api().codeConfirm.confirmRegister"
                    :request-code-endpoint="$config.api().codeConfirm.resendCode"
                    :token="token"
                    :counter="counter"
                    @confirmed="confirmed"
                    @cancel="registered = false"
                />
            </div>
        </div>
    </Page>
</template>

<script>

import FormCodeConfirm from './form/FormCodeConfirm.vue'

export default {
    name: "Reg",
    data() {
        return {
            formData: {
                name: "",
                surname: "",
                patronymic: "",
                phone: 7,
                agree: false,
            },
            registered: false,
            token: "",
            counter: 0
        }
    },
    components: {FormCodeConfirm},
    methods: {
        reg() {
            this.$repo('auth').register(this.formData)
                .then((r) => {
                    this.counter = r.data.resendCodeAfter
                    this.registered = true
                    this.token = r.data.token
                }).alert('Не удалось зарегистрироваться.')
        },
        confirmed(result, params, resp) {
            if (!result) return

            this.$service.account.setSession(resp.data.session)
            this.$service.account.setRefreshTokenId(resp.data.refreshTokenUID)
            this.$util.page.reload({})
        },
        getUserAgreement() {
            this.$service.file.getAndDownload({
                repo: 'docs',
                repoMethod: 'getAgreement',
                params: null,
                name: 'Пользовательское соглашение',
                errMsg: 'Не удалось загрузить Пользовательское соглашение'
            }).catch(() => {this.formData.agree = false})
        },
    }
}

</script>
