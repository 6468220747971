<template>
    <div>
        <DataForm
            :key="view"
            v-if="form.model"
            v-model="form.model"
            :model="form"
            required
            :view="view"
            :form="{
                bind: {
                    ...$attrs,
                    buttons: [
                        view ? {text: 'Редактировать', on: {click: () => view = false}} : 'Сохранить',
                        truckId ? {text: 'Удалить', bind: {class: 'btn btn-red'}, on: {click: remove}} : null
                    ]
                },
                on: {submit},
            }"
        />
        <BackToggle v-if="form.model" @toggle="toggle()" />
    </div>
</template>

<script>

export default {
    name: "FormTruck",
    props: {
        id: Number,
        truckName: String,
        toggle: Function
    },
    data() {
        return {
            form: {},
            truckId: _.isNumber(this.id) ? this.id : null,
            view: true
        }
    },
    created() {
        this.truckId ? this.getTruck() : this.form = this.$apiModel.truck
        this.truckId || (this.view = false)
    },
    methods: {
        getName() {
            if (this.form?.model?.model) return ` "${this.form.model.model}-${this.form.model.number}" `
            return this.truckName ? ` "${this.truckName}" ` : ''
        },
        getTruck() {
            return this.$repo('transport').getTruck(this.truckId).then(({object}) => {
                this.$set(this, 'form', {
                    ...this.$apiModel.truck,
                    ...{model: object}
                })
            }).alert(`Не удалось загрузить тягач${this.getName()}.`)
        },
        remove() {
            this.$confirm({
                text: 'Вы действительно хотите удалить информацию о тягаче?',
                onConfirm: () => {
                    this.$repo('transport').removeTruck(this.truckId)
                        .then(() => this.toggle(true))
                        .alert(`Не удалось удалить тягач.`)
                        .done(`Тягач ${this.getName()} удален из списка.`)
                }
            })
        },
        submit() {
            this.view || this.$repo('transport')[this.truckId ? 'patchTruck' : 'postTruck'](this.form.model)
                .then(() => this.toggle(true))
                .done(this.truckId ? `Тягач ${this.getName()} изменен.` : 'Тягач добавлен.')
                .alert(`Не удалось добавить/редактировать информацию о тягаче.`)
        }
    }
}

</script>
