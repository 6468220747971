import Repo from '@/api/service/repo'

export const CONFIRM_LOGIN_URL = '/auth/confirmLogin'
export const RESEND_CODE_URL = '/auth/resendCode'

export default class extends Repo {

    _stubs = {
        refresh: null
    }

    constructor() {
        super()
        this.$service.account.get('userEmpty') && (this._stubs.refresh = ['auth', 'account'])
    }

    checkSession() {
        return this.client.get('/auth/checkSession')
    }

    refreshSession({phone, refreshTokenUID}) {
        return this.client.encode().post('/auth/refreshSession', {
            phone: _.toNum(phone),
            refreshTokenUID
        })
    }

    login({phone, password}) {
        return this.client.encode().post('/auth/login', {
            phone: _.toNum(phone),
            password,
        })
    }

    loginConfirm(token, passwordRecoveryToken, mode) {
        return this.client.encode().post(CONFIRM_LOGIN_URL, {token, passwordRecoveryToken, ...(mode ? {mode} : {})})
    }

    checkPermission(permission) {
        return this.client.get(`/auth/checkPermission`, {permission})
    }

    passwordRecovery(params) {
        return this.client.encode().post('/auth/passwordRecovery', params)
    }

    passwordChange(password) {
        return this.client.encode().post('/auth/user/password/change', password)
    }

    register(data) {
        return this.client.post('/auth/register', data)
    }

}
