<template>
    <div :key="updateKey" :class="['form-code-confirm ', compact && 'compact']">

        <Form ref="form" v-if="requestCode === true && dataToken === ''" @keyup.enter.native="getConfirmCode">
            <h4 v-if="dataRequestCodeTitle" class="tx-c mb-20"> {{ dataRequestCodeTitle }} </h4>
            <FormItem v-if="dataType === 'phone'" label="Номер телефона">
                <FormInput required :mask="$app().mask.phone" v-model="requisite" />
            </FormItem>
            <FormItem v-else-if="dataType === 'email'" label="Email">
                <FormInput required v-model="requisite" />
            </FormItem>
            <FormItem v-if="proceed" class="t-xc">
                <a href="#" @click.prevent="$emit('proceed', type)">
                    Пропустить проверку {{dataType === 'phone' ? 'Номера телефона' : 'Email адреса'}}
                </a>
            </FormItem>
            <div class="buttons mt-40">
                <button class="btn btn-blue" @click="getConfirmCode">
                    Отправить
                </button>
                <button v-if="back" class="btn btn-blue" @click="$emit('back', type)">
                    Назад
                </button>
                <button v-if="requestCancelBtn" class="btn btn-white" @click="$emit('cancel')">
                    Отмена
                </button>
            </div>
        </Form>

        <Form ref="form" v-else @keyup.enter.native="submit">
            <h4 v-if="dataRequestCodeTitle" class="clr-ltg-4 mb-20" :class="[compact || 'tx-c']"> {{ dataRequestCodeTitle }} </h4>
            <FormItem>
                <FormInput
                    required
                    v-model="code"
                    class="form-code-confirm-code-input"
                    :placeholder="codeInputPlaceholder"
                />
            </FormItem>
            <div class="form-code-confirm-resend-text tx-c" v-if="resendCodeAfter > 0 && !compact">
                <p class="clr-ltg-4">Получить новый Код возможно через {{resendCodeAfter}} секунд</p>
            </div>
            <div class="buttons mb-40">
                <button
                    v-if="!hideSendCodeButton"
                    class="form-code-confirm-code-send-btn btn btn-blue"
                    @click="submit"
                >
                    Отправить
                </button>
                <button
                    class="btn btn-blue form-code-confirm-code-resend-btn"
                    :class="resendCodeAfter && 'btn-disabled'"
                    @click="resendCodeAfter === 0 && getConfirmCode(false)"
                >
                    Получить {{ compact ? '' : 'новый' }} код
                </button>
                <div class="form-code-confirm-resend-text tx-c" v-if="resendCodeAfter > 0 && compact">
                    <p class="clr-ltg-4">Получить новый Код возможно через {{resendCodeAfter}} секунд</p>
                </div>
                <button v-if="confirmCancelBtn" class="btn btn-white" @click="$emit('cancel')">
                    Отмена
                </button>
            </div>
        </Form>

    </div>
</template>

<script>

    export default {
        name: "FormCodeConfirm",
        props: {
            endpoint: String,
            requestCodeEndpoint: String,
            requestCode: {
                type: Boolean,
                default: false
            },
            requestCodeTitle: {
                type: [String, Boolean],
                default: ""
            },
            resendRequestCodeEndpoint: {
                type: String,
                default: ""
            },
            hideSendCodeButton: {
                type: Boolean,
                default: false
            },
            useApiKey: {
                type: Boolean,
                default: true
            },
            type: {
                type: String,
                required: true
            },
            token: {
                type: String,
                default: ""
            },
            dest: {
                type: [String, Number],
                required: true
            },
            getCode: {
                type: Boolean,
                default: false
            },
            proceed: {
                type: Boolean,
                default: false
            },
            back: {
                type: Boolean,
                default: false
            },
            counter: {
                type: Number,
                default: 0
            },
            requestCancelBtn: {
                type: Boolean,
                default: true
            },
            confirmCancelBtn: {
                type: Boolean,
                default: true
            },
            compact: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                updateKey: 0,
                uri: this.endpoint || this.$config.api().codeConfirm.confirm,
                requestCodeUri: this.requestCodeEndpoint || this.$config.api().codeConfirm.send,
                dataType: this.type?.toLowerCase(),
                codeRequested: false,
                requisite: "",
                code: "",
                dataToken: "",
                resendCodeAfter: null,
                interval: null,
                params: {
                    apiKey: this.$config.api().apiKey,
                    destination: this.dest,
                    value: this.dest,
                    type: this.type,
                    token: "",
                },
                sendMessage: "",
                sendErrorMessage: "",
                codeInputPlaceholder: "Код подтверждения",
                dataRequestCodeTitle: "",
            }
        },
        destroyed() {
            clearInterval(this.interval)
        },
        mounted() {
            this.$emit('load', this)
        },
        created() {
            const counter = this.counter || this.$service.store.get('code-confirm-timeout', null)

            this.requestCode && (this.requisite = this.params.destination)
            this.token && (this.dataToken = this.params.token = this.token)
            this.getCode ? this.getConfirmCode(false) : (counter && this.setCounter(counter))

            switch (this.dataType) {
                case 'phone':
                    this.params.destination = this.params.value = this.dest
                    this.sendMessage = `<h3>На Ваш номер телефона выслан Код подтверждения телефона.</h3>`
                    this.sendErrorMessage = 'Не удалось отправить код подтверждения на указанный номер телефона.'
                    this.codeInputPlaceholder = 'Код из SMS'
                    this.requestCodeTitle && (this.requestCode === true && this.dataToken === ""
                        ? this.dataRequestCodeTitle = _.isBoolean(this.requestCodeTitle) ? 'Подтверждение телефона' : this.requestCodeTitle
                        : this.dataRequestCodeTitle = _.isBoolean(this.requestCodeTitle) ? 'На указанный номер телефона выслан Код подтверждения' : this.requestCodeTitle)
                    break
                case 'email':
                    this.params.destination = this.params.value = this.dest
                    this.sendMessage = `<h3>На Вашу почту выслан код подтверждения.</h3>`
                    this.sendErrorMessage = 'Не удалось отправить код подтверждения на Вашу почту.'
                    this.codeInputPlaceholder = 'Код из письма'
                    this.requestCodeTitle && (this.requestCode === true && this.dataToken === ""
                        ? this.dataRequestCodeTitle = _.isBoolean(this.requestCodeTitle)
                            ? 'Подтверждение почты'
                            : this.requestCodeTitle
                        : this.dataRequestCodeTitle = _.isBoolean(this.requestCodeTitle)
                            ? 'На указанную почту выслано письмо с кодом подтверждения'
                            : this.requestCodeTitle)
                    break
            }
        },
        methods: {
            getConfirmCode(validate) {

                if (this.requestCode && !this.dataToken) this.params.destination = this.params.value = this.requisite

                const endpoint = this.resendRequestCodeEndpoint && this.dataToken
                    ? this.resendRequestCodeEndpoint
                    : this.requestCodeUri

                this.$nextTick(() => {

                    this.$refs.form.validate(validate, validate).then(() => {

                        this.$rs.encode().post(endpoint, this.format()).finally(data => {
                            const res = data.isSuccess
                            const resp = data.response || {}

                            res && this.$emit('code-requested', resp)

                            if (!res || (resp.data.token === null && resp.data.resendCodeAfter === null)) {
                                !res && (resp.data.result === 'OBJECT_NOT_FOUND' || resp.data.result === 'BAD_CREDENTIALS'
                                    ? this.$notify().warn(`Не удалось получить код подтверждения. ${this.getDataTypeName()} не зарегистрирован.`)
                                    : this.$notify().warn('Не удалось получить код подтверждения.'))
                                this.$emit('send-error', res, this.format(), resp)
                                return false
                            }

                            this.updateKey += 1
                            this.$emit('send-success', resp)
                            resp.data.token !== null && (this.dataToken = this.params.token = resp.data.token)

                            this.$notify().info(this.sendMessage)
                            this.codeRequested = true
                            this.setCounter(resp.data.resendCodeAfter)
                        })
                    })

                })

            },
            getDest() {
                return this.requestCode && !this.dataToken ? this.requisite : this.params.destination
            },
            getDataTypeName() {
                switch (this.dataType) {
                    case 'phone':
                        return 'Номер телефона';
                    case 'email':
                        return 'Адрес электронной почты'
                }
            },
            submit(validate) {
                this.$refs.form.validate(validate, validate).then(() => {
                    this.$rs.encode().post(this.uri, {
                        token: this.dataToken,
                        code: this.code,
                        apiKey: this.useApiKey ? this.$config.api().apiKey : "",
                    })
                        .finally(({isSuccess, response}) => this.$emit('confirmed', isSuccess, this.format(), response || {}))
                        .done(`${this.getDataTypeName()} подтвержден.`)
                        .alert('Не удалось подтвердить код.')
                })
            },
            format() {
                const params = {...this.params}
                params.type = params.type?.toUpperCase()
                this.dataType === 'phone' && (params.destination = params.value = _.toNum(params.value))
                !this.useApiKey && (delete params.apiKey)
                return params
            },
            setCounter(countFrom) {
                countFrom && (this.resendCodeAfter = parseInt(countFrom))
                this.interval = setInterval(() => {
                    this.resendCodeAfter -= 1
                    this.$service.store.set('code-confirm-timeout', this.resendCodeAfter, 60 * 1000)
                    if (this.resendCodeAfter <= 0) {
                        this.resendCodeAfter = 0
                        this.$service.store.rm('code-confirm-timeout')
                        clearInterval(this.interval)
                        this.$emit('count')
                    }
                }, 1000)
            }
        }
    }
</script>

<style scoped>

    .form-code-confirm.compact .form-code-confirm-code-input,
    .form-code-confirm.compact .form-code-confirm-code-send-btn,
    .form-code-confirm.compact .form-code-confirm-code-resend-btn {
        display: inline-block !important;
        margin: 0 0 2px -1px !important;
    }

    .form-code-confirm.compact .form-code-confirm-code-input {
        max-width: 30% !important;
        min-width: 150px !important;
    }

    .form-code-confirm.compact .form-code-confirm-resend-text {
        text-align: left !important;
    }

    .form-code-confirm.compact .form-code-confirm-code-send-btn,
    .form-code-confirm.compact .form-code-confirm-code-resend-btn {
        border-radius: 2px !important
    }

</style>
