export default {
    model: {
        "beginWorkDate": "",
        "currentCarrierId": 0,
        "driverLicense": {
            "expireDate": "",
            "number": "",
            "scans": []
        },
        "endWorkDate": "",
        "id": null,
        "kins": [
            {
                "id": null,
                "kinType": null,
                "name": null,
                "patronymic": null,
                "phoneNumber": "+7",
                "phoneType": null,
                "surname": null,
            }
        ],
        "medicalCard": {
            "expireDate": "",
            "scans": []
        },
        "name": "",
        "otherScans": [],
        "passport": {
            "birthDate": "",
            "birthLocation": "",
            "citizenship": "",
            "departmentCode": "",
            "firstPageScan": "",
            "issuingAuthority": "",
            "passportDate": "",
            "passportNumber": "",
            "passportSeries": "",
            "registration": "",
            "registrationPageScan": ""
        },
        "patronymic": "",
        "phone": "+7",
        "semitrailerId": 0,
        "skills": {
            "count": 0,
            "unit": ""
        },
        "status": "",
        "surname": "",
        "truckId": 0
    },
    groups: [
        {name: 'Контактная информация', fields: ["surname", "name", "patronymic", "phone", "status"]},
        {
            name: 'Паспортные данные', fields: [
                "passport.birthDate", "passport.birthLocation", "passport.passportSeries",
                "passport.passportNumber", "passport.passportDate", "passport.departmentCode",
                "passport.issuingAuthority", "passport.registration", "passport.citizenship",
                "passport.firstPageScan", "passport.registrationPageScan"
            ]
        },
        {
            name: 'Водительское удостоверение', fields: [
                "driverLicense.number", "driverLicense.expireDate", "driverLicense.scans"
            ]
        },
        {name: 'Медицинская книжка', fields: ["medicalCard.expireDate", "medicalCard.scans"]},
        {name: 'Опыт работы', fields: ["skills.unit", "skills.count"]},
        {name: 'Родственники', fields: ["kins"]},
    ],
    fields: {
        "surname": {
            name: "Фамилия",
        },
        "name": {
            name: "Имя",
        },
        "patronymic": {
            name: "Отчество",
        },
        "phone": {
            name: "Основной моб. телефон",
            mask: 'phone',
            format: v => _.toNum(v)
        },
        "status": {
            name: "Статус водителя",
            disabled: true,
        },
        "passport.birthDate": {
            name: "Дата рождения",
            type: 'date',
        },
        "passport.birthLocation": {
            name: "Место рождения",
        },
        "passport.passportSeries": {
            name: "Серия",
            rules: [['minLength', 4]],
            mask: "####",
        },
        "passport.passportNumber": {
            name: "Номер паспорта",
            rules: [['minLength', 6]],
            mask: "######",
        },
        "passport.passportDate": {
            name: "Дата выдачи",
            type: 'date',
        },
        "passport.departmentCode": {
            name: "Код подразделения",
            rules: [['minLength', 7]],
            mask: "###-###",
        },
        "passport.issuingAuthority": {
            name: "Кем выдан",
        },
        "passport.registration": {
            name: "Адрес регистрации",
        },
        "passport.citizenship": {
            name: "Гражданство",
        },
        "passport.firstPageScan": {
            name: "Фото 1-й страницы",
            type: 'img',
        },
        "passport.registrationPageScan": {
            name: "Фото регистрации",
            type: 'img',
        },
        "driverLicense.number": {
            name: "Номер",
            format: v => _.toNum(v),
            rules: [
                {
                    rule: ['regex', /^[A-z\d]{3,4}$/],
                    message: 'Номер водит. удостоверения должен состоять из англ. букв, цифр, макс. длина 3-4 символа.'
                },
                ['maxLength', 4]
            ],
        },
        "driverLicense.expireDate": {
            name: "Срок действия",
            type: 'date',
        },
        "driverLicense.scans": {
            name: "Фото удостоверения",
            type: 'img',
        },
        "medicalCard.expireDate": {
            name: "Срок действия",
            type: 'date',
        },
        "medicalCard.scans": {
            name: "Фото мед. книжки",
            type: 'img',
        },
        "skills.unit": {
            name: "Тип",
            type: 'object',
            options: {
                'YEARS': 'Лет',
                'MONTHS': 'Месяцев',
                'TRIPS': 'Поездок',
            },
        },
        "skills.count": {
            name: "Количество",
            type: 'number'
        },
        "kins": {
            component: 'div',
            wrap: {bind: {noWrap: true}},
            render: ({value, vm, set}) => {

                const view = vm.$parent?.view

                const dataModel = _.cloneDeep(vm.$apiModel.driver.model.kins[0])

                const data = {
                    model: dataModel,
                    fields: {
                        kinType: {name: 'Степень родства'},
                        name: {name: 'Имя'},
                        patronymic: {name: 'Отчество'},
                        surname: {name: 'Фамилия'},
                        phoneType: {
                            name: 'Телефон',
                            type: 'select',
                            options: {
                                'HOME': 'Домашний',
                                'WORK': 'Рабочий',
                            }
                        },
                        phoneNumber: {
                            name: 'Номер телефона',
                            mask: 'phone',
                            type: 'input',
                            format: v => _.toNum(v)
                        }
                    },
                }

                const del = (index) => {
                    if (view) return {}
                    return {
                        after: {
                            buttons: {
                                'button.btn.btn-small.btn-white': 'Удалить',
                                on: {
                                    click: () => {
                                        vm.$confirm({
                                            text: 'Вы действительно хотите удалить информацию о родственнике?',
                                            onConfirm: () => {
                                                value.splice(index, 1)
                                                set(value)
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    }
                }

                const kins = []

                if (!value?.length) value = [dataModel]

                if (value.length > 1 && !value[0]?.id && value[1]?.id) value.splice(0, 1)

                _.toArray(value).forEach((kin, index) => {
                    kins.push({
                        DataForm: '',
                        bind: {
                            model: {...data, model: kin, groups: [del(index)]},
                            view,
                            required: true,
                            form: {
                                bind: {inflect: true, radius: true, class: 'mb-20'},
                            },
                            onChange: (data) => {
                                value[index] = data
                            }
                        }
                    })
                })

                view || kins.push({
                    buttons: {
                        'button.btn.btn-small.btn-blue': 'Добавить родственника',
                        on: {
                            click: () => {
                                value.push(dataModel)
                                set(value)
                            }
                        }
                    }
                })

                return kins

            }
        }

    }
}
