<template>
    <Page class="block-contents">
        <Tabs class="fuel-card-tabs" :tabs="tabs" v-model="tab">

            <div slot="active">
                <FormRepairOrderSearch v-model="search.active" @search="reload()" />
                <div class="flex flex-center mb-20">
                    <button class="btn btn-small btn-blue" @click="create">Подать заявку на ремонт</button>
                </div>
                <DataTable
                    :key="load.active"
                    :cols="cols"
                    :cell-bind="{'status.name': {class: 'clr-green'}}"
                    :cells="{
                        number: v => [{a: v}],
                        date: v => $util.date.date(v, true)
                    }"
                    :fetch="fetch"
                    :paginate="true"
                    :assign="{source: 'ACTIVE'}"
                />
            </div>

            <div slot="archived">
                <FormRepairOrderSearch v-model="search.archived" @search="reload()" />
                <div class="flex flex-center mb-20">
                    <button class="btn btn-small btn-blue" @click="create">Подать заявку на ремонт</button>
                </div>
                <DataTable
                    :key="load.archived"
                    :cols="cols"
                    :cell-bind="{'status.name': {class: 'clr-green'}}"
                    :cells="{
                        number: v => ({a: v}),
                        date: v => $util.date.date(v, true)
                    }"
                    :fetch="fetch"
                    :paginate="true"
                    :assign="{source: 'ARCHIVED'}"
                />
            </div>

        </Tabs>

        <Popup name="create-repair-order">
            <h4 class="tx-bold tx-c block-wide m-0-a my-20">
                Вы можете сообщить о проблеме, возникшей в процессе перевозки груза (на загрузке, в пути следования, на выгрузке).
            </h4>
            <FormRepairOrder @success="$popupClose(); $reload()"  />
        </Popup>
    </Page>
</template>

<script>

import FormRepairOrder from "./form/FormRepairOrder.vue"
import FormRepairOrderSearch from "./form/FormRepairOrderSearch.vue"

export default {
    name: "RepairOrders",
    components: {
        FormRepairOrder,
        FormRepairOrderSearch
    },
    data() {
        return {
            load: {
                active: 0,
                archived: 0,
            },
            cols: {
                id: 'ID',
                number: 'Номер заявки',
                date: 'Дата создания',
                'status.name': 'Статус заявки',
                description: 'Описание проблемы',
                comment: 'Комментарий',
            },
            tabs: [
                {
                    id: 'active',
                    name: 'Активные'
                },
                {
                    id: 'archived',
                    name: 'Архивные'
                },
            ],
            tab: 'active',
            search: {
                active: {
                    search: '',
                    orderStatuses: []
                },
                archived: {
                    search: '',
                    orderStatuses: []
                },
            },
        }
    },
    methods: {
        reload() {
            this.load[this.tab] += 1
        },
        fetch(h, a) {
            this.$repo('enterprise').getRepairOrders({...this.search[this.tab], ...a}).then(({list}) => h(list))
        },
        create() {
            this.$popup('create-repair-order', {})
        }
    }
}

</script>
