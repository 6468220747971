import Repo from '@/api/service/repo'

export default class extends Repo {

    //Получение списка связок водителей с ТС
    assigns(params) {
        return this.client.get('/carrier/assigns', params)
    }

    //Редактирование связки водителя с ТС
    patchAssign(id, data) {
        return this.client.patch(`/carrier/assigns/${id}`, data)
    }

    //Удаление связки водителя с ТС
    removeAssign(id) {
        return this.client.delete(`/carrier/assigns/${id}`)
    }

    trucks(params) {
        return this.client.get('/carrier/trucks', params)
    }

    getTruck(id) {
        return this.client.get(`/carrier/trucks/${id}`)
    }

    postTruck(data) {
        return this.client.post(`/carrier/trucks`, data)
    }

    patchTruck(data) {
        return this.client.patch(`/carrier/trucks/${data.id}`, data)
    }

    removeTruck(id) {
        return this.client.delete(`/carrier/trucks/${id}`)
    }

    semitrailers(params) {
        return this.client.get('/carrier/semitrailers', params)
    }

    getSemitrailer(id) {
        return this.client.get(`/carrier/semitrailers/${id}`)
    }

    postSemitrailer(data) {
        return this.client.post(`/carrier/semitrailers`, data)
    }

    patchSemitrailer(id) {
        return this.client.patch(`/carrier/semitrailers/${id}`)
    }

    removeSemitrailer(id) {
        return this.client.delete(`/carrier/semitrailers/${id}`)
    }

    // Список типов транспорта (полуприцепы)
    transportTypes() {
        return this.client.cache({source: 'transport_types'}).get(this.isLogged ? '/transportTypes' : '/transportTypes/unAuthUser')
    }

    //Список способов погрузки
    loadingMethods() {
        return this.client.cache({source: 'loading_methods'}).get('/loadingMethods')
    }

    //Список способов перевозки
    carriageTypes() {
        return this.client.cache({source: 'carriage_types'}).get(this.isLogged ? '/carriageTypes' : '/carriageTypes/unAuthUser')
    }

    //Список способов погрузки/разгрузки
    feedingTypes() {
        return this.client.cache({source: 'feeding_types'}).get(this.isLogged ? '/feedingTypes' : '/feedingTypes/unAuthUser')
    }

}
