<template>
    <DataModel
        v-if="dataModel"
        v-model="dataValue"
        :model="dataModel"
        :tag="dataForm"
        :onChange="onChange"
        class="data-form"
        @setup="i => $emit('setup', i)"
        @change="$emit('change', dataValue)"
    >
        <template v-for="(v, name) of $slots" :slot="name">
            <slot :name="name" />
        </template>
    </DataModel>
</template>

<script>
import {COMPONENT} from '@plugins/DataModel'
import { FORM_ALIASES, INPUT_COMPONENT_TAGS, FORM_ITEM_COMPONENT_TAG, FORM_COMPONENT_TAG } from './Form.vue'

import {FETCH_INPUT_OPTIONS} from './FormInput.vue'

const DEFAULT_INPUT_COMPONENT = FORM_ALIASES['input']
const DEFAULT_INPUT_VIEW_COMPONENT = 'div'
const DEFAULT_INPUT_WRAPPER = {
    ...COMPONENT,
    component: FORM_ITEM_COMPONENT_TAG
}
const DEFAULT_FORM = {
    ...COMPONENT,
    component: FORM_COMPONENT_TAG
}

export default {
    name: "DataForm",
    props: {
        model: {
            type: Object,
            required: true
        },
        form: Object,
        assign: Object,
        rules: [Object, Array, String],
        required: Boolean,
        view: Boolean,
        value: Object,
        onChange: Function
    },
    data() {
        return {
            dataModel: null,
            dataForm: {},
            dataValue: this.value,
        }
    },
    created() {
        this.createForm()
    },
    methods: {
        createForm() {

            this.dataForm = _.merge({}, DEFAULT_FORM, this.form || {})

            const data = _.cloneDeep(this.model)

            const model = _.isPlainObject(data.model) ? data.model : {}

            let fields = _.isPlainObject(data.fields) ? data.fields : {}

            let form = _.isPlainObject(data.form) ? data.form : {}

            this.assign && (form = _.merge(form, this.assign))

            if (_.isEmpty(fields)) return

            _.isEmpty(form) || (fields = _.merge(fields, form))

            Object.keys(fields).forEach(field => {

                _.isPlainObject(fields[field]) || (fields[field] = {})

                const label = fields[field].name

                this.setComponent(field, fields[field], model)

                fields[field] = _.merge({}, COMPONENT, fields[field])

                this.setRules(field, fields[field])

                this.compileField(fields[field])

                this.wrapField(field, fields[field], label)
            })

            data.fields = fields

            this.$set(this, 'dataModel', data)
        },
        setComponent(field, fieldData, model) {

            if (this.view) {
                this.setViewComponent(field, fieldData, model)
                return
            }

            if (fieldData.component) return

            let type = fieldData.type
            let dataType = _.get(model, field)
            let component

            type || _.isNil(dataType) || (type = _.isArray(dataType)
                ? 'array'
                : _.isPlainObject(dataType) ? 'object' : dataType instanceof Date ? 'date' : typeof dataType)

            if (type && INPUT_COMPONENT_TAGS.includes(type)) {
                delete fieldData.type
                component = {component: type}
            }

            type || (component = {component: DEFAULT_INPUT_COMPONENT})

            component || (component = this.getComponentByType(type, dataType, fieldData))

            component && Object.assign(fieldData, component)
        },
        setViewComponent(field, fieldData, model) {

            if (fieldData.type === 'img') {
                Object.assign(fieldData, {
                    component: fieldData.component || FORM_ALIASES['file'],
                    image: true,
                    view: true,
                    type: false,
                })
                return
            }

            fieldData.component || Object.assign(fieldData, {component: DEFAULT_INPUT_VIEW_COMPONENT})

            if (fieldData.value !== undefined) return

            const type = fieldData.type || fieldData.component

            const dataModel = fieldData.model !== undefined ? fieldData.model : _.get(model, field)

            const isConfirm = type === 'confirm' || fieldData.confirm === true

            if (isConfirm || ['boolean', 'checkbox', 'radio'].includes(type)) {
                const options = FETCH_INPUT_OPTIONS(isConfirm, fieldData.options)
                fieldData.value = _.toArray(options).find(opt => opt.value === dataModel)?.text
                return
            }

            if (_.isString(dataModel) && _.includes(type, ['date', 'time', FORM_ALIASES['date']])) {
                fieldData.value = this.$util.date.date(dataModel)
                return
            }

            if ((_.isString(dataModel) || _.isNumber(dataModel))
                && ['select', 'array', 'find', 'object', FORM_ALIASES['select']].includes(type)) {

                if (_.isPlainObject(fieldData.options)) {
                    fieldData.value = fieldData.options[dataModel]
                } else {
                    const label = fieldData.label || 'id'
                    const labelName = fieldData.labelName || 'name'

                    fieldData.value = _.isArrayOfObjects(fieldData.options)
                        ? _.find(fieldData.options, label, dataModel, labelName)
                        : dataModel
                }
                return
            }

            if (fieldData.mask && _.isString(dataModel)) {
                fieldData.value = this.$filter.mask(dataModel, fieldData.mask)
                return
            }

            fieldData.value = _.isObject(dataModel) ? '' : dataModel

        },
        getComponentByType(type, dataType, fieldData) {
            switch (type) {
                case 'select':
                case 'array':
                case 'find':
                    return {
                        component: FORM_ALIASES[type === 'find' ? 'find' : 'select'],
                        objectsArray: type === 'array' || (!_.isNil(fieldData.objectsArray)
                            ? fieldData.objectsArray
                            : (fieldData.options ? _.isArrayOfObjects(fieldData.options) : true)),
                        options: fieldData.options || [],
                    }
                case 'object':
                    return {
                        component: FORM_ALIASES['select'],
                        options:  fieldData.options || {},
                    }
                case 'input':
                case 'text':
                    return {
                        component: DEFAULT_INPUT_COMPONENT
                    }
                case 'number':
                    return {
                        component: DEFAULT_INPUT_COMPONENT,
                        type: fieldData.type || 'number',
                        step: fieldData.step || (dataType?.toString().split('.')[1] || 1)
                    }
                case 'boolean':
                case 'checkbox':
                case 'radio':
                    return {
                        component: DEFAULT_INPUT_COMPONENT,
                        type: fieldData.type || 'checkbox',
                    }
                case 'confirm':
                    return {
                        component: DEFAULT_INPUT_COMPONENT,
                        confirm: true,
                    }
                case 'date':
                case 'time':
                case 'time-s':
                case 'datetime':
                case 'datetime-s':
                case 'datetime-local':
                    return {
                        component: FORM_ALIASES['date'],
                        time: !_.isNil(fieldData.time) ? fieldData.time : type === 'time' || type === 'time-s',
                        sec: !_.isNil(fieldData.sec) ? fieldData.sec : type.includes('time-s'),
                        withTime: !_.isNil(fieldData.withTime) ? fieldData.withTime : type.includes('datetime'),
                        type: type.replace('-s', '')
                    }
                case 'file':
                case 'img':
                    return {
                        component: FORM_ALIASES['file'],
                        image: type === 'img',
                        type: false
                    }
                case 'textarea':
                    return {
                        component: FORM_ALIASES['textarea'],
                    }
                default:
                    return {
                        component: DEFAULT_INPUT_COMPONENT
                    }
            }
        },
        setRules(field, fieldData) {

            if (this.view) return

            if (_.isArray(fieldData.rules)) {

                this.required
                && fieldData.required === undefined
                && !fieldData.rules.find(r => _.isString(r) && r.includes('required'))
                && fieldData.rules.push('required')

                return
            }

            let rules = []

            if (!this.rules) {
                if (this.required) {
                    rules = ['required']
                } else return
            }

            const assign = []

            if (_.isPlainObject(this.rules)) {

                rules = this.rules[field] ? (_.isArray(this.rules[field]) ? this.rules[field] : [this.rules[field]]) : []

            } else if (this.rules && !_.isArray(this.rules)) {

                rules = [this.rules]

            }

            this.required
            && fieldData.required === undefined
            && !rules.find(r => _.isString(r) && r.includes('required'))
            && assign.push('required')

            rules.forEach(rule => (rule && (_.isFunction(rule) || _.isString(rule))) && assign.push(rule))

            Object.assign(fieldData, {rules: assign})
        },
        compileField(fieldData) {

            const data = {}
            const bind = {}
            const component = Object.keys(COMPONENT)

            if (_.isObject(fieldData.bind)) {

                _.isFunction(fieldData.bind)

                    ? Object.assign(bind, {bind: fieldData.bind})

                    : Object.assign(bind, fieldData.bind)

                delete fieldData.bind
            }

            Object.keys(fieldData).forEach(key => {
                if (component.includes(key)) {
                    data[key] = fieldData[key]
                } else {
                    if (!this.view || (fieldData.component === FORM_ALIASES['file'] && fieldData.view === true)) {
                        bind[key] = fieldData[key]
                    } else {
                        delete fieldData[key]
                    }
                }
            })

            Object.assign(data, {bind})

            Object.assign(fieldData, data)

        },
        wrapField(field, fieldData, label) {

            const wrap = fieldData.wrap = _.merge({}, DEFAULT_INPUT_WRAPPER, _.isPlainObject(fieldData.wrap) ? fieldData.wrap : {})

            if (wrap.component === FORM_ITEM_COMPONENT_TAG) {

                _.isPlainObject(wrap.bind) || (wrap.bind = {})

                label === undefined || wrap.bind.label || (wrap.bind.label = label)

                this.view && (wrap.bind.noWrap !== undefined || (wrap.bind.noWrap = true))
            }
        }
    }
}
</script>

<style scoped lang="scss">

.data-form /deep/ .data-model-group {
    margin-bottom: 40px;
    &-header {
        text-align: center;
        color: $black-50;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: $font-proxima-bold;
    }
}

</style>
