<template>
    <div>
        <Popup name="message-confirmation">
            <h3 class="tx-c tx-bold clr-dark">Уведомление</h3>
            <Form v-if="confirm" center wide>
                <FormItem label="Заголовок">
                    <FormInput v-model="confirm.title" disabled/>
                </FormItem>
                <FormItem label="Сообщение">
                    <FormInput v-model="confirm.message" disabled/>
                </FormItem>
                <FormItem label="Файлы">
                    <FormFile v-model="confirm.files" view image/>
                </FormItem>
                <buttons v-if="confirm.confirmation === 'UNCONFIRMED'">
                    После того как ознакомитесь с уведомлением,
                    <br/> нажмите кнопку «Ознакомлен(-а)» и пройдите процесс подтверждения.
                    <button class="btn btn-blue" @click="getMessageConfirmation">Ознакомлен(-а)</button>
                </buttons>
            </Form>
        </Popup>
        <Confirm name="confirm-message">
            <div class="clr-dark">Ваш код подтверждения - {{ code }}</div>
            <FormItem>
                <FormInput v-model="sendCode" placeholder="Введите код подтверждения"/>
            </FormItem>
        </Confirm>
    </div>
</template>

<script>

export default {
    name: "MessageConfirm",
    data() {
        return {
            confirm: null,
            code: null,
            sendCode: null,
        }
    },
    mounted() {
        this.$emit('setup', this)
    },
    methods: {
        open() {
            this.$popup('message-confirmation', {})
        },
        getMessageConfirmation() {
            this.code = null
            this.$repo('notify').getMessageConfirmCode(this.confirm.id).then(resp => {
                this.code = resp.data.code
                this.$confirm('confirm-message', {
                    title: 'Подтверждение',
                    buttons: 'Подтвердить',
                    onConfirm: this.confirmMessage
                })
            }).alert('Не удалось получить код подтверждения.')
        },
        confirmMessage() {
            const code = parseInt(this.sendCode)
            if (this.code !== code) return this.$notify().warn('Не верный код.')

            this.$repo('notify').confirmMessageCode(this.confirm.id, code).then(() => {
                this.confirm.confirmation = 'CONFIRMED'
                this.$popupClose('confirm-message')
            }).done('Код подтвержден.').alert('Не удалось подтвердить код.')
        },
    }
}


</script>

