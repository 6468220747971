import Repo from '@/api/service/repo'

export default class extends Repo {

    list(assign) {
        return this.client.get(this.isLogged ? '/preorders' : '/preorders/unAuthUser', assign)
    }

    get(id, versionOfDataBeforeBid) {
        return this.client.get(this.isLogged ? `/preorders/${id}` : `/preorders/${id}/unAuthUser`, {versionOfDataBeforeBid})
    }

    //Информация о механизме определения позиции в тендере
    agreement() {
        return this.client.get('/bidAgreement')
    }

    favorites() {
        return this.client.get('/favorites/routes/preorder')
    }

    postFavorite(data) {
        return this.client.encode().post('/favorites/routes/preorder', data)
    }

    patchFavorite(data) {
        return this.client.encode().patch('/favorites/routes/preorder', data)
    }

    deleteFavorite(id) {
        return this.client.delete(`/favorites/routes/preorder/${id}`)
    }

    // ПОДАЧА СТАВКИ:

    bids(id) {
        return this.client.get(`/preorders/${id}/allBids`)
    }

    //Получение информации о своей поданной ставке
    getBid(id) {
        return this.client.get(`/preorders/${id}/bid`)
    }

    //Подача информации о своей поданной ставке
    postBid(id, data) {
        return this.client.post(`/preorders/${id}/bid`, data)
    }

    //Удаление информации о своей поданной ставке
    deleteBid(id, params) {
        return this.client.delete(`/preorders/${id}/bid`, params)
    }

    //Обработчик формы подачи ставки
    bidForm(id, data) {
        return this.client.post(`/preorders/${id}/bid/form`, data)
    }

    //Получение подсказки по улучшению ставки
    bidHint(id, params) {
        return this.client.get(`/preorders/${id}/bid/hint`, params)
    }

    //Проверить является ли ставка лучшей
    bidCheck(id, params) {
        return this.client.get(`/preorders/${id}/bid/check`, params)
    }

}
