<template>
    <div class="form-row form-filter">
        <div v-if="$slots.header" class="form-row-header">
            <slot name="header"/>
        </div>
        <div class="form-filter-wrap">
            <Form @submit="$emit('search')" class="form-row-inner">
                <div class="form-row-inner-row">
                    <FormItem
                        type="search"
                        clear
                        @search="$emit('search')"
                        @remove="$emit('search')"
                        style="width: 50%"
                    >
                        <FormInput
                            v-model="form.search"
                            placeholder="Номер заказа, водитель, номер авто"
                            title="Номер заказа, фамилия водителя или номер авто (Целиком или частично)"
                        />
                    </FormItem>
                    <FormItem style="width: 50%">
                        <Multiselect
                            v-if="orderStatuses"
                            v-model="form.orderStatuses"
                            :data="orderStatuses"
                            @closed="s => s.length && $emit('search')"
                            @clear="$emit('search')"
                            class="form-input input"
                        >
                            <div slot="placeholder">Статусы заявок</div>
                        </Multiselect>
                    </FormItem>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormOrderSearch",
    props: {
        value: Object
    },
    data() {
        return {
            form: this.value,
            orderStatuses: null
        }
    },
    created() {
        this.$repo('order').statusTypes().then(({list}) => this.orderStatuses = list)
    }
}
</script>
