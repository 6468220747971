<template>
    <Page>
        <FormRegulation
            v-if="regulation"
            :id="regulation"
            :name="name"
            @toggle="setRegulation(null)"
            @confirmed="setRegulation(null); reload()"
        />
        <div v-show="!regulation" class="pt-40">
            <FormRegulationsSearch v-model="search" @search="reload" />
            <DataTable
                :key="load"
                :cols="{
                    name: 'Наименование',
                    confirmation: 'Статус',
                }"
                :cells="{
                    confirmation: v => v === false ? {'div.clr-gold.tx-inline': 'Новый'} : '',
                }"
                :click="el => setRegulation(el.id, el.name)"
                :assign="{search: search.search}"
                paginate
                :fetch="fetch"
            />
        </div>
    </Page>
</template>

<script>

import FormRegulation from './form/FormRegulation.vue'
import FormRegulationsSearch from './form/FormRegulationsSearch.vue'

export default {
    name: "Regulations",
    props: {
        id: Number
    },
    components: {
        FormRegulationsSearch,
        FormRegulation
    },
    data() {
        return {
            load: 0,
            regulation: null,
            search: {search: ''},
            name: '',
        }
    },
    created() {
        _.isNumber(this.id) && this.setRegulation(this.id)
    },
    methods: {
        reload() {
            this.load += 1
        },
        fetch(h, a) {
            this.$repo('enterprise').regulations(a).then(({list}) => h(list))
        },
        setRegulation(id, name) {
            this.regulation = id
            this.name = name
            if (this.$route.query.id && id) return
            id ? this.$redirect({name: 'Regulations', query: {id}}) : this.$redirect({name: 'Regulations'}, true)
        },
    }
}

</script>
