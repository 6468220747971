<template>
    <Page class="pt-40">
        <h2 class="section-page-title tx-c pl-0">Регистрация водителем</h2>
        <DataForm
            v-if="form"
            :model="form"
            v-model="form.model"
            required
            :form="{
                bind: {
                    buttons: ['Сохранить изменения'],
                    buttonCancel: true,
                    onCancel: () => $router.go(-1),
                    wide: true,
                    center: true
                },
                on: {submit}
            }"
        />
    </Page>
</template>

<script>

export default {
    name: "FormDriverRegister",
    data() {
        return {
            form: null,
        }
    },
    created() {
        this.getForm()
    },
    methods: {
        getForm() {
            this.$service.account.fetchProfile('carrier').then(({object}) => {
                this.form = _.merge(this.$apiModel.driver, { model: object })
                this.form.form = {
                    name: {model: object.name, disabled: true},
                    surname: {model: object.surname, disabled: true},
                    patronymic: {model: object.patronymic, disabled: true},
                    phone: {model: object.phone, disabled: true},
                }
                this.form.fields.passport = _.merge(this.form.fields.passport, object.directorPassport)
                this.load += 1
            })
        },
        submit() {
            this.$repo('driver').save(this.form.model).then(() => {
                setTimeout(() => this.$router.go(-1), 3000)
            })
                .alert('Не удалось зарегистрироваться с ролью "Водитель".')
                .done('Профиль водителя зарегистрирован.')
        }
    },
}

</script>
