<template>
    <section v-if="routeChecked">

        <template v-if="pending"> ...Авторизация</template>

        <router-view v-else :key="$route.path"/>

        <Confirm/>

        <Confirm v-if="authExpired" name="confirm-auth-expired">
            <p>Страница будет перезагружена через {{ authExpiredCountDown }} сек. </p>
        </Confirm>

        <template v-if="acceptAgreement">
            <Confirm name="confirm-agreement">
                Я прочитал условия
                <p><a class="link" @click.prevent="getUserAgreement">ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</a></p>
                и согласен на обработку своих персональных данных.
            </Confirm>
            <Popup name="popup-agreement">
                <h3 class="section-page-title tx-c">Пользовательское соглашение</h3>
                <Document v-if="agreement" :html="agreement" @format="formatUserAgreement" class="block-inflect"/>
            </Popup>
        </template>

    </section>
</template>

<script>
import {PUBLIC_ROUTES} from '@/router/routes'
import Document from '@plugins/Document.vue'

export default {
    name: 'App',
    components: {Document},
    data() {
        return {
            pending: false,
            account: this.$service.account,
            acceptAgreement: false,
            agreementDocVersion: null,
            agreement: null,
            refreshSessionInterval: null,
            authExpiredCountDown: 20,
            routeChecked: false
        }
    },
    computed: {
        authExpired() {
            return this.account.get('authExpired')
        }
    },
    watch: {
        authExpired(expired) {
            expired && this.account.isAuthorized() && this.handleSessionExpired()
        }
    },
    created() {

        setTimeout(() => {

            if (this.$route.hash?.includes('#!/privacy')) {

                this.$router.replace({name: 'Privacy'})

                return this.routeChecked = true
            }

            this.routeChecked = true

            this.authorize().then(() => {

                this.pending = this.account.access.hasAnyRole()

                this.refreshSessionInterval = setInterval(() => this.refreshSession(), this.$app().sessionRefreshPeriod)

                if (this.pending) {
                    this.account.fetchProfile().finally(() => {
                        this.pending = false

                        this.checkUserAgreement(() => {
                            this.$route.name === 'Regulations'
                            || !this.account.access.isDriver()
                            || !this.account.access.isEnterprise()
                            || this.getRegulations()
                        })
                    })
                } else {
                    this.$redirect({name: 'LinkCarrier'})
                    return
                }

                if (this.$app().isTest()) return

                this.$service.firebase.setOnMessage()

                this.$nextTick(() => this.$app().yandexMapSetup())

            }).catch(() => null)

        }, 100)
    },
    methods: {
        authorize() {
            return new Promise((resolve, reject) => {
                console.log('is logged', this.account.isLogged())
                if (false === this.account.isLogged()) {
                    this.account.logout()
                    this.$route.path === '/' || PUBLIC_ROUTES.includes(this.$route.name) || this.$router.replace({name: 'Login'})
                    reject()
                    return
                }

                this.pending = true
                this.account.checkSession().then(() => {
                    this.account.fetchAccount().then(({object}) => {
                        if (this.account.isAuthExpired()) {
                            this.account.set('authExpired', true)
                            reject()
                            return
                        }
                        object ? resolve(object) : reject()
                    }).alert('Не удалось авторизоваться. Профиль не загружен.')
                }).error(() => {
                    reject()
                    this.refreshSession(true)
                    this.pending = false
                }).alert(false)
            })
        },
        refreshSession(reload) {
            const phone = this.account.get('phone')
            const refreshTokenUID = this.account.getRefreshTokenId()

            this.account.refreshSession({phone, refreshTokenUID}).finally(({response}) => {
                if (response?.data.session) {
                    this.account.setSession(response.data.session)
                    return reload && this.$util.page.reload({})
                }

                clearInterval(this.refreshSessionInterval)
                this.account.logout()
                this.$route.path === '/' || PUBLIC_ROUTES.includes(this.$route.name) || this.$router.replace({name: 'Login'})
            }).alert(false)
        },
        handleSessionExpired() {
            let interval
            let retry = 0
            const reload = () => {
                this.account.logout()
                this.$util.page.reload({})
            }
            const handle = () => {
                if (retry > 1) return reload()
                this.authExpiredCountDown = 20
                this.$confirm('confirm-auth-expired', {
                    title: 'Рабочая сессия истекла',
                    buttons: 'Перезагрузить',
                    buttonCancel: true,
                    onConfirm: reload,
                    onCancel: () => {
                        retry += 1
                        clearInterval(interval)
                        setTimeout(handle, 20000)
                    }
                })
                interval = setInterval(() => {
                    if (this.authExpiredCountDown <= 0) {
                        return reload()
                    }
                    this.authExpiredCountDown -= 1
                }, 1000)
            }
            this.$nextTick(handle)
        },
        checkUserAgreement(cb) {
            this.acceptAgreement = false
            this.agreementDocVersion = null
            this.agreement = null
            this.$repo('docs').checkAgreement().then(({object}) => {
                this.agreementDocVersion = object.docVersion
                if (this.agreementDocVersion === null)
                    return cb && cb()
                this.acceptAgreement = true
                this.$nextTick(() => {
                    this.$confirm('confirm-agreement', {
                        title: 'Пользовательское соглашение было изменено и с ним необходимо ознакомиться',
                        buttons: [{
                            text: 'Ознакомиться',
                            on: {click: this.getUserAgreement}
                        }]
                    })
                })
            }).error(cb).catch(cb)
        },
        getUserAgreement() {
            this.$repo('docs').getAgreement(this.agreementDocVersion).then(r => {
                this.agreement = r.data
                this.$popup('popup-agreement', {
                    buttons: [{
                        text: 'Прочитал и согласен',
                        on: {click: this.acceptUserAgreement}
                    }]
                })
            })
        },
        formatUserAgreement(doc) {
            doc = $(doc)
            const title = this.$util.page.findEl({index: 0, text: 'Пользовательское соглашение'}, doc)
            title && title.remove()
            doc.html(doc.html().replace(/<p[^>]+?>\s*?<br[^>]+?>\s*?<\/p>/g, '<p> </p>'))
            this.$service.device.isPhone() || doc.css({textAlign: 'justify'})
        },
        acceptUserAgreement() {
            this.$repo('docs').acceptAgreement(this.agreementDocVersion).then(() => {
                this.$popupClose()
            }).done('Пользовательское соглашение подтверждено.').alert('Не удалось подтвердить пользовательское соглашение.')
        },
        getRegulations() {
            this.$rs.bg().repo('enterprise').regulations({confirmation: false}).catch().alert(false).finally(({list}) => {
                list?.length && this.$confirm('confirm-agreement', {
                    text: `${list.length > 1
                        ? 'Есть нормативные документы с которыми'
                        : 'Есть нормативный документ с которым'} Вам необходимо ознакомиться.`,
                    onConfirm: () => {
                        this.$router.replace({path: `/enterprise/regulations/${list.length === 1 ? list[0]['id'] : ''}`})
                    },
                    buttons: 'Открыть'
                })
            })
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss";
@import "@/assets/scss/fonts";
@import "@/assets/scss/form";
@import "@/assets/scss/buttons";
@import "@/assets/scss/utils";
@import "@/assets/scss/vendors";

// MODULES:
@import "@/assets/scss/mod/block";
@import "@/assets/scss/mod/form-row";
@import "@/assets/scss/mod/form-filter";
@import "@/assets/scss/mod/loader";

//COMMON:
.user-link-icon {
    background: url(~@/assets/icons/add-user.png) center no-repeat;
    background-size: cover;
    background-position: center center;
}

</style>
