var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"pt-40"},[_c('h2',{staticClass:"section-page-title tx-c pl-0"},[_vm._v("Регистрация водителем")]),(_vm.form)?_c('DataForm',{attrs:{"model":_vm.form,"required":"","form":{
            bind: {
                buttons: ['Сохранить изменения'],
                buttonCancel: true,
                onCancel: function () { return _vm.$router.go(-1); },
                wide: true,
                center: true
            },
            on: {submit: _vm.submit}
        }},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }