import {RequestService} from '@argab/request-service'
import ApiClient from './client'
import ApiHandler from './handler'
import ApiCacheHandler from '../handlers/api_cache_handler'
import ApiNotificationHandler from '../handlers/api_notification_handler'
import ApiStubHandler from '../handlers/api_stub_handler'
import ApiLoader from './loader'
import Cache from './cache'
import helper from '@/helpers'
import app from '@/config/app'

const request = new RequestService({
    getRepo: (path) => {
        const Repo = require(`@/api/repos/${path}`).default
        return new Repo
    },
    config: {
        client: ApiClient,
        handler: [ApiHandler, ApiNotificationHandler, ApiStubHandler, ApiCacheHandler],
        loader: ApiLoader,
        useLoader: true,
    },
    extend: {
        middleware: {
            format: function (func) {
                this.config({format: func})
            },
            cache: function ({source, onGet, onSend}) {
                this.config({
                    cache: new Cache({
                        provider: 'STORAGE',
                        props: {source, onGet, onSend}
                    })
                })
            },
            db: function ({source, onGet, onSend}) {
                this.config({
                    cache: new Cache({
                        provider: 'DATABASE',
                        props: {source, onGet, onSend}
                    })
                })
            },
        },
        request: {
            done: function (messageOnSuccess) {
                this.data.done = messageOnSuccess
            },
            alert: function (messageOnError) {
                this.data.alert = messageOnError
            }
        }
    }
})

export const getTitle = (resp) => {
    return helper.$config.api().responseMessages[resp.data.result] || ''
}

export const getMessage = (resp) => {
    const paramsMsg = _.get(resp.data, 'params.message', '')
    const details = _.get(resp.data, 'params.details', '')
    const msg = _.get(resp.data, 'message', '')
    return helper.$filter.cutHtml(paramsMsg || details || msg)
}

export class RequestWatch {

    constructor(requestService) {
        this.rs = requestService || request
    }

    getLog() {
        const log = this.rs.getLog()
        return Array.isArray(log) ? [...log] : []
    }

    filter({uri, method, search}) {
        const match = (endpoint, data) => {
            return search ? search(data) : endpoint.includes(uri)
        }
        const list = []
        this.getLog().forEach(r => {
            if (method !== undefined
                ? (match(r.data.uri, r.data) && r.data.method === method)
                : match(r.data.uri, r.data)) list.push(r)
        })
        return list
    }

    find({uri, method, search, index}) {
        const list = this.filter({uri, method, search})
        const request = list[index !== undefined ? index : list.length - 1]
        return request ? request.data : null
    }
}

if (app.isDev()) {
    global.rs = new RequestWatch()
}

export default request
