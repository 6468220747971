<template>
    <div class="h-scroll">
        <slot></slot>
        <div class="--dir" v-if="scrollable" v-bind="dirBind || {}">
            <div class="--left" @mousedown="toLeft" @touchstart="toLeft" v-html="dirLeft"></div>
            <div class="--right" @mousedown="toRight" @touchstart="toRight" v-html="dirRight"></div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "HScroll",
        props: {
            width: Number,
            getWidth: Function,
            container: Function,
            scrollStep: {
                type: Function,
                default: () => 300
            },
            scrollSpeed: {
                type: Function,
                default: () => 3
            },
            dirBind: Object,
            dirLeft: {
                type: String,
                default: '&#8249;'
            },
            dirRight: {
                type: String,
                default: '&#8250;'
            },
            offset: [Function, Number]
        },
        data() {
            return {
                scrollable: false,
                scrollingTimer: null
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.apply()
                if (this.offset && this.container) {
                    const offset = this.offset instanceof Function ? this.offset(this) : +this.offset
                    if (!_.isNumber(offset)) return
                    this.container().scrollLeft = offset
                }
            })
        },
        methods: {
            apply() {
                const width = this.width || this.$el.offsetWidth
                this.scrollable = false
                this.$emit('scrollable', false)
                if (!this.container || !this.container()) {
                    this.scrollable = this.getWidth && this.getWidth(width) > width
                } else {
                    this.scrollable = this.container().offsetWidth > width
                    this.container().style.justifyContent = 'left'
                    this.container().style.overflowX = 'hidden'
                }
                this.$emit('scrollable', this.scrollable)
            },
            toRight() {
                this.$emit('scrollRight')
                if (!this.container || !this.container())
                    return false
                this.scroll(1, this.scrollStep(), this.container())
            },
            toLeft() {
                this.$emit('scrollLeft')
                if (!this.container || !this.container())
                    return false
                this.scroll(-1, this.scrollStep(), this.container())
            },
            scroll(direct, step, container) {
                clearInterval(this.scrollingTimer)
                let currentShift = 0
                this.scrollingTimer = setInterval(() => {
                    container.scrollLeft += direct*this.scrollSpeed()
                    currentShift += this.scrollSpeed()
                    if (currentShift >= step) {
                        clearInterval(this.scrollingTimer)
                    }
                }, 1)
            }
        }
    }

</script>

<style lang="scss" scoped>

    .h-scroll {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        .--dir {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 2px;
            font-size: 14pt;
            > div {
                background-color: $blue;
                color: #fff;
                text-align: center;
                cursor: pointer;
                padding: 5px;
            }
            .--left {
                border-radius: 5px 0 0 5px;
                border-right: #fff 1px solid;
            }
            .--right {
                border-radius: 0 5px 5px 0;
            }
        }
    }

</style>
