import Repo from '@/api/service/repo'

export default class extends Repo {

    // Привязка пользователя к существующему водителю по подтвержденным реквизитам
    linkExisting(carrierBackendID) {
        return this.client.encode().post('/carrier/linkExisting', {carrierBackendID})
    }

    // Поиск Перевозчиков по ранее подтвержденным реквизитам
    search({phone, email}) {
        return this.client.get('/carrier/search', {phone, email})
    }

    save(data) {
        return this.client.post('/carrier', data)
    }

    edit(data) {
        return this.client.patch('/carrier', data)
    }

    tryLinkToSelfOwn() {
        return this.client.get('/driver/tryLinkToSelfOwn')
    }

}
