<template>
    <Popup :name="name" class="confirm">
        <div class="confirm-text">
            <!--<img v-if="type === 'danger'" src="@/assets/images/error.svg" alt="">-->
            <!--<img v-if="type === 'success'" src="@/assets/images/published.svg" alt="">-->
            <h3 v-if="title" class="confirm-title">{{ title }}</h3>
            <div v-html="text"></div>
            <slot/>
        </div>
        <Buttons
            v-if="buttons"
            :buttons="buttons"
            :inline="buttonsInline"
            :cancel="buttonCancel"
             @btn-click="btnClick"
             @cancel="cancel"
            class="mb-20"
        />
    </Popup>
</template>

<script>

import {CONFIRMATION_MODAL_NAME} from '@/config/app'

const PARAMS = {
    type: '',
    text: '',
    title: '',
    buttons: null,
    buttonsInline: false,
    buttonCancel: false,
    onConfirm: null,
    onDecline: null,
    onCancel: null,
    onClose: null,
    onOpen: null,
    style: function () {
        const device = this.$service.device
        return {
            height: 'auto',
            width: device.isTablet() ? '70%' : device.isLaptop() ? '50%' : '35%',
        }
    }
}

export default {
    name: 'Confirm',
    data: () => ({
        ...PARAMS
    }),
    computed: {
        name() {
            return this.$attrs.name || CONFIRMATION_MODAL_NAME
        },
        confirmationButtons() {
            return [
                {text: 'Да', on: {click: this.confirm}},
                {text: 'Нет', on: {click: this.decline}, bind: {class: 'btn btn-white'}},
            ]
        }
    },
    created() {
        this.$registerComponent('confirms', this.name)
    },
    methods: {
        show(data) {
            this.resetData()
            this.setData(data)
            this.$nextTick(() => this.$popup(this.name, {
                style: this.style,
                onOpen: this.onOpen,
                onClose: this.onClose,
                header: false,
                footer: false,
                clickToClose: false,
                pageScroll: false,
            }))
        },
        setData({
            style,
            type,
            text,
            title,
            buttons,
            buttonsInline,
            buttonCancel,
            onConfirm,
            onDecline,
            onCancel,
            onClose,
            onOpen
        }) {
            _.isFunction(style) && (this.style = style)
            _.isPlainObject(style) && Object.assign(this.style(), style)
            this.buttonCancel = Boolean(buttonCancel)
            this.onCancel = onCancel
            this.onClose = onClose
            this.onOpen = onOpen
            this.buttonsInline = Boolean(buttonsInline)
            this.setHandlers(onConfirm, onDecline)
            type && this.setType(type)
            text && this.setText(text)
            title && this.setTitle(title)
            this.buttons = buttons || this.confirmationButtons
        },
        resetData() {
            Object.assign(this, {...PARAMS})
        },
        setType(type) {
            this.type = type
        },
        setText(text) {
            this.text = text
        },
        setTitle(title) {
            this.title = title
        },
        setHandlers(onConfirm, onDecline) {
            this.onConfirm = onConfirm
            this.onDecline = onDecline
        },
        confirm() {
            this.onConfirm && this.onConfirm()
            this.close()
        },
        decline() {
            this.onDecline && this.onDecline()
            this.close()
        },
        cancel() {
            this.onCancel && this.onCancel()
            this.close()
        },
        close() {
            this.$popup(this.name).close()
        },
        btnClick(index) {
            const length = _.isArray(this.buttons) ? this.buttons.length - 1 : 1
            index === 0 && (length > 1 ? this.decline() : this.confirm())
            index >= 1 && this.confirm()
        }
    }
}
</script>

<style lang="scss" scoped>
.confirm {
    &-text {
        padding: 40px 52px 25px;
        text-align: center;
        font-size: 18px;
        margin: 0;

        > h1, h2, h3 {
            margin: 0 0 20px;
            padding: 0;
        }
    }

    &-title {
        margin-top: 20px;
        margin-bottom: 0;
        font-weight: 800;
        font-size: 24px;
    }
}
</style>
