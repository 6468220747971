<template>
    <div class="manager-chat">

        <template v-if="chat">
            <div class="manager-chat-messages">
                <div class="manager-chat-messages-header">
                    <div class="clr-blue">
                        <small class="tx-bold">
                            {{chat.title}} ({{chat.phone | mask('phone')}})
                        </small>
                    </div>
                    <div class="clr-ltg-2">
                        <small>
                            {{chat.description}}
                        </small>
                    </div>
                    <div class="tx-c mt-5">
                        <a @click="resetChat">
                            <small>&lt;&lt; Вернуться к списку чатов</small>
                        </a>
                    </div>
                </div>
                <div ref="messages" class="manager-chat-messages-body">
                    <template v-for="message in messages">
                        <div :key="message.id" :class="[message.messageType === 'IN' ? '--message-in' : '--message-out']">
                            <div v-html="$filter.nl2br(message.message)"></div>
                            <div class="--message-author">
                                <small v-if="message.userName" class="tx-bold pr-15">{{message.userName}}</small>
                                <small class="clr-ltg-2">{{ $util.date.fromUtc(message.date) }}</small>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="manager-chat-messages-footer">
                    <div style="width: 100%">
                        <TextareaAutosize
                            placeholder="Ваше сообщение"
                            :max-height="150"
                            v-model="messageText"
                        />
                    </div>
                    <div style="min-width: 40px" class="--send-btn">
                        <FaIcon
                            icon="angle-double-right"
                            class="--send-icon link"
                            title="Отправить сообщение"
                            @click="sendMessage"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <h3 class="tx-c tx-bold">Список чатов</h3>
            <div class="manager-chat-list">
                <ol v-for="item in list" :key="item.id">
                    <li>
                        <h4 class="link link-black" @click="resetChat(item.id)">
                            <strong class="tx-bold">{{item.title}} ({{item.phone | mask('phone')}})</strong>
                        </h4>
                        <small class="clr-ltg-3">{{item.description}}</small>
                    </li>
                </ol>
            </div>
        </template>
    </div>
</template>

<script>

const OFFSET_SCROLL_PAGE = 0.10

const CHAT = {
    messages: [],
    page: 0,
    pageSize: 50,
    paginate: true,
    lastMessageId: 0,
    lockMessages: false,
    messageText: null,
    scroll: null,
    scrollPos: null,
    checkMessagesInterval: null,
}

export default {
    name: "ManagerChat",
    props: {
        promptMessage: String,
        fetchFirstChat: Boolean,
        chatId: Number,
    },
    data() {
        return {
            list: [],
            chat: null,
            ...CHAT
        }
    },
    created() {
        this.getChats(this.chatId).then(() => {
            this.fetchFirstChat && !this.chatId && this.list.length && this.resetChat(this.list[0].id)
            this.promptMessage && this.$set(this, 'messageText', this.promptMessage)
        })
    },
    beforeDestroy() {
        this.resetChat()
    },
    methods: {
        getChats(chatId) {
            return this.$repo('chat').managerChats().then(({list}) => {
                this.list = list
                chatId && this.list.find(i => i.id === parseInt(chatId)) && this.resetChat(parseInt(chatId))
            })
        },
        getMessages() {

            if (this.lockMessages || !this.chat?.id) return

            const scroll = this.scroll

            const params = {}

            if (scroll === 'UP') {

                Object.assign(params, {lastMessageId: this.lastMessageId})

            } else if (!scroll || scroll === 'DOWN') {

                if (!this.paginate) return

                Object.assign(params, {page: this.page, pageSize: this.pageSize})
            }

            this.lockMessages = true

            this.$rs.useLoader(!this.messages.length).repo('chat').getMessages(this.chat.id, params).then(({list}) => {

                if (!scroll || scroll === 'DOWN') {

                    if (!list.length) {

                        this.paginate = false

                        return
                    }

                    if (!this.lastMessageId && list[0]?.id) this.lastMessageId = list[0].id

                    this.appendMessages(list)

                    this.page += 1

                } else {

                    this.prependMessages(list)
                }

            }).finally(() => {
                this.lockMessages = false
            })
        },
        checkMessages() {

            if (!this.chat?.id || !this.lastMessageId) return

            this.$rs.bg().repo('chat').getMessages(this.chat.id, {lastMessageId: this.lastMessageId}).then(({list}) => {
                if (!list.length) return
                this.prependMessages(list)
                if (this.scrollPos && this.scrollPos <= OFFSET_SCROLL_PAGE*2) {
                    this.$refs.messages.scrollTop = 0
                }
            })
        },
        prependMessages(list) {
            this.$set(this, 'messages', _.uniqBy([...list, ...this.messages], 'id'))
            list[0]?.id && (this.lastMessageId = list[0].id)
        },
        appendMessages(list) {
            this.$set(this, 'messages', _.uniqBy([...this.messages, ...list], 'id'))
        },
        resetChat(chatId) {
            this.chat = chatId ? this.list.find(i => i.id === chatId) : null
            Object.assign(this, {...CHAT})
            chatId && this.$nextTick(() => {
                this.getMessages()
                this.$refs.messages?.addEventListener('scroll', this.onScroll)
                this.checkMessagesInterval ||= setInterval(this.checkMessages, 30000)
            })
            if (!chatId) {
                clearInterval(this.checkMessagesInterval)
                this.checkMessagesInterval = null
            }
        },
        onScroll() {

            const messages = this.$refs.messages
            const scrollTop = messages.scrollTop
            const scrollHeight = messages.scrollHeight - messages.clientHeight
            const scrollPos = parseFloat((scrollTop/scrollHeight).toFixed(2))

            if (this.scrollPos !== null && scrollPos < this.scrollPos) {
                this.$set(this, 'scrollPos', scrollPos)
                this.$set(this, 'scroll', 'UP')
                return scrollPos <= OFFSET_SCROLL_PAGE && this.getMessages()
            }

            if (this.scrollPos !== null && scrollPos > this.scrollPos) {
                this.$set(this, 'scrollPos', scrollPos)
                this.$set(this, 'scroll', 'DOWN')
                return scrollPos >= (1-OFFSET_SCROLL_PAGE) && this.getMessages()
            }

            this.$set(this, 'scrollPos', scrollPos)
            this.$set(this, 'scroll', null)
        },
        sendMessage() {

            if (!this.messageText?.trim()) return

            this.lockMessages = true

            this.$repo('chat').sendMessage(this.chat.id, {

                linkedObjectId: this.chat.id,
                linkedObjectType: 'CHAT',
                message: this.messageText

            }).then(({object}) => {

                this.messageText = null
                this.prependMessages([object])
                this.$refs.messages.scrollTop = 0

            }).finally(() => {
                this.lockMessages = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
.manager-chat {
    height: 98%;
    &-messages {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin: 5px;
        text-align: left;

        > div {
            border: #ddd 1px solid;
            border-radius: 4px;
        }

        &-header {
            display: block;
            height: 10%;
            padding: 10px;
        }
        &-body {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 5px;
            overflow: hidden;
            overflow-y: auto;
            height: 95%;

            > div {
                padding: 10px;
                border-radius: 4px;
                word-break: break-word;
                font-size: 0.95em;

                .--message-author {
                    margin-top: 10px;
                }

                &.--message-in {
                    background-color: aliceblue;
                }

                &.--message-out {
                    width: 60%;
                    align-self: end;
                    background-color: #dcefff;

                    @media (max-width: 425px) {
                        width: 80%;
                    }
                }
            }
        }
        &-footer {
            padding: 0;
            display: flex;
            justify-content: center;

            textarea {
                padding: 10px;
            }

            .--send-btn {
                align-self: center;
                text-align: center;
            }

            .--send-icon {
                font-size: 1.5em;
            }
        }
    }

    &-list {
        > ol {
            padding-left: 40px;
            text-align: left;
        }
    }
}
</style>
