<template>
    <Form @submit="submit" inflect class="my-20 py-20 px-15" :buttons="[
        'Сохранить', id ? {
            text: 'Удалить',
            on: {click: remove},
            bind: {class: 'btn btn-red'}
        } : null]"
    >
        <FormItem row label="Интервал погрузки" noShadow>
            <FormDate
                v-model="formData.loadingDateFrom"
                placeholder="От"
                box
            />
            <FormDate
                :key="formData.loadingDateFrom"
                v-model="formData.loadingDateTo"
                placeholder="До"
                fromCurrent
                box
                :rules="[
                    v => {
                        return $util.date.toUtc(v) <= $util.date.toUtc(formData.loadingDateFrom)
                        && 'Некорректно задан интервал погрузки.'
                    }
                ]"
            />
        </FormItem>
        <FormItem :key="formData.loadingRegionId + 'a'" label="Город загрузки" clear>
            <FormFindSelect
                :required="(v) => !v && !formData.loadingRegionId"
                objectsArray
                v-model="formData.loadingCityId"
                :options="cities"
                label="id"
                labelName="name"
                :watch="v => { !v || (formData.loadingRegionId = '') }"
            />
        </FormItem>
        <FormItem :key="formData.loadingCityId + 'b'" label="Регион загрузки" clear>
            <FormFindSelect
                :required="(v) => !v && !formData.loadingCityId"
                objectsArray
                v-model="formData.loadingRegionId"
                :options="regions"
                label="id"
                labelName="name"
                :watch="v => { !v || (formData.loadingCityId = '') }"
            />
        </FormItem>

        <FormItem :key="formData.unloadingRegionId + 'c'" label="Город выгрузки" clear>
            <FormFindSelect
                :required="(v) => !v && !formData.unloadingRegionId"
                objectsArray
                v-model="formData.unloadingCityId"
                :options="cities"
                label="id"
                labelName="name"
                :watch="v => { !v || (formData.unloadingRegionId = '') }"
            />
        </FormItem>
        <FormItem :key="formData.unloadingCityId + 'd'" label="Регион выгрузки" clear>
            <FormFindSelect
                :required="(v) => !v && !formData.unloadingCityId"
                objectsArray
                v-model="formData.unloadingRegionId"
                :options="regions"
                label="id"
                labelName="name"
                :watch="v => { !v || (formData.unloadingCityId = '') }"
            />
        </FormItem>

        <FormItem label="Тип транспорта">
            <Multiselect
                v-model="formData.transportTypeId"
                :data="transportTypes"
                class="form-input input"
            />
        </FormItem>
        <FormItem label="Способы погрузки">
            <Multiselect
                v-model="formData.loadingMethodsId"
                :data="loadingMethods"
                class="form-input input"
            />
        </FormItem>
        <FormItem label="Активно" noWrap class="tx-c pb-10">
            <Toggle
                :value="formData.enabled"
                @change="({value}) => formData.enabled = value"
            />
        </FormItem>
    </Form>
</template>

<script>

export default {
    name: "FormFavoriteRoutes",
    props: {
        cities: undefined,
        regions: undefined,
        transportTypes: undefined,
        loadingMethods: undefined,
        form: {
            type: Object,
            default: null,
            required: false
        }
    },
    data() {
        return {
            id: 0,
            formData: {
                loadingDateFrom: null,
                loadingDateTo: null,
                loadingCityId: '',
                loadingRegionId: '',
                unloadingCityId: '',
                unloadingRegionId: '',
                transportTypeId: [],
                loadingMethodsId: [],
                enabled: true,
            }
        }
    },
    created() {
        _.isEmpty(this.form) || (this.formData = {
            id: this.id = _.get(this.form, 'id', null),
            loadingDateFrom: _.get(this.form, 'loadingDateFrom', null),
            loadingDateTo: _.get(this.form, 'loadingDateTo', null),
            loadingCityId: _.get(this.form, 'loadingCity.id', ''),
            loadingRegionId: _.get(this.form, 'loadingRegion.id', ''),
            unloadingCityId: _.get(this.form, 'unloadingCity.id', ''),
            unloadingRegionId: _.get(this.form, 'unloadingRegion.id', ''),
            transportTypeId: _.get(this.form, 'transportType', []),
            loadingMethodsId: _.get(this.form, 'loadingMethods', []),
            enabled: this.form.enabled,
        })
    },
    methods: {
        submit() {
            this.$repo('preorder')[this.id ? 'patchFavorite' : 'postFavorite'](this.formData).then(() => {
                this.$emit('success')
                this.$popupClose()
            })
                .done(this.id > 0 ? 'Направление успешно изменено.' : 'Направление успешно добавлено.')
                .alert(this.id > 0 ? 'Не удалось изменить направление.' : 'Не удалось добавить направление.')
        },
        remove() {
            this.$repo('preorder').deleteFavorite(this.id).then(() => {
                this.$emit('success')
                this.$popupClose()
            }).done('Направление успешно удалено.').alert('Не удалось удалить направление.')
        }
    }
}

</script>
