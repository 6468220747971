import Repo from '@/api/service/repo'

export default class extends Repo {

    //Получение списка статусов рейса
    rftStatuses(orderId) {
        return this.client.get(`/carrier/orders/${orderId}/rft/${orderId}/statuses`)
    }

    //Установка статуса рейса
    setRftStatus(orderId, data) {
        return this.client.encode().post(`/driver/rft/${orderId}/setStatus`, data)
    }

    //Список заявок на перевозку для водителя
    driverRft(params) {
        return this.client.get('/driver/rft', params)
    }

    //Подтверждение заказа водителем
    driverRftConfirm(orderId, { confirmationCode, carrierOrderKey }) {
        return this.client.encode().post(`/driver/rft/${orderId}/confirm`, { confirmationCode, carrierOrderKey })
    }

    //Отказ от заказа водителем
    driverRftDecline(orderId, carrierOrderKey) {
        return this.client.encode().post(`/driver/rft/${orderId}/decline`, {carrierOrderKey})
    }

    //Получение кода для подтверждения заказа водителем
    getDriverRftConfirmationCode(id, carrierOrderKey) {
        return this.client.get(`/driver/rft/${id}/getConfirmationCode`, {carrierOrderKey})
    }
}
