<template>
    <Multiselect
        v-model="val"
        :data="options"
        :label="label"
        :labelName="labelName"
        :output="output"
        class="form-input input"
        @selected="input"
    />
</template>

<script>
export default {
    name: "FormMultiSelect",
    props: {
        index: Number,
        value: Array,
        rules: Array,
        options: [Function, Array],
        label: String,
        labelName: String,
        output: String,
        watch: Function,
        format: Function,
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    computed: {
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    data() {
        return {
            val: this.value,
            validator: null,
        }
    },
    created() {
        let rules = this.rules || []
        this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')
        this.required === false && (rules = rules.filter(r => r !== 'required'))
        rules.length && (this.validator = new this.$service.validation({rules, name: this.$attrs.name}))
        this.isDisabled || (this.validator && this.validator.validate(this.val))
    },
    methods: {
        input() {
            let val = this.val

            if (this.format) {
                val = this.format(val)
            }
            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(val))
            }
            if (this.watch) {
                const value = this.watch(val, this)
                value !== undefined && (val = value)
            }
            this.$emit('input', val)
        },
        getAttributes() {
            return this.$attrs || {}
        },
    }
}
</script>
