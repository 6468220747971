<template>
    <Page class="fuel-card">
        <template v-if="canViewFuelReminder === null && canTopUpFuelCards === null">
            Загрузка...
        </template>
        <template v-else>
            <div v-if="getMode() === 'topup'">
                <template v-if="cards && cards.length">
                    <p class="tx-c clr-ltg up mt-40">
                        {{ canTopUpFuelCards ? `Выберите карту для пополнения` : `Выберите карту` }}
                    </p>
                    <FormItem class="my-20" clear>
                        <FormInput
                            v-model="cardFilter"
                            placeholder="Номер карты или название"
                        />
                    </FormItem>
                </template>
                <Accordion v-if="cards && cards.length" :key="updateKey">
                    <AccordionItem
                        v-for="(card, k) in getCardFiltered()"
                        :key="k"
                        @opened="getOrders(card.fuelCardId)"
                        @closed="deleteCardOrdersAmount()"
                    >
                        <template slot="title">
                            <div class="fuel-card-title">
                                <div class="fuel-card-name">
                                    <div class="mb-10">
                                        {{ card.fuelCardNumber }}
                                    </div>
                                    <template v-if="canTopUpFuelCards">
                                        <FaIcon
                                            icon="edit"
                                            @click.stop="setCardName(card.fuelCardId)"
                                            title="Добавить/Изменить имя карты"
                                            class="link link-black"
                                        />
                                    </template>
                                    <template v-if="card.fuelCardShowing">
                                        <i class="clr-ltg-2 pl-15">{{ card.fuelCardShowing }}</i>
                                    </template>
                                    <i v-else-if="canTopUpFuelCards" class="clr-ltg-2 pl-15">
                                        - без имени -
                                    </i>
                                </div>
                                <div>
                                    <img
                                        v-if="card.fillingStationId"
                                        alt=""
                                        :src="require(`@/assets/icons/fuel-card/${card.fillingStationId}.png`)"
                                        class="fuel-card-icon"
                                    />
                                </div>
                            </div>
                        </template>
                        <div class="fuel-card-content">
                            <template v-if="canViewFuelReminder === false && canTopUpFuelCards === false">
                                <h3>
                                    У Вас нет разрешений на просмотр <br>свободного остатка
                                    д/с по топливным картам и их пополнения...
                                </h3>
                            </template>
                            <template v-else>
                                <template v-if="!(card.fuelCardId in cardOrders) || !cardOrders[card.fuelCardId]">
                                    Загрузка...
                                </template>
                                <div v-else-if="cardOrders[card.fuelCardId].length" class="pb-15 px-15">
                                    <p>
                                        {{
                                            canTopUpFuelCards
                                                ? `Выберите заказы для пополнения`
                                                : `Свободные остатки д/с по заказам`
                                        }}
                                    </p>
                                    <ul class="fuel-card-orders list">
                                        <li v-for="(o, k) in cardOrders[card.fuelCardId]" :key="k">
                                            <div class="fuel-card-order-num">
                                                №{{ o.orderNumber }}
                                            </div>
                                            <div v-if="canViewFuelReminder" class="fuel-card-order-amount clr-green my-10">
                                                {{ $$.price(o.affordableBidAmount) }}
                                            </div>
                                            <FormItem v-if="canTopUpFuelCards" class="mb-20">
                                                <FormInput
                                                    v-model.lazy="cardOrdersAmount[o.orderId]"
                                                    @keyup="setOrderAmount(o.orderId)"
                                                    placeholder="Сумма"
                                                />
                                            </FormItem>
                                        </li>
                                    </ul>
                                    <div v-if="canTopUpFuelCards" class="buttons my-20">
                                        <button class="btn btn-blue" @click="topUpFuelCard(card.fuelCardId)">
                                            Пополнить
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    <h3 class="tx-c clr-ltg-2">Нет доступных заказов для пополнения</h3>
                                </div>
                            </template>
                        </div>
                    </AccordionItem>
                </Accordion>
                <h2 v-else class="tx-c clr-ltg up">Карт не обнаружено</h2>
            </div>

            <div v-if="getMode() === 'history'">
                <FormItem v-if="cards && cards.length" class="my-20" clear>
                    <FormInput
                        v-model="cardFilter"
                        placeholder="Номер карты или название"
                    />
                </FormItem>
                <Accordion v-if="cards && cards.length" :key="updateKey">
                    <AccordionItem
                        v-for="(card, k) in getCardFiltered()"
                        :key="k"
                        @opened="openHistory(card.fuelCardId)"
                        @closed="closeHistory(card.fuelCardId)"
                    >
                        <template slot="title">
                            <div class="fuel-card-title">
                                <div class="fuel-card-name">
                                    <div class="mb-10">
                                        {{ card.fuelCardNumber }}
                                    </div>
                                    <template v-if="canTopUpFuelCards">
                                        <FaIcon
                                            icon="edit"
                                            @click.stop="setCardName(card.fuelCardId)"
                                            title="Добавить/Изменить имя карты"
                                            class="link link-black"
                                        />
                                    </template>
                                    <template v-if="card.fuelCardShowing">
                                        <i class="clr-ltg-2 pl-15">{{ card.fuelCardShowing }}</i>
                                    </template>
                                    <i v-else-if="canTopUpFuelCards" class="clr-ltg-2 pl-15">
                                        - без имени -
                                    </i>
                                </div>
                                <div>
                                    <img
                                        v-if="card.fillingStationId"
                                        alt=""
                                        :src="require(`@/assets/icons/fuel-card/${card.fillingStationId}.png`)"
                                        class="fuel-card-icon"
                                    />
                                </div>
                            </div>
                        </template>
                        <div class="fuel-card-content">
                            <template v-if="!cardHistory[card.fuelCardId]">
                                Загрузка...
                            </template>
                            <div v-show="cardHistory[card.fuelCardId]">
                                <DataTable
                                    v-if="card.fuelCardId in cardHistory"
                                    :key="cardHistory[card.fuelCardId]"
                                    paginate
                                    noShadow
                                    :cols="{
                                        dateTime: 'Дата',
                                        orderNumber: '№ заказа',
                                        amount: 'Сумма',
                                        status: 'Статус',
                                    }"
                                    :cell-bind="(v, item) => ({
                                        orderNumber: {class: 'fuel-card-order-num'},
                                        amount: item.operationType !== 'CREDIT' || {class: 'clr-green'},
                                        status: {class: 'clr-green'},
                                    })"
                                    :page-size="10"
                                    :cells="{
                                        dateTime: v => $util.date.date(v, true),
                                        amount: (v, item) => `${item.operationType === 'CREDIT' ? '+' : '-'} ${$$.price(v)}`,
                                    }"
                                    :fetch="(h, a, t) => getHistory(card.fuelCardId, h, a, t)"
                                />
                            </div>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        <Confirm name="fc-name">
            <FormItem>
                <FormInput
                    placeholder="Укажите название карты"
                    v-model="fuelCardName"
                />
            </FormItem>
        </Confirm>
        </template>
    </Page>
</template>

<script>
export default {
    name: "FuelCard",
    props: {
        mode: String
    },
    data() {
        return {
            cards: null,
            cardOrders: {},
            cardOrdersAmount: {},
            cardHistory: {},
            cardFilter: null,
            updateKey: _.randKey(),
            canViewFuelReminder: null,
            canTopUpFuelCards: null,
            fuelCardName: null
        }
    },
    created() {
        this.checkPermissions().then(() => {
            this.getMode() && this.getCards()
        })
    },
    mounted() {
        this.$emit('load', this)
    },
    methods: {
        getMode() {
            return (['topup', 'history']).includes(this.mode) ? this.mode : null
        },
        async checkPermissions() {
            this.canViewFuelReminder = null
            this.canTopUpFuelCards = null
            if (this.$service.account.access.isCarrier()) {
                this.canViewFuelReminder = true
                this.canTopUpFuelCards = true
            } else {
                await this.$service.account.hasRule('CAN_VIEW_FUEL_REMINDER').then(can => this.canViewFuelReminder = can)
                this.$service.account.access.isDispatcher() === false
                    ? this.canTopUpFuelCards = false
                    : await this.$service.account.hasRule('CAN_TOPUP_FUEL_CARDS').then(can => this.canTopUpFuelCards = can)
            }
        },
        async getCards() {
            await this.checkPermissions()
            this.$repo('fc').cards().then(({list}) => {
                this.cards = list
                this.updateKey = _.randKey()
            })
        },
        getOrders(fuelCardId) {
            this.cardOrders[fuelCardId] && this.$delete(this.cardOrders, fuelCardId)

            if (this.canTopUpFuelCards === false && this.canViewFuelReminder === false)
                return false

            this.$repo('fc').cardOrders(fuelCardId).then(({list}) => {
                this.$set(this.cardOrders, fuelCardId, list)
                list.forEach(item => this.$set(this.cardOrdersAmount, item.orderId, ""))
            })
        },
        openHistory(fuelCardId) {
            this.$set(this.cardHistory, fuelCardId, false)
        },
        closeHistory(fuelCardId) {
            this.$delete(this.cardHistory, fuelCardId)
        },
        getHistory(fuelCardId, h, a) {
            this.$repo('fc').history(fuelCardId, a).then(({list}) => {
                h(list)
                this.$set(this.cardHistory, fuelCardId, true)
            })
        },
        deleteCardOrdersAmount() {
            this.cardOrdersAmount = {}
        },
        setOrderAmount(orderId) {
            const value = _.num(this.cardOrdersAmount[orderId])
            value > 0
                ? this.$set(this.cardOrdersAmount, 'orderId', value)
                : this.$delete(this.cardOrdersAmount, orderId)
        },
        getAmountData(fuelCardId) {
            const data = []
            this.cardOrders[fuelCardId].forEach(o => {
                let exceeded = false
                const amountData = this.cardOrdersAmount[o.orderId]
                if (_.isNil(amountData)) return

                const amount = _.num(amountData)
                if (amount > _.num(o.affordableBidAmount)) exceeded = true
                if (amount > 0) {
                    const obj = exceeded ? false : Object.assign({
                        amount: amount
                    }, {orderId: o.orderId})

                    data.push(obj)
                }
            })
            return data
        },
        topUpFuelCard(fuelCardId) {
            if (!this.canTopUpFuelCards) return false

            const data = this.getAmountData(fuelCardId)

            if (data.length === 0) {
                this.$notify().warn('Не указана сумма пополнения.')
                return false
            }
            if (data.includes(false)) {
                this.$notify().warn('Сумма пополнения превышает доступный баланс.')
                return false
            }

            this.$repo('fc').balance(fuelCardId, data).then(({list}) => {
                const err = []
                list.forEach(order => {
                    if (order.result === 'SUCCESS') {
                        this.cardOrders[fuelCardId].forEach(o => {
                            const amount = o.affordableBidAmount
                            o.orderId === order.orderId && this.$set(o, 'affordableBidAmount', amount-order.amount)
                        })
                        return
                    }
                    err.push(this.cardOrders[fuelCardId].find(i => i.orderId === order.orderId).orderNumber)
                })
                err.length
                    ? this.$ok(`Не удалось произвести пополнение по заказам №: ${err.join(', ')}`)
                    : this.$notify().info('Карта пополнена.')
                this.$emit('payed')
            }).alert('Не удалось произвести пополнение.')
        },
        setCardName(fuelCardId) {
            const card = this.cards.find(c => c.fuelCardId === fuelCardId)
            this.fuelCardName = card.fuelCardShowing
            this.$confirm('fc-name', {
                title: 'Изменение имени карты',
                buttonCancel: true,
                buttons: 'Сохранить',
                onConfirm: () => {
                    this.fuelCardName?.trim() && this.$repo('fc').setName(fuelCardId, this.fuelCardName).then(() => {
                        this.$set(card, 'fuelCardShowing', this.fuelCardName)
                        this.fuelCardName = ''
                    }).done('Карта успешно переименована.').alert('Не удалось установить имя карты.')
                }
            })
        },
        getCardFiltered() {
            return this.cardFilter
                ? _.cloneDeep(this.cards.filter(o => (o.fuelCardNumber + o.fuelCardShowing).match(new RegExp(this.cardFilter, 'gi'))))
                : this.cards
        }
    }
}

</script>

<style scoped lang="scss">
.fuel-card {
    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
            padding: 0 10px;
        }
    }
    &-icon {
        max-height: 50px;

        @media (max-width: 425px) {
            max-width: 75px;
        }
    }
}
</style>
