<template>
    <div class="dual-range-slider">
        <div :id="id" :se-min="minThreshold" :se-step="step" :se-min-value="min" :se-max-value="max" :se-max="threshold"
             class="slider">
            <div class="slider-touch-left">
                <span></span>
            </div>
            <div class="slider-touch-right">
                <span></span>
            </div>
            <div class="slider-line">
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>

// https://dev.to/mandrewcito/vuejs-double-range-slider-component-2c5a
// https://codepen.io/zebresel/pen/xGLYOM
const RangeSlider = function (id) {
    let self = this;
    let startX = 0, x = 0;

    // retrieve touch button
    let slider = document.getElementById(id)
    let touchLeft = slider.querySelector('.slider-touch-left');
    let touchRight = slider.querySelector('.slider-touch-right');
    let lineSpan = slider.querySelector('.slider-line span');

    // get some properties
    let min = parseFloat(slider.getAttribute('se-min'));
    let max = parseFloat(slider.getAttribute('se-max'));

    // retrieve default values
    let defaultMinValue = min;
    if (slider.hasAttribute('se-min-value')) {
        defaultMinValue = parseFloat(slider.getAttribute('se-min-value'));
    }
    let defaultMaxValue = max;

    if (slider.hasAttribute('se-max-value')) {
        defaultMaxValue = parseFloat(slider.getAttribute('se-max-value'));
    }

    // check values are correct
    if (defaultMinValue < min) {
        defaultMinValue = min;
    }

    if (defaultMaxValue > max) {
        defaultMaxValue = max;
    }

    if (defaultMinValue > defaultMaxValue) {
        defaultMinValue = defaultMaxValue;
    }

    let step = 0.0;

    if (slider.getAttribute('se-step')) {
        step = Math.abs(parseFloat(slider.getAttribute('se-step')));
    }

    // normalize flag
    let normalizeFact = 26;

    self.slider = slider;
    self.reset = function () {
        touchLeft.style.left = '0px';
        touchRight.style.left = (slider.offsetWidth - touchLeft.offsetWidth) + 'px';
        lineSpan.style.marginLeft = '0px';
        lineSpan.style.width = (slider.offsetWidth - touchLeft.offsetWidth) + 'px';
        startX = 0;
        x = 0;
    };

    self.setMinValue = function (minValue) {
        let ratio = ((minValue - min) / (max - min));
        touchLeft.style.left = Math.ceil(ratio * (slider.offsetWidth - (touchLeft.offsetWidth + normalizeFact))) + 'px';
        lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px';
        lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px';
        slider.setAttribute('se-min-value', minValue);
    }

    self.setMaxValue = function (maxValue) {
        let ratio = ((maxValue - min) / (max - min));
        touchRight.style.left = Math.ceil(ratio * (slider.offsetWidth - (touchLeft.offsetWidth + normalizeFact)) + normalizeFact) + 'px';
        lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px';
        lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px';
        slider.setAttribute('se-max-value', maxValue);
    }

    // initial reset
    self.reset();

    // usefull values, min, max, normalize fact is the width of both touch buttons
    let maxX = slider.offsetWidth - touchRight.offsetWidth;
    let selectedTouch = null;
    let initialValue = (lineSpan.offsetWidth - normalizeFact);

    // set defualt values
    self.setMinValue(defaultMinValue);
    self.setMaxValue(defaultMaxValue);

    self.getMinValue = function () {
        return slider.getAttribute('se-min-value')
    }

    self.getMaxValue = function () {
        return slider.getAttribute('se-max-value')
    }

    // setup touch/click events
    function onStart(event) {

        // Prevent default dragging of selected content
        event.preventDefault();
        let eventTouch = event;

        if (event.touches) {
            eventTouch = event.touches[0];
        }

        if (this === touchLeft) {
            x = touchLeft.offsetLeft;
        } else {
            x = touchRight.offsetLeft;
        }

        startX = eventTouch.pageX - x;
        selectedTouch = this;
        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onStop);
        document.addEventListener('touchmove', onMove);
        document.addEventListener('touchend', onStop);


    }

    function onMove(event) {
        let eventTouch = event;

        if (event.touches) {
            eventTouch = event.touches[0];
        }

        x = eventTouch.pageX - startX;

        if (selectedTouch === touchLeft) {
            if (x >= (touchRight.offsetLeft - selectedTouch.offsetWidth)) {
                return
            } else if (x < 0) {
                x = 0;
            }

            selectedTouch.style.left = x + 'px';
        } else if (selectedTouch === touchRight) {
            if (x <= (touchLeft.offsetLeft + touchLeft.offsetWidth)) {
                return
            } else if (x > maxX) {
                x = maxX;
            }
            selectedTouch.style.left = x + 'px';
        }

        // update line span
        lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px';
        lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px';

        // write new value
        calculateValue();

        // call on change
        if (slider.getAttribute('on-change')) {
            let fn = new Function('min, max', slider.getAttribute('on-change'));
            fn(slider.getAttribute('se-min-value'), slider.getAttribute('se-max-value'));
        }

        if (self.onChange) {
            self.onChange(self.getMinValue(), self.getMaxValue());
        }

    }


    function onStop() {
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onStop);
        document.removeEventListener('touchmove', onMove);
        document.removeEventListener('touchend', onStop);

        selectedTouch = null;

        // write new value
        calculateValue();

        // call did changed
        if (slider.getAttribute('did-changed')) {
            let fn = new Function('min, max', slider.getAttribute('did-changed'));
            fn(slider.getAttribute('se-min-value'), slider.getAttribute('se-max-value'));
        }

        if (self.didChanged) {
            self.didChanged(slider.getAttribute('se-min-value'), slider.getAttribute('se-max-value'));
        }
    }

    function calculateValue() {
        let newValue = (lineSpan.offsetWidth - normalizeFact) / initialValue;
        let minValue = lineSpan.offsetLeft / initialValue;
        let maxValue = minValue + newValue;

        minValue = minValue * (max - min) + min;
        maxValue = maxValue * (max - min) + min;

        if (step !== 0.0) {
            let multi = Math.floor((minValue / step));
            minValue = step * multi;

            multi = Math.floor((maxValue / step));
            maxValue = step * multi;
        }

        slider.setAttribute('se-min-value', minValue.toString());
        slider.setAttribute('se-max-value', maxValue.toString());
    }

    // link events
    touchLeft.addEventListener('mousedown', onStart);
    touchRight.addEventListener('mousedown', onStart);
    touchLeft.addEventListener('touchstart', onStart);
    touchRight.addEventListener('touchstart', onStart);
};

export default {
    name: 'RangeDualSlider',
    props: {
        minThreshold: {
            type: Number,
            default: 0
        },
        maxThreshold: {
            type: Number,
            default: 0
        },
        step: {
            type: Number,
            default: 1
        },
        min: {
            type: Number,
            default: 0,
            required: true
        },
        max: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            id: 'range-slider-' + this.$uuid.v1(),
            instance: null,
            threshold: this.maxThreshold || this.max,
            updateKey: 0
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    mounted() {
        this.init()
        window.addEventListener('resize', this.onResize)
    },
    methods: {
        init() {
            this.$nextTick(() => {
                const onChange = (min, max) => this.$emit('change', {min: parseFloat(min), max: parseFloat(max)})
                this.instance = new RangeSlider(this.id)
                this.instance.onChange = onChange
            })
        },
        onResize() {
            this.updateKey += 1
            this.init()
        }
    }
}
</script>

<style scoped lang="scss">
.dual-range-slider {
    width: 100%;
    padding: 0;
    .slider {
        display: block;
        position: relative;
        height: 36px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        .slider-touch-left, .slider-touch-right {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            display: block;
            position: absolute;
            height: 25px;
            width: 25px;
            padding: 6px;
            top: 5px;
            z-index: 2;

            span {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: #5392E1;
                box-shadow: 0 0 0 4px rgba(83, 146, 225, 0.18)
            }
        }

        .slider-line {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            position: absolute;
            width: calc(100% - 36px);
            left: 18px;
            top: 16px;
            height: 4px;
            border-radius: 4px;
            background: #f0f0f0;
            z-index: 0;
            overflow: hidden;

            span {
                display: block;
                height: 100%;
                width: 0%;
                background: #3F8DCD;
            }
        }
    }
}

</style>

