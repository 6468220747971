import Repo from '@/api/service/repo'

export default class extends Repo {

    fetchAccount() {
        return this.client.get('/auth/profile')
    }

    driver() {
        return this.client.get('/driver')
    }

    carrier() {
        return this.client.get('/carrier')
    }

    dispatcher() {
        return this.client.get('/dispatcher')
    }

}
