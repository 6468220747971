import Stomp from 'stompjs'

export default class {
    stompClient = null
    subscribeDelay = 3000

    constructor({uri, connectDelay: connectDelay = 3000}) {
        this.stompClient = Stomp.client(uri)
        this.connectDelay = connectDelay
    }

    connect({onConnect, onError, onReconnectError}) {
        this.stompClient.connect(
            null,
            null,
            frame => _.isFunction(onConnect) ? onConnect(frame) : console.log("Connected: " + frame),
            err => _.isFunction(onError) ? onError(err) : (onError === false || this.reconnect({
                onConnect,
                onReconnectError
            }))
        );
        return this
    }

    isConnected() {
        return this.stompClient.connected === true
    }

    reconnect({onConnect, onReconnectError}) {
        _.promise(() => {
            if (this.isConnected())
                return true
            this.connect({onConnect, onError: false})
        }, {maxCount: 5, interval: 3000}).catch(() => _.isFunction(onReconnectError) && onReconnectError())
    }

    subscribe(uri, cb) {
        setTimeout(() => {
            this.stompClient.subscribe(uri, function (LOC) {
                console.log(LOC)
                cb(LOC)
            })
        }, this.subscribeDelay)
    }

    unsubscribe(uri) {
        this.stompClient.unsubscribe(uri)
    }

    disconnect() {
        this.stompClient.disconnect()
    }
}
