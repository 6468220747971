<template>
    <DataForm
        v-if="form"
        v-model="form.model"
        :model="form"
        :form="{
            on: {submit},
            bind: {
                inflect: true,
                buttons: ['Отправить']
            }
        }"
    />
</template>

<script>

export default {
    name: "FormRepairOrder",
    data() {
        return {
            transports: null,
            form: null
        }
    },
    created() {
        this.form = _.cloneDeep(this.$apiModel.repair_order)
    },
    methods: {
        submit() {
            this.$repo('enterprise').createRepairOrder(this.form.model).then(() => {
                this.$emit('success')
            })
                .alert('Не удалось отправить заявку.')
                .done('Заявка успешно отправлена.')
        }
    }
}

</script>
