import Vue from 'vue'
import store from './store'
import filters from './filters'
import request from '@/api/service/request'
import {CONFIRMATION_MODAL_NAME, DATABASE} from '@/config/app'
import Database from '@/services/db'
import {jquery} from '@/vendors'

const proxy = obj => new Proxy(obj, {
    get: function get(target, name) {
        try {
            return require(`@/${target.path}/${name}`).default
        } catch(e) {
            console.error(target.path, name)
        }
    }
})

const utils = proxy({path: 'utils'})

global.$ = jquery
global._ = utils.common

const getMessage = (source) => {

    let title, text

    const get = (arr) => {
        const message = _.isArray(arr) ? arr : [arr]
        title = message[0]
        message.shift()
        text = message.join(' ')
    }

    _.isArray(source) && (source = {text: source})

    if (_.isPlainObject(source)) {

        title = source.title
        text = source.text
        _.isArray(text) && get(text)

    } else typeof source === 'string' ? title = source : get(source)

    _.isPlainObject(source) || (source = {})

    return {...source, title, text}
}

const popup = (name, data) => {
    const popup = store.state.modals[name]
    if (_.isPlainObject(data)) {
        Object.assign(data, getMessage(data))
        popup?.show(data)
    }
    return popup
}

const popupClose = (name) => {
    const modals = _.isPlainObject(store.state.modals) ? store.state.modals : {}
    if (name !== undefined) {
        modals[name]?.isOpen && modals[name].close()
        return
    }
    Object.keys(modals).forEach(name => modals[name]?.isOpen && modals[name].close())
}

const confirm = (name, data) => {
    const confirm = store.state.confirms[name]
    if (_.isPlainObject(data)) {
        Object.assign(data, getMessage(data))
        confirm?.show(data)
    }
    return confirm
}

const dropdown = (name, data) => {
    const dropdown = store.state.dropdowns[name]
    _.isPlainObject(data) && dropdown?.show(data)
    return dropdown
}

const routeReplace = function (to, resetRouteId) {

    if (this.$route === undefined) return

    const data = typeof to === 'string' ? {path: to} : _.isPlainObject(to) ? to : {}

    if (resetRouteId) {
        resetRouteId = _.isString(resetRouteId) ? resetRouteId : 'id'
        this.$route.query[resetRouteId] && (this.$route.query[resetRouteId] = false)
        this.$route.params[resetRouteId] && (this.$route.params[resetRouteId] = false)
    }

    if ((this.$route.name === data.name || this.$route.path === data.path)
        && (JSON.stringify(this.$route.params) === JSON.stringify(data.params || {}))
        && (JSON.stringify(this.$route.query) === JSON.stringify(data.query || {}))
    ) return

    this.$router.replace(data)
}

const routePush = function (to) {
    if (this.$route === undefined) return
    const data = typeof to === 'string' ? {path: to} : to
    if (this.$route.name === data.name || this.$route.path === data.path) return
    this.$router.push(data)
}

const notify = (params) => {
    _.isPlainObject(params) || (params = {})
    const _params = {
        id: _.randKey(),
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
        mode: 'queue',
        closeable: true,
        transition: 'slide-down'
    }
    Object.assign(_params, params)
    return {
        info: (msg) => Vue.toast(msg, Object.assign(_params, {className: ['et-info', 'toast-notify']})),
        warn: (msg) => Vue.toast(msg, Object.assign(_params, {className: ['et-warn', 'toast-notify']})),
        alert: (msg) => Vue.toast(msg, Object.assign(_params, {className: ['et-alert', 'toast-notify']})),
    }
}

const getParent = function ({tag, name, cb}) {
    const get = (parent) => {
        if ((tag && parent?.$options?._componentTag === tag) || (name && parent?.$options?.name === name)) {
            return cb ? cb(parent) : parent
        }
        return parent && get(parent.$parent)
    }
    return get(this.$parent)
}

export const $db = new Database(DATABASE.name)

$db.open(DATABASE.tables)

export default {
    $$: utils.common,
    $rs: request,
    $db,
    $repo: function (path) {
        return this.$rs.repo(path)
    },
    $config: proxy({path: 'config'}),
    $app: () => ({...proxy({path: 'config'}).app}),
    $util: utils,
    $service: proxy({ path: 'services' }),
    $filter: filters,
    $popup: popup,
    $popupClose: popupClose,
    $dropdown: dropdown,
    $confirm: (name, data) => {
        return name && typeof name === 'string' ? confirm(name, data) : confirm(CONFIRMATION_MODAL_NAME, name)
    },
    $ok: (data) => {
        data = getMessage(data)
        data.buttons || (data.buttons = 'ok')
        return confirm(CONFIRMATION_MODAL_NAME, data)
    },
    $registerComponent: function (type, name) {
        store.commit('registerComponent', {type, name, component: this})
    },
    $resetComponent: function (type, name) {
        store.commit('resetComponent', {type, name})
    },
    $notify: notify,
    $redirect: routeReplace,
    $link: routePush,
    $apiModel: proxy({path: 'api/models'}),
    $getParent: getParent
}
