import ApiHandler from '../service/handler'

export default class extends ApiHandler {

    constructor(data) {
        super(data)
    }

    before() {

        if (!this._cache) return

        return new Promise(resolve => {

            (async () => {

                // если ранее подключили заглушку для запроса, то данные из кэша брать не нужно:

                if (_.isPlainObject(this._data.stubData) && this._data.stubData.data) {

                    this._data.stubData.data = this._format(this._data.stubData.data)

                    resolve()

                    return
                }

                ////

                const result = await this._cache.onSend(this._data)

                if (result === false) {

                    resolve()

                    return
                }

                this._data.stubData = result !== undefined ? result : (await this._cache.getCache()).data

                if (_.isEmpty(this._data.stubData)) {

                    this._data.stubData = null

                    resolve()

                    return
                }

                this._data.stubData = {

                    data: _.isArray(this._data.stubData)

                        ? { list: this._format(this._data.stubData) } : this._format(this._data.stubData)
                }

                resolve()
            })()
        })
    }

    /*
    * request checking method
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return Boolean on Success
    * */
    isSuccess(resp) {
        if (this._data.stubData && resp.data && !resp.data.result) return this._data.isSuccess = true
    }

    /*
    * method executes at the start of a Promise.prototype.then()
    * @params: response
    * @return: void
    * */
    async after(response) {

        if (this._data.isError
            || (_.isNumber(response.status) && response.status !== 200)
            || (response.data?.result && response.data.result !== 'SUCCESS')) return

        const stub = this._data.stubData

        if (stub?.data?.list || stub?.data?.object) {
            Object.assign(response, stub.data)
            Object.assign(this._data, stub.data)
            Object.assign(this._data.response, stub.data)
        }

        if (stub || this.isSuccess(response) === false || !this._cache) return

        let result = await this._cache.onGet(response)

        if (result === false) return

        if (result !== undefined) {

            await this._cache.save(result)

            result = this._format(result)

            if (_.isArray(result)) {

                this._data.list = this._data.response.list = response.list = result

            } else {

                response.data = result
            }

        } else if (this._data.list) {

            await this._cache.save(this._data.list)

        } else {

            await this._cache.save(response.data)
        }
    }

}
