import Repo from '@/api/service/repo'

export default class extends Repo {

    //Список диспетчеров перевозчика
    dispatchers(params) {
        return this.client.get('/carrier/dispatchers', params)
    }

    //Получение списка разрешений диспетчера перевозчика
    dispatcherPermissions(id) {
        return this.client.get(`/carrier/dispatchers/${id}/permissions`)
    }

    //Изменение списка разрешений диспетчера перевозчика
    saveDispatcherPermissions(id, list) {
        return this.client.post(`/carrier/dispatchers/${id}/permissions`, list)
    }

    //Удаление диспетчера из списка перевозчика
    deleteDispatcher(id) {
        return this.client.delete(`/carrier/dispatchers/${id}`)
    }

    //Список водителей перевозчика
    drivers(params) {
        return this.client.get('/carrier/drivers', params)
    }

    //Получение списка разрешений водителя перевозчика
    driverPermissions(id) {
        return this.client.get(`/carrier/drivers/${id}/permissions`)
    }

    //Изменение списка разрешений диспетчера перевозчика
    saveDriverPermissions(id, list) {
        return this.client.post(`/carrier/drivers/${id}/permissions`, list)
    }

    //Профиль водителя перевозчика
    driverProfile(id){
        return this.client.get(`/carrier/drivers/${id}/profile`)
    }

    //Удаление водителя из списка водителей
    deleteDriver(id) {
        return this.client.delete(`/carrier/drivers/${id}`)
    }

}
