import _ from '@/helpers'

const config = {..._.$config.pushes.firebase}

export default {
    firebase: null,
    init(data) {
        if (this.firebase === null || data) {
            this.firebase = firebase
            this.firebase.initializeApp(data ? data : config)
        }
    },
    checkPermission(sendTokenCallback, onBlockedCallback) {
        this.init()
        this.firebase.messaging().requestPermission().then(() => {
            if (sendTokenCallback instanceof Function)
                this.firebase.messaging().getToken()
                    .then(currentToken => sendTokenCallback(currentToken))
                    .catch(err => console.error('Firebase Messaging: An error occurred while retrieving token. ', err))
        }).catch(err => {
            if (typeof onBlockedCallback === 'function') onBlockedCallback(err)
        })
    },
    setOnMessage() {
        try {
            this.checkPermission(token => {
                if (token) {
                    _.$rs.bg().repo('notify').setPushToken({
                        pushToken: token,
                        fcmToken: token,
                    }).success(() => {
                        this.firebase.messaging().onMessage(function (payload) {
                            if (!("Notification" in window) || !("notification" in payload))
                                return false;
                            const data = payload.notification
                            const options = {
                                body: data.body,
                                icon: require('@/assets/icons/logo-icon-48x48.png'),
                                requireInteraction: true
                            }
                            const notification = new Notification(data.title, options)
                            setTimeout(() => notification.close(), 10000)
                            notification.onclick = () => {
                            }
                        })
                    })
                }
            })
        } catch (e) {
            console.error(e)
        }
    }
}
