<template>
    <DataForm
        v-model="form.model"
        :model="form"
        required
        :form="{
            bind: {
                ...$attrs,
                buttons: [
                    'Сохранить',
                    {text: 'Удалить', bind: {class: 'btn btn-red'}, on: {click: remove}}
                ]
            },
            on: {submit},
        }"
    />
</template>

<script>

export default {
    name: "form-assign",
    props: {
        assign: {
            type: Object,
            required: true,
        },
        toggle: Function,
    },
    data() {
        return {
            form: {},
            data: {...this.assign}
        }
    },
    created() {
        this.$set(this, 'form', {
            ...this.$apiModel.assign,
            model: {
                id: this.data.id,
                driverID: this.data.driver.id,
                truckID: this.data.truck.id,
                semitrailerID: this.data.semitrailer.id,
            }
        })
    },
    methods: {
        getName() {
            const assign = this.data
            return `${this.$filter.fullName(assign.driver)} (${assign.truck.model} - ${assign.semitrailer.number})`
        },
        remove() {
            this.$confirm({
                text: 'Вы действительно хотите удалить информацию о транспорте контрагента?',
                onConfirm: () => {
                    this.$repo('transport').removeAssign(this.assign.id)
                        .then(() => this.toggle())
                        .alert('Не удалось удалить транспорт контрагента.')
                        .done(`Транспорт контрагента ${this.getName()} удален из списка.`)
                }
            })
        },
        submit() {
            this.$repo('transport').patchAssign(this.assign.id, this.form.model)
                .then(() => this.toggle())
                .done(`Транспорт контрагента ${this.getName()} изменен.`)
                .alert('Не удалось редактировать информацию о транспорте контрагента.')
        }
    }
}

</script>
