export default {
    model: {
        "bunks": {
            "exists": false,
            "height": null
        },
        "color": "",
        "dimensions": {
            "height": null,
            "length": null,
            "width": null
        },
        "id": 0,
        "model": "",
        "number": "",
        "sanitization": {
            "exists": false,
            "untilDate": ""
        },
        "scans": [],
        "status": "",
        "transportTypeId": null,
        "vin": "",
        "year": null
    },
    groups: [
        {name: "Характеристики", fields: ['model', 'number', 'vin', 'year', 'transportTypeId']},
        {name: "Внутренние габариты", fields: ['dimensions.length', 'dimensions.width', 'dimensions.height']},
        {name: "Коники", fields: ['bunks.height']},
        {name: "Сан. обработка", fields: ['sanitization.untilDate', 'scans']},
    ],
    fields: {
        "model": {
            name: "Марка",
        },
        "number": {
            name: "Гос. номер",
            format: v => _.toUpper(v).replace(/\s/g, '')
        },
        "vin": {
            name: "VIN номер",
        },
        "year": {
            name: "Год выпуска",
            mask: "####",
            rules: [['minLength', 4]]
        },
        "transportTypeId": {
            name: "Тип полуприцепа",
            type: 'array',
            value: ({field, value}) => _.find(field.bind.options, 'id', value, 'name')
        },
        "dimensions.length": {
            name: "Длина, сантиметры",
            type: 'number',
        },
        "dimensions.width": {
            name: "Ширина, сантиметры",
            type: 'number',
        },
        "dimensions.height": {
            name: "Высота, сантиметры",
            type: 'number',
        },
        "bunks.height": {
            name: "Высота коников",
            type: 'number',
        },
        "sanitization.untilDate": {
            name: "Срок санитарной обработки",
            type: 'date'
        },
        "scans": {
            name: "Скан ПТС или СТС",
            type: 'img',
        }
    },
    assign: {
        transportTypeId: ({bind, vm}) => {
            vm.$repo('transport').transportTypes().then(({list}) => bind(list, 'options'))
        },
    }
}
