import date from '@/utils/date'

const IDB = window.indexedDB
const SCHEMA = {
    storage: '_schema',
    table: 'schema',
    versionTable: '_version',
    key: {
        keyPath: 'table',
        unique: true,
    }
}

export default class {
    version = 1

    constructor(storageName) {
        this.storage = storageName
    }

    open(table, params) {
        const exec = (request, resolve) => request.onsuccess = () => resolve(request.result)
        const handle = (resolve, reject) => {
            const request = IDB.open(this.storage, this.version)
            request.onupgradeneeded = () => this.upgrade(request.result, table, params)
            request.onblocked = () => {
                try {
                    setTimeout(() => exec(handle(resolve, reject), resolve), 1000)
                } catch (e) {
                    console.error(`db storage "${this.storage}" on blocked error: `, e)
                }
            }
            request.onerror = (e) => {
                this.setVersion(e.newVersion)
                reject(request.error)
                console.error(`db open storage "${this.storage}" error: `, request.error)
            }
            return request
        }
        return new Promise((resolve, reject) => {
            this.getVersion(() => {
                const request = handle(resolve, reject)
                request.onsuccess = () => {
                    const db = request.result
                    let upgrade = false
                    _.toArray(table).some(item => upgrade = db.objectStoreNames.contains(item) === false)
                    upgrade ? this.setVersion(null, () => exec(handle(resolve, reject), resolve)) : resolve(db)
                }
            })
        })
    }

    setVersion(ver, cb) {
        this.version = _.isNumber(ver) ? ver : parseInt(this.version) + 1
        this.setSchema(SCHEMA.versionTable, {version: this.version}, cb)
    }

    getVersion(cb) {
        this.getSchema(SCHEMA.versionTable, ({schema}) => {
            this.version = _.isNumber(schema?.version) ? schema?.version : this.version
            _.isFunction(cb) && cb()
        })
    }

    upgrade(db, table, params) {
        let _params = {
            keyPath: 'id',
            autoIncrement: false
        }
        _.isPlainObject(params) && (_params = _.merge(_params, params))
        _.toArray(table).forEach(objStore => {
            const store = {
                name: _.isPlainObject(objStore) && objStore.name ? objStore.name : objStore,
                params: _.isPlainObject(objStore) && _.isPlainObject(objStore.params) ? _.merge({}, objStore.params, _params) : _params,
            }
            !store.name || db.objectStoreNames.contains(store.name) || db.createObjectStore(store.name, store.params)
        })
    }

    get(db, table, query, data) {
        return query ? new Promise((resolve, reject) => {
            const request = db.transaction(table, 'readwrite').objectStore(table)[query](data)
            request.onsuccess = () => resolve(request.result)
            request.onerror = () => {
                reject(request.error)
                console.error(`db transaction table "${table}" error: `, request.error)
            }
        }) : db.transaction(table, 'readwrite').objectStore(table)
    }

    query(table, query = 'getAll', queryData) {
        return new Promise((resolve, reject) => {
            this.getSchema(table, ({schema, error}) => {
                if (error) {
                    reject(error)
                    return
                }
                this.open(table).then((db) => {
                    this.get(db, table, query, queryData)
                        .then(data => resolve({schema, data, db}))
                        .catch((e) => reject(e))
                }).catch((e) => reject(e))
            })
        })
    }

    save({db, table, data, format, schemaData}) {
        return new Promise((resolve, reject) => {
            if (!table) throw 'IndexedDB Table name not specified.'

            const items = _.cloneDeep(_.toArray(data))
            const count = items.length - 1
            const setSchema = () => this.setSchema(table, Object.assign({
                timestamp: date.toUtc(date.curDate()),
                entries: items.length
            }, _.isPlainObject(schemaData) ? schemaData : {}))

            const setData = (db) => {
                items.forEach((item, key) => {
                    format && Object.assign(item, format(item))
                    const req = this.get(db, table).put(item)
                    req.onsuccess = () => {
                        if (key === count) {
                            resolve(req.result)
                            setSchema()
                        }
                    }
                    req.onerror = () => reject(req.error)
                })
            }
            db ? setData(db) : this.open(table).then(db => setData(db)).catch(() => reject())
        })
    }

    clear(db, table, schemaData) {
        this.get(db, table).clear()
        this.setSchema(table, Object.assign({
            timestamp: date.toUtc(date.curDate()),
            entries: 0
        }, _.isPlainObject(schemaData) ? schemaData : {}))
    }

    getSchema(table, cb) {
        _.isFunction(cb) || (cb = () => null)
        const request = window.indexedDB.open(SCHEMA.storage, 1)
        request.onupgradeneeded = () => this.upgrade(request.result, SCHEMA.table, SCHEMA.key)
        request.onsuccess = () => {
            const schema = request.result
            const req = this.get(schema, SCHEMA.table).get(table)
            req.onsuccess = () => cb({schema: req.result || {}, error: null, db: schema})
            req.onerror = () => {
                cb({schema: {}, error: req.error, db: schema})
                console.error(`db get schema table "${SCHEMA.table}" error: `, req.error)
            }
        }
        request.onerror = () => {
            cb({schema: {}, error: request.error, db: null})
            console.error(`db upgrade schema table "${SCHEMA.table}" error: `, request.error)
        }
    }

    setSchema(table, data, cb) {
        this.getSchema(table, ({schema, error, db}) => {
            db && this.get(db, SCHEMA.table).put(_.merge({}, data, {table}))
            _.isFunction(cb) && cb({schema, error, db})
        })
    }
}
