<template>
    <div class="form-file">
        <div v-if="image && showThumbs && thumbs.length" class="form-file-thumbs">
            <div v-for="(thumb, k) in thumbs" class="form-file-thumbs-item" :key="k">
                <div v-html="thumb.image.outerHTML" @click="images.onOpen(thumb.id)"></div>
                <div v-if="!view" class="--remove" title="Удалить" @click="remove(thumb.id, k)">&times;</div>
            </div>
        </div>
        <input
            v-if="!view && !this.isDisabled"
            ref="file"
            class="form-input"
            type="file"
            :multiple="isMultiple"
            @change="upload"
        />
    </div>
</template>

<script>
import {FORM_ITEM_COMPONENT_TAG} from "@ui/form/Form";

export default {
    name: "FormFile",
    props: {
        index: Number,
        value: [Array, String, Number],
        rules: Array,
        watch: Function,
        format: Function,
        image: Boolean,
        min: Number,
        max: Number,
        showThumbs: {
            type: Boolean,
            default: true
        },
        thumbsOutput: Object,
        view: Boolean,
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    computed: {
        isMultiple() {
            return _.isArray(this.value)
        },
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    data() {
        return {
            val: this.value,
            validator: null,
            images: new this.$service.image({}),
            files: this.$service.file,
            thumbs: []
        }
    },
    created() {

        let rules = this.rules || []

        this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')

        this.required === false && (rules = rules.filter(r => r !== 'required'))

        if (this.min || this.max) rules.push(this.validateFilesLength)

        rules.length && (this.validator = new this.$service.validation({rules, name: this.$attrs.name}))
    },
    mounted() {

        this.$nextTick(() => {

            const wrap = this.$getParent({tag: FORM_ITEM_COMPONENT_TAG})

            wrap && this.$set(wrap, 'wrap', false)

            const val = _.toArray(this.val)

            if (this.image && this.showThumbs && val.length) {
                this.getThumbs(val)
                const thumbs = this.thumbs.map(v => v.id)
                thumbs.length !== val.length && this.fetchThumbs(val.filter(v => !thumbs.includes(v)))
            }
        })

        this.isDisabled || (this.validator && this.validator.validate(this.val))
    },
    methods: {
        input() {

            let val = this.val

            _.isEmpty(val) && (this.isMultiple ? val = [] : val = '')
            _.isArray(val) && !this.isMultiple && (val = val[val.length-1])

            if (this.format) {
                val = this.format(val)
            }
            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(val))
            }
            if (this.watch) {
                const value = this.watch(val, this)
                value !== undefined && (val = value)
            }

            this.$emit('input', val)
        },
        getAttributes() {
            return this.$attrs || {}
        },
        reset() {
            this.val = this.isMultiple ? [] : ''
            this.input()
        },
        getValue() {
            return this.val
        },
        setValue(ids, excludes) {

            const val = _.toArray(this.val)

            const set = _.toArray(ids)

            let value = _.uniq(val.concat(set))

            excludes?.length && (value = value.filter(v => !excludes.includes(v)))

            this.thumbs.length && this.$set(this, 'thumbs', _.uniqBy(this.thumbs.filter(v => value.includes(v.id)), 'id'))

            this.$set(this, 'val', this.isMultiple ? value : value[value.length-1])

            this.input()
        },
        upload(e) {

            const files = e.target.files

            if (this.image) {

                this.images.upload({files}).then(ids => {
                    this.setValue(ids)
                    this.showThumbs && this.fetchThumbs(this.val)
                })

            } else {

                const conf = this.$config.api()

                this.files.upload({
                    repo: conf.files.DEFAULT_UPLOAD_REPO,
                    repoMethod: conf.files.DEFAULT_UPLOAD_REPO_METHOD,
                    files
                }).then(r => {
                    this.setValue(r.data.fileIds)
                })
            }
        },
        validateFilesLength(val) {

            const min = this.min
            const max = this.max
            const length = _.toArray(val).length

            let error = false

            min && length < min && (error = `Требуется не менее ${min} файлов.`)
            max && length > max && (error = `Требуется не более ${max} файлов.`)

            return error
        },
        fetchThumbs(ids) {

            return this.images.fetchThumbs(ids).then(({thumbs, fails}) => {

                this.getThumbs(thumbs.map(i => i.id))

                if (fails.length) {
                    console.error('Failed to load files: ', fails)
                    this.setValue([], fails)
                }
            })
        },
        getThumbs(ids) {
            if (this.thumbsOutput) {
                const thumbsHtml = this.images.images(ids)
                thumbsHtml && $(thumbsHtml).appendTo(this.thumbsOutput)
            } else {
                this.thumbs = _.uniqBy(this.thumbs.concat(this.images.images(ids, false)), 'id')
            }
        },
        remove(id, thumbIndex) {
            const files = _.toArray(this.val)
            const index = files.findIndex(v => v?.toString() === id?.toString())

            if (index !== -1) {
                files.splice(index, 1)
                this.setValue(files)
                thumbIndex !== undefined && this.thumbs.splice(thumbIndex, 1)
                this.input()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.form-file {
    &-thumbs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;

        &-item {
            position: relative;
            cursor: pointer;

            .--remove {
                display: none;
                position: absolute;
                top: -7px;
                right: -5px;
                border-radius: 50%;
                border: $black-50 2px solid;
                padding: 0 4px;
                color: $black;
                background: #fff;
            }

            &:hover .--remove {
                display: block;
            }
        }
    }
    .form-input {
        padding-left: 0;
    }
}
</style>
