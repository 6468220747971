export const DIMENSIONS = {
    xl: 1440,
    lg: 1024,
    md: 768,
    sm: 425,
    xs: 375,
}

export default {
    _screen: "",
    _device: "",
    _dimensions: {},
    setDimensions({xl, lg, md, sm, xs}) {
        const dm = DIMENSIONS
        this._dimensions = {
            xl: xl || dm.xl,
            lg: lg || dm.lg,
            md: md || dm.md,
            sm: sm || dm.sm,
            xs: xs || dm.xs,
        }
    },
    viewport(native) {
        return {
            width: (native && window.screen) ? window.screen.width : window.innerWidth,
            height: (native && window.screen) ? window.screen.height : window.innerHeight,
        }
    },
    screen(native) {
        const dm = {...DIMENSIONS, ...this._dimensions}
        const ww = this.viewport(native).width
        if (ww > dm.lg) return this._screen = 'xl'
        if (ww <= dm.lg && ww > dm.md) return this._screen = 'lg'
        if (ww <= dm.md && ww > dm.sm) return this._screen = 'md'
        if (ww <= dm.sm && ww > dm.xs) return this._screen = 'sm'
        return this._screen = 'xs'
    },
    device(native) {
        switch (this.screen(native)) {
            case 'xs':
                this._device = 'phone-s'
                break
            case 'sm':
                this._device = 'phone-m'
                break
            case 'md':
                this._device = 'tablet'
                break
            case 'lg':
                this._device = 'laptop'
                break
            case 'xl':
                this._device = 'desktop'
                break
        }
        return this._device
    },
    getScreen() {
        return this._screen
    },
    getDevice() {
        return this._device
    },
    isMobile(native) {
        return ['xs', 'sm', 'md'].includes(this.screen(native))
    },
    isPhone(native) {
        return ['xs', 'sm'].includes(this.screen(native))
    },
    isTablet(native) {
        return this.device(native) === 'tablet'
    },
    isLaptop(native) {
        return this.device(native) === 'laptop'
    },
    isDesktop(native) {
        return !this.isMobile(native)
    },
    /*
    * https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    * */
    browser() {
        // Opera 8.0+
        const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; //eslint-disable-line
        // Firefox 1.0+
        const isFirefox = typeof InstallTrigger !== 'undefined'
        // Safari 3.0+ "[object HTMLElementConstructor]"
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
            return p.toString() === "[object SafariRemoteNotification]"
        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)); //eslint-disable-line
        // Internet Explorer 6-11
        const isIE = /*@cc_on!@*/ !!document.documentMode
        // Edge 20+
        const isEdge = !isIE && !!window.StyleMedia
        // Chrome 1 - 71
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
        // Blink engine detection
        const isBlink = (isChrome || isOpera) && !!window.CSS
        return {
            isFirefox: isFirefox,
            isChrome: isChrome,
            isSafari: isSafari,
            isOpera: isOpera,
            isIE: isIE,
            isEdge: isEdge,
            isBlink: isBlink
        }
    },

    cursorPosition(e) {
        e = e || window.event
        let pageX = e.touches ? e.touches[0].clientX : e.pageX
        let pageY = e.touches ? e.touches[0].clientY : e.pageY
        // IE 8
        if (pageX === undefined) {
            pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
            pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
        }
        return {
            x: pageX,
            y: pageY,
        }
    },
    cursorIsOutOfScreen(e, range = {x: 5, y: 5}) {
        const pos = this.cursorPosition(e)
        const screen = this.viewport()
        _.isPlainObject(range) || (range = {})
        return pos.x <= 1
            || pos.x >= screen.width
            || pos.y <= 1
            || pos.y >= screen.height
            || (range.x && (pos.x <= range.x || pos.x >= screen.width - range.x))
            || (range.y && (pos.y <= range.y || pos.y >= screen.height - range.y))
    },
}
