import Repo from '@/api/service/repo'

export default class extends Repo {

    //список топливных карт
    cards() {
        return this.client.get('/carriers/fuelCards')
    }

    //список заказов, доступных для пополнения карты
    cardOrders(cardId) {
        return this.client.get(`/carriers/fuelCards/${cardId}/orders`)
    }

    //Задание отображаемого названия ТК
    setName(cardId, fuelCardShowing) {
        return this.client.post(`/carriers/fuelCards/${cardId}/setShowing`, {fuelCardShowing})
    }

    //Пополнение ТК по списку заказов
    balance(cardId, orders) {
        return this.client.post(`/carriers/fuelCards/${cardId}/balance`, {orders})
    }

    //История пополенения ТК
    history(cardId, params) {
        return this.client.get(`/carriers/fuelCards/${cardId}/history`, params)
    }

    //Список ТК перевозчика, которые могут быть пополнены из заказа
    orderCards(orderId) {
        return this.client.get(`/carriers/orders/${orderId}/fuelCards`)
    }

    //Пополнение списка ТК по заказу
    orderBalance(orderId, fuelCards) {
        return this.client.post(`/carriers/orders/${orderId}/fuelCardsBalance`, {fuelCards})
    }

    //История пополенения всех ТК по заказу
    orderHistory(orderId) {
        return this.client.get(`/carriers/orders/${orderId}/fuelCardsHistory`)
    }

}
