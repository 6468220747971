
export const PUBLIC_ROUTES = [
    'Login', 'Reg', 'HomePage', 'PageNotFound', 'PreOrders', 'PreOrdersAll', 'Privacy'
]

const baseLayoutRoutes = [
    {
        path: '/', name: 'HomePage',
        component: () => import('@pages/HomePage.vue')
    },
    {
        path: '/privacy', name: 'Privacy',
        component: () => import('@pages/Privacy.vue')
    },
    {
        path: '/login', name: 'Login',
        component: () => import('@pages/auth/Login.vue'),
        props: (route) => ({ passwordRecoveryToken: route.params.passwordRecoveryToken })
    },
    {
        path: '/password-recovery', name: 'PasswordRecovery',
        component: () => import('@pages/auth/PasswordRecovery.vue'),
        props: (route) => ({ phone: +route.params.phone })
    },
    {
        path: '/reg', name: 'Reg',
        component: () => import('@pages/auth/Reg.vue'),
    },
    {
        path: '/link-carrier', name: 'LinkCarrier',
        component: () => import('@pages/auth/LinkCarrier.vue'),
    },
    {
        path: '/profile/driver-register', name: 'ProfileDriverRegister',
        component: () => import('@pages/profile/Profile.vue'),
        props: () => ({driverRegister: true})
    },
    {
        path: '/profile/carrier-info', name: 'ProfileCarrierInfo',
        component: () => import('@pages/profile/ProfileCarrierInfo.vue'),
    },
    {
        path: '/profile/:role', name: 'ProfileEdit',
        component: () => import('@pages/profile/Profile.vue'),
        props: (route) => ({ role: route.params.role })
    },
    {
        path: '/preorders/all/:id?', name: 'PreOrdersAll',
        component: () => import('@pages/preorder/PreOrders.vue'),
        props: (route) => ({ mode: 'all', id: +route.params.id || +route.query.id })
    },
    {
        path: '/preorders/self/:id?', name: 'PreOrdersSelf',
        component: () => import('@pages/preorder/PreOrders.vue'),
        props: (route) => ({ mode: 'self', id: +route.params.id || +route.query.id })
    },
    {
        path: '/preorders-favorites', name: 'FavoriteRoutes',
        component: () => import('@pages/preorder/FavoriteRoutes.vue'),
    },
    {
        path: '/orders/archive/:id?', name: 'OrdersArchive',
        component: () => import('@pages/order/Orders.vue'),
        props: (route) => ({ mode: 'archive', id: +route.params.id || +route.query.id })
    },
    {
        path: '/orders/active/:id?', name: 'OrdersActive',
        component: () => import('@pages/order/Orders.vue'),
        props: (route) => ({ mode: 'active', id: +route.params.id || +route.query.id })
    },
    {
        path: '/rft/archive/:id?', name: 'RftArchive',
        component: () => import('@pages/order/Rft.vue'),
        props: (route) => ({ mode: 'archive', id: +route.params.id || +route.query.id })
    },
    {
        path: '/rft/active/:id?', name: 'RftActive',
        component: () => import('@pages/order/Rft.vue'),
        props: (route) => ({ mode: 'active', id: +route.params.id || +route.query.id })
    },
    {
        path: '/fuel-cards/topup', name: 'FuelCardTopup',
        component: () => import('@pages/order/FuelCard.vue'),
        props: () => ({ mode: 'topup' })
    },
    {
        path: '/fuel-cards/history', name: 'FuelCardHistory',
        component: () => import('@pages/order/FuelCard.vue'),
        props: () => ({ mode: 'history' })
    },
    {
        path: '/transport/trucks/:id?', name: 'Trucks',
        component: () => import('@pages/transport/Trucks.vue'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/transport/semitrailers/:id?', name: 'Semitrailers',
        component: () => import('@pages/transport/Semitrailers.vue'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/transport/assigns', name: 'Assigns',
        component: () => import('@pages/transport/Assigns.vue')
    },
    {
        path: '/staff/drivers/:id?', name: 'Drivers',
        component: () => import('@pages/staff/Drivers.vue'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/staff/dispatchers/:id?', name: 'Dispatchers',
        component: () => import('@pages/staff/Dispatchers.vue'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/settings', name: 'ProfileSettings',
        component: () => import('@pages/profile/ProfileSettings.vue')
    },
    {
        path: '/enterprise/accept-certs/:id?', name: 'AcceptCerts',
        component: () => import('@pages/enterprise/AcceptCerts'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/enterprise/route-sheets/:id?', name: 'RouteSheets',
        component: () => import('@pages/enterprise/RouteSheets'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/enterprise/repair-orders/:id?', name: 'RepairOrders',
        component: () => import('@pages/enterprise/RepairOrders'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/enterprise/regulations/:id?', name: 'Regulations',
        component: () => import('@pages/enterprise/Regulations'),
        props: (route) => ({ id: +route.params.id || +route.query.id })
    },
    {
        path: '/enterprise/wage/:id?', name: 'Wage',
        component: () => import('@pages/enterprise/Wage'),
        props: (route) => ({ id: route.params.id || route.query.id })
    },
    {
        path: '/enterprise/sensors-data/:id?', name: 'SensorsData',
        component: () => import('@pages/enterprise/SensorsData'),
    },
    {
        path: '/enterprise/contact-phones', name: 'ContactPhones',
        component: () => import('@pages/enterprise/ContactPhones')
    },
]

let routes = [
    { path: '/preorders', redirect: { name: 'PreOrdersAll'} },
    { path: '/orders', redirect: { name: 'OrdersActive'} },
    { path: '/rft', redirect: { name: 'RftActive'} },
    { path: '/fuel-cards', redirect: { name: 'FuelCardTopup'} },
    { path: '/transport', redirect: { name: 'Trucks'} },
    { path: '/staff', redirect: { name: 'Drivers'} },
    { path: '/enterprise', redirect: { name: 'RouteSheets'} },
    {
        path: '', name: '',
        component: () => import('@ui/layouts/BaseLayout.vue'),
        children: baseLayoutRoutes
    },
    {
        path: '*', name: 'PageNotFound',
        component: () => import('@ui/layouts/BaseLayout.vue'),
        props: () => ({ status: 404 })
    }
]

export default routes
