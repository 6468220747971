export default {
    model: {
        "formerAmountRefrigeratorFuel": 0,
        "formerAmountTruckFuel": 0,
        "newAmountRefrigeratorFuel": 0,
        "newAmountTruckFuel": 0,
        "routeSheetId": 0
    },
    routeSheet: {},
    groups: [
        {name: 'Текущие значения', fields: ['formerAmountTruckFuel', 'formerAmountRefrigeratorFuel', 'amount']},
        {name: 'Новые значения', fields: ['newAmountTruckFuel', 'newAmountRefrigeratorFuel']}
    ],
    fields: {
        "formerAmountTruckFuel": {
            name: "ДТ, л. (тягач)",
            type: "number",
            disabled: true,
            model: ({meta}) => {
                let count = 0
                meta.routeSheet.fuel.refueled.forEach(item =>
                    count += item.fuelType === 'DIESEL' && item.vehicleType === 'TRUCK' ? parseFloat(item.volume) : 0)
                return count
            }
        },
        "formerAmountRefrigeratorFuel": {
            name: "ДТ, л. (рефрижератор)",
            type: "number",
            disabled: true,
            required: false,
            model: ({meta}) => {
                let count = 0
                meta.routeSheet.fuel.refueled.forEach(item =>
                    count += item.fuelType === 'DIESEL' && item.vehicleType === 'REFRIGERATOR' ? parseFloat(item.volume) : 0)
                return count
            }
        },
        "amount": {
            name: "Итого",
            type: "number",
            disabled: true,
            model: ({model}) => {
                return model.formerAmountTruckFuel + model.formerAmountRefrigeratorFuel
            }
        },
        "newAmountTruckFuel": {
            name: "ДТ, л. (тягач)",
            type: "number",
            initial: 0,
            format: v => v ? parseFloat(v) : '',
            watch: ({value, field, fields, vm}) => {
                value = parseFloat(value)
                if (!_.isNumber(value) || value === field.initial) return
                const amount = fields.amount.model || 0
                const result = amount <= 0 ? 0 : amount - value
                vm.set('newAmountRefrigeratorFuel', result >= 0 ? result : 0)
            }
        },
        "newAmountRefrigeratorFuel": {
            name: "ДТ, л. (рефрижератор)",
            type: "number",
            initial: 0,
            format: v => v ? parseFloat(v) : '',
            watch: ({value, field, fields, vm}) => {
                value = parseFloat(value)
                if (!_.isNumber(value) || value === field.initial) return
                const amount = fields.amount.model || 0
                const result = amount <= 0 ? 0 : amount - value
                vm.set('newAmountTruckFuel', result >= 0 ? result : 0)
            }
        }
    },
}
