const moment = require('moment')
import 'moment/locale/ru'

const OFFSET_MSK = '+0300'
const OFFSET_MSK_HOURS = 3

export default {

    moment(date) {
        return moment(date)
    },

    getMoment() {
        return moment
    },

    dateToMsk(date, format) {
        return moment(date).isValid() ? moment(date).utcOffset(OFFSET_MSK).format(format || 'YYYY-MM-DDTHH:mm:ss') : ''
    },

    dateFromMsk(date, format) {
        date = this.dateFormat(date, 'YYYY-MM-DDTHH:mm:ss')
        if (date) {
            const offset = moment().utcOffset() / 60
            const diff = (offset * 3600 - 3600 * OFFSET_MSK_HOURS) * 1000
            date = this.dateFormat(this.toUtc(date) + diff, format || 'YYYY-MM-DDTHH:mm:ss')
        }
        return date
    },

    /*
    * https://stackoverflow.com/questions/13903897/javascript-return-number-of-days-hours-minutes-seconds-between-two-dates
    * */
    dateDiff(secEnd, secStart) {
        secStart = secStart || 0
        let delta = Math.abs(secEnd - secStart) / 1000
        const isNegative = secStart > secEnd ? -1 : 1
        return [
            ['years', 365 * 24 * 60 * 60],
            ['month', 30 * 24 * 60 * 60],
            ['days', 24 * 60 * 60],
            ['hours', 60 * 60],
            ['minutes', 60],
            ['seconds', 1]
        ].reduce((acc, [key, value]) => (acc[key] = Math.floor(delta / value) * isNegative, delta -= acc[key] * isNegative * value, acc), {})
    },

    getFullDate(msec, countHours) {
        const diff = this.dateDiff(msec)
        let output = ""
        msec < 3600 * 24 * 30 * 1000 || (output = `${diff.years > 0 ? `${diff.years<5 ?'г.':'л.'} ` : ''}${diff.month}мес. `)
        output += `${diff.days}дн. ${diff.hours}ч. ${diff.minutes}мин. `
        !countHours || msec < 3600 * 24 * 1000 || (output += `(${Math.floor(msec / 3600 / 1000)} ч.)`)
        return output
    },

    date(date, h, s) {
        let format = 'DD.MM.YYYY'
        !h || (format += ' HH:mm')
        !(h && s) || (format += ':ss')
        return this.dateFormat(date, format)
    },

    dateMsk(date, h, s) {
        let format = 'DD.MM.YYYY'
        h && (format += ' HH:mm')
        !(h && s) || (format += ':ss')
        return this.dateFromMsk(date, format)
    },

    curDate(format) {
        return moment().format(format)
    },

    toUtc(date) {
        return moment(date).isValid() ? moment(date).utc().valueOf() : 0
    },

    toSec(date) {
        return moment.duration(date).asSeconds()
    },

    toMsec(date) {
        return moment.duration(date).asMilliseconds()
    },

    secToTime(secs, delim) {
        const diff = this.dateDiff(secs)
        return delim !== false ? ([
            diff.hours < 10 ? `0${diff.hours}` : diff.hours,
            diff.minutes < 10 ? `0${diff.minutes}` : diff.minutes,
            diff.seconds < 10 ? `0${diff.seconds}` : diff.seconds
        ]).join(typeof delim === 'string' ? delim : ':') : [diff.hours, diff.minutes, diff.seconds]
    },

    utcDate(date, format) {
        return moment(date).isValid() ? moment(date).utc().format(format) : ''
    },

    fromUtc(seconds, format = 'DD.MM.YYYY HH:mm') {
        return moment(new Date(seconds * 1000)).format(format)
    },

    dateFormat(date, format, locale) {
        date = moment(date)
        return date.isValid() ? date.locale(locale || 'ru').format(format || 'YYYY-MM-DD') : ''
    },

    isValidDate(date) {
        return moment(date).isValid()
    },

    isMatchDate(date, pattern) {
        pattern = typeof pattern === 'string' ? pattern : `^[\\d]{4}-[\\d]{2}-[\\d]{2}(T|\\s)?[\\d]{2}:[\\d]{2}(:[\\d]{2})?`
        return !date ? false : date.toString().match(new RegExp(pattern, 'gi')) !== null && this.isValidDate(date)
    },

    arrivingDate(data) {
        let date
        _.get(data, 'arriving.arrivingType') && data.arriving.arrivingType === 'TIME_RANGE'
            ? date = [this.dateMsk(_.get(data, 'arriving.beginDateTime'), true), this.dateMsk(_.get(data, 'arriving.endDateTime'), true)]
            : date = (_.get(data, 'arriving.dateTime') ? this.dateMsk(data.arriving.dateTime, true) : '')
        return _.isArray(date) ? `${date[0] ? `с ${date[0]}` : ''}${date[1] ? ` по ${date[1]}` : ''}` : date
    },
}
