<template>
    <div class="fuel-card">
        <p v-if="canViewFuelReminder === null && canTopUpFuelCards === null">
            Загрузка...
        </p>
        <div v-else>
            <Tabs class="fuel-card-tabs" :tabs="tabs">
                <div slot="topup">
                    <template v-if="cards && cards.length">
                        <h3 class="mt-5 tx-c clr-ltg-4">
                            Пополнение по заказу №{{ order.number }}
                        </h3>
                        <FormItem>
                            <FormInput
                                v-model="cardFilter"
                                placeholder="Номер карты или название"
                            />
                        </FormItem>
                        <h4 class="tx-c my-20 clr-green" v-if="canViewFuelReminder">
                            Доступно: {{ $$.price(order.affordableBidAmount) }}
                        </h4>
                    </template>
                    <Accordion v-if="cards && cards.length" :key="updateKey">
                        <AccordionItem
                            v-for="(card, k) in getCardFiltered()"
                            :key="k"
                            :expand="true"
                            :panelContentBind="{style: {minHeight: '0'}}"
                        >
                            <template slot="title">
                                <div class="fuel-card-title pt-10">
                                    <div class="fuel-card-name">
                                        <div class="mb-10 tx-bold">
                                            {{ card.fuelCardNumber }}
                                        </div>
                                        <template v-if="canTopUpFuelCards">
                                            <FaIcon
                                                icon="edit"
                                                @click.stop="setCardName(card.fuelCardId)"
                                                title="Добавить/Изменить имя карты"
                                                class="link link-black"
                                            />
                                        </template>
                                        <template v-if="card.fuelCardShowing">
                                            <small><i class="clr-dark pl-15 tx-bold">{{ card.fuelCardShowing }}</i></small>
                                        </template>
                                        <i v-else-if="canTopUpFuelCards" class="clr-ltg-2 pl-15">
                                            - без имени -
                                        </i>
                                    </div>
                                    <div>
                                        <img
                                            v-if="card.fillingStationId"
                                            alt=""
                                            :src="require(`@/assets/icons/fuel-card/${card.fillingStationId}.png`)"
                                            class="fuel-card-icon"
                                        />
                                    </div>
                                </div>
                            </template>
                            <div class="fuel-card-content">
                                <template v-if="canViewFuelReminder === false && canTopUpFuelCards === false">
                                    <h3>
                                        У Вас нет разрешений на просмотр <br>свободного остатка
                                        д/с по топливным картам и их пополнения...
                                    </h3>
                                </template>
                                <FormItem v-else-if="canTopUpFuelCards">
                                    <FormInput
                                        :ref="'orderAmount' + card.fuelCardId"
                                        v-model.lazy="cardOrdersAmount[card.fuelCardId]"
                                        @keyup="setOrderAmount(card.fuelCardId)"
                                        placeholder="Сумма"
                                        type="number"
                                    />
                                </FormItem>
                                <h3 v-else>
                                    У Вас нет разрешения на пополнение по картам
                                </h3>
                            </div>
                        </AccordionItem>
                    </Accordion>
                    <h2 v-else class="tx-c clr-ltg tx-up">Карт не обнаружено</h2>
                    <div v-if="cards && cards.length" class="buttons">
                        <h4 class="tx-c my-20 clr-green" v-if="canViewFuelReminder">
                            Доступно: {{ $$.price(order.affordableBidAmount) }}
                        </h4>
                        <button v-if="canTopUpFuelCards" class="btn btn-blue" @click="topUpFuelCards">Пополнить</button>
                    </div>
                </div>


                <div slot="history">
                    <div v-if="cards && cards.length">
                        <FormItem search>
                            <FormInput
                                v-model="cardFilter"
                                placeholder="Номер карты или название"
                            />
                        </FormItem>
                    </div>
                    <div>
                        <p v-if="cardHistory === null">
                            Загрузка...
                        </p>
                        <div v-else-if="cardHistory.length">
                            <div class="fuel-card-history flex flex-col-20 mt-20">
                                <div v-for="(item, k) in getCardFiltered(cardHistory)" :key="k" class="flex flex-align w-100">
                                    <div class="flex flex-row-10 flex-align fuel-card-history-name">
                                        <div class="tx-bold w-100">№{{ item.cartNumber }}</div>
                                        <small v-if="item.fuelCardShowing" class="clr-dark tx-bold">
                                            &laquo;{{ item.fuelCardShowing }}&raquo;
                                        </small>
                                        <div class="clr-ltg-3">
                                            <small>{{ $util.date.date(item.dateTime, true) }}</small>
                                        </div>
                                    </div>
                                    <div class="fuel-card-history-icon">
                                        <img
                                            v-if="item.fillingStationId"
                                            alt=""
                                            class="fuel-card-icon"
                                            :src="require(`@/assets/icons/fuel-card/${item.fillingStationId}.png`)"
                                        />
                                    </div>
                                    <div class="clr-green tx-inline flex flex-row-10 flex-align fuel-card-history-amount">
                                        <div>{{ $$.price(item.amount) }}</div>
                                        <div>{{ item.status }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Tabs>
        </div>
        <Confirm name="fc-name">
            <FormItem>
                <FormInput
                    placeholder="Укажите название карты"
                    v-model="fuelCardName"
                />
            </FormItem>
        </Confirm>
    </div>
</template>

<script>

export default {
    name: "FuelCardOrder",
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tabs: [{
                id: 'topup',
                name: 'Пополнение',
                active: true,
                onOpen: this.show
            }, {
                id: 'history',
                name: 'История',
                onOpen: this.getHistory
            }],
            cards: null,
            cardOrders: {},
            cardOrdersAmount: {},
            cardHistory: {},
            cardFilter: null,
            updateKey: _.randKey(),
            canViewFuelReminder: null,
            canTopUpFuelCards: null,
            fuelCardName: null
        }
    },
    mounted() {
        this.$emit('setup', this)
        this.show()
    },
    methods: {
        async checkPermissions() {
            this.canViewFuelReminder = null
            this.canTopUpFuelCards = null
            if (this.$service.account.access.isCarrier()) {
                this.canViewFuelReminder = true
                this.canTopUpFuelCards = true
            } else {
                await this.$service.account.hasRule('CAN_VIEW_FUEL_REMINDER').then(can => this.canViewFuelReminder = can)
                this.$service.account.access.isDispatcher() === false
                    ? this.canTopUpFuelCards = false
                    : await this.$service.account.hasRule('CAN_TOPUP_FUEL_CARDS').then(can => this.canTopUpFuelCards = can)
            }
        },
        async show() {
            await this.checkPermissions()

            this.$repo('fc').orderCards(this.order.id).then(({list}) => {
                this.cards = list
                this.getHistory()
                this.updateKey = _.randKey()

                list.forEach(card => this.cardOrders[card.fuelCardId] = [{
                    orderNumber: this.order.number,
                    orderId: this.order.id,
                    affordableBidAmount: this.order.affordableBidAmount
                }])
            })
        },
        getHistory() {
            this.cardHistory = null
            this.$repo('fc').orderHistory(this.order.id).then(({list}) => {
                this.cardHistory = list
            })
        },
        setOrderAmount(cardId) {
            const value = _.num(this.$refs[`orderAmount${cardId}`][0].value)
            value > 0 ? this.$set(this.cardOrdersAmount, cardId, value) : this.$delete(this.cardOrdersAmount, cardId)
        },
        topUpFuelCards() {
            let data = [];
            this.cards.forEach(card => {
                if (card.fuelCardId in this.cardOrders) {
                    const amountData = this.getAmountData(card.fuelCardId)
                    amountData !== null && data.push(amountData)
                }
            })
            this.topUpFuelCard(data)
        },
        getAmountData(fuelCardId) {
            let data = null
            this.cardOrders[fuelCardId].forEach(o => {
                let exceeded = false
                const amountData = this.cardOrdersAmount[fuelCardId]
                if (_.isNil(amountData)) return

                const amount = _.num(amountData)
                if (amount > _.num(o.affordableBidAmount)) exceeded = true
                if (amount > 0) {
                    data = exceeded ? false : Object.assign({
                        amount: amount
                    }, {fuelCardId})
                }
            })
            return data
        },
        topUpFuelCard(amountData) {

            if (!this.canTopUpFuelCards) return false

            const data = amountData

            if (data.length === 0) {
                this.$notify().warn('Не указана сумма пополнения.')
                return false
            }
            if (data.includes(false)) {
                this.$notify().warn('Сумма пополнения превышает доступный баланс.')
                return false
            }

            this.$repo('fc').orderBalance(this.order.id, data).then(({list}) => {
                const err = []
                list.forEach(card => {
                    if (card.result === 'SUCCESS') return
                    let i = this.cards.findIndex(c => c.fuelCardId = card.fuelCardId)
                    err.push(this.cards[i].fuelCardNumber)
                })

                this.show()
                err.length
                    ? this.$ok(`Не удалось произвести пополнение по картам №: ${err.join(', ')}`)
                    : this.$notify().info('Карта пополнена.')
                this.$emit('payed')

            }).alert('Не удалось произвести пополнение.')
        },
        setCardName(fuelCardId) {
            const card = this.cards.find(c => c.fuelCardId === fuelCardId)
            const cardHistory = card ? this.cardHistory.find(c => c.cartNumber === card.fuelCardNumber) : null

            this.fuelCardName = card?.fuelCardShowing

            this.$confirm('fc-name', {
                title: 'Изменение имени карты',
                buttonCancel: true,
                buttons: 'Сохранить',
                onConfirm: () => {
                    this.fuelCardName?.trim() && this.$repo('fc').setName(fuelCardId, this.fuelCardName).then(() => {
                        card && this.$set(card, 'fuelCardShowing', this.fuelCardName)
                        cardHistory && this.$set(cardHistory, 'fuelCardShowing', this.fuelCardName)
                        this.fuelCardName = ''
                    }).done('Карта успешно переименована.').alert('Не удалось установить имя карты.')
                }
            })
        },
        getCardFiltered(source) {
            source = source || this.cards
            return this.cardFilter?.trim()
                ? source.filter(o => ((o.fuelCardNumber || o.cartNumber) + o.fuelCardShowing).match(new RegExp(this.cardFilter, 'gi')))
                : source
        }
    }
}

</script>

<style scoped lang="scss">
.fuel-card {
    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
            padding: 0 10px;
        }
    }
    &-icon {
        max-height: 50px;

        @media (max-width: 425px) {
            max-width: 75px;
        }
    }

    &-history-name {
        width: 40%;
    }
    &-history-icon {
        width: 30%;
    }
    &-history-amount {
        width: 30%;
    }

    @media (max-width: 425px) {
        &-history-name {
            width: 100%;
        }
        &-history-icon {
            width: 50%;
        }
        &-history-amount {
            width: 50%;
        }
    }
}
</style>
