<template>
    <div v-if="messages" class="messages">
        <p v-if="noMessages" class="txc">Нет новых сообщений</p>
        <template v-else>
            <div v-for="(m, k) in messages" :key="k" class="messages-item">
                <div v-if="m.route || m.linkedObjectType === 'CONFIRMATION' || m.linkedObjectType === 'CHAT'">
                    <a @click.prevent="linkTo(m)">{{ m.message }}</a>
                </div>
                <div v-else>{{ m.message }}</div>
                <div class="messages-item-date">{{ $util.date.dateMsk(m.createdDate, true) }}</div>
            </div>
        </template>
        <div v-if="!loaded && !noMessages" class="flex flex-center p-20">
            <button class="btn btn-blue" @click="getMessages">Загрузить еще</button>
        </div>
        <div v-if="setAllMessagesRead" class="flex flex-center p-20">
            <button class="btn btn-blue" @click="markAllAsRead">
                Пометить все сообщения прочитанными
            </button>
        </div>
    </div>
</template>

<script>

/*
TEST -> "Тестовое сообщение", "Это тестовое сообщение, отправленное ${LocalDateTime.now()}"

LINK_CODE_APPROVED -> "Код привязки подтвержден", "Код привязки подтвержден перевозчиком \"${carrierInfo.companyName}\""

USER_LINKED -> "Водитель добавлен", "Водитель ${user.person.fullName} добавлен в список Вашего персонала"
-> "Диспетчер добавлен", "Диспетчер ${user.person.fullName} добавлен в список Вашего персонала"

CARRIER_STATUS_CHANGED  -> "Ваш статус изменился", "Статус Вашего профиля перевозчика изменился"
-> "Статус перевозчика изменился", "Статус профиля Вашего перевозчика \"${carrierInfo.companyName}\" изменился"

DRIVER_STATUS_CHANGED -> "Ваш статус изменился", "Статус Вашего профиля водителя изменился"
-> "Статус водителя изменился", "Статус профиля Вашего водителя ${driverInfo.fullName} изменился"
-> "Статус водителя изменился", "Статус профиля водителя ${driverInfo.fullName} изменился"

TRUCK_STATUS_CHANGED -> "Статус тягача изменился", "Статус тягача ${truckInfo.model} изменился"

SEMITRAILER_STATUS_CHANGED  -> "Статус полуприцепа изменился", "Статус полуприцепа ${semitrailerInfo.model} изменился"

RFT_ASSIGNED -> "Назначен заказ", "Вам назначен заказ #${rftNumber}."
-> "Назначен заказ", "Вам назначен заказ #${rftNumber}."

OUTBID -> "Измените ставку", "Ваше предложение по тендеру № ${preorder.number} больше не является лучшим. Перейти для изменения Вашего предложения"

REPAIR_ORDER_ADDED -> "Новая заявка на ремонт", "Водитель ${user.person.fullName} (${Phone(user.person.phone)}) подал заявку на ремонт$vehicle: \"${dto.description}\""

REPAIR_ORDER_CHANGED -> "Обновление заявки на ремонт", "Заявка № $number переведена механиком в состояние \"$newStatusName\"$callTo"

// уведомления из 1С от администратора
SIMPLE_MESSAGE -> "$title", "$message"
ADMIN_MESSAGE  -> "$title", "$message"*/

export default {
    name: "Messages",
    props: {
        messageConfirm: Object
    },
    data() {
        return {
            messages: null,
            unreadQuantity: 0,
            page: 0,
            pageSize: 50,
            loaded: false,
            noMessages: false,
            setAllMessagesRead: false,
            linkObjectRoutes: {
                DRIVER: {name: 'Drivers'},
                DISPATCHER: {name: 'Dispatchers'},
                TRUCK: {name: 'Trucks'},
                SEMITRAILER: {name: 'Semitrailers'},
                TENDER: {name: 'PreOrdersAll'},
                RFT: this.$service.account.access.isAdmin()
                    ? {name: 'OrdersActive'}
                    : {name: 'RftActive'},
                REPAIR_ORDER: {name: 'RepairOrders'},
                WAGE: {name: 'Wage'},
                REGULATION: {name: 'Regulations'},
                ACCEPTANCE_CERTIFICATE: {name: 'AcceptCerts'},
                ROUTE_SHEET: {name: 'RouteSheets'},
            }
        }
    },
    created() {
        this.getUnreadQuantity().finally(unreadQuantity => {
            this.setAllMessagesRead = unreadQuantity > 0
            this.getMessages()
        })
    },
    methods: {
        getMessages() {
            this.messages = this.messages ? this.messages : []
            this.page += 1
            this.$repo('notify').getMessages({
                page: this.page - 1,
                size: this.pageSize,
            }).finally(({list}) => {
                if (list) {
                    if (list.length === 0 && this.messages.length === 0) {
                        this.noMessages = true
                        this.setAllMessagesRead = false
                        return
                    }
                    list.length && (list = this.setLinkedObjects(list))
                    this.messages = _.uniqBy(this.messages.concat(list), 'id')
                    this.loaded = list.length === 0 || list.length < this.pageSize
                    if (list.length && this.unreadQuantity > 0) {
                        this.markAsRead(list.map(v => v.id))
                    }
                } else this.page -= 1
            })
        },
        setLinkedObjects(messages) {
            const list = []
            messages.forEach(msg => {
                msg.route = this.linkObjectRoutes[msg.linkedObjectType] || null
                list.push(msg)
            })
            return list
        },
        linkTo(item) {
            const redirect = setId => {
                const route = item.route
                const id = setId === false || !item.linkedObjectId ? '' : parseInt(item.linkedObjectId)

                if (!route) return

                route.params = {}

                id && (route.params.id = id)
                item.linkedObjectPage && (route.params.page = item.linkedObjectPage)

                this.$redirect(route)
                this.$popupClose()
            }

            if (item.linkedObjectType === 'TENDER') {
                return this.$repo('preorder').get(item.linkedObjectId).finally(({response, isSuccess}) => {
                    if (response?.data.result === 'TENDER_COMPLETED') {
                        return this.$confirm({
                            title: 'Данный тендер завершен',
                            buttons: 'Перейти к тендерам',
                            buttonCancel: true,
                            onConfirm: () => redirect(false)
                        })
                    }
                    isSuccess ? redirect() : this.$notify().warn('Не удалось получить детализацию по тендеру.')
                }).alert(false)
            }

            if (item.linkedObjectType === 'CONFIRMATION') {
                return this.$repo('notify').getConfirmMessage(item.linkedObjectId).then(({object}) => {
                    if (this.messageConfirm) {
                        this.messageConfirm.confirm = object
                        this.messageConfirm.open()
                    }
                }).alert('Не удалось загрузить данные сообщения.')
            }

            if (item.linkedObjectType === 'CHAT') {
                this.$emit('chat', parseInt(item.linkedObjectId))
                return
            }

            redirect()
        },
        markAsRead(ids) {
            this.$rs.bg().repo('notify').markAsRead(ids).then(this.getUnreadQuantity)
        },
        getUnreadQuantity() {
            return this.$repo('notify').getUnreadQuantity().finally(({object}) => {
                const q = !object ? 0 : +object
                this.$emit('unread-quantity', q)
                return this.unreadQuantity = q
            })
        },
        markAllAsRead() {
            this.$repo('notify').markAllAsRead(this.messages[0].id).then(() => {
                this.setAllMessagesRead = false
                this.getUnreadQuantity()
            }).done('Сообщения помечены как прочитанные.').alert('Не удалось пометить сообщения прочитанными.')
        }
    }
}


</script>

<style scoped lang="scss">
.messages {
    &-item {
        padding: 20px 35px 15px;
        border-bottom: $gray-e7 1px solid;

        a {
            color: $blue-dark-md;
        }

        &-date {
            padding: 10px 0 0;
            font-size: 10pt;
            color: $black-73;
        }
    }
}
</style>
