<template>
    <textarea
        class="form-text"
        v-model="val"
        v-mask="mask ? ($app().mask[mask] || mask) : undefined"
        @keyup="input"
        @blur="input"
    >
    </textarea>
</template>

<script>
import {FORM_ITEM_COMPONENT_TAG} from "@ui/form/Form"

export default {
    name: "FormText",
    props: {
        index: Number,
        mask: String,
        value: [Number, String],
        rules: Array,
        watch: Function,
        format: Function,
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    computed: {
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    data() {
        return {
            val: this.value,
            validator: null
        }
    },
    created() {
        let rules = this.rules || []
        this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')
        this.required === false && (rules = rules.filter(r => r !== 'required'))
        rules.length && (this.validator = new this.$service.validation({rules, name: this.$attrs.name}))
    },
    mounted() {
        this.$nextTick(() => {
            const wrap = this.$getParent({tag: FORM_ITEM_COMPONENT_TAG})
            wrap && !wrap.controls && wrap.setControls()
            wrap && this.$set(wrap, 'wrap', false)
        })
        this.isDisabled || (this.validator && this.validator.validate(this.val))
    },
    methods: {
        input() {
            if (this.format) {
                this.val = this.format(this.val)
            }
            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(this.val))
            }
            if (this.watch) {
                const value = this.watch(this.val, this)
                value !== undefined && (this.val = value)
            }
            this.$emit('input', this.val)
        },
        getAttributes() {
            return this.$attrs || {}
        },
        reset() {
            this.val = ''
            this.input()
        },
        getValue() {
            return this.val
        }
    }
}
</script>

<style lang="scss" scoped>
.form-text {
box-shadow: $box-shadow-thin;
}
</style>
