import ApiHandler from "../service/handler"
import helper from '@/helpers'
import Stub from '../service/stub'

export default class extends ApiHandler {

    constructor(data) {

        super(data)

        if (helper.$app().isDev() && helper.$config.api().useStubs) {

            this.stub = new Stub(this._data)

            const repoStubsSettings = data.repo?._stubs || {}

            this.stub.refresh(Object.assign({}, repoStubsSettings))

            this.stubData = this.stub?.get() || null

            this.stubData && (this._data.stubData = this.stubData.response)
        }
    }

    /*
    * method executes at the start of a Promise.prototype.then()
    * @params: response
    * @return: void
    * */
    async after(response) {
        this.isSuccess(response) && this.stub && !this.stubData && this.stub.push(response)
    }
}
