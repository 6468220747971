<template>
    <div v-if="driverId" class="driver-location">
        <YaMap
            v-if="mapKey > 0"
            :key="mapKey"
            :options="{
                center: [defLat, defLon],
                zoom: 5
            }"
            @setup="m => this.map = m"
        />
        <div v-if="route" class="driver-location-route">
            <CarriageRoute :route="route" @setup="i => this.dataRoute = i"/>
        </div>
        <!--        <util-inform @init="init => this.mapLoader = init"/>-->
    </div>
</template>

<script>

import YaMap from '@plugins/YaMap.vue'
import CarriageRoute from './CarriageRoute.vue'

//TODO: toast info
export default {
    name: "DriverLocation",
    components: {YaMap, CarriageRoute},
    props: {
        driverId: Number,
        orderId: Number,
        route: Object
    },
    data() {
        return {
            mapKey: 0,
            defLat: 55.7504461,
            defLon: 37.6174943,
            map: null,
            stomp: null,
            connected: null,
            dataRoute: null,
            mapLoader: null,
            mapObject: {
                lat: 0,
                lon: 0,
                iconOptions: {
                    iconImageHref: require('@/assets/icons/truck-marker.png'),
                },
                moveInterval: this.$app().isLocalhost() ? 10000 : 30000
            }
        }
    },
    beforeDestroy() {
        this.disconnect()
    },
    created() {
        this.open()
    },
    methods: {
        getDriverLocation(cb) {
            this.stomp = new this.$service.stomp({uri: this.$app().stomp}).connect({
                onConnect: () => this.connected = true,
                onError: () => this.connected = false
            })
            this.stomp.subscribe(this.$config.api().driverLocation(this.driverId), loc => {
                const data = JSON.parse(loc.body)
                const coord = data?.coordinates || null
                coord && cb && cb(coord)
            })
        },
        inform(id, message) {
            this.mapLoader && this.mapLoader.inform(id, message)
        },
        open() {
            const id = this.driverId
            this.map = null
            this.mapKey += 1

            _.promise(() => this.map !== null, {}).then(() => {
                const mapObject = {...this.mapObject}
                this.map.addObject(id, mapObject)

                this.getDriverLocation(c => {
                    Object.assign(mapObject, c)
                    const hasPoint = !!this.map.getPoint(id)

                    this.map.addObject(id, mapObject)
                    this.map.resolveObject(id)
                    hasPoint || this.map.setPointZoom(id, 15)

                    this.map.getObject(id) && this.map.getPoint(id) && setTimeout(() => {
                        this.map.getPoint(id) && this.map.move(id, {
                            coord: [mapObject.lat, mapObject.lon],
                            toPoint: true
                        })
                    }, 1000)
                })

                this.map.getObject(id) || this.inform('Определение местоположения водителя.')

                _.promise(() => {
                    if (!this.map.getObject(id))
                        return false
                    if (this.map.getPoint(id))
                        return true
                }, {}).then(() => this.inform()).catch(() => {
                    if (!this.map.getObject(id))
                        return false
                    this.inform({error: 'Не удалось определить местоположение водителя.'})
                })

                this.orderId && this.fetchMapRoute()
            })
        },
        fetchMapRoute() {
            this.$repo('order').orderRoute(this.orderId).alert(false).then(({object}) => {
                const route = []
                const refPoints = object.referencePoints?.length ? object.referencePoints : []
                const fuelStations = object.fuelStations?.length ? object.fuelStations : []

                if (refPoints.length || fuelStations.length) {

                    const points = []
                    const indexes = []

                    refPoints.forEach((p, i) => {
                        p.transitPoint === true || points.push([p.lat, p.lon])
                        p.transitPoint === false || indexes.push(i)
                        route.push([p.lat, p.lon])
                    })

                    this.setMapRoute({
                        id: this.orderId,
                        route,
                        points,
                        indexes
                    })

                    // let coordinates = data.coordinates
                    // // let id = coordinates.lat + coordinates.lon
                    // let id = this.$uuid.v4()
                    // this.mapSidebarItems = Object.assign(this.mapSidebarItems, { [id]: data })
                    // objects[id] = { lat: +coordinates.lat, lon: +coordinates.lon }
                    // objects[id].iconImageHref = data.logo ? data.logo : require('@/assets/img/map-tip-green.svg')
                    // objects[id].hideIconOnBalloonOpen = false
                    // objects[id].balloonContent = {
                    //     balloonContentHeader: this.data.partner.name,
                    //     balloonContentBody: data.address,
                    //     balloonContentFooter: [...data.workingHours.map(wh => (`${wh.DAY}: ${wh.TIME}`)), ...data.phoneNumber].join('<br>'),
                    // }

                    fuelStations.forEach((p, i) => {
                        this.map.addPoint(`fuel-station-${i}`, {
                            lat: parseFloat(p.lat),
                            lon: parseFloat(p.lon),
                            hasBalloon: true,
                            preset: p.refueledVolume !== 0 ? 'islands#greenIcon' : 'islands#redIcon',
                            balloonOffset: [0, -30],
                            hideIconOnBalloonOpen: false,
                            content: {
                                balloonContentHeader: p.fuelStationNumber,
                                balloonContentBody: `
                                        <p><strong>Поставщик ГСМ:</strong> ${p.fuelSupplier || ''}<br>
                                        <strong>Бренд:</strong> ${p.brand || ''}<br>
                                        <strong>Адрес:</strong> ${p.fuelStationAdress || ''}</p>
                                        <p><strong>Цена:</strong> ${p.price || 0}<br>
                                        <strong>Необходимо заправить:</strong> ${p.refueledVolume || 0}</p>
                                        <p><strong>Сумма заправки:</strong> ${p.refueledAmount || 0}</p>
                                    `,
                            },
                        })
                    })
                } else this.map && this.dataRoute && this.setMapRoute({
                    id: this.driverId,
                    route: this.dataRoute.getPoints()
                })

            }).catch(() => {
                this.map && this.map.getObject(this.driverId) && this.dataRoute && this.setMapRoute({
                    id: this.driverId,
                    route: this.dataRoute.getPoints()
                })
            })
        },
        setMapRoute({id, route, points, indexes}) {
            if (!route || !route.length)
                return false
            const polyline = route.length > 85
            this.map.addObject(id, {
                [polyline ? 'polyLineRoute' : 'route']: route,
                routePoints: points,
                routeIndexes: indexes,
                routePointParams: {abc: true},
                polyLineRouteParams: {
                    strokeColor: '#255377',
                    strokeOpacity: 0.8
                }
            })

            this.inform('Определение маршрута.')

            _.promise(() => this.map.hasRoute(id, polyline), {})
                .then(() => this.inform())
                .catch(() => this.inform({error: 'Не удалось определить маршрут.'}))
        },
        disconnect() {
            if (this.stomp) {
                this.stomp.disconnect()
                this.stomp = null
            }
        }
    }
}

</script>

<style scoped>

.driver-location {
    height: 100%;
    width: 100%;
}

</style>
