import Repo from '@/api/service/repo'

export default class extends Repo {

    // Привязка пользователя Перевозчиком или уполномоченным Диспетчером
    add(linkType, userId) {
        return this.client.encode().post('/linkToCarrier', { linkType, userId })
    }

    // Удаление связи с перевозчиком
    delete() {
        return this.client.delete('/linkToCarrier')
    }

    // Отклонение заявки на привязку Перевозчиком или уполномоченным Диспетчером
    cancel(linkType, userId) {
        return this.client.get('/linkToCarrier/cancel', { linkType, userId })
    }

    // Получение базовой информации о пользователе по коду привязки
    userInfo(data) {
        return this.client.get('/linkToCarrier/userInfo', data)
    }

    // Подтверждение кода перевозчиком
    acceptCode(data) {
        return this.client.encode().post('/linkToCarrier/acceptCode', data)
    }

    // Получение кода привязки
    requestCode(linkType) {
        return this.client.encode().post('/linkToCarrier/requestCode', {linkType})
    }

    // Поиск подходящих Перевозчиков
    searchCarriers(linkType) {
        return this.client.get('/linkToCarrier/searchCarriers', {linkType})
    }

    // Подача заявки пользователя на привязку к Перевозчику
    requestCarrier({carrierId, userId, linkType}) {
        return this.client.encode().post('/linkToCarrier/request', {carrierId, userId, linkType})
    }

    userRemovalRequest(removalPhone) {
        return this.client.encode().post('/userRemovalRequest', {removalPhone})
    }

    // Подтверждение перевозчика пользователем
    acceptCarrier() {
        return this.client.post('/linkToCarrier/acceptCarrier')
    }

    requisitesStatus() {
        return this.client.get('/requisites/status')
    }

}
