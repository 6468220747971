<template>
    <component
        :is="component.component"
        :key="component.key"
        v-if="compiled && component.if(getInstance())"
        v-show="component.show(getInstance())"
        v-bind="component.bind"
        v-on="component.on"
        v-model="component.model"
        class="data-model"
    >
        <slot name="header" />
        <template v-for="(group, groupIndex) in data.groups">
            <slot :name="'beforeGroup_' + (group.label || groupIndex)"/>
            <template v-for="(data, ii) in [append(group, 'before')]">
                <Render v-if="data" :data="data" :key="'append-group-before-' + ii" />
            </template>
            <component
                :is="group.component"
                :key="groupIndex + group.key"
                v-if="group.if(getInstance())"
                v-show="group.show(getInstance())"
                v-bind="group.bind"
                v-on="group.on"
                v-model="group.model"
                class="data-model-group"
            >
                <slot :name="'startGroup_' + (group.label || groupIndex)"/>
                <h3
                    v-if="group.title !== false && (group.title || group.name)"
                    v-html="group.title || group.name"
                    class="data-model-group-header"
                ></h3>

                <template v-for="(field, key) in group.fields">
                    <template v-if="field.wrap || data.wrap">
                        <template v-for="(wrap) in [getWrapper(key)]">
                            <template v-for="(data, ii) in [append(wrap, 'before')]">
                                <Render v-if="data" :data="data" :key="'append-wrap-before-' + ii" />
                            </template>
                            <component
                                :is="wrap.component"
                                :key="wrap.key"
                                v-if="wrap.if(getData(key))"
                                v-show="wrap.show(getData(key))"
                                v-bind="wrap.bind"
                                v-on="wrap.on"
                                v-model="wrap.model"
                            >
                                <slot :name="'beforeField_' + key"/>
                                <template v-for="(data, ii) in [append(field, 'before')]">
                                    <Render v-if="data" :data="data" :key="'append-field-before-' + ii" />
                                </template>
                                <component
                                    :is="field.component"
                                    :key="key + field.key"
                                    v-if="field.if(getData(key))"
                                    v-show="field.show(getData(key))"
                                    v-bind="field.bind"
                                    v-on="field.on"
                                    v-model="models[key]"
                                >
                                    <template v-if="field.value !== undefined">
                                        {{ getValue(key) }}
                                    </template>
                                    <template v-for="(render, i) in [getRender(key)]">
                                        <template v-if="render !== undefined">
                                            <Render v-if="(typeof render === 'object')" :data="render" :key="i"/>
                                            <div v-else v-html="render" :key="field.model + i"></div>
                                        </template>
                                    </template>
                                </component>
                                <template v-for="(data, ii) in [append(field, 'after')]">
                                    <Render v-if="data" :data="data" :key="'append-field-after-' + ii" />
                                </template>
                                <slot :name="'afterField_' + key"/>
                            </component>
                            <template v-for="(data, ii) in [append(wrap, 'after')]">
                                <Render v-if="data" :data="data" :key="'append-wrap-after-' + ii" />
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <slot :name="'beforeField_' + key"/>
                        <template v-for="(data, ii) in [append(field, 'before')]">
                            <Render v-if="data" :data="data" :key="'append-field-before-' + ii" />
                        </template>
                        <component
                            :is="field.component"
                            :key="key + field.key"
                            v-if="field.if(getData(key))"
                            v-show="field.show(getData(key))"
                            v-bind="field.bind"
                            v-on="field.on"
                            v-model="models[key]"
                        >
                            <template v-if="field.value !== undefined">
                                {{ getValue(key) }}
                            </template>
                            <template v-for="(render, i) in [getRender(key)]">
                                <template v-if="render !== undefined">
                                    <Render v-if="(typeof render === 'object')" :data="render" :key="i"/>
                                    <div v-else v-html="render" :key="field.model + i"></div>
                                </template>
                            </template>
                        </component>
                        <template v-for="(data, ii) in [append(field, 'after')]">
                            <Render v-if="data" :data="data" :key="'append-field-after-' + ii" />
                        </template>
                        <slot :name="'afterField_' + key"/>
                    </template>
                </template>

                <slot :name="'endGroup_' + (group.label || groupIndex)"/>
            </component>
            <template v-for="(data, ii) in [append(group, 'after')]">
                <Render v-if="data" :data="data" :key="'append-group-after-' + ii" />
            </template>
            <slot :name="'afterGroup_' + (group.label || groupIndex)"/>
        </template>
        <slot name="footer" />
    </component>
</template>

<script>

export const COMPONENT = {
    key: '',
    if: () => true,
    show: () => true,
    component: 'div',
    bind: {},
    on: {},
    initial: undefined,
    model: undefined,
    prompt: undefined,
    wrap: undefined,
    render: undefined,
    watch: undefined,
    value: undefined,
    before: undefined,
    after: undefined,
}

export const GROUP_COMPONENT = {
    key: '',
    label: '',
    name: '',
    title: '',
    if: () => true,
    show: () => true,
    component: 'div',
    bind: {},
    on: {},
    model: undefined,
    fields: [],
    before: undefined,
    after: undefined,
}

export default {
    name: "DataModel",
    props: {
        model: Object,
        tag: Object,
        value: Object,
        onChange: Function
    },
    data() {
        return {
            component: _.merge({}, COMPONENT, this.tag || {}),
            data: {
                fields: {},
                assign: {},
                model: {},
                groups: [],
                render: null,
                wrap: null
            },
            dataModel: this.value,
            fields: {},
            models: {},
            stageModels: {},
            compiled: false
        }
    },
    watch: {
        models: {
            deep: true,
            handler(fields) {

                Object.keys(fields).forEach(field => {

                    const has = _.has(this.data.model, field)

                    has && _.set(this.data.model, field, fields[field])

                    if (this.stageModels[field] !== undefined && this.stageModels[field] === this.getModel(field)) {

                        return
                    }

                    const data = this.fieldWatch(field)

                    data === undefined || (has && _.set(this.data.model, field, data))

                    this.stageModels[field] = this.getModel(field)
                })

                this.dataModel && Object.assign(this.dataModel, this.data.model)

                this.change()
            }
        }
    },
    created() {

        _.isPlainObject(this.model) && (this.data = _.cloneDeep(this.model))

        this.data.model = _.isPlainObject(this.data.model) ? this.data.model : {}
        this.data.fields = _.isPlainObject(this.data.fields) ? this.data.fields : {}
        this.data.assign = _.isPlainObject(this.data.assign) ? this.data.assign : {}
        this.data.groups = _.isArray(this.data.groups) ? this.data.groups : []

        this.fetchFields()
        this.fetchGroups()

        this.compiled = true
    },
    mounted() {
        this.$emit('setup', this)
    },
    methods: {
        change() {
            this.onChange && this.onChange(this.data.model)
            this.$emit('change', this.data.model)
        },
        getInstance() {
            return this
        },
        setModel(field, data) {
            this.$set(this.models, field, data)
            _.has(this.data.model, field) && _.set(this.data.model, field, data)
        },
        getModel(field) {
            return this.models[field]
        },
        getValue(field) {
            const fieldData = this.data.fields[field]
            return _.isFunction(fieldData.value) ? fieldData.value(this.getData(field)) : fieldData.value
        },
        setData(path, value) {
            if (!_.isString(path) || !_.has(this.data, path)) return
            _.set(this.data, path, value)
        },
        getData(field) {
            const set = (value, prop) => this.set(field, value, prop)
            const bind = (value, prop) => this.set(field, value, `bind.${prop}`)
            const assign = (value, prop) => this.assign(field, value, prop)

            return {
                set,
                bind,
                assign,
                key: field,
                value: this.getModel(field),
                field: this.data.fields[field],
                fields: this.data.fields,
                model: this.data.model,
                vm: this,
                meta: this.data
            }
        },
        assign(field, value, prop) {

            if (prop !== undefined) {

                const fieldData = this.data.fields[field]
                const fieldStaged = this.fields[field]

                _.set(fieldData, prop, value)
                _.set(fieldStaged, prop, value)

                this.$set(this.data.fields, field, fieldData)
                this.$set(this.fields, field, fieldStaged)

            } else {

                this.setModel(field, value)
            }

            return true
        },
        set(field, value, prop) {

            this.assign(field, value, prop)

            this.updateField(field)
        },
        fetchFields() {

            Object.keys(this.data.fields).forEach(field => {

                _.isPlainObject(this.data.fields[field]) || (this.data.fields[field] = {})

                this.fields[field] = _.cloneDeep(this.data.fields[field])

                this.fetchField(field)

                if (this.fields[field].initial !== undefined) {

                    const initial = _.isFunction(this.fields[field].initial)

                        ? this.fields[field].initial(this.getData(field)) : this.fields[field].initial

                    this.setModel(field, initial)

                    this.fields[field].initial = this.data.fields[field].initial = initial

                } else {

                    this.fields[field].initial = this.data.fields[field].initial = this.getModel(field)
                }

                _.isFunction(this.data.assign[field]) && this.data.assign[field](this.getData(field))
            })
        },
        fetchField(field, assign) {

            const data = Object.assign({}, _.cloneDeep(COMPONENT), _.cloneDeep(this.fields[field]))

            _.isPlainObject(assign) && Object.assign(data, assign)

            const model = _.isFunction(data.model) ? data.model(this.getData(field)) : data.model

            this.setModel(field, model !== undefined ? model : _.get(this.data.model, field))

            model !== undefined && (data.model = model)

            _.isFunction(data.bind) && (data.bind = data.bind(this.getData(field)))

            this.$set(this.data.fields, field, this.compileField(field, data))

            this.data.groups.some(group => {
                if (group.fields?.[field]) {
                    this.$set(group.fields, field, this.data.fields[field])
                    return true
                }
            })

            return this.data.fields[field]
        },
        compileField(field, fieldData) {

            const data = {}
            const bind = {}
            const component = Object.keys(COMPONENT)

            Object.keys(fieldData).forEach(key => {
                if (component.includes(key)) {
                    data[key] = fieldData[key]
                } else {
                    bind[key] = fieldData[key]
                    delete fieldData[key]
                }
            })

            _.isPlainObject(fieldData.bind) && Object.assign(bind, fieldData.bind)

            data.bind = bind

            Object.assign(fieldData, data)

            if (_.isFunction(fieldData.bind.bind)) {

                Object.assign(fieldData.bind, fieldData.bind.bind(this.getData(field)))

                delete fieldData.bind.bind
            }

            return fieldData
        },
        fetchGroups() {

            this.data.groups.length || (this.data.groups.push({name: ''}))

            const groups = [...this.data.groups]

            groups.forEach((group, i) => {

                _.isPlainObject(group) || (group = {})

                this.data.groups[i] = _.merge({}, GROUP_COMPONENT, group)

                if (_.isArray(group.fields) && group.fields.length) {

                    this.data.groups[i].fields = {}

                    group.fields.forEach(field => {
                        this.data.fields[field] && (this.data.groups[i].fields[field] = this.data.fields[field])
                    })
                }
            })

            this.data.groups.length === 1 && !this.data.groups[0].fields?.length && (this.data.groups[0].fields = this.data.fields)
        },
        fieldWatch(field) {
            if (_.isFunction(this.data.fields[field].watch)) return this.data.fields[field].watch(this.getData(field))
        },
        getRender(field) {

            const render = this.data.fields[field].render || this.data.render

            return _.isFunction(render) ? render(this.getData(field)) : render
        },
        append(data, key) {

            if (!key || !['before', 'after'].includes(key) || data[key] === undefined) return

            return data[key]
        },
        getWrapper(field) {

            let wrap = this.data.fields[field].wrap || this.data.wrap

            _.isFunction(wrap) && (wrap = wrap(this.getData(field)))

            _.isPlainObject(wrap) || (wrap = {})

            wrap.key || (wrap.key = _.randKey())

            return _.merge({}, COMPONENT, wrap)
        },
        updateField(field, assign) {

            _.isPlainObject(assign) || (assign = {})

            this.fetchField(field, {...assign, key: _.randKey()})
        },
        updateGroup(nameOrLabel, index, assign) {

            const group = this.data.groups.find((data, i) => {
                return nameOrLabel ? (data.name === nameOrLabel || data.label === nameOrLabel) : i === index
            })

            if (!group) return

            _.isPlainObject(assign) && Object.assign(group, assign)

            Object.keys(group.fields).forEach(field => this.updateField(field))

            this.$set(group, 'key', _.randKey())
        },
        updateWrapper(field, assign) {

            _.isPlainObject(assign) && Object.assign(this.data.fields[field].wrap, assign)

            this.$set(this.data.fields[field].wrap, 'key', _.randKey())
        },
    }
}
</script>
