<template>
    <section class="section-page">
        <slot></slot>
    </section>
</template>

<script>
    export default {
        name: "Page",
    }
</script>
