import Repo from '@/api/service/repo'

export default class extends Repo {

    // ЗАЯВКИ НА РЕМОНТ СТ

    createRepairOrder(data) {
        return this.client.post('/repairs/order', data)
    }

    getRepairOrders(params) {
        return this.client.get('/repairs/orders', params)
    }

    repairOrderStatuses() {
        return this.client.get('/repairOrderStatuses')
    }

    // МАРШРУТНЫЕ ЛИСТЫ:

    routeSheets(params) {
        return this.client.get('/routeSheets', params)
    }

    getRouteSheet(id) {
        return this.client.get(`/routeSheets/${id}`)
    }

    refueledChangeRequest(data) {
        return this.client.post('/routeSheets/refueledChangeRequest', data)
    }

    // Проверка данных автономого отопителя
    validateRouteSheetAH(id, data) {
        return this.client.post(`/routeSheets/${id}/validate/ah`, data)
    }

    saveRouteSheet(id, data) {
        return this.client.patch(`/routeSheets/${id}`, data)
    }

    // НАЧИСЛЕНИЯ З/П ВОДИТЕЛЯ СТ:

    wage() {
        return this.client.get('/driver/wage')
    }

    // Получить файл начисления из 1С
    getWage(date) {
        return this.client.get(`/driver/wage/${date}`)
    }

    // НОРМАТИВНЫЕ ДОКУМЕНТЫ:

    regulations(params) {
        return this.client.get('/regulations', params)
    }

    getRegulation(id) {
        return this.client.get(`/regulations/${id}`)
    }

    regulationDetails(id, ver) {
        return this.client.html().get(`/regulations/${id}/details/${ver}`)
    }

    regulationConfirm(id, ver) {
        return this.client.encode().post(`/regulations/${id}/confirm/${ver}`)
    }

    // АКТЫ ОСМОТРА ТС:

    getAcceptanceCerts(params) {
        return this.client.get('/certs/acceptance', params)
    }

    acceptanceCert(id) {
        return this.client.get(`/certs/acceptance/${id}`)
    }

    saveAcceptanceCert(id, data) {
        return this.client.patch(`/certs/acceptance/${id}`, data)
    }

    acceptanceCertCode(id, certVersion) {
        return this.client.get(`/certs/acceptance/${id}/getConfirmationCode/${certVersion}`)
    }

    acceptanceCertConfirm(id, certVersion, confirmationCode) {
        return this.client.encode().post(`/certs/acceptance/${id}/confirm`, {certVersion, confirmationCode})
    }

    // ПРОЧЕЕ:

    // API 2.5+ / Данные с датчиков уровня топлива для водителей СТ
    sensorsData() {
        return this.client.get('/sensorsData')
    }

    // Контакты Предприятия
    contacts() {
        return this.client.get('/enterprise/contacts')
    }

}
