var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('h2',{staticClass:"section-page-title tx-c pl-0 mb-40"},[_vm._v("Редактирование профиля перевозчика")]),(_vm.form)?_c('DataForm',{key:_vm.load,attrs:{"model":_vm.form,"required":"","form":{
            bind: {
                buttons: ['Сохранить изменения'],
                buttonCancel: true,
                onCancel: function () { return _vm.$redirect('/'); },
                wide: true,
                center: true,
            },
            on: {submit: _vm.submit}
        }},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }