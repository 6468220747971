export default {
    model: {
        startRoute: {
            dateTime: null,
            city: {
                id: null
            }
        },
        endRoute: {
            dateTime: null,
            city: {
                id: null
            }
        },
        // ПРОБЕГ
        mileage: {
            odometerOnStart: null,
            odometerOnFinish: null,
            mileageLoaded: null,
            mileageEmpty: null,
            vehicleHoursOnStart: null,
            vehicleHoursOnFinish: null,
            autonomousHeater: [
                {
                    "id": null,
                    "startDate": null,
                    "endDate": null,
                    "coordinates": {
                        "lat": 0,
                        "lon": 0
                    },
                    "city": {
                        "name": "",
                        "id": null
                    }
                }
            ],
            engineWarming: [
                {
                    "city": {
                        "id": null,
                        "name": null
                    },
                    "coordinates": {
                        "lat": 0,
                        "lon": 0
                    },
                    "endDate": null,
                    "id": null,
                    "startDate": null
                }
            ],
        },
        // ГСМ
        fuel: {
            balanceFuelTruck: null,
            balanceGasTruck: null,
            balanceFuelRefrigerator: null,
            initialFuelTruck: null,
            initialGasTruck: null,
            initialFuelRefrigerator: null,
            refueled: [
                {
                    "byCash": true,
                    "dateTime": "",
                    "fileId": "",
                    "fuelType": "",
                    "id": null,
                    "vehicleType": "",
                    "volume": 0,
                    "gasStation": "",
                    "gasStationAddress": "",
                }
            ]
        },
        // ПРОЧЕЕ
        other: {
            cashInitial: null,
            used: [
                {
                    "amount": 0,
                    "comment": "",
                    "dateTime": "",
                    "fileId": "",
                    "id": null
                }
            ]
        }
    },
    groups: [
        {
            name: "Начало рейса",
            label: "start",
            fields: ['startRoute.dateTime', 'startRoute.city.id']
        },
        {
            name: "Конец рейса",
            label: "end",
            fields: ['endRoute.dateTime', 'endRoute.city.id']
        },
        {
            name: "Показания одометра",
            label: "odometer",
            fields: [
                'mileage.odometerOnStart',
                'mileage.odometerOnFinish',
                'mileage.mileageLoaded',
                'mileage.mileageEmpty',
                'totalMileage'
            ]
        },
        {
            name: "Моточасы",
            label: "mileage",
            fields: ['mileage.vehicleHoursOnStart', 'mileage.vehicleHoursOnFinish', 'mileageEarn']
        },
        {
            name: "Автономный отопитель",
            label: "heater",
            fields: ['mileageAutonomousHeater', 'mileageEngineWarming']
        },
        {
            name: "Остаток с прошлого рейса",
            label: "fuelInitial",
            fields: ['fuel.initialFuelTruck', 'fuel.initialGasTruck', 'fuel.initialFuelRefrigerator']
        },
        {
            name: "Заправлено в рейсе",
            label: "refueled",
            fields: ['refueledFuelTruck', 'refueledGasTruck', 'refueledFuelRefrigerator']
        },
        {
            name: "Остаток ГСМ на конец рейса",
            label: "fuelBalance",
            fields: ['fuel.balanceFuelTruck', 'fuel.balanceGasTruck', 'fuel.balanceFuelRefrigerator']
        },
        {
            name: "Наличные ДС",
            label: "cash",
            fields: ['other.cashInitial', 'cashSpent']
        },
    ],
    fields: {
        "startRoute.dateTime": {
            name: "Дата/время",
            type: 'datetime',
            format: 'YYYY-MM-DDTHH:mm:00',
            watch: ({vm}) => {
                vm.updateField('endRoute.dateTime')
            },
        },
        "startRoute.city.id": {
            name: "Город",
            type: 'find',
            watch: ({model, value, field}) => {
                if (!field.bind.options?.length) return
                model.startRoute.city.name = field.bind.options.find(v => v.id === value)?.name
            },
        },
        "endRoute.dateTime": {
            name: "Дата/время",
            type: 'datetime',
            format: 'YYYY-MM-DDTHH:mm:00',
            bind: ({model, vm}) => ({
                rules: [
                    v => {
                        if (vm.$util.date.toUtc(v) <= vm.$util.date.toUtc(model.startRoute.dateTime))
                            return 'Дата окончания рейса не может быть меньше или равна Дате начала рейса.'
                    }
                ],
                required: (v) => _.isEmpty(v) && model.endRoute.city.id > 0,
            }),
        },
        "endRoute.city.id": {
            name: "Город",
            type: 'find',
            required: false,
            watch: ({vm, model, value, field}) => {
                vm.updateField('mileage.vehicleHoursOnFinish')
                vm.updateField('endRoute.dateTime')

                if (!field.bind.options?.length) return
                model.endRoute.city.name = field.bind.options.find(v => v.id === value)?.name
            }
        },
        // Показания одометра
        "mileage.odometerOnStart": {
            name: "При выезде",
            type: "number",
            required: (v) => !_.isNumber(parseFloat(v)) || parseFloat(v) <= 0,
            watch: ({vm}) => {
                vm.updateField('totalMileage')
            },
        },
        "mileage.odometerOnFinish": {
            name: "По возвращении",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    v => {
                        v = parseFloat(v)
                        if (!v && !model.endRoute.city.id) return
                        if (v < model.mileage.odometerOnStart)
                            return 'Укажите большее значение, чем в поле "При выезде"'
                    }
                ],
                required: (v) => !v && model.endRoute.city.id
            }),
            watch: ({vm}) => {
                vm.updateField('totalMileage')
                vm.fieldWatch('mileage.mileageLoaded')
                vm.fieldWatch('mileage.mileageEmpty')
            },
        },
        "mileage.mileageLoaded": {
            name: "Пробег с грузом",
            type: "number",
            required: false,
            bind: ({model, fields}) => ({
                rules: [
                    v => {
                        if (!parseFloat(model.mileage.odometerOnFinish)) return
                        if (parseFloat(v) > parseFloat(fields.totalMileage?.model))
                            return 'Укажите меньшее значение, чем в поле "Общий пробег".'
                    }
                ],
            }),
            watch: ({vm, model}) => {
                if (!parseFloat(model.mileage.odometerOnFinish)) return
                vm.fieldWatch('mileage.mileageEmpty')
            }
        },
        "mileage.mileageEmpty": {
            name: "Пробег без груза",
            type: "number",
            required: false,
            bind: ({model, fields}) => ({
                rules: [
                    v => {
                        if (!parseFloat(model.mileage.odometerOnFinish)) return
                        if (parseFloat(v) > parseFloat(fields.totalMileage?.model))
                            return 'Укажите меньшее значение, чем в поле "Общий пробег".'
                    }
                ],
            }),
            watch: ({model, value}) => {
                if (!parseFloat(model.mileage.odometerOnFinish)) return

                const totalMileage = parseFloat(model.totalMileage)
                const v = parseFloat(value)

                if (totalMileage >= v) return totalMileage - v
            }
        },
        "totalMileage": {
            name: "Общий пробег",
            type: "number",
            disabled: true,
            model: ({model}) => {
                const onStart = parseFloat(model.mileage.odometerOnStart)
                const onFinish = parseFloat(model.mileage.odometerOnFinish)
                return onFinish > 0 && onStart > 0 ? onFinish - onStart : 0
            },
            watch: ({vm, model, value}) => {
                vm.fieldWatch('mileage.mileageLoaded')
                vm.fieldWatch('mileage.mileageEmpty')
                const mileageLoaded = parseFloat(model.mileage.mileageLoaded)
                const mileageEmpty = parseFloat(model.mileage.mileageEmpty)
                return parseFloat(value) || mileageLoaded + mileageEmpty
            }
        },
        // Моточасы
        "mileage.vehicleHoursOnStart": {
            name: "При выезде",
            type: "number",
            bind: ({model}) => ({
                required: (v) => !parseFloat(v) && model.mileage.vehicleHoursOnFinish > 0,
            })
        },
        "mileage.vehicleHoursOnFinish": {
            name: "По возвращении",
            type: "number",
            bind: ({model}) => ({
                required: (v) => !v && model.endRoute.city.id && model.mileage.vehicleHoursOnStart > 0,
                rules: [
                    v => {
                        if (model.endRoute.city.id
                            && model.mileage.vehicleHoursOnStart > 0
                            && parseFloat(v) < parseFloat(model.mileage.vehicleHoursOnStart))
                            return 'Укажите большее значение, чем в поле "При выезде"'
                    }
                ]
            }),
            watch: ({vm}) => vm.updateField('mileageEarn'),
        },
        "mileageEarn": {
            name: "Наработано",
            type: "number",
            disabled: true,
            model: ({model}) => {
                let v = parseFloat(model.mileage.vehicleHoursOnFinish) - parseFloat(model.mileage.vehicleHoursOnStart)
                return v > 0 ? v : 0
            }
        },
        // Автономный отопитель
        "mileageAutonomousHeater": {
            name: "Время работы автономного отопителя, ч.",
            disabled: true,
            model: ({model, vm}) => {
                let msec = 0
                model.mileage.autonomousHeater.forEach(i => {
                    const startDate = vm.$util.date.isValidDate(i.startDate) ? vm.$util.date.toUtc(i.startDate) : 0
                    const endDate = vm.$util.date.isValidDate(i.endDate) ? vm.$util.date.toUtc(i.endDate) : 0
                    startDate && endDate && (msec += endDate - startDate)
                })
                return vm.$util.date.getFullDate(msec, true)
            }
        },
        // Время работы двигателя (пока автомобиль стоял и грелся)
        "mileageEngineWarming": {
            name: "Время работы двигателя, ч.",
            disabled: true,
            model: ({model, vm}) => {
                let msec = 0
                model.other.engineWarming?.forEach(i => {
                    const startDate = vm.$util.date.isValidDate(i.startDate) ? vm.$util.date.toUtc(i.startDate) : 0
                    const endDate = vm.$util.date.isValidDate(i.endDate) ? vm.$util.date.toUtc(i.endDate) : 0
                    startDate && endDate && (msec += endDate - startDate)
                })
                return vm.$util.date.getFullDate(msec, true)
            }
        },
        // Остаток с прошлого рейса
        "fuel.initialFuelTruck": {
            name: "ДТ, л. (тягач)",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    ['max', model.fuel.truckDieselTankVolume],
                    ['min', 0],
                ],
                disabled: model.fuel.truckDieselTankVolume === 0
            }),
            required: (v) => !_.isNumber(parseFloat(v)) || parseFloat(v) <= 0,
        },
        "fuel.initialGasTruck": {
            name: "Газ, м.куб. (тягач)",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    ['max', model.fuel.truckGasTankVolume],
                    ['min', 0],
                ],
                disabled: model.fuel.truckGasTankVolume === 0
            }),
            required: false,
        },
        "fuel.initialFuelRefrigerator": {
            name: "ДТ, л. (рефрижератор)",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    ['max', model.fuel.refrigeratorDieselTankVolume],
                    ['min', 0],
                ],
                disabled: model.fuel.refrigeratorDieselTankVolume === 0
            }),
            required: false,
        },
        // Заправлено в рейсе
        "refueledFuelTruck": {
            name: "ДТ, л. (тягач)",
            type: "number",
            disabled: true,
            model: ({model}) => {
                let count = 0
                model.fuel.refueled.forEach(item =>
                    count += item.fuelType === 'DIESEL' && item.vehicleType === 'TRUCK' ? parseFloat(item.volume) : 0)
                return count
            }
        },
        "refueledGasTruck": {
            name: "Газ, м.куб. (тягач)",
            type: "number",
            disabled: true,
            required: false,
            model: ({model}) => {
                let count = 0
                model.fuel.refueled.forEach(item =>
                    count += item.fuelType === 'GAS' && item.vehicleType === 'TRUCK' ? parseFloat(item.volume) : 0)
                return count
            }
        },
        "refueledFuelRefrigerator": {
            name: "ДТ, л. (рефрижератор)",
            type: "number",
            disabled: true,
            required: false,
            model: ({model}) => {
                let count = 0
                model.fuel.refueled.forEach(item =>
                    count += item.fuelType === 'DIESEL' && item.vehicleType === 'REFRIGERATOR' ? parseFloat(item.volume) : 0)
                return count
            }
        },
        // Остаток ГСМ на конец рейса
        "fuel.balanceFuelTruck": {
            name: "ДТ, л. (тягач)",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    ['max', model.fuel.truckDieselTankVolume],
                    ['min', 0],
                ],
                disabled: model.fuel.truckDieselTankVolume === 0,
                required: (v) => !parseFloat(v) && model.endRoute.city.id,
            })
        },
        "fuel.balanceGasTruck": {
            name: "Газ, м.куб. (тягач)",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    ['max', model.fuel.truckGasTankVolume],
                    ['min', 0],
                ],
                disabled: model.fuel.truckGasTankVolume === 0,
                required: (v) => !parseFloat(v) && model.endRoute.city.id && model.fuel.initialGasTruck > 0,
            })
        },
        "fuel.balanceFuelRefrigerator": {
            name: "ДТ, л. (рефрижератор)",
            type: "number",
            bind: ({model}) => ({
                rules: [
                    ['max', model.fuel.refrigeratorDieselTankVolume],
                    ['min', 0],
                ],
                disabled: model.fuel.refrigeratorDieselTankVolume === 0,
                required: (v) => !parseFloat(v) && model.endRoute.city.id && model.fuel.initialFuelRefrigerator > 0,
            })
        },
        // Наличные ДС
        "other.cashInitial": {
            name: "Остаток с прошлого рейса, руб.",
            type: "number",
            required: false,
        },
        "cashSpent": {
            name: "Израсходовано ДС в рейсе, руб.",
            type: "number",
            disabled: true,
            model: ({model}) => {
                let count = 0
                model.other.used.forEach(item => count += parseFloat(item.amount))
                return count
            }
        },
    }
}
