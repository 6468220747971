var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buttons",class:[
        _vm.inline && 'buttons-inline',
        _vm.align && 'buttons-align',
        _vm.initial && 'buttons-initial' ],style:({
        maxWidth: _vm.width ? (_vm.width + "px") : false,
        marginLeft: _vm.center ? 'auto' : false,
        marginRight: _vm.center ? 'auto' : false,
    })},[_vm._t("default"),_vm._l((_vm.items),function(btn,k){return [(_vm.$$.isPlainObject(btn))?_c('button',_vm._g(_vm._b({key:k},'button',btn.bind,false),btn.on),[_vm._v(" "+_vm._s(btn.text)+" ")]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }