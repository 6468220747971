import toBuffer from 'blob-to-buffer'
import FileSaver from 'file-saver'
import helper from '@/helpers'

export default {
    get({repo, repoMethod, cb, params, useLoader: useLoader = true, fileName}) {

        return helper.$rs
            .config({axios: {responseType: 'blob', headers: {Accept: '*/*'}}})
            .useLoader(Boolean(useLoader))
            .repo(repo)[repoMethod](params)
            .catch(false)
            .finally(({response}) => {

                if (!response || !_.isPlainObject(response?.data)) {
                    helper.$notify().warn(`Не удалось загрузить файл.`)
                }

                const type = response?.data?.type
                const resolve = {
                    url: null,
                    buffer: null,
                    response,
                    fileName: fileName || response?.headers['content-disposition']
                }
                const handle = (url, buffer) => {
                    _.isNil(url) || Object.assign(resolve, {url, buffer})
                    _.isFunction(cb) && cb(resolve)
                }

                try {
                    const blob = new Blob([response.data], {type})
                    type?.includes('image')
                        ? toBuffer(blob, (err, buffer) => err ? handle(null) : handle("", buffer))
                        : handle(window.URL.createObjectURL(blob), "")
                } catch (err) {
                    handle(null)
                    console.error(err.message)
                }
            })
    },
    download(urlObject, name, open = true) {

        if (open === false) return FileSaver.saveAs(urlObject, name)

        const popup = window.open(urlObject)

        if (!popup || popup.closed || typeof popup.closed === 'undefined') helper.$confirm({
            text: [
                'Всплывающие окна заблокированы',
                'Разрешите всплывающие окна в настройках браузера либо скачайте файл.',
                'Скачать файл?'
            ],
            onConfirm: () => FileSaver.saveAs(urlObject, name)
        })
    },
    getAndDownload({repo, repoMethod, params, useLoader, name, open: open = true, errMsg}) {
        return new Promise((res, rej) => {
            this.get({
                repo,
                repoMethod,
                params,
                useLoader,
                cb: ({url, response, fileName}) => {
                    if (url) {
                        this.download(url, name || fileName || _.randKey(), open)
                        res({url, response})
                    } else {
                        helper.$notify().warn(errMsg || 'Не удалось загрузить файл.')
                        rej(response)
                    }
                }
            })
        })
    },
    upload({repo, repoMethod, files, imagesOnly}) {

        const formData = new FormData()

        for (let x = 0; x < files.length; x++) {
            if (imagesOnly && !files[x].type.includes('image'))
                continue
            formData.append('file', files[x])
        }

        return helper.$rs.form().repo(repo)[repoMethod](formData)
    },
}
