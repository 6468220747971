<template>
    <Page>
        <div class="block-center tx-c">
            <h3 class="mt-40">Восстановление пароля</h3>
            <FormCodeConfirm
                request-code-title="Укажите номер телефона для отправки кода подтверждения"
                :request-code="true"
                type="phone"
                :dest="phone"
                @confirmed="recoverPassword"
                @cancel="$redirect('/login')"
            />
        </div>
    </Page>
</template>

<script>

import FormCodeConfirm from './form/FormCodeConfirm.vue'

export default {
    name: "PasswordRecovery",
    components: {FormCodeConfirm},
    props: {
        phone: Number
    },
    methods: {
        recoverPassword(result, params) {
            result && this.$repo('auth').passwordRecovery(params)
                .then(r => {
                    this.$router.replace({name: 'Login', params: {passwordRecoveryToken: r.data.passwordRecoveryToken}})
                })
                .done('Вам отправлен новый пароль.')
                .alert('Не удалось сбросить пароль.')
        }
    }
}

</script>
