<template>
    <Page>
        <FormSemitrailer
            v-if="semitrailer"
            :id="semitrailer"
            :truckName="semitrailerName"
            :toggle="toggle"
            center
            contents
        />
        <div v-show="!semitrailer">
            <FormTransportSearch v-model="search" @search="reload"/>
            <div class="buttons">
                <button class="btn btn-blue btn-small" @click="openForm">
                    Добавить
                </button>
            </div>
            <DataTable
                :key="load"
                :cols="{
                    id: 'ID',
                    model: 'Модель',
                    number: 'Гос.номер',
                    status: 'Статус',
                    vin: 'VIN',
                    year: 'Год выпуска',
                }"
                :fetch="fetchData"
                paginate
                :click="toggle"
                class="mt-20"
            />
            <Popup name="popup-add-semitrailer">
                <FormSemitrailer :toggle="toggle" inflect/>
            </Popup>
        </div>
    </Page>
</template>

<script>

import FormSemitrailer from './form/FormSemitrailer.vue'
import FormTransportSearch from './form/FormTransportSearch.vue'

export default {
    name: "Semitrailers",
    props: {
        id: Number,
    },
    components: {
        FormSemitrailer,
        FormTransportSearch
    },
    data() {
        return {
            load: 0,
            semitrailer: null,
            search: {data: ''},
            semitrailerName: ''
        }
    },
    created() {
        _.isNumber(this.id) && this.$set(this, 'semitrailer', this.id)
    },
    methods: {
        reload() {
            this.load += 1
        },
        toggle(item) {
            if (!item || item === true) {
                this.semitrailer = null
                item === true && this.reload()
                this.$redirect({name: 'Semitrailers'}, true)
                return
            }
            this.semitrailerName = `${item.model} - ${item.number}`
            this.semitrailer = item.id
            this.$redirect({name: 'Semitrailers', query: {id: item.id}})
        },
        fetchData(h, a) {
            this.$repo('transport').semitrailers({search: this.search.data, ...a}).then(({list}) => h(list))
        },
        openForm() {
            this.$popup('popup-add-semitrailer', {
                clickToClose: false,
                onOpen: this.toggle
            })
        },
    }
}

</script>
