<template>
    <li v-bind="getBind('li')">
        <Render :data="template(list, getDepth)"/>
        <ul v-if="(listKey in list)" v-bind="getBind('ul')">
            <Tree
                v-for="(v, k) in getList"
                :key="k"
                :depth="depth + 1"
                :list="v"
                :list-key="listKey"
                :bind="bind"
                :template="template"
            />
        </ul>
    </li>
</template>

<script>

export default {
    name: "Tree",
    props: {
        list: Object,
        template: Function,
        depth: {
            type: Number,
            default: 0,
        },
        listKey: {
            type: String,
            required: false,
            default: 'list',
        },
        bind: {
            type: Object,
            required: false
        }
    },
    computed: {
        getDepth() {
            return this.depth - 1 > 0 ? this.depth - 1 : 0
        },
        getList() {
            const list = this.list[this.listKey]
            return _.isArray(list) && list.length ? list : []
        },
    },
    methods: {
        getBind(tag) {
            const params = this.bind[tag] || null
            return _.isFunction(params) ? params(this.list, this.getDepth) : (params || {})
        }
    }
}

</script>
