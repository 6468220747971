import Vue from 'vue'
import Vuex from 'vuex'
// import { set } from 'vuex-intern'
import { ACCOUNT_STATE_NAME, ACCOUNT_STATE } from './account.state'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modals: {},
        confirms: {},
        dropdowns: {},
        forms: {},
    },
    mutations: {
        registerComponent ( state, { type, name, component } ) {
            !state[type] || (state[type] = Object.assign({}, state[type], { [name]: component }))
        },
        resetComponent ( state, { type, name } ) {
            !state[type] || !state[type][name] || delete state[type][name]
        },
        getComponent ( state, { type, name } ) {
            if (!name && type) return state[type] || null
            return state[type] && state[type][name] ? state[type][name] : null
        },
    },
    actions: {},
    modules: {
        [ACCOUNT_STATE_NAME]: ACCOUNT_STATE,
    }
})
