export default {
    model: {
        description: "",
        speedometerValue: 0,
        "transport": {
            "id": 0,
            "type": null
        }
    },
    assign: {
        driverTransports: ({vm, bind}) => {
            vm.$repo('driver').transports().then(({list}) => {
                list.push({
                    id: 0,
                    type: null,
                    description: 'Подобрано неверно, ТС укажу в описании проблемы'
                })
                bind(list, 'options')
            })
        }
    },
    fields: {
        description: {
            name: 'Описание проблемы',
            type: 'textarea',
            required: true
        },
        speedometerValue: {
            name: 'Показания одометра',
            type: 'number',
            required: v => !v || !_.isNumber(parseFloat(v)),
            format: v => parseFloat(v)
        },
        driverTransports: {
            name: 'Транспортное средство',
            type: 'select',
            labelName: 'description',
            required: true,
            options: [],
            watch: ({value, model, field}) => {
                if (!value || !field.bind?.options?.length) return
                const transport = field.bind.options.find(v => v.id===value)
                transport && Object.assign(model.transport, transport)
            }
        },
    },
}
