import {CLIENT_HEADER, CLIENT_VERSION} from "@/config/app"

export default () => ({

    apiKey: 'OGJtVJp1GC',

    useStubs: false,

    clientConfig: {
        timeout: 60000,
        headers: {
            [CLIENT_HEADER]: CLIENT_VERSION
        }
    },

    driverLocation: (id) => `/topic/locations/${id}`, // подписка на координаты водителя по веб-сокет

    responseMessages: {
        AUTH_FAILED: 'Ошибка авторизации.',
        INVALID_CODE: 'Неверный код подтверждения.',
        SESSION_EXPIRED: 'Рабочая сессия истекла.',
        ACCESS_DENIED: 'Доступ запрещен.',
        INSUFFICIENT_PERMISSIONS: 'Не достаточно прав.',
        MISSING_PARAMETERS: 'Недействительный запрос. Отсутствуют обязательные данные.',
        INVALID_PARAMETERS: 'Недействительный запрос.',
        BAD_CREDENTIALS: 'Информация по запросу не найдена либо введенные данные не верны.',
        OBJECT_NOT_FOUND: 'Информация по запросу не найдена либо введенные данные не верны.',
        INTERNAL_ERROR: 'Внутренняя ошибка сервера.',
        BACKEND_ERROR: 'Внутренняя ошибка сервера.',
        INVALID_OPERATION: 'Внутренняя ошибка сервера.',
    },

    bids: {
        responseMessages: {
            BID_LIMIT_EXCEEDED: ['Превышена маскимальная сумма', 'Наша система автоматически предложит рекомендуемую ставку.'],
            BID_MIN_STEP: ['Ошибка', 'Минимальный шаг ставки не был достигнут.'],
            TENDER_EXPIRED: ['Ошибка', 'Тендер уже завершен.'],
            TENDER_ALREADY_COMPLETED: ['Тендер уже забрал другой пользователь.'],
        }
    },

    files: {
        DEFAULT_UPLOAD_REPO: 'common',
        DEFAULT_UPLOAD_REPO_METHOD: 'fileUpload',
    },

    images: {
        DEFAULT_DOWNLOAD_REPO: 'common',
        DEFAULT_DOWNLOAD_REPO_METHOD: 'getImage'
    },

    codeConfirm: {
        confirm: '/code/confirm',
        send: '/code/send',
        passwordConfirm: '/auth/user/password/confirm',
        resendCode: '/auth/resendCode',
        confirmRegister: '/auth/confirmRegister',
        confirmRequisites: '/requisites/confirm',
        requisitesRequestConfirmation: '/requisites/requestConfirmation',
        requisitesResendCode: '/requisites/resendCode',
    },
})
