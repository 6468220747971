<template>
    <Page class="favorite-routes">
        <h3 class="tx-c clr-ltg-3">
            Здесь Вы можете указать интересующие Вас направления,
            когда в системе появится подходящая заявка, Вы получите уведомление.
        </h3>
        <template v-if="cities && regions && transportTypes && loadingMethods">
            <div class="buttons">
                <button class="btn btn-blue btn-small" @click="setForm(); openForm()">
                    Добавить
                </button>
            </div>
            <DataTable
                class="mt-20"
                :key="load"
                :cols="{
                    enabled: 'Активно',
                    loadingDateFrom: 'Дата погрузки (От)',
                    loadingDateTo: 'Дата погрузки (До)',
                    'loadingCity.name': 'Город загрузки',
                    'loadingRegion.name': 'Регион загрузки',
                    'unloadingCity.name': 'Город выгрузки',
                    'unloadingRegion.name': 'Регион выгрузки',
                    'transportType': 'Тип транспорта',
                    'loadingMethods': 'Способ погрузки',
                }"
                :cells="{
                    loadingDateFrom: v => $util.date.date(v),
                    loadingDateTo: v => $util.date.date(v),
                    transportType: v => [...v].map(i => (i && i.name) || '').join(', '),
                    loadingMethods: v => [...v].map(i => (i && i.name) || '').join(', '),
                    enabled: (v, item) => [
                        {'Toggle': '', on: {change: ({srcEvent}) => toggle(srcEvent, item.id)}, bind: {value: v}}
                    ]
                }"
                :fetch="fetchData"
                @setup="i => this.list = i"
                :click="{exclude: ['enabled'], action: route => {setForm(route.id); openForm()}}"
            />
<!--   TODO: ПОФИКСИТЬ МОДАЛКУ         -->
            <Popup name="popup-form-fav-routes">
                <FormFavoriteRoutes
                    v-bind="{
                        form,
                        cities,
                        regions,
                        transportTypes,
                        loadingMethods,
                    }"
                    :form="form"
                    @success="() => this.load += 1"
                />
            </Popup>
        </template>
    </Page>
</template>

<script>

import FormFavoriteRoutes from './form/FormFavoriteRoutes.vue'

export default {
    name: "FavoriteRoutes",
    data() {
        return {
            load: 0,
            form: null,
            list: null,
            cities: null,
            regions: null,
            transportTypes: null,
            loadingMethods: null,
        }
    },
    components: {
        FormFavoriteRoutes
    },
    created() {
        this.$repo('common').cities().then(({list}) => this.cities = list)
        this.$repo('common').regions().then(({list}) => this.regions = list)
        this.$repo('transport').loadingMethods().then(({list}) => {
            list && (this.loadingMethods = [...[{id: 0, name: 'Все способы погрузки'}], ...list])
        })
        this.$repo('transport').transportTypes().then(({list}) => {
            list && (this.transportTypes = [...[{id: 0, name: 'Все типы транспорта'}], ...list])
        })
    },
    methods: {
        setForm(routeId) {
            this.form = this.list.getItem(i => i.id === routeId)
        },
        openForm() {
            this.$nextTick(() => this.$popup('popup-form-fav-routes', {
                clickToClose: false
            }))
        },
        fetchData(h) {
            this.$repo('preorder').favorites().then(({list}) => h(list))
        },
        toggle(e, id) {
            this.setForm(id)
            this.$repo('preorder').patchFavorite({
                id: _.get(this.form, 'id', null),
                loadingCityId: _.get(this.form, 'loadingCity.id', ''),
                loadingRegionId: _.get(this.form, 'loadingRegion.id', ''),
                unloadingCityId: _.get(this.form, 'unloadingCity.id', ''),
                unloadingRegionId: _.get(this.form, 'unloadingRegion.id', ''),
                transportTypeId: _.get(this.form, 'transportType', []).map(i => i.id),
                loadingMethodsId: _.get(this.form, 'loadingMethods', []).map(i => i.id),
                enabled: e.target.checked
            }).error(() => e.target.checked = !e.target.checked).catch(() => e.target.checked = !e.target.checked)
        }
    }
}

</script>
