<template>
    <Page class="block-contents">
        <FormAcceptanceCert
            v-if="cert"
            ref="form"
            :cert="cert"
            @toggle="cert = null; $redirect({name: 'AcceptCerts'})"
        />
        <DataTable
            v-show="!cert"
            :key="load"
            :cols="cols"
            :cells="{
                dateTime: v => $util.date.dateMsk(v, true),
                condition: v => statuses[v] || ''
            }"
            :fetch="fetch"
            :click="i => getCert(i.id)"
            paginate
        />
    </Page>
</template>

<script>

import FormAcceptanceCert from './form/FormAcceptCert.vue'

export default {
    name: "AcceptCerts",
    props: {
        id: Number
    },
    components: {FormAcceptanceCert},
    data() {
        return {
            load: 0,
            cols: {
                'city.name': 'Город',
                dateTime: 'Дата составления акта',
                number: 'Номер ТС',
                'transmittingUser.name': 'Сдал',
                'receivingUser.name': 'Принял',
                condition: 'Статус',
            },
            cert: null,
            statuses: {
                FILLING: 'Заполнение',
                CONFIRMATION_PENDING: 'Ожидаются подтверждения',
                HAND_OVER_CONFIRMATION: 'Подтвержден сдающим',
                TAKE_CONFIRMATION: 'Подтвержден принимающим',
                CONFIRMED: 'Подтвержден'
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        const changed = this.$refs.form?.isCertChanged(() => next())
        next(!changed)
    },
    created() {
        _.isNumber(this.id) && this.getCert(this.id)
    },
    methods: {
        getCert(id) {
            this.$repo('enterprise').acceptanceCert(id).then(({object}) => {
                this.cert = object
                parseInt(this.$route.query.id) === parseInt(object.id) || this.$redirect({name: 'AcceptCerts', query: {id}})
            }).alert('Не удалось загрузить Акт проверки ТС.')
        },
        fetch(h, a) {
            this.$repo('enterprise').getAcceptanceCerts(a).then(({list}) => h(list))
        },
    }
}

</script>
