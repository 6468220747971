import LoadScript from 'load-script'

export default {
    loadScript(uri) {
        return new Promise((res, rej) => LoadScript(uri, (err, script) => err ? rej(err) : res(script)))
    },
    reload({location, delay: delay = 0}) {
        setTimeout(() => window.location.replace(location || '/'), delay)
    },
    findEl(options, wrapper) {
        typeof options === 'string' && (options = {sel: options})
        options = _.merge({
            sel: '',
            text: '',
            index: null,
        }, _.isPlainObject(options) ? options : (options = {}))

        let index = -1
        let resolve

        (wrapper || $('body')).find(options.sel || '*').each((i, el) => {
            if (resolve) return false

            if (options.text) {
                if ($(el).text().toLowerCase().trim() === options.text.toLowerCase()) {
                    index += 1
                    if (_.isNumber(options.index) && options.index !== null)
                        return options.index !== index ? undefined : resolve = $(el)
                    return resolve = $(el)
                }
                return
            }
            index += 1
            if (options.sel && _.isNumber(options.index) && options.index !== null)
                return options.index !== index ? undefined : resolve = $(el)
            return !options.sel ? undefined : resolve = $(el)
        })

        return resolve
    },
    fitToCenter(el) {
        const ww = window.innerWidth
        const wh = window.innerHeight
        const h = el.offsetHeight
        const w = el.offsetWidth
        el.style.left = (ww / 2) - (w / 2) + 'px'
        el.style.top = (wh / 2) - (h / 2) + 'px'
    },
    fitToWindow({el, width, height, resize: resize = false}) {
        const ww = window.innerWidth
        const wh = window.innerHeight
        const offset = el.getBoundingClientRect()
        const h = height || el.offsetHeight
        const w = width || el.offsetWidth

        // console.log({ww, wh})
        // console.log({w, h})
        // console.log('left+w', offset.left + w)
        // console.log('left', offset.left)
        // console.log('top+h', offset.top + h)
        // console.log('top', offset.top)

        if (offset.left + w > ww) el.style.marginLeft = -((w + offset.left) - ww) + 'px'
        if (offset.left < 0) el.style.marginLeft = offset.left * -1 + 'px'
        if (offset.top + h > wh) el.style.marginTop = -((h + offset.top) - wh) + 'px'
        if (offset.top < 0) el.style.marginTop = offset.top * -1 + 'px'

        if (resize) {
            if (w > ww) el.style.width = ww - (el.style.marginLeft + el.style.marginRight) + 'px'
            if (h > wh) el.style.height = wh - (el.style.marginTop + el.style.marginBottom) + 'px'
        }
        return el
    },
}
