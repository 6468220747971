<template>
    <div>
        <DataForm
            :key="view"
            v-if="form.model"
            v-model="form.model"
            :model="form"
            required
            :view="view"
            :form="{
                bind: {
                    ...$attrs,
                    buttons: [
                        view ? {text: 'Редактировать', on: {click: () => view = false}} : 'Сохранить',
                        semitrailerId ? {text: 'Удалить', bind: {class: 'btn btn-red'}, on: {click: remove}} : null
                    ]
                },
                on: {submit},
            }"
        />
        <BackToggle v-if="form.model" @toggle="toggle()" />
    </div>
</template>

<script>

export default {
    name: "FormSemitrailer",
    props: {
        id: Number,
        semitrailerName: String,
        toggle: Function
    },
    data() {
        return {
            form: {},
            semitrailerId: _.isNumber(this.id) ? this.id : null,
            view: true
        }
    },
    created() {
        this.semitrailerId ? this.getSemitrailer() : this.form = this.$apiModel.semitrailer
        this.semitrailerId || (this.view = false)
    },
    methods: {
        getName() {
            if (this.form?.model?.model) return ` "${this.form.model.model}-${this.form.model.number}" `
            return this.semitrailerName ? ` "${this.semitrailerName}" ` : ''
        },
        getSemitrailer() {
            return this.$repo('transport').getSemitrailer(this.semitrailerId).then(({object}) => {
                this.$set(this, 'form', {
                    ...this.$apiModel.semitrailer,
                    ...{model: object}
                })
            }).alert(`Не удалось загрузить полуприцеп${this.getName()}.`)
        },
        remove() {
            this.$confirm({
                text: 'Вы действительно хотите удалить информацию о полуприцепе?',
                onConfirm: () => {
                    this.$repo('transport').removeSemitrailer(this.semitrailerId)
                        .then(() => this.toggle(true))
                        .alert(`Не удалось удалить полуприцеп.`)
                        .done(`Полуприцеп ${this.getName()} удален из списка.`)
                }
            })
        },
        submit() {
            this.view || this.$repo('transport')[this.semitrailerId ? 'patchSemitrailer' : 'postSemitrailer'](this.form.model)
                .then(() => this.toggle(true))
                .done(this.semitrailerId ? `Полуприцеп ${this.getName()} изменен.` : 'Полуприцеп добавлен.')
                .alert(`Не удалось добавить/редактировать информацию о полуприцепе.`)
        }
    }
}

</script>
