<template>
    <FormDriverRegister v-if="driverReqister" />
    <FormProfileDriver v-else-if="role === 'driver'" />
    <FormProfileCarrier v-else-if="role === 'carrier'" />
</template>

<script>
import FormProfileDriver from "@pages/profile/form/FormProfileDriver"
import FormProfileCarrier from "@pages/profile/form/FormProfileCarrier"
import FormDriverRegister from "@pages/profile/form/FormDriverRegister"

export default {
    name: "Profile",
    props:{
        role: String,
        driverReqister: Boolean
    },
    components: {FormProfileDriver, FormProfileCarrier, FormDriverRegister},
    data() {
        return {
            form: null
        }
    }
}
</script>
