import store from '@/store'
import {ACCOUNT_STATE_NAME, ACCESS} from '@/store/account.state'

export default {

    dispatch(action, data) {
        return store.dispatch(`${ACCOUNT_STATE_NAME}/${action}`, data)
    },

    get(name) {
        return store.getters[`${ACCOUNT_STATE_NAME}/${name}`]
    },

    set(name, data) {
        return store.commit(`${ACCOUNT_STATE_NAME}/${name}`, data)
    },

    get access () {
        return ACCESS
    },

    get withTax () {
        const formOfPayment = _.get(this.get('profile'), 'companyDetails.formOfPayment')
        return !_.isNil(formOfPayment) ? Boolean(formOfPayment.match(/с ндс/i)) : null
    },

    fetchAccount() {
        return store.$repo('account').fetchAccount().then(({object}) => {
            object && this.set('user', object)
        }).alert('Не удалось получить данные Вашего аккаунта.')
    },

    fetchProfile(role) {
        role = role || ACCESS.getRole()
        if (!role) return null
        return store.$repo('account')[role.toLowerCase()]().then(({object}) => {
            object && this.set('profile', object)
        }).alert('Не удалось получить данные Вашего профиля.')
    },

    isAuthorized() {
        return this.isLogged() && !this.get('userEmpty')
    },

    isAuthExpired() {
        if (store.$service.store.getExpire('auth') === 0) {
            this.isLogged() && store.$service.store.setExpire('auth')
            return false
        }
        return store.$service.store.isExpired('auth')
    },

    isLogged() {
        return this.getSession() !== null
    },

    login({phone, password}) {
        return this.$repo('auth').login({phone, password}).then(r => {
            this.setToken(r.data.token)
        })
    },

    logout() {
        this.dispatch('destroy')
        store.$service.store.clear()
    },

    setToken(val) {
        store.$service.store.set('auth_token', val)
    },

    getSession() {
        return store.$service.store.get('auth_session')
    },

    setSession(val) {
        return store.$service.store.set('auth_session', val)
    },

    checkSession() {
        return store.$repo('auth').checkSession()
    },

    refreshSession({phone, refreshTokenUID}) {
        return store.$rs.bg().repo('auth').refreshSession({phone, refreshTokenUID}).catch()
    },

    setRefreshTokenId(val) {
        store.$service.store.set('auth_refresh_token_uid', val)
    },

    getRefreshTokenId() {
        return store.$service.store.get('auth_refresh_token_uid')
    },

    fullName() {
        return store.$filter.fullName(this.get('user'))
    },

    shortName() {
        return store.$filter.shortName(this.get('user'))
    },

    roleName(name) {
        return store.$app().roles[_.toUpper(name)]
    },

    hasRule(name, cb) {
        return store.$repo('auth').checkPermission(name)
            .then(r => r.data.allowed === true)
            .then(allowed => { cb && cb(allowed) })
            .alert('Доступ ограничен. Недостаточно прав.')
    },

}
