<template>
    <div class="block-center block-contents">
        <h2 class="section-page-title tx-c pl-0">{{ this.data | fullName }}</h2>
        <h4 class="tx-c">
            {{ data.phone | mask('phone') }}
        </h4>

        <div v-if="data.linkToCarrierRequired && canAddDispatcher">
            <div class="flex flex-center">
                <div class="user-link-icon" style="width: 70px; height: 70px"></div>
            </div>
            <div class="flex flex-center flex-row-20">
                <div>
                    <h3 class="tx-up">
                        <a href="#" @click="$emit('add-user')" class="link">привязать</a>
                    </h3>
                </div>
                <div>
                    <h3 class="tx-up">
                        <a href="#" @click="$emit('unlink-user')" class="link">отклонить</a>
                    </h3>
                </div>
            </div>
        </div>

        <buttons :width="250" initial center class="block-contents">
            <template v-if="!data.linkToCarrierRequired">
                <button v-if="canChangePermissions" class="btn btn-blue" @click="$emit('permissions')">
                    Управление разрешениями
                </button>
                <button v-if="canDelete" class="btn btn-red" @click="$emit('remove')">
                    Удалить диспетчера
                </button>
            </template>
            <BackToggle :flag="true" @toggle="$emit('toggle')" initial/>
        </buttons>
    </div>
</template>

<script>

export default {
    name: "FormStaffDispatcher",
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        }
    },
    data() {
        return {
            canChangePermissions: false,
            canAddDispatcher: false,
            canDeclineDispatcher: false,
            canDelete: false,
        }
    },
    created() {
        this.data.linkToCarrierRequired ? this.$service.account.hasRule('CAN_ADD_DISPATCHER', res => {
            this.canAddDispatcher = res
            this.canDeclineDispatcher = res === true
        }) : this.$service.account.hasRule('CAN_CHANGE_DISPATCHER_PERMISSIONS', res => this.canChangePermissions = res)
        this.$service.account.hasRule('CAN_DELETE_DISPATCHER', res => this.canDelete = res)
    }
}

</script>
