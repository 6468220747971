<template>
    <buttons v-if="this.flag && (this.flag === true || $route.query[this.flag])" v-bind="{...$attrs}">
        <button class="btn btn-white" @click="$emit('toggle')">{{text}}</button>
    </buttons>
</template>

<script>
export default {
    name: "BackToggle",
    props: {
        flag: {
            type: [String, Boolean],
            default: 'id'
        },
        text: {
            type: String,
            default: 'Вернуться к списку'
        }
    }
}
</script>
