<template>
    <select class="form-select" v-model="val" @change="input">
        <option v-for="(option, k) in options" :key="k" :value="getLabel(option, k)">
            {{ getLabelName(option) }}
        </option>
    </select>
</template>

<script>
export default {
    name: "FormSelect",
    props: {
        index: Number,
        value: [Number, String],
        rules: Array,
        options: [Array, Object],
        label: {
            type: [String, Function],
            default: 'id',
        },
        labelName: {
            type: [String, Function],
            default: 'name',
        },
        objectsArray: Boolean,
        watch: Function,
        format: Function,
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    computed: {
        isArrayOfObjects() {
            return Boolean(_.isArrayOfObjects(this.options) || this.objectsArray)
        },
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    data() {
        return {
            val: this.value,
            validator: null,
        }
    },
    created() {
        let rules = this.rules || []
        this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')
        this.required === false && (rules = rules.filter(r => r !== 'required'))
        rules.length && (this.validator = new this.$service.validation({rules, name: this.$attrs.name}))
        this.isDisabled || (this.validator && this.validator.validate(this.val))
    },
    methods: {
        input() {
            let val = this.val

            if (this.format) {
                val = this.format(val)
            }
            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(val))
            }
            if (this.watch) {
                const value = this.watch(val, this)
                value !== undefined && (val = value)
            }
            this.$emit('input', val)
        },
        getAttributes() {
            return this.$attrs || {}
        },
        reset() {
            this.val = ''
            this.input()
        },
        getValue() {
            return this.val
        },
        getLabel(option, key) {
            return _.isFunction(this.label)
                ? this.label(option, this)
                : this.isArrayOfObjects ? _.get(option, this.label) : (_.isPlainObject(this.options) ? key : option)
        },
        getLabelName(option) {
            return _.isFunction(this.labelName)
                ? this.labelName(option, this)
                : this.isArrayOfObjects ? _.get(option, this.labelName) : option
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
