import Vue from 'vue'
import {hyphenateSync} from 'hyphen/ru'
import common from '@/utils/common'
import { mask } from 'maska'
import helper from '@/helpers'

const filters = {

    cutHtml(string) {
        return string?.toString().replace(/<[^>]*>?/gm, '')
    },

    cutString(string, limit) {
        const words = string?.toString().trim() || ''
        return (limit && words.length > limit) ? words.substr(0, limit) + '...' : words
    },

    removeMultiSpaces(string) {
        return string?.toString().replace(/ +(?= )/g, '')
    },

    cutLineBreaks(str) {
        str = str?.replace(/[\r\n]+/g, " ") || ''
        return str.replace(/\s\s+/g, ' ')
    },

    truncate(string, limit = 20) {
        const words = string?.toString().trim().split(' ') || []
        return words.length - 1 > limit ? words.slice(0, limit).join(' ') + '...' : string
    },

    hyphen(text) {
        return text?.split(' ').map(word => hyphenateSync(word)).join(' ')
    },

    get(obj, path, def) {
        return common.get(obj, path, def === undefined ? '' : def)
    },

    trim(str, characters, flags) {
        return common.trim(str, characters, flags)
    },

    mask(value, opt) {
        opt = _.isString(opt) && helper.$app().mask[opt] ? helper.$app().mask[opt] : opt
        return mask(value?.toString(), opt)
    },

    fullName(obj) {
        return _.isPlainObject(obj) ? `${obj.surname} ${obj.name} ${obj.patronymic}` : ''
    },

    shortName(obj) {
        return _.isPlainObject(obj) ? `${obj.surname} ${obj.name?.[0].toUpperCase()}. ${obj.patronymic?.[0].toUpperCase()}.` : ''
    },

    date(date, format = 'YYYY.MM.DD') {
        return helper.$util.date.date(date, format)
    },

    nl2br(text) {
        return text?.toString().replace(/[\r\n]+/g, '</br>')
    }

}

Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

export default filters
