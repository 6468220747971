export default {
    firebase: {
        apiKey: "AIzaSyDYwqVXcXsCUeUjcbg9JOaj-tyE3apyxMg",
        authDomain: "project-id.firebaseapp.com",
        databaseURL: "https://fbase-rosprom-ttonline.firebaseio.com",
        projectId: "fbase-rosprom-ttonline",
        storageBucket: "fbase-rosprom-ttonline.appspot.com",
        messagingSenderId: "914340119418",
    },
    names: {
        LINK_CODE_APPROVED: "Код подтвержден",
        USER_LINKED: "Пользователь привязан",
        CARRIER_STATUS_CHANGED: "Статусы перевозчика",
        DRIVER_STATUS_CHANGED: "Статусы водителя",
        TRUCK_STATUS_CHANGED: "Статусы грузовиков",
        SEMITRAILER_STATUS_CHANGED: "Статусы полуприцепов",
        TENDER_STATUS_CHANGED: "Статусы тендеров",
        RFT_ASSIGNED: "Назначен заказ",
        OUTBID: "Изменение ставки"
    },
    exclude: ["TENDER_STATUS_CHANGED", "USER_LINKED"],
    dispatcherExclude: ["CARRIER_STATUS_CHANGED", "TRUCK_STATUS_CHANGED", "SEMITRAILER_STATUS_CHANGED"],
}
