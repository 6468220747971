<template>
    <div>
        <h2 class="section-page-title tx-c">Ваш перевозчик</h2>
        <h2 class="section-page-title tx-c clr-blue">&laquo;{{carrier.companyName}}&raquo;</h2>
        <Buttons initial>
            <button class="btn btn-blue" @click="$emit('accept')">
                Принять приглашение от перевозчика
            </button>
            <button class="btn btn-red" @click="$emit('decline')">
                Отклонить
            </button>
        </Buttons>
    </div>
</template>

<script>
    export default {
        name: "FormAcceptCarrier",
        props: {carrier: Object}
    }
</script>
