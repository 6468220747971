import helper from '@/helpers'

const date = helper.$util.date

export default {
    model: {
        id: null,
        startDate: null,
        endDate: null,
        city: {id: null, name: null},
        coordinates: {
            lat: 0,
            lon: 0,
        },
    },
    periodsList: [],
    fields: {
        'startDate': {
            name: 'Время включения',
            type: 'datetime',
            current: true,
            bind: ({meta, model}) => {
                return {
                    rules: [
                        v => {
                            if (date.toUtc(v) > date.toUtc(date.curDate()))
                                return 'Время включения не может быть больше чем текущая дата.'
                            return meta.checkPeriod({model, index: meta.index, periodsList: meta.periodsList})
                        }
                    ],
                }
            },
            format: 'YYYY-MM-DDTHH:mm:00',
            watch: ({vm, field, value}) => {
                vm.updateField('endDate')
                vm.updateField('dateEarn')
                return value === date.dateFormat(field.initial, 'YYYY-MM-DD HH:mm:ss')
                    ? field.initial
                    : date.dateFormat(value, 'YYYY-MM-DDTHH:mm:00')
            },
        },
        'endDate': {
            name: 'Время выключения',
            type: 'datetime',
            required: false,
            bind: ({meta, model}) => {
                return {
                    rules: [
                        v => {
                            const startDate = date.toUtc(model.startDate)
                            const valid = date.isValidDate(v) && date.isValidDate(model.startDate)
                            const val = date.toUtc(v)

                            if (date.isValidDate(v) && val > date.toUtc(date.curDate()))
                                return 'Время выключения не может быть больше чем текущая дата.'
                            if (valid && val < startDate)
                                return 'Время выключения не может быть меньше чем Время включения.'
                            if (valid && val > startDate + (3600 * 24 * 10 * 1000))
                                return 'Вы указали слишком большой период.'
                            if (date.isValidDate(v))
                                return meta.checkPeriod({model, index: meta.index, periodsList: meta.periodsList})
                        }
                    ],
                }
            },
            format: 'YYYY-MM-DDTHH:mm:00',
            watch: ({vm, field, value}) => {
                vm.updateField('dateEarn')
                return value === date.dateFormat(field.initial, 'YYYY-MM-DD HH:mm:ss')
                    ? field.initial
                    : date.dateFormat(value, 'YYYY-MM-DDTHH:mm:00')
            }
        },
        'dateEarn': {
            name: 'Наработка, ч.',
            disabled: true,
            model: ({model}) => {
                const startDate = model.startDate
                const endDate = model.endDate

                if (date.isValidDate(startDate) && date.isValidDate(endDate)) {
                    const earn = date.toUtc(endDate) - date.toUtc(startDate)
                    return earn < 1000 ? "0ч." : date.getFullDate(earn, true)
                }
                return "0ч."
            }
        },
        'city.id': {
            name: 'Город',
            type: 'find',
            bind: ({model}) => ({
                required: v => {
                    if (!v && !model.coordinates.lat && !model.coordinates.lon)
                        return 'Укажите Город либо Координаты местоположения.'
                }
            }),
            watch: ({model, value, field}) => {
                if (!field.bind.options?.length) return
                model.city.name = field.bind.options.find(v => v.id === value)?.name
            },
        },
        'coordinates.lat': {
            name: 'Координаты местоположения: Широта',
            type: 'number',
            wrap: {
                if: ({model}) => !model.city.id
            },
            bind: ({model}) => ({
                required: v => {
                    if (!v && !model.city.id) return 'Обязательно, если не заполнен город.'
                }
            }),
            format: v => parseFloat(v),
            watch: ({vm}) => {
                vm.updateField('city.id')
            },
        },
        'coordinates.lon': {
            name: 'Координаты местоположения: Долгота',
            type: 'number',
            wrap: {
                if: ({model}) => !model.city.id
            },
            bind: ({model}) => ({
                required: v => {
                    if (!v && !model.city.id) return 'Обязательно, если не заполнен город.'
                }
            }),
            format: v => parseFloat(v),
            watch: ({vm}) => {
                vm.updateField('city.id')
            },
        },
    },
    checkPeriod({model, index, periodsList}) {

        if (!date.isMatchDate(model.startDate)) return false

        const msg = 'Такой период уже имеется в списке, укажите другой период.'
        const dataStart = date.toUtc(model.startDate)
        const dataEnd = date.isMatchDate(model.endDate) ? date.toUtc(model.endDate) : null

        let err = false

        periodsList.some((item, i) => {

            if (!item || i === index) return false

            const startDate = date.toUtc(item.startDate)
            const endDate = item.endDate ? date.toUtc(item.endDate) : null

            if (!endDate && !dataEnd) return err = dataStart === startDate ? msg : false

            if (endDate && dataEnd) {
                return err = (dataStart >= startDate && dataEnd <= endDate)
                || (dataStart <= endDate && dataEnd >= endDate)
                || (dataStart <= startDate && dataEnd >= startDate) ? msg : false
            }

            if (!endDate && dataEnd)
                return err = dataStart <= startDate && dataEnd >= startDate ? msg : false

            if (endDate && !dataEnd)
                return err = startDate <= dataStart && endDate >= dataStart ? msg : false
        })

        return err
    }
}
